import React, { useState } from 'react'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Box, Button, Dialog } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import downloadExcel from '../../../../hooks/downloadExcel';
import apiService from '../../../../api/apiService';
import Viewmarks from './Viewmarks';
import Viewjuryscore from './Viewjuryscore';


const HomeSolutionlist = ({solutionListValue,handleSolutionDetails,totalPage,page,handleSolutionChange,name}) => {
    let role = localStorage.getItem('role')

    const exportExcel = (download) => {
        // setLoading(true);
        // apiService('export/users?roleid=3', '', 'download').then((res) => {
        //     setLoading(false);
        //     downloadExcel(res.data,'jury_')
        // })
        // var req = {
        //     "download": 'Yes',
        //     "roleId": 3,
        //     "active": 1
        // }
        let challengeCategoryVal
        if(name === "corporate"){
          challengeCategoryVal = 0
        }else if(name === "government"){
          challengeCategoryVal = 1
        }else if(name === "msme"){
          challengeCategoryVal = 2
        }else if(name === "enablers"){
          challengeCategoryVal = 3
        }
        var req = {
            "download": 'yes',
            "userId": localStorage.getItem('userId'),
            "listSize": 12,
            "pageNumber": page,
           "challengeCategory": challengeCategoryVal,
           "challengeProvider" : true
        }

        apiService('solution/approval/list', req, 'downloadpost').then((res) => {
            // setLoading(false);
            if (res) {
                if (res.data) {
                    downloadExcel(res.data, 'solutionlist_')
                }
            }
        })
    }
    const [solutionId, setSolutionId] = useState(0)
    const [challengeId, setChallengeId] = useState(0)
    // const [challengeStatus, setChallengeStatus] = useState(0)
    const [open, setOpen] = useState(false);
    const handleOpen = (solutionid,challengeid) => {
        setOpen(true);
        setSolutionId(solutionid)
        setChallengeId(challengeid)
        debugger
        // setChallengeStatus(status)
    }
    const handleClose = () => {
        setOpen(false);
        // userListItem()
    }
    return (
        <div>
            <div className='export-btn'>
           {(solutionListValue.length > 0  && role !== 'Solution Provider')? (
             <LoadingButton 
             // loading={loading}
             // loadingPosition="start"
             startIcon={<CloudDownloadIcon />}
             variant="outlined"
             // onClick={exportExcel}
             onClick={() => exportExcel()}
         >
             <span>EXPORT EXCEL</span>
         </LoadingButton>
           ):(<></>)}
           </div>
            <div>
                {
                    solutionListValue.length > 0 ? (
                        <div className="row mt-3">
                            <div className='col-12'>
                                <p className='showPage'>Showing Page <span>{page}</span> of <span>{totalPage}</span></p>
                            </div>
                            {
                                solutionListValue.map((list, index) => (
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                        {
                                            role === 'Challenge Provider' || role === "MSME's" || role === "Other Enablers" ? (
                                                <div className='challege_status_div'>
                                                    <div>
                                                        <h6>{list.challengeTitle}</h6>
                                                    </div>
                                                    <div className='status_details'>
                                                        {
                                                            list.participant.map((res, index) => (
                                                                <p><span className='c-gray'> Participant Name {index + 1} :</span> <span className='c-blue font-lg f-weight-600'>{res.participantName}</span></p>
                                                            ))
                                                        }
                                                        <p><span className='c-gray'>Email :</span> <span className='c-blue font-lg f-weight-600'>{list.email}</span></p>
                                                        <p><span className='c-gray'>Shortlisted :</span> <span className='c-green font-lg f-weight-600'>{list.shortlisted === 1 && <>Yes</>}</span>
                                                            <span className='c-origin font-lg f-weight-600'>{list.shortlisted === 0 && <>Pending for Review</>}</span>
                                                            <span className='c-red font-lg f-weight-600'>{list.shortlisted === 2 && <>No</>}</span>
                                                        </p>
                                                        {/* {
                                                                list.shortlisted === 2 && (<p ><span className='c-gray'>Remarks :</span>  <span >{list.remarks}</span></p>)
                                                            } */}

                                                    </div>
                                                    <div className='button_div text-center px-3'>
                                                        <Button className='view_live_btn' onClick={() => { handleSolutionDetails(list.id) }}>View Details</Button>
                                                      <div>
                                                        {
                                                            
                                                    (role === 'Challenge Provider' || role === "MSME's" || role === "Other Enablers") &&  (
                                                        <Button className='view_download_btn ml-2 ' onClick={() => handleOpen(list.id,list.challengeId)}>View Score</Button>
                                                    )
                                                }
                                                </div>
                                                    </div>
                                                    {/* <div className='button_div text-center px-3'>
                                                            {
                                                                list.shortlisted === 0 && (
                                                                    <>
                                                                        <LoadingButton
                                                                            loadingPosition="start"
                                                                            onClick={() => approvalApi(list.id, list.challengeId)}
                                                                            loading={loading}
                                                                            className="green_btn"
                                                                        >
                                                                            <span>Approve</span>
                                                                        </LoadingButton>
                                                                        <Button className='red_btn' onClick={() => handleClickOpen(list.id, list.challengeId)}>Reject</Button>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                (list.shortlisted === 1 || list.shortlisted === 2) && (
                                                                    <p className='text-center'>
                                                                        <span className='c-green font-lg f-weight-600'>{list.shortlisted === 1 && <>Approved</>}</span>
                                                                        <span className='c-red font-lg f-weight-600'>{list.shortlisted === 2 && <>Rejected</>}</span>
                                                                    </p>
                                                                )
                                                            } 
                                                        </div>*/}

                                                </div>
                                            ) : (
                                                <div className='challege_status_div'>
                                                    <div>
                                                        <h6>{list.challengeTitle}</h6>
                                                    </div>
                                                    <div className='status_details'>
                                                        {
                                                            list.participant.map((res, index) => (
                                                                <p><span className='c-gray'> Participant Name {index + 1} :</span> <span className='c-blue font-lg f-weight-600'>{res.participantName}</span></p>
                                                            ))
                                                        }
                                                        <p><span className='c-gray'>Project Management Team :</span>  <span className='c-blue font-lg f-weight-600'>{list.pmtApproval === 0 && <>Pending for Approval</>}</span>
                                                            <span className='c-origin font-lg f-weight-600'>{list.pmtApproval === 1 && <>On Hold</>}</span>
                                                            <span className='c-green font-lg f-weight-600'>{list.pmtApproval === 2 && <>Approved</>}</span>
                                                            <span className='c-red font-lg f-weight-600'>{list.pmtApproval === 3 && <>Rejected</>}</span></p>
                                                        {
                                                            list.pmtApproval === 3 && (<p ><span className='c-gray'>PMT Remarks :</span>  <span >{list.pmtRemarks}</span></p>)
                                                        }
                                                        <p><span className='c-gray'>Shortlisted :</span> <span className='c-green font-lg f-weight-600'>{list.shortlisted === 1 && <>Yes</>}</span>
                                                            <span className='c-origin font-lg f-weight-600'>{list.shortlisted === 0 && <>Pending for Review</>}</span>
                                                            <span className='c-red font-lg f-weight-600'>{list.shortlisted === 2 && <>No</>}</span>
                                                        </p>
                                                        {
                                                            list.shortlisted === 2 && (<p ><span className='c-gray'>Remarks :</span>  <span >{list.remarks}</span></p>)
                                                        }

                                                    </div>
                                                    <div className='button_div text-center p-3'>
                                                        <Button className='view_live_btn' onClick={() => { handleSolutionDetails(list.id) }}>View Details</Button>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                ))
                            }
                            {
                                totalPage > 1 && (
                                    <div className='col-12'>
                                        <Stack spacing={2}>
                                            <Pagination count={totalPage} page={page} onChange={handleSolutionChange} />
                                        </Stack>
                                    </div>
                                )
                            }

                        </div>
                    ) : (
                        <div className='no_data_div'>
                            <img src='/images/blue-logo.png' alt='' className='img-fluid mb-4' />
                            <h5 className='no_data_h5'>Applications Not Available</h5>
                        </div>
                    )
                }
            </div>
            <Dialog
                maxWidth={`lg`}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="form_tag p-4">
                    <Viewjuryscore handleClose={handleClose} solutionId={solutionId} challengeId={challengeId} />
                </Box>
            </Dialog>
        </div>
    )
}

export default HomeSolutionlist