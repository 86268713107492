import React from 'react'
import { Link } from 'react-router-dom';

const Smarttext = ({ text, length }) => {
    
    const [showLess, setShowLess] = React.useState(true);

    if (text.length < length) {        
        return <p dangerouslySetInnerHTML={{__html:text}}></p>;
    }
    return (
        <div>
            <p dangerouslySetInnerHTML={{
                    __html: showLess ? `${text.slice(0, length)}...` : text,
                }}
            ></p>
            <Link
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => setShowLess(!showLess)}
            >
                &nbsp;View {showLess ? "More" : "Less"}
            </Link>
        </div>
    )
}

export default Smarttext