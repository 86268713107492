import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { ReactNotifications } from 'react-notifications-component';
import {BrowserRouter as Router,  Route, Routes } from 'react-router-dom';
import 'react-notifications-component/dist/theme.css';
import Baseroute from './components/Baseroute';
import DataContext, { DataProvider } from './context/DataContext';
import Scrolltotop from './components/common-pages/Scrolltotop';
function App() {
  return (
    <div className="App">
     
      {/* <header className="navbar-fixed">
        <nav className="navbar navbar-expand-sm bg-light">
          <a href="#" className="logo"><img src="assets/images/blue-logo.png" alt="" className="img-fluid" /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse nav-center" id="navbarSupportedContent">
            <ul className="navbar-nav ">
              <li className="nav-item ">
                <a className="nav-link" href="#" id="myButton1">Home <span className="sr-only">(current)</span></a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" id="myButton2">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" id="myButton3">Contact</a>
              </li>


              <div className="cir1"></div>
              <div className="cir2"></div>
            </ul>

            <form className="form-inline my-2 my-lg-0">
              <div className="circle">
                <span className="menu"></span>
              </div>
            </form>
          </div>
        </nav>
      </header> */}
      <ReactNotifications />
      <DataProvider>
        <Scrolltotop />
        <Baseroute />
      </DataProvider>
    </div>
  );
}

export default App;
