import { Autocomplete, Box, Button, FormHelperText, FormLabel, Grid, Pagination, Stack, Tab, TextField } from '@mui/material'
import { tabsClasses } from '@mui/material/Tabs';
import React, { useContext, useState } from 'react'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Controller, useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Helmet } from 'react-helmet';
import DataContext from '../../../context/DataContext';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import SolutionApplicationList from '../../home-module/live-challenges-page/Solution-page/SolutionApplicationList';
// import Smarttext from '../../common-pages/Smarttext';
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
const SearchinputDiv = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "21ch",
            "&:focus": {
                width: "27ch",
            },
        },
    },
}));

const Adminrequirementlist = ({ name }) => {
    const { navigator, setPageLoading } = useContext(DataContext)
    // const [formData, setFormData] = useState({});
    const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({});
    const [page, setPage] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState(1);
    let roleId = localStorage.getItem('roleId')
    let roleName = localStorage.getItem('role')
    let role = localStorage.getItem('role')
    const [challengeCategory, setChallengeCategory] = useState(1)
    // useDidMountEffect(() => {
    //     reqTitleApi()
    // }, [])
    const [title, setTitle] = useState('')
    useDidMountEffect(() => {
        requirementList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, challengeCategory, title])
    const [requirementListValue, setRequirementListValue] = useState([])
    const requirementList = () => {
        setRequirementListValue([])
        setPageLoading(true)
        let path
        let method
        if (roleName === 'Admin' || roleName === 'GOVT' || roleName === 'Project Management Team') {
            path = 'requirement/list'
            method = 'post'
        } else if (role === 'Solution Provider') {
            path = 'requirement/home/list'
            method = 'post'
        }
        var req = {
            "listSize": 12,
            "pageNumber": page,
            "requirementType": challengeCategory,
            "requirementTitle": title,
        }
        apiService(path, req, method).then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data) {
                    if (res.data.responseModelList) {
                        setRequirementListValue(res.data.responseModelList)
                        setTotalPage(res.data.totalPages)
                    }
                }
            }
        })
    }
    const [tabValue, setTabValue] = React.useState('1');
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleType = (value) => {
        setChallengeCategory(value)
        // requirementList()
    }
    const handleChange = (event, value) => {
        setPage(value);
    };
    const handleViewDetails = (id) => {
        navigator(`/admin/requirement/status/${btoa(id)}`)
    }
    const handleRequirement = () => {
        if (roleId === null) {
            navigator('/sign-in')
            localStorage.setItem('click', 'post')
        } else {
            navigator(`government/post-requirement`)
        }
    }
    const [requestTitleList, setRequestTitleList] = useState([])
    const reqTitleApi = () => {
        apiService(`requirement/title/list?entityType=${''}`, '', 'get').then((result) => {
            if (result) {
                if (result.data) {
                    setRequestTitleList(result.data)
                }
            }
        }).catch((err) => {

        });
    }
    const handleSearch = (searchValue) => {
        setTitle(searchValue);
    };
    const resetFiliter = () => {
        setTitle('')
    }
    return (
        <>
            {/* <Helmet>
                <title>Open Innovation Portal | Admin | Requirement-status </title>
            </Helmet> */}
            <main className='live_challenges_mains'>
                <div className="container-fluid ">
                    <div className='row'>
                        <div className='col-12 p-0'>
                            <div className="live_bgs">
                                {/* <div className="title_filter_dev">
                                    {
                                        (roleName === 'GOVT') && (
                                            <Button startIcon={<AddIcon />} className='yellow-btn-sm px-3 ml-3' onClick={() => handleRequirement()}>Post Your Requirement</Button>
                                        )
                                    }
                                </div> */}
                                <TabContext sx={{ padding: '25px' }} value={tabValue} >
                                    {
                                        roleId && (
                                            <Box sx={{
                                                flexGrow: 1,
                                                bgcolor: 'background.paper',
                                            }}>
                                                <TabList value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                    aria-label="visible arrows tabs example"
                                                    sx={{
                                                        [`& .${tabsClasses.scrollButtons}`]: {
                                                            '&.Mui-disabled': { opacity: 0.3 },
                                                        },
                                                    }}

                                                >
                                                    <Tab className='tab_lable_name' label="Live Requirements" value="1" onClick={() => handleType(1)} />
                                                    <Tab className='tab_lable_name' label="Past Requirements" value="2" onClick={() => handleType(0)} />
                                                    <Tab className='tab_lable_name' label="Applications" value="3" />
                                                </TabList>
                                            </Box>
                                        )
                                    }
                                    <Box>
                                        <TabPanel value="1" sx={{ padding: '15px 0px' }}>
                                            <div className='d-flex w-100'>
                                                <div>
                                                    <SearchinputDiv
                                                        sx={{ display: { xs: "none", sm: "block" } }}
                                                        onChange={(e) => handleSearch(e.target.value)}
                                                    >
                                                        <SearchIconWrapper>
                                                            <SearchIcon />
                                                        </SearchIconWrapper>
                                                        <StyledInputBase
                                                            placeholder="Search by Requirement Title"
                                                            type="search"
                                                            inputProps={{ "aria-label": "Search by Requirement Title" }}
                                                        />
                                                    </SearchinputDiv>
                                                </div>
                                                <form className="ml-auto d-flex align-item-center">
                                                    {/* <div className='col'>
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            sx={{ background: 'white', width: "250px" }}
                                                            options={requestTitleList}
                                                            value={title}
                                                            onChange={(e, selectedOptions) => handleSelectChallenges(selectedOptions)}
                                                            renderInput={(params) => <TextField {...params} label="Requirement Title" placeholder="Filter by Requirement Title" />}
                                                        />
                                                    </div>
                                                    {
                                                        (title !== '') && (
                                                            <div className='col text-center'>
                                                                <Button onClick={resetFiliter} variant="outlined" color="error" className='ml-3'>Reset</Button>
                                                            </div>
                                                        )
                                                    } */}
                                                </form>
                                            </div>

                                            {
                                                requirementListValue.length > 0 ? (
                                                    <div className="row mt-3 ml-2">
                                                        <div className='col-12'>
                                                            <p className='showPage'>Showing Page <span>{page}</span> of <span>{totalPage}</span></p>
                                                        </div>
                                                        {
                                                            requirementListValue.map((list, index) => (
                                                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                    <div className='live_req_list'>
                                                                        <div className='text-center'>
                                                                            <img src={list.requirementImageName ? list.requirementImageName : '/images/no-image-icon.png'} className='img-fluid req_img' alt='' />
                                                                        </div>
                                                                        <div className='mb-3'>
                                                                            <Button className='challenges_pro_btn' onClick={() => { handleViewDetails(list.id) }}>
                                                                                {list.requirementTitle}
                                                                            </Button>
                                                                        </div>
                                                                        <p>
                                                                            <span className='c-gray'>Department :</span>  <span className='c-blue font-md f-weight-600'>{list.departmentName ? list.departmentName : (`-`)}</span>
                                                                        </p>
                                                                        <p>
                                                                            <span className='c-gray'>Ends On </span>  <span className='c-blue font-md f-weight-600'>{list.endDate2 ? list.endDate2 : (`-`)}</span>
                                                                        </p>
                                                                        <p>
                                                                            <span className='c-gray'>Status :</span>  <span className='c-blue font-md f-weight-600'>{list.pmtApproval === 0 && <>Pending for Approval</>}</span>
                                                                            <span className='c-green font-md f-weight-600'>{list.pmtApproval === 1 && <>Approved</>}</span>
                                                                            <span className='c-origin font-md f-weight-600'>{list.pmtApproval === 2 && <>Rejected</>}</span>
                                                                        </p>
                                                                        <div className='d-flex'>
                                                                            <div className='view_div'>
                                                                                <Button className='view_live_btn' onClick={() => { handleViewDetails(list.id) }}> View Details</Button>
                                                                            </div>
                                                                            <div className='ml-auto'>
                                                                                <p className='c-gray mb-0 font-sm'>Last updated</p>
                                                                                <p className='c-blue font-sm'>{list.lastUpdated}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            totalPage > 1 && (
                                                                <div className='col-12'>
                                                                    <Stack spacing={2}>
                                                                        <Pagination count={totalPage} page={page} onChange={handleChange} />
                                                                    </Stack>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className='no_data_div'>
                                                        <img src='/images/blue-logo.png' alt='' className='img-fluid mb-4' />
                                                        <h5 className='no_data_h5'>Live Requirements Not Available</h5>
                                                    </div>
                                                )
                                            }
                                        </TabPanel>
                                        <TabPanel value="2" sx={{ padding: '15px 0px' }}>
                                        <div className='d-flex w-100'>
                                                <div>
                                                    <SearchinputDiv
                                                        sx={{ display: { xs: "none", sm: "block" } }}
                                                        onChange={(e) => handleSearch(e.target.value)}
                                                    >
                                                        <SearchIconWrapper>
                                                            <SearchIcon />
                                                        </SearchIconWrapper>
                                                        <StyledInputBase
                                                            placeholder="Search by Requirement Title"
                                                            type="search"
                                                            inputProps={{ "aria-label": "Search by Requirement Title" }}
                                                        />
                                                    </SearchinputDiv>
                                                </div>                                                
                                            </div>
                                            {
                                                requirementListValue.length > 0 ? (
                                                    <div className="row mt-3 ml-2">
                                                        <div className='col-12'>
                                                            <p className='showPage'>Showing Page <span>{page}</span> of <span>{totalPage}</span></p>
                                                        </div>
                                                        {
                                                            requirementListValue.map((list, index) => (
                                                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                    <div className='live_req_list'>
                                                                        <div className='text-center'>
                                                                            <img src={list.requirementImageName ? list.requirementImageName : '/images/no-image-icon.png'} className='img-fluid req_img' alt='' />
                                                                        </div>
                                                                        <div className='mb-3'>
                                                                            <Button className='challenges_pro_btn' onClick={() => { handleViewDetails(list.id) }}>
                                                                                {list.requirementTitle}
                                                                            </Button>
                                                                        </div>

                                                                        <p>
                                                                            <span className='c-gray'>Ends On </span>  <span className='c-blue font-md f-weight-600'>{list.endDate2 ? list.endDate2 : (`-`)}</span>
                                                                        </p>
                                                                        <div className='button_div text-center p-3'>
                                                                            <p className='text-center'>
                                                                                <span className='c-blue font-lg f-weight-600'>{list.pmtApproval === 0 && <>Pending for Approval</>}</span>
                                                                                <span className='c-green font-lg f-weight-600'>{list.pmtApproval === 1 && <>Approved</>}</span>
                                                                                <span className='c-red font-lg f-weight-600'>{list.pmtApproval === 2 && <>Rejected</>}</span>
                                                                            </p>
                                                                        </div>
                                                                        <div className='button_div text-center p-3'>
                                                                            <Button className='view_live_btn' onClick={() => { handleViewDetails(list.id) }}>View Details</Button>
                                                                        </div>
                                                                        {/* <p>
                                                                            <span className='c-gray'>Status :</span>  <span className='c-blue font-md f-weight-600'>{list.pmtApproval === 0 && <>Pending for Approval</>}</span>
                                                                            <span className='c-green font-md f-weight-600'>{list.pmtApproval === 1 && <>Approved</>}</span>
                                                                            <span className='c-origin font-md f-weight-600'>{list.pmtApproval === 2 && <>Rejected</>}</span>
                                                                        </p>
                                                                        <div className=''>
                                                                            <div className='view_div text-center'>
                                                                                <Button className='view_live_btn' onClick={() => { handleViewDetails(list.id) }}> View Details</Button>
                                                                            </div>
                                                                            <div className='ml-auto'>
                                                                                <p className='c-gray mb-0 font-sm'>Last updated</p>
                                                                                <p className='c-blue font-sm'>{list.lastUpdated}</p>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            totalPage > 1 && (
                                                                <div className='col-12'>
                                                                    <Stack spacing={2}>
                                                                        <Pagination count={totalPage} page={page} onChange={handleChange} />
                                                                    </Stack>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className='no_data_div'>
                                                        <img src='/images/blue-logo.png' alt='' className='img-fluid mb-4' />
                                                        <h5 className='no_data_h5'>Past Requirements Not Available</h5>
                                                    </div>
                                                )
                                            }
                                        </TabPanel>
                                        <TabPanel value="3" sx={{ padding: '15px 0px' }}>                                        
                                            <SolutionApplicationList name={name} />
                                        </TabPanel>
                                    </Box>
                                </TabContext>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Adminrequirementlist
