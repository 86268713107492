import React, { useContext, useState, useRef } from "react";
import DataContext from "../../../context/DataContext";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import notifyService from "../../../api/notifySerivce";
import {
  Autocomplete,
  Button,
  FormHelperText,
  FormLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { useParams } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import DOMPurify from "dompurify";
import $ from "jquery";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import { Helmet } from "react-helmet";
const fileTypes = ["JPG", "JPEG", "PNG"];
const tomorrow = dayjs().add(0, "day");
const Postchallenges = ({ name, htmlContent }) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  const { id } = useParams();
  let userId;
  if (id !== undefined) {
    userId = atob(id);
  }
  const {
    navigator,
    setPageLoading,
    validatePhoneNumber,
    validateEmail,
    VisuallyHiddenInput,
    toolbarOptions,
  } = useContext(DataContext);
  const [formData, setFormData] = useState({
    problemStatement: [
      {
        id: 0,
        challengeId: 0,
        problemStatement: "",
      },
    ],
    benefits: [
      {
        id: 0,
        challengeId: 0,
        benefits: "",
      },
    ],
    eligibility: [
      {
        id: 0,
        challengeId: 0,
        eligibility: "",
      },
    ],
    process: [
      {
        id: 0,
        challengeId: 0,
        process: "",
      },
    ],
    partner: [
      {
        id: 0,
        challengeId: 0,
        partner: "",
      },
    ],
  });
  const {
    reset,
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const [loading, setLoading] = useState(false);
  const [benefitsCount, setBenefitsCount] = React.useState(0);
  const [outcomeCount, setOutcomeCount] = React.useState(0);
  const [introductionCount, setIntroductionCount] = React.useState(0);

  useDidMountEffect(() => {
    const userName = localStorage.getItem("userName");
    if (userName === null) {
      navigator("/sign-in");
    }

    if (userId) {
      getApi();
    } else {
      setValue("id", 0);
      dropdownValueApi();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [partnerLogoURL, setPartnerLogoURL] = useState(
    "/images/product-image.png"
  );
  const roleId = localStorage.getItem("roleId");
  const getApi = () => {
    setPageLoading(true);
    apiService(`challenge/get?id=${userId}`, "", "get").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          var response = res.data;
          response.startDate = dayjs(response.startDate, "YYYY-MM-DD");
          response.endDate = dayjs(response.endDate, "YYYY-MM-DD");
          if (response.challengeImageName) {
            response.challengeImageURL = response.challengeImageName;
          }
          if (response.problemStatement) {
            response.problemStatement.forEach((element) => {
              element.imageURL = element.imageName;
            });
          }
          if (response.partner) {
            response.partner.forEach((element) => {
              element.partnerLogoURL = element.partnerLogoFileName;
            });
          }

          setFormData(res.data);
          dropdownValueApi();

          if (response.technologyArea) {
            setTechnologyAreaValue(response.technologyArea);
          }
          // if (response.partnerLogoFileName) {
          //   response.partnerLogoURL = response.partnerLogoFileName;
          // }
          // if (res.data.partnerLogoFileName) {
          //   setPartnerLogoURL(res.data.partnerLogoFileName)
          // }
          setBenefitsCount(response.benefits);
          setOutcomeCount(response.expectedOutcome);

          if (response.images) {
            response.images.forEach((element) => {
              if (element.file) {
                element.imageURL = `data:image/jpeg;base64,${element.file}`;
              }
            });
          }
          // if (response.startDate) {
          //   tomorrow = dayjs(response.startDate).add(0, 'day');
          // }
        }
      }
    });
  };

  const [technologyAreasDataList, setTechnologyAreasDataList] = useState([]);
  const [sectorDataList, setsectorDataList] = useState([]);
  const dropdownValueApi = () => {
    apiService(`admin/dropdownvalues/get?key=dropdown`, "", "get").then(
      (res) => {
        var val = res.data.dropDownValues;
        setsectorDataList(val.sectors);
        if (userId) {
          var value = getValues("sector");
          if (value !== "Others") {
            const name = val.sectors.filter(
              (item) =>
                item.sector.includes(value) || item.sector.includes(value)
            );
            setTechnologyAreasDataList(name[0].technologyAreas);
          }
        }
      }
    );
  };
  const {
    fields: problemFields,
    append: problemAppend,
    remove: problemRemove,
  } = useFieldArray({
    control,
    name: "problemStatement",
  });
  const addProblemDetailsBtn = () => {
    problemAppend({
      id: 0,
      problemStatement: "",
      filename: "",
    });
  };
  const removeProblemDetailsBtn = (index) => {
    problemRemove(index);
  };

  const {
    fields: benefitsFields,
    append: benefitsAppend,
    remove: benefitsRemove,
  } = useFieldArray({
    control,
    name: "benefits",
  });
  const addBenefitsDetailsBtn = () => {
    benefitsAppend({
      id: 0,
      benefits: "",
    });
  };
  const removeBenefitsDetailsBtn = (index) => {
    benefitsRemove(index);
  };

  const {
    fields: eligibilityFields,
    append: eligibilityAppend,
    remove: eligibilityRemove,
  } = useFieldArray({
    control,
    name: "eligibility",
  });
  const addEligibilityDetailsBtn = () => {
    eligibilityAppend({
      id: 0,
      eligibility: "",
    });
  };
  const removeEligibilityDetailsBtn = (index) => {
    eligibilityRemove(index);
  };

  const {
    fields: processFields,
    append: processAppend,
    remove: processRemove,
  } = useFieldArray({
    control,
    name: "process",
  });
  const addProcessDetailsBtn = () => {
    processAppend({
      id: 0,
      process: "",
    });
  };
  const removeProcessDetailsBtn = (index) => {
    processRemove(index);
  };

  const {
    fields: partnerFields,
    append: partnerAppend,
    remove: partnerRemove,
  } = useFieldArray({
    control,
    name: "partner",
  });
  const addPartnerDetailsBtn = () => {
    partnerAppend({
      id: 0,
      partner: "",
      partnerLogo: "",
      partnerLogoURL: "",
      partnerLogoFileName: "",
    });
  };
  const removePartnerDetailsBtn = (index) => {
    partnerRemove(index);
  };
  // const challengerTittleList = [
  //   "Challenger Tittle 1",
  //   "Challenger Tittle 2"
  // ]
  // const [challengerTittleValue, setChallengerTittleValue] = useState([]);
  // const handleSelectChallengerTittle = (value) => {
  //   setChallengerTittleValue(value);
  //   setValue('technologyArea', value)
  //   clearErrors('technologyArea');
  // };
  // const sectorDataList = [
  //   "Sector 1", "Sector 2", "Others"
  // ]
  const handleSelectSector = (value) => {
    setTechnologyAreasDataList([]);
    setTechnologyAreaValue([]);
    setValue("sector", value);
    clearErrors("sector");
    setValue("sectorOther", "");
    setValue("technologyArea", []);
    if (value !== "Others") {
      const name = sectorDataList.filter(
        (item) => item.sector.includes(value) || item.sector.includes(value)
      );
      setTechnologyAreasDataList(name[0].technologyAreas);
    }
  };
  const [technologyAreaValue, setTechnologyAreaValue] = useState([]);
  const handleSelecttechnologyArea = (value) => {
    setTechnologyAreaValue(value);
    setValue("technologyArea", value);
    clearErrors("technologyArea");
  };
  // DATE
  const [date, setDate] = useState(null);

  const handleStartDateFormat = (newValue) => {
    setEndDateValue(newValue);
    setDate(newValue);
    setValue("startDate", newValue);
    clearErrors("startDate");
    setEndDate("");
    setValue("endDate", "");
  };
  const [endDate, setEndDate] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const handleEndDateFormat = (newValue) => {
    setEndDate(newValue);
    setValue("endDate", newValue);
    clearErrors("endDate");
  };

  const {
    fields: imagesFields,
    append: imagesAppend,
    remove: imagesRemove,
  } = useFieldArray({
    control,
    name: "images",
  });
  const addImageDetails = () => {
    imagesAppend({
      id: 0,
      file: "",
      filename: "",
      imageURL: "",
    });
  };

  const [imageDel, setImageDel] = useState("");

  // const deletePhotos = (index) => {
  //   // delItem(index);
  //   // const updatedData = [...imageDel];
  //   // updatedData.splice(index,1);
  //   setImageDel();
  // }

  // const {
  //   remove: imagessRemove
  // } = useFieldArray({
  //     control,
  //     name: "imagess"
  // });
  // const deleteProblemPhotos = async (index) => {
  //   const id = getValues(`imagess.${index}.id`);
  //     imagessRemove(index)
  // }

  let logoselecetdFile = "";
  const handleFileUpload = (
    event,
    filekey,
    fileName,
    fileURL,
    arrayName,
    index
  ) => {
    // debugger
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          debugger
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            img_crt_type === "jpeg" ||
            img_crt_type === "jpg" ||
            img_crt_type === "png" 
          ) {
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              // setValue(filekey, ImageValue)
              // setValue(fileName, img_name)
              // setValue(fileURL, logourl1)
              if (arrayName !== "") {
                setValue(`${arrayName}.${index}.${filekey}`, ImageValue);
                setValue(`${arrayName}.${index}.${fileName}`, img_name);
                setValue(`${arrayName}.${index}.${fileURL}`, logourl1);
                clearErrors(`${arrayName}.${index}.${filekey}`);
                clearErrors(`${arrayName}.${index}.${fileName}`);
                clearErrors(`${arrayName}.${index}.${fileURL}`);
              } else {
                setValue(filekey, ImageValue);
                setValue(fileName, img_name);
                setValue(fileURL, logourl1);
                clearErrors(filekey);
                clearErrors(fileName);
                clearErrors(fileURL);
              }
              setPartnerLogoURL(logourl1);
            
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalid",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File size exceeds 5MB",
            "Please check your file size"
          );
        }
      }
    }
  };
  const onSubmit = async (data) => {
    setPageLoading(true);
    setLoading(true);
    var getform = data;
    getform.userId = localStorage.getItem("userId");
    getform.startDate = data.startDate.format("YYYY-MM-DD");
    getform.endDate = data.endDate.format("YYYY-MM-DD");
    if (getform.sector === "Others") {
      if(roleId == 2 ){
        getform.technologyArea = getform.technologyArea;
      } else{
        getform.technologyArea = [getform.technologyArea];
      }
      
    }
    console.log(getform);
    debugger
    let challengeCategoryVal
    if(name === "corporate"){
      challengeCategoryVal = 0
    }else if(name === "government"){
      challengeCategoryVal = 1
    }else if(name === "msme"){
      challengeCategoryVal = 2
    }else if(name === "enablers"){
      challengeCategoryVal = 3
    }
    getform.challengeCategory = challengeCategoryVal
    debugger
    apiService(`challenge/save`, getform, "post").then((res) => {
      setPageLoading(false);
      setLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", 'Success', 'Submited successfully !!');
          handleCancel();
        }
      }
    });
  };
  const onError = (errors, e) => {
    notifyService(
      "danger",
      "Form is invalid",
      "Please check the mandatory fields"
    );
  };
  const handleCancel = () => {
    if (userId) {
      navigator(`admin/${name}/challenge-status/${id}`);
      debugger;
    } else {
      navigator(`${name}/challenges/list`);
    }
  };

  // $(document).ready(function () {
  //   $("#richtext").click(function(){
  //     var input = $("#input").val();
  //     console.log(input);

  //     var StrippedString = input.replace(/(<([^>]+)>)/ig,"").trim();
  //     $("#output").val(StrippedString);
  //   });
  // })

  const [content, setContent] = useState("");
  const handleContentChange = (value) => {
    setContent(value);
  };

  const [benefitsContent, setbenefitsContent] = useState("");
  const handleBenefitsContentChange = (value) => {
    setbenefitsContent(value);
  };

  const [problemContent, setProblemContent] = useState("");
  const handleProblemContentChange = (value) => {
    setProblemContent(value);
  };

  const [expectedContent, setExpectedContent] = useState("");
  const handleExpectedContentChange = (value) => {
    setExpectedContent(value);
  };

  const [eligibilityContent, setEligibilityContent] = useState("");
  const handleEligibilityContentChange = (value) => {
    setEligibilityContent(value);
  };

  const [howToParticipateContent, setHowToParticipateContent] = useState("");
  const handleHowToParticipateContentChange = (value) => {
    setHowToParticipateContent(value);
  };

  const deletePhotos = () => {
    setValue("challengeImageName", "");
    setValue("challengeImageURL", "");
    setValue("challengeImage", "");

    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const deleteProblemStatementPhotos = (index) => {
    setValue(`problemStatement.${index}.image`, "");
    setValue(`problemStatement.${index}.imageURL`, "");
    setValue(`problemStatement.${index}.imageName`, "");

    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const deletePartnerPhotos = (index) => {
    setValue(`partner.${index}.partnerLogo`, "");
    setValue(`partner.${index}.partnerLogoFileName`, "");
    setValue(`partner.${index}.partnerLogoURL`, "");

    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  // const strippedText = () => {
  //   const doc = new DOMParser().parseFromString(content, 'text/html');
  //   return doc.body.textContent.replace(/(<([^>]+)>)/gi, "");
  // }

  // const sanitizedText = (html) => {
  //   const clean = DOMPurify.sanitize(html, { ALLOWED_TAGS: [] });
  //   return clean;
  // };

  function createMarkup() {
    return { __html: "First &middot; Second" };
  }

  const [remainCount, setRemainCount] = useState(50);

  const [limitCount, setLimitCount] = useState(1000);

  return (
    <>
      <Helmet>
        <title>Open Innovation Portal | Challenges | Post-challenges</title>
      </Helmet>
      <main>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        <div className="post_challenges_title">
          <h5>POST YOUR CHALLENGE</h5>
        </div>
        <div className="post_challenges_details">
          <div className="container">
            <div className="col-12 p-0">
              <h5 className="point_Lable">
                Challenge Details<span className="c-blue"> *</span>{" "}
              </h5>
            </div>
            <form
              className="signin-form"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group mb-3">
                    <FormLabel shrink="true" className="input_Lable">
                      Title <span className="text-danger">*</span>
                    </FormLabel>
                    <Controller
                      name="challengeTitle"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Title is required" }}
                      render={({ field }) => (
                        <TextField
                          placeholder="Enter Title Name"
                          fullWidth
                          type="text"
                          {...field}
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => {
                            field.onChange(e);
                            setRemainCount(50 - e.target.value.length)
                          }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.challengeTitle && errors.challengeTitle.message}
                    </FormHelperText>
                    <FormHelperText style={{ textAlign: 'right' }}>
                      {remainCount} / 50
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="form-group m-0">
                    <FormLabel shrink="true" className="input_Lable">
                      Logo <span className="text-danger">*</span>
                    </FormLabel>
                  </div>
                  {/* {
                  imagesFields.map((field, index, type) => ( */}
                  <div className="row">
                    {/* <div className='col-lg-6 col-12' > */}
                    <Controller
                      name="challengeImage"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Image is required" }}
                      render={({ field }) => (
                        <Button
                          fullWidth
                          className="imgupload_div"
                          component="label"
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              "challengeImage",
                              "challengeImageName",
                              "challengeImageURL",
                              "",
                              ""
                            )
                          }
                        >
                          {getValues(`challengeImageName`) ? (
                            <div className="disPhotos">
                              <img
                                src={getValues(`challengeImageURL`)}
                                className="logo"
                                alt={`challengeImageName`}
                              />
                              <div className="hoverDelete">
                                <div className="center_display">
                                  <DeleteForeverIcon
                                    onClick={() => deletePhotos()}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="camera_icon disPhotos">
                              <VisuallyHiddenInput type="file" />
                              <img
                                src="/images/product-image.png"
                                style={{ width: "67%" }}
                                className="no_logo"
                                alt={`challengeImageName`}
                              />
                              <div className="ml-4 mt-3">Upload Photo *</div>
                              <FormHelperText>
                                File format should be in .jpg, .jpeg, .png
                              </FormHelperText>
                              <FormHelperText>
                                Maximum file size: 5 MB
                              </FormHelperText>
                            </div>
                          )}
                        </Button>
                      )}
                    />
                    {/* <div className='text-center'>
                            <DeleteForeverIcon className='c-red c-pointer' onClick={() => deletePhotos(index)} />
                        </div> */}

                    <FormHelperText className="text-danger">
                      {errors.challengeImage && errors.challengeImage.message}
                    </FormHelperText>
                    {/* </div> */}
                  </div>
                  {/* ))
                  } */}
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group mb-3">
                    <FormLabel shrink="true" className="input_Lable">
                      Sector <span className="text-danger">*</span>{" "}
                    </FormLabel>
                    <Controller
                      name="sector"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Sector Name is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          {...field}
                          ListboxProps={{
                            style: { textAlign: 'left' }
                          }}
                          options={sectorDataList.map(
                            (option) => option.sector
                          )}
                          onChange={(e, selectedOptions) =>
                            handleSelectSector(selectedOptions)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select the Sector"
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.sector && errors.sector.message}
                    </FormHelperText>
                  </div>
                </div>
                {getValues("sector") === "Others" ? (
                  <>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Other Sector <span className="text-danger">*</span>{" "}
                        </FormLabel>
                        <Controller
                          name="sectorOther"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Other Sector Name is required" }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Other Sector Name"
                              fullWidth
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.sectorOther && errors.sectorOther.message}
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Technology Area <span className="text-danger">*</span>{" "}
                        </FormLabel>
                        <Controller
                          name="technologyArea"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Technology Area Name is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Technology Area Name"
                              fullWidth
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.technologyArea &&
                            errors.technologyArea.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-group mb-3">
                      <FormLabel shrink="true" className="input_Lable">
                        Technology Area <span className="text-danger">*</span>{" "}
                      </FormLabel>
                      <Controller
                        name="technologyArea"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Technology Area is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            ListboxProps={{
                              style: { textAlign: 'left' }
                            }}
                            multiple={true}
                            value={technologyAreaValue}
                            limitTags={2}
                            options={technologyAreasDataList}
                            onChange={(e, selectedOptions) =>
                              handleSelecttechnologyArea(selectedOptions)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Technology Area"
                              />
                            )}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.technologyArea && errors.technologyArea.message}
                      </FormHelperText>
                    </div>
                  </div>
                )}

                <div className="col-lg-12 col-md-6 col-12">
                  <div className="form-group mb-3">
                    <FormLabel shrink="true" className="input_Lable">
                      Introduction <span className="text-danger">*</span>{" "}
                    </FormLabel>
                    <Controller
                      name="challengeIntroduction"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Challenge Introduction is required" }}
                      render={({ field }) => (
                        // <TextField
                        //   placeholder="Enter Challenge Introduction"
                        //   fullWidth
                        //   type='text'
                        //   {...field}
                        //   multiline

                        // rows={3}
                        // onInput={(e) => setIntroductionCount(e.target.value.length)}
                        // inputProps={{ maxLength: 150 }}
                        <ReactQuill
                          theme="snow"
                          value={content}
                          modules={toolbarOptions}
                          formats={formats}
                          onChange={(content, delta, source, editor) => {
                            handleContentChange(content);
                            field.onChange(content);
                          }}
                          // onChange={handleContentChange}
                          placeholder="Enter Challenge Introduction"
                          fullWidth
                          type="text"
                          {...field}
                          multiline
                        />
                      )}
                    />
                    {/* <FormHelperText className='text-right'>{introductionCount} / 150</FormHelperText> */}
                    <FormHelperText className="text-danger">
                      {errors.challengeIntroduction &&
                        errors.challengeIntroduction.message}
                    </FormHelperText>
                  </div>
                </div>

                {/* <div className='col-lg-6 col-md-6 col-12'>
                  <div className='form-group mb-3'>
                    <FormLabel shrink="true" className='input_Lable'>Benefits <span className='text-danger'>*</span>  </FormLabel>
                    <Controller name="benefits" control={control} defaultValue="" rules={{ required: 'Benefits is required' }}
                      render={({ field }) =>
                        <TextField
                          placeholder="Enter Benefits"
                          fullWidth
                          type='text'
                          {...field}
                          multiline
                          onInput={(e) => setBenefitsCount(e.target.value.length)}
                          inputProps={{ maxLength: 150 }}
                        />
                      } />
                    <FormHelperText className='text-right'>{benefitsCount} / 150</FormHelperText>
                    <FormHelperText className='text-danger'>{errors.benefits && errors.benefits.message}</FormHelperText>
                  </div>
                </div> */}
                <div className="col-12">
                  <h5 className="point_Lable">
                    Problem Statement <span className="c-blue">*</span>{" "}
                  </h5>

                </div>
                {problemFields.map((field, index, type) => (
                  <div className="col-lg-12 col-md-12 col-12 mb-3" key={index}>
                    <div className="row align-item-center">
                      <div className="col-lg-7 col-md-8 col-12">
                        <div className="form-groups">
                          <Controller
                            name={`problemStatement.${index}.problemStatement`}
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Problem Statement is required",
                            }}
                            render={({ field }) => (
                              <ReactQuill
                                theme="snow"
                                modules={toolbarOptions}
                                formats={formats}
                                value={problemContent}
                                onChange={handleProblemContentChange}
                                placeholder="Describe your Problem Statement"
                                fullWidth
                                type="text"
                                {...field}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.problemStatement?.[index]
                              ?.problemStatement &&
                              errors.problemStatement?.[index]?.problemStatement
                                .message}
                          </FormHelperText>
                        </div>
                      </div>

                      <div className="mar-top col-lg-3 col-12">
                        <Controller
                          name={`problemStatement.${index}.image`}
                          control={control}
                          defaultValue=""
                          rules={{ required: "Image is required" }}
                          render={({ field }) => (
                            <Button
                              fullWidth
                              className="imgupload_div"
                              component="label"
                              onChange={(e) =>
                                handleFileUpload(
                                  e,
                                  "image",
                                  "imageName",
                                  "imageURL",
                                  "problemStatement",
                                  index
                                )
                              }
                            >
                              {getValues(
                                `problemStatement.${index}.imageName`
                              ) ? (
                                <div className="disPhotos">
                                  <img
                                    src={getValues(
                                      `problemStatement.${index}.imageURL`
                                    )}
                                    className="logo"
                                    alt={`problemStatement.${index}.imageName`}
                                  />
                                  <div className="hoverDelete">
                                    <div className="center_display">
                                      <DeleteForeverIcon
                                        onClick={() =>
                                          deleteProblemStatementPhotos(index)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="camera_icon disPhotos">
                                  <VisuallyHiddenInput type="file" />
                                  <img
                                    src="/images/product-image.png"
                                    style={{ width: "67%" }}
                                    className="no_logo"
                                    alt={`problemStatement.${index}.imageName`}
                                  />
                                  <div className="ml-4 mt-3">
                                    Upload Photo *
                                  </div>
                                </div>
                              )}
                            </Button>
                          )}
                        />

                        <FormHelperText>
                          File format should be in .jpg, .jpeg, .png
                        </FormHelperText>
                        <FormHelperText>Maximum file size: 5 MB</FormHelperText>
                        <FormHelperText className="text-danger">
                          {errors.problemStatement?.[index]?.image &&
                            errors.problemStatement?.[index]?.image.message}
                        </FormHelperText>
                      </div>

                      <div className="col-lg-2 col-md-4 col-12">
                        <Button
                          className="green_btn"
                          onClick={addProblemDetailsBtn}
                          startIcon={<AddIcon />}
                        >
                          Add
                        </Button>
                        {index !== 0 && (
                          <Button
                            className="red_btn ml-3"
                            onClick={() => removeProblemDetailsBtn(index)}
                          >
                            <DeleteForeverIcon />
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                <div className="col-lg-12 col-md-6 col-12 mar-top">
                  <div className="form-group mb-3">
                    <FormLabel shrink="true" className="input_Lable">
                      Expected Outcome <span className="text-danger">*</span>{" "}
                    </FormLabel>
                    <Controller
                      name="expectedOutcome"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Expected Outcome is required" }}
                      render={({ field }) => (
                        // <TextField
                        //   placeholder="Enter Expected Outcome"
                        //   fullWidth
                        //   type='text'
                        //   {...field}
                        // onInput={(e) => setOutcomeCount(e.target.value.length)}
                        // inputProps={{ maxLength: 50 }}
                        // />
                        <ReactQuill
                          theme="snow" // You can choose other themes like 'bubble' or 'core'
                          modules={toolbarOptions}
                          formats={formats}
                          value={expectedContent}
                          onChange={handleExpectedContentChange}
                          placeholder="Enter Expected Outcome"
                          fullWidth
                          type="text"
                          {...field}
                          onInput={(e) =>
                            setOutcomeCount(e.target.value.length)
                          }
                        />
                      )}
                    />
                    {/* <FormHelperText className='text-right'>{outcomeCount} / 50</FormHelperText> */}
                    <FormHelperText className="text-danger">
                      {errors.expectedOutcome && errors.expectedOutcome.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-6 col-md-4 col-12 mar-top">
                  <div className="form-group mb-3">
                    <FormLabel shrink="true" className="input_Lable">
                      Start Date <span className="text-danger">*</span>{" "}
                    </FormLabel>
                    <Controller
                      name="startDate"
                      control={control}
                      defaultValue={date}
                      rules={{ required: "Start Date is required" }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              format="DD/MM/YYYY"
                              minDate={tomorrow}
                              {...field}
                              sx={{ width: "100% " }}
                              inputFormat="DD/MM/YYYY"
                              onChange={(newValue) =>
                                handleStartDateFormat(newValue)
                              }
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.startDate && errors.startDate.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-6 col-md-4 col-12 mar-top">
                  <div className="form-group mb-3">
                    <FormLabel shrink="true" className="input_Lable">
                      End Date <span className="text-danger">*</span>{" "}
                    </FormLabel>
                    <Controller
                      name="endDate"
                      control={control}
                      defaultValue={endDate}
                      rules={{ required: "End Date is required" }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              format="DD/MM/YYYY"
                              minDate={endDateValue}
                              {...field}
                              sx={{ width: "100% " }}
                              inputFormat="DD/MM/YYYY"
                              onChange={(newValue) =>
                                handleEndDateFormat(newValue)
                              }
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.endDate && errors.endDate.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-12">
                  <h5 className="point_Lable">
                    Benefits <span className="c-blue">*</span>{" "}
                  </h5>
                </div>
                {benefitsFields.map((field, index, type) => (
                  <div className="col-lg-12 col-md-12 col-12 mb-3" key={index}>
                    <div className="row align-item-center">
                      <div className="col-lg-10 col-md-8 col-12">
                        <div className="form-groups">
                          <Controller
                            name={`benefits.${index}.benefits`}
                            control={control}
                            defaultValue=""
                            rules={{ required: "Benefits is required" }}
                            render={({ field }) => (
                              // <TextField
                              //   placeholder="Enter Benefits"
                              //   fullWidth
                              //   type='text'
                              //   {...field}
                              // />
                              <ReactQuill
                                theme="snow" // You can choose other themes like 'bubble' or 'core'
                                modules={toolbarOptions}
                                formats={formats}
                                value={benefitsContent}
                                // onChange={handleBenefitsContentChange}
                                onChange={(value) => {
                                  handleBenefitsContentChange(value);
                                  setValue("benefits", value);
                                }}
                                placeholder="Enter Benefits"
                                fullWidth
                                type="text"
                                {...field}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.benefits?.[index]?.benefits &&
                              errors.benefits?.[index]?.benefits.message}
                          </FormHelperText>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-12 mar-top">
                        <Button
                          className="green_btn"
                          onClick={addBenefitsDetailsBtn}
                        >
                          Add
                        </Button>
                        {index !== 0 && (
                          <Button
                            className="red_btn"
                            onClick={() => removeBenefitsDetailsBtn(index)}
                          >
                            <DeleteForeverIcon />
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="col-12">
                  <h5 className="point_Lable">
                    Problem Statement <span className="c-blue">*</span>{" "}
                  </h5>
                  
                </div> */}
                {/* {
                  problemFields.map((field, index, type) => (
                    <div className='col-lg-12 col-md-12 col-12 mb-3' key={index}>
                      <div className='row align-item-center'>
                        <div className='col-lg-7 col-md-8 col-12'>
                          <div className='form-groups'>
                           
                            <Controller name={`problemStatement.${index}.problemStatement`} control={control} defaultValue="" rules={{ required: 'Problem Statement is required' }}
                              render={({ field }) =>
                                
                                <ReactQuill
                                  theme="snow" 
                                  modules={toolbarOptions}
                                  formats={formats}
                                  value={problemContent}
                                  onChange={handleProblemContentChange}
                                  placeholder="Describe your Problem Statement"
                                  fullWidth
                                  type='text'
                                  {...field}
                                />
                              } />
                            <FormHelperText className='text-danger'>
                              {errors.problemStatement?.[index]?.problemStatement && errors.problemStatement?.[index]?.problemStatement.message}
                            </FormHelperText>
                          </div>
                        </div>

                        <div className='mar-top col-lg-3 col-12'>
                          <Controller name={`problemStatement.${index}.image`} control={control} defaultValue="" rules={{ required: 'Image is required' }}
                            render={({ field }) =>
                              <Button fullWidth className='imgupload_div' component="label" onChange={(e) => handleFileUpload(e, "image", "imageName", "imageURL", "problemStatement", index)}>
                                {
                                  (getValues(`problemStatement.${index}.imageName`)) ? (
                                    <div className='disPhotos'>
                                      <img src={getValues(`problemStatement.${index}.imageURL`)} className='logo' alt={`problemStatement.${index}.imageName`} />
                                      <div className='hoverDelete'>
                                        <div className='center_display'>
                                          <DeleteForeverIcon onClick={() => deleteProblemStatementPhotos(index)} />
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className='camera_icon disPhotos'>
                                      <VisuallyHiddenInput type="file" />
                                      <img src='/images/product-image.png' style={{ width: '67%' }} className='no_logo' alt={`problemStatement.${index}.imageName`} />
                                      <div className='ml-4 mt-3'>Upload Photo *</div>
                                    </div>
                                  )
                                }
                              </Button>
                            } />
                         
                          <FormHelperText>File format should be in .jpg, .jpeg, .png</FormHelperText>
                          <FormHelperText>Maximum file size: 5 MB</FormHelperText>
                          <FormHelperText className='text-danger'>{errors.problemStatement?.[index]?.image && errors.problemStatement?.[index]?.image.message}</FormHelperText>
                        </div>

                        <div className='col-lg-2 col-md-4 col-12'>
                          <Button className='green_btn' onClick={addProblemDetailsBtn} startIcon={<AddIcon />}>Add</Button>
                          {
                            index !== 0 && (
                              <Button className='red_btn ml-3' onClick={() => removeProblemDetailsBtn(index)}><DeleteForeverIcon /></Button>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  ))
                } */}
                <div className="col-12">
                  <h5 className="point_Lable">Point of Contact Details </h5>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Name <span className="text-danger">*</span>{" "}
                        </FormLabel>
                        <Controller
                          name="pocName"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Name is required" }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Name"
                              fullWidth
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pocName && errors.pocName.message}
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Email <span className="text-danger">*</span>{" "}
                        </FormLabel>
                        <Controller
                          name="pocEmail"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Email is required",
                            validate: validateEmail,
                          }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Email"
                              fullWidth
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pocEmail && errors.pocEmail.message}
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Mobile Number <span className="text-danger">*</span>{" "}
                        </FormLabel>
                        <Controller
                          name="pocMobile"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Mobile Number is required",
                            validate: validatePhoneNumber,
                          }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Mobile Number"
                              fullWidth
                              type="number"
                              {...field}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 10);
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pocMobile && errors.pocMobile.message}
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Landline Number{" "}
                        </FormLabel>
                        <Controller
                          name="pocPhone"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Landline Number"
                              fullWidth
                              type="number"
                              {...field}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 15);
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pocPhone && errors.pocPhone.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </div>
                </div>
                {userId && (
                  <div className="col-12">
                    <h5 className="point_Lable">Additional Details </h5>
                    <div className="row">
                      <div className="col-lg-12 col-md-4 col-12">
                        <div className="form-group mb-3">
                          <FormLabel shrink="true" className="input_Lable">
                            How to Participate{" "}
                            <span className="text-danger">*</span>
                          </FormLabel>
                          <Controller
                            name="howToParticipate"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Participate is required" }}
                            render={({ field }) => (
                              // <TextField
                              //   placeholder="Enter Participate Details"
                              //   fullWidth
                              //   type='text'
                              //   {...field}
                              //   multiline
                              // />
                              <ReactQuill
                                theme="snow" // You can choose other themes like 'bubble' or 'core'
                                modules={toolbarOptions}
                                formats={formats}
                                value={howToParticipateContent}
                                onChange={(value) => {
                                  handleHowToParticipateContentChange(value);
                                  setValue("howToParticipate", value);
                                }}
                                placeholder="Enter Participate Details"
                                fullWidth
                                type="text"
                                {...field}
                                multiline
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.howToParticipate &&
                              errors.howToParticipate.message}
                          </FormHelperText>
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="input_Lable">
                          Eligibility <span className="text-danger">*</span>{" "}
                        </h6>
                      </div>
                      {eligibilityFields.map((field, index, type) => (
                        <div
                          className="col-lg-12 col-md-12 col-12 mb-3"
                          key={index}
                        >
                          <div className="row align-item-center">
                            <div className="col-lg-10 col-md-8 col-12">
                              <div className="form-group mb-3">
                                {/* <FormLabel shrink="true" className='input_Lable'>Eligibility <span className='text-danger'>*</span></FormLabel> */}

                                <Controller
                                  name={`eligibility.${index}.eligibility`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "Eligibility is required",
                                  }}
                                  render={({ field }) => (
                                    // <TextField
                                    //   placeholder="Enter Eligibility Details"
                                    //   fullWidth
                                    //   type='text'
                                    //   {...field}
                                    //   multiline
                                    // />
                                    <ReactQuill
                                      theme="snow" // You can choose other themes like 'bubble' or 'core'
                                      modules={toolbarOptions}
                                      formats={formats}
                                      value={eligibilityContent}
                                      onChange={(value) => {
                                        handleEligibilityContentChange(value);
                                        setValue(
                                          `eligibility.${index}.eligibility`,
                                          value
                                        );
                                      }}
                                      placeholder="Enter Eligibility Details"
                                      fullWidth
                                      type="text"
                                      {...field}
                                      multiline
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.eligibility?.[index]?.eligibility &&
                                    errors.eligibility?.[index]?.eligibility
                                      .message}
                                </FormHelperText>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-12">
                              <Button
                                className="green_btn"
                                onClick={addEligibilityDetailsBtn}
                                startIcon={<AddIcon />}
                              >
                                Add
                              </Button>
                              {index !== 0 && (
                                <Button
                                  className="red_btn ml-3"
                                  onClick={() =>
                                    removeEligibilityDetailsBtn(index)
                                  }
                                >
                                  <DeleteForeverIcon />
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-12">
                        <h6 className="input_Lable">
                          Procedure Post Submission{" "}
                          <span className="text-danger">*</span>{" "}
                        </h6>
                      </div>
                      {processFields.map((field, index, type) => (
                        <div
                          className="col-lg-12 col-md-12 col-12 mb-3"
                          key={index}
                        >
                          <div className="row align-item-center">
                            <div className="col-lg-10 col-md-8 col-12">
                              <div className="form-group mb-3">
                                {/* <FormLabel shrink="true" className='input_Lable'>Procedure Post Submission <span className='text-danger'>*</span></FormLabel> */}
                                <div>
                                  <p className="input_Lable">
                                    {" "}
                                    {`Step ${index + 1}`}{" "}
                                  </p>
                                </div>
                                <Controller
                                  name={`process.${index}.process`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required:
                                      "Procedure Post Submission is required",
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      placeholder="Enter Procedure Post Submission"
                                      fullWidth
                                      type="text"
                                      {...field}
                                      multiline
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.process?.[index]?.process &&
                                    errors.process?.[index]?.process.message}
                                </FormHelperText>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-12 mt-4">
                              <Button
                                className="green_btn"
                                onClick={addProcessDetailsBtn}
                                startIcon={<AddIcon />}
                              >
                                Add
                              </Button>
                              {index !== 0 && (
                                <Button
                                  className="red_btn ml-3"
                                  onClick={() => removeProcessDetailsBtn(index)}
                                >
                                  <DeleteForeverIcon />
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <h5 className="point_Lable">Host Details </h5>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="form-group mb-3">
                          <FormLabel shrink="true" className="input_Lable">
                            Name <span className="text-danger">*</span>
                          </FormLabel>
                          <Controller
                            name="contactHostName"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Name is required" }}
                            render={({ field }) => (
                              <TextField
                                placeholder="Enter Name"
                                fullWidth
                                type="text"
                                {...field}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.contactHostName &&
                              errors.contactHostName.message}
                          </FormHelperText>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="form-group mb-3">
                          <FormLabel shrink="true" className="input_Lable">
                            Email <span className="text-danger">*</span>
                          </FormLabel>
                          <Controller
                            name="contactHostEmail"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Email is required",
                              validate: validateEmail,
                            }}
                            render={({ field }) => (
                              <TextField
                                placeholder="Enter Email"
                                fullWidth
                                type="text"
                                {...field}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.contactHostEmail &&
                              errors.contactHostEmail.message}
                          </FormHelperText>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="form-group mb-3">
                          <FormLabel shrink="true" className="input_Lable">
                            Mobile Number <span className="text-danger">*</span>
                          </FormLabel>
                          <Controller
                            name="contactHostPhone"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Mobile Number is required",
                              validate: validatePhoneNumber,
                            }}
                            render={({ field }) => (
                              <TextField
                                placeholder="Enter Mobile Number"
                                fullWidth
                                type="number"
                                {...field}
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 10);
                                }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.contactHostPhone &&
                              errors.contactHostPhone.message}
                          </FormHelperText>
                        </div>
                      </div>
                    </div>
                    <h5 className="point_Lable">Partner Details </h5>
                    {partnerFields.map((field, index, type) => (
                      <div className="row">
                        <div className="col-lg-6 col-md-3 col-12" key={index}>
                          <div className="form-group mb-3">
                            <FormLabel shrink="true" className="input_Lable">
                              Name <span className="text-danger">*</span>
                            </FormLabel>
                            <Controller
                              name={`partner.${index}.partnerName`}
                              control={control}
                              defaultValue=""
                              rules={{ required: "Name is required" }}
                              render={({ field }) => (
                                <TextField
                                  placeholder="Enter Name"
                                  fullWidth
                                  type="text"
                                  {...field}
                                />
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.partner?.[index]?.partnerName &&
                                errors.partner?.[index]?.partnerName.message}
                            </FormHelperText>
                          </div>
                        </div>
                        {/* <div className='col-lg-4 col-md-6 col-12' key={index}>
                                <div className='form-group mb-3'>
                                <FormLabel shrink="true" className='input_Lable'>Logo <span className='text-danger'>*</span></FormLabel>
                                <Controller name={`partner[${index}].partnerLogo`} control={control} defaultValue="" rules={{ required: 'Logo is required' }}
                                    render={({ field }) =>
                                    <FileUploader {...field} handleChange={(e) => handleFileUpload(e, "partnerLogo","partnerLogoFileName","partnerLogoURL","partner", index)} name="file" types={fileTypes} />
                                    } />
                                <FormHelperText>File format should be in .jpg, .jpeg, .png</FormHelperText>
                                <FormHelperText>Maximum file size: 5 MB</FormHelperText>
                                <FormHelperText className='text-danger'>{errors.partner?.[index]?.partnerLogo && errors.partner?.[index]?.partnerLogo.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-2 col-md-2 col-12' key={index}>
                                <div className='text-center'>
                                {
                                    (getValues(`partner.${index}.partnerLogoFileName`)) ? (
                                        <div className='disPhotos'>
                                            <img src={getValues(`partner.${index}.partnerLogoFileName`)} className='logo1' alt={`partner.${index}.partnerLogoFileName`} />
                                        </div>
                                    ) : (
                                        <div className='camera_icon disPhotos'>
                                          <VisuallyHiddenInput type="file" />
                                          <img src='/images/product-image.png' style={{width: '67%'}} className='no_logo' alt={`partner.${index}.partnerLogoFileName`} />
                                        </div>
                                    )
                                }
                                </div>
                            </div> */}
                        <div className="col-lg-4 col-md-6 col-12" key={index}>
                          <div className="form-group mb-3">
                            <FormLabel shrink="true" className="input_Lable">
                              Logo <span className="text-danger">*</span>
                            </FormLabel>
                            <Controller
                              name={`partner.${index}.partnerLogo`}
                              control={control}
                              defaultValue=""
                              rules={{ required: "Image is required" }}
                              render={({ field }) => (
                                <Button
                                  fullWidth
                                  className="imgupload_div"
                                  component="label"
                                  onChange={(e) =>
                                    handleFileUpload(
                                      e,
                                      "partnerLogo",
                                      "partnerLogoFileName",
                                      "partnerLogoURL",
                                      "partner",
                                      index
                                    )
                                  }
                                  name="file"
                                  types={fileTypes}
                                >
                                  {getValues(
                                    `partner.${index}.partnerLogoFileName`
                                  ) ? (
                                    <div className="disPhotos">
                                      <img
                                        src={getValues(
                                          `partner.${index}.partnerLogoURL`
                                        )}
                                        className="logo1"
                                        alt={`partner.${index}.partnerLogoFileName`}
                                      />
                                      <div className="hoverDelete">
                                        <div className="center_display">
                                          <DeleteForeverIcon
                                            onClick={() =>
                                              deletePartnerPhotos(index)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="camera_icon disPhotos">
                                      <VisuallyHiddenInput type="file" />
                                      <img
                                        src="/images/product-image.png"
                                        style={{ width: "67%" }}
                                        className="no_logo"
                                        alt={`partner.${index}.partnerLogoFileName`}
                                      />
                                      <div className="ml-4 mt-3">
                                        Upload Photo *
                                      </div>
                                      <FormHelperText>
                                        File format should be in .jpg, .jpeg,
                                        .png
                                      </FormHelperText>
                                      <FormHelperText>
                                        Maximum file size: 5 MB
                                      </FormHelperText>
                                    </div>
                                  )}
                                </Button>
                              )}
                            />
                          </div>
                        </div>
                        {/* <div className='col-lg-4 col-md-6 col-12' key={index}>
                              <div className='form-group mb-3'>
                                <FormLabel shrink="true" className='input_Lable'>Logo <span className='text-danger'>*</span></FormLabel>
                                <Controller name={`partner.${index}.partnerLogo`} control={control} defaultValue="" rules={{ required: 'Logo is required' }}
                                  render={({ field }) =>
                                    <FileUploader {...field} handleChange={(e) => handleFileUpload(e,"partnerLogo","partnerLogoFileName","partnerLogoURL")} name="file" types={fileTypes} />
                                  } />
                                <FormHelperText>File format should be in .jpg, .jpeg, .png</FormHelperText>
                                <FormHelperText>Maximum file size: 5 MB</FormHelperText>
                                <FormHelperText className='text-danger'>{errors.partner?.[index]?.partnerLogo && errors.partner?.[index]?.partnerLogo.message}</FormHelperText>
                              </div>
                            </div>
                            <div className='col-lg-2 col-md-2 col-12' key={index}>
                              <div className='text-center'>
                                <img src={partnerLogoURL} alt='' className='img-fluid sml-img' />
                              </div>
                            </div> */}

                        <div className="col-lg-2 col-md-3 col-12 mt-4">
                          <Button
                            className="green_btn"
                            onClick={addPartnerDetailsBtn}
                            startIcon={<AddIcon />}
                          >
                            Add
                          </Button>
                          {index !== 0 && (
                            <Button
                              className="red_btn ml-3"
                              onClick={() => removePartnerDetailsBtn(index)}
                            >
                              <DeleteForeverIcon />
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* <span>{strippedText(content)}</span> */}
              {/* <div dangerouslySetInnerHTML={{ __html: sanitizedText(content) }} /> */}
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <div className="text-center">
                <LoadingButton
                  loadingPosition="start"
                  type="submit"
                  loading={loading}
                  className="b-yellow-btn"
                  id="richtext"
                >
                  <span>SUBMIT</span>
                </LoadingButton>
                <Button className="blue-btn ml-3" onClick={handleCancel}>
                  BACK
                </Button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </>
  );
};

export default Postchallenges;
