import { Button, Divider } from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import Accordionpage from "../../common-pages/Accordionpage";
import notifyService from "../../../api/notifySerivce";
import RequirementReject from "./RequirementReject";
const tomorrow = dayjs().add(0, "day");

const Adminrequirementdetail = () => {
  const htmlToText = require("html-to-text");
  const { fromHtml } = require("html-to-text");
  const { id } = useParams();
  let userId = localStorage.getItem("userId");
  let requirementIds;
  if (id !== undefined) {
    requirementIds = atob(id);
  }
  const { navigator, setPageLoading, btnLoading, setBtnLoading } =
    useContext(DataContext);
  let roleId = localStorage.getItem("roleId");
  let roleName = localStorage.getItem("role");
  let role = localStorage.getItem("role");
  useDidMountEffect(() => {
    getApi();
  }, []);
  const [getValue, setGetValue] = useState({});
  const getApi = () => {
    setPageLoading(true);
    apiService(
      `requirement/get?id=${requirementIds}&userId=${userId}`,
      "",
      "get"
    ).then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          var response = res.data;
          // response.startDate = dayjs(response.startDate, "YYYY-MM-DD");
          response.endDate = dayjs(response.endDate, "YYYY-MM-DD");
          if (response.requirementImageName) {
            response.requirementImageURL = response.requirementImageName;
          }
          if (response.requirements) {
            response.requirements.forEach((element) => {
              element.imageURL = element.imageName;
            });
          }

          setGetValue(res.data);

          if (response.images) {
            response.images.forEach((element) => {
              if (element.file) {
                element.imageURL = `data:image/jpeg;base64,${element.file}`;
              }
            });
          }
        }
      }
    });
  };
  // const handleBack = () => {
  //     navigator('/requirement/list')
  // }
  const handleBack = () => {
    if (roleName === "Admin" || roleName === "Project Management Team") {
      navigator("/admin/government/challenge-status");
    } else {
      navigator("/requirement/list");
    }
  };
  const onApplyNow = (value) => {
    // setBtnLoading(true)
    // apiService(`solution/apply/verify?challengeid=${userId}`, '', 'get').then((res) => {
    //   setBtnLoading(false)
    //   if (res) {
    //     if (res.data.responseStatus === "Success") {
    //       navigator(`${name}/challenges/apply/${btoa(userId)}/${value}`)
    //     } else {
    //       notifyService('danger', res.data.responseMessage)
    //     }
    //   }
    // })
  };
  const handleEdit = () => {
    navigator(`/admin/requirement/status/post-requirement/${id}`);
  };
  const [requirementId, setRequirementId] = useState("");
  const handleClickOpen = (requirementId) => {
    setRequirementId(requirementId);
    approvalApi();
  };
  const approvalApi = (requirementId, approve) => {
    setPageLoading(true);
    setBtnLoading(true);
    var req = {
      requirementId: requirementId,
      approval: approve,
    };

    apiService("admin/requirement/approve", req, "post").then((res) => {
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", res.data.responseMessage);
          // handleBack()
        }
      }
    });
  };
  const handleApproval = (id, value) => {
    if (value === 1) {
      var req = {
        requirementId: id,
        approval: value,
      };
      apiService("admin/requirement/approve", req, "post").then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            notifyService("success", res.data.responseMessage);
            getApi();
            handleApproveBack();
          }
        }
      });
    } else {
      handleRejectOpen();
    }
  };
  const handleApproveBack = () => {
    navigator("/admin/government/challenge-status");
  };
  const [openReject, setOpenReject] = React.useState(false);
  const handleRejectOpen = () => {
    setOpenReject(true);
  };
  const handleRejectClose = () => {
    setOpenReject(false);
  };
  return (
    <>
      <Helmet>
        <title>Open Innovation Portal | Admin | Requirement-status </title>
      </Helmet>
      <section className="Challenges_details_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row mb-4">
                <div className="col-lg-8 col-md-8 col-12">
                  {getValue.requirementImageURL && (
                    <img
                      src={getValue.requirementImageURL}
                      className="img-fluid challenge_img"
                    />
                  )}

                  <h1 className="my-3">{getValue.requirementTitle}</h1>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="align_data">
                    <div className="apply_challenges">
                      <div className="row">
                        <div className="col-12">
                          <div className="timeline_div">
                            <h5>Timeline</h5>
                            {/* <p>Start Date : <b>{getValue.startDate2}</b></p> */}
                            <p>
                              End Date :{" "}
                              <b>
                                {getValue.endDate2 ? getValue.endDate2 : `-`}
                              </b>
                            </p>
                          </div>
                        </div>
                        {/* {
                                                    (role === 'Solution Provider' && getValue.showButton === true) && (
                                                        <div className='col-12 text-center mt-3'>
                                                            <LoadingButton
                                                                loadingPosition="start"
                                                                onClick={() => onApplyNow(getValue.requirementTitle)}
                                                                loading={btnLoading}
                                                                className="b-yellow-btn"
                                                            >
                                                                <span>Expression Of Interest</span>
                                                            </LoadingButton>
                                                        </div>
                                                    )
                                                } */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-12">
                  {getValue && getValue.pmtApproval === 2 && (
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="solution_div">
                        <p>
                          Remarks :{" "}
                          <span>
                            {getValue && getValue.remarks
                              ? getValue.remarks
                              : `-`}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  {(getValue.pmtApproval === 0 ||
                    getValue.pmtApproval === 1) && (
                    <div className="challenge_banner_details my-3">
                      <div className="d-flex">
                        {/* <h1 className='mb-3'>{getValue.requirementTitle}</h1> */}
                        <div className="ml-auto mb-3">
                          <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            startIcon={<EditIcon />}
                            onClick={() => handleEdit()}
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                      {/* <div className='challenge_intro mb-2' dangerouslySetInnerHTML={{ __html: getValue.requirementIntroduction }}></div> */}
                    </div>
                  )}
                </div>
              </div>

              <Divider />
            </div>
          </div>
        </div>
      </section>
      <section className="more_details_section">
        <div className="container">
          <div className="challenge_banner_details">
            {/* <div className='challenge_intro my-3'>
                            <h4 className='challenge_intro_title mb-4'><span>Department</span> Name</h4>
                            <h6>{getValue.departmentName}</h6>
                        </div> */}
            <Divider />
            <div className="challenge_intro my-3">
              <h4 className="challenge_intro_title mb-4">Requirements</h4>
              {getValue.requirements && (
                <>
                  <ul>
                    {getValue.requirements.map((list, index) => (
                      <li key={index} className="box-code">
                        <div className="row">
                          {/* <div className='col-lg-3 col-md-4 col-12'>
                                                                <img src={list.imageURL} className='img-fluid prob_img' />
                                                            </div> */}
                          <div className="col-lg-12 col-md-12 col-12">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: list.requirementDesc,
                              }}
                            ></div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <Divider />
          </div>
        </div>
      </section>
      <main className="live_challenges_main">
        <div className="container">
          {/* <div className='challenge_more'>
      <h4><span>Additional</span> Details</h4>
    </div>
    <Divider /> */}
          <Accordionpage getValue={getValue} path="Requirement" />
        </div>
        <div className="text-center mb-5">
          {getValue.pmtApproval === 1 || getValue.pmtApproval === 2 ? (
            <Button
              className="yellow-btn ml-2"
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
            >
              BACK
            </Button>
          ) : (
            <>
              {getValue.endDate2 ? (
                <Button
                  loadingPosition="start"
                  type="submit"
                  loading={btnLoading}
                  className="green_btn"
                  onClick={() => handleApproval(getValue.id, 1)}
                >
                  <span>Submit & Approve</span>
                </Button>
              ) : (
                <Button
                  onClick={() => handleEdit()}
                  className="green_btn ml-3"
                  startIcon={<EditIcon />}
                >
                  <span>Edit</span>
                </Button>
              )}
              <Button
                className="red_btn"
                onClick={() => handleApproval(getValue.id, 2)}
              >
                Reject
              </Button>
              <Button
                className="yellow-btn ml-2"
                startIcon={<ArrowBackIcon />}
                onClick={handleBack}
              >
                BACK
              </Button>
            </>
          )}
          {/* <Button
                        loadingPosition="start"
                        type="submit"
                        loading={btnLoading}
                        className="green_btn"
                        onClick={() => handleApproval(getValue.id, 1)}
                    >
                        <span>Submit & Approve</span>
                    </Button>
                    <Button className='red_btn' onClick={() => handleApproval(getValue.id, 2)}>Reject</Button>
                    <Button className='yellow-btn ml-2' startIcon={<ArrowBackIcon />} onClick={handleBack}>BACK</Button> */}
          {/* <Button className='yellow-btn ml-3'>Apply Now</Button> */}
          {/* {
                        (role === 'Solution Provider' && getValue.showButton === true) && (
                            <LoadingButton
                                loadingPosition="start"
                                onClick={() => onApplyNow(getValue.challengeTitle)}
                                loading={btnLoading}
                                className="blue-btn-sm ml-3"
                            >
                                <span>Expression Of Interest</span>
                            </LoadingButton>
                        )
                    } */}
        </div>
      </main>
      <RequirementReject
        openReject={openReject}
        handleRejectClose={handleRejectClose}
        handleBack={handleBack}
        formData={getValue}
      />
    </>
  );
};

export default Adminrequirementdetail;
