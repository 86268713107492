import React, { useContext } from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TiSocialFacebookCircular } from "react-icons/ti";
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { colors } from '@mui/material';
import { ColorLens } from '@mui/icons-material';
import DataContext from '../../../context/DataContext';
const Footer = () => {
  const { navigator } = useContext(DataContext)
  $(document).ready(function () {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 100) {
        $('#backtotop').fadeIn();
      } else {
        $('#backtotop').fadeOut();
      }
    });
    $('#backtotop').click(function () {
      $("html, body").animate({
        scrollTop: 0
      }, 600);
      return false;
    });
  });
  const [open, setOpen] = React.useState(false);
  const modalOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLC = () => {
    navigator('/challenges/live-challenges')
  }

  const scrollToTop = () => {
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      600
    );
  };
  return (
    <>
      <footer className="footer">

        <div className='footer_details text-white'>
          <div className="container">
            <section className="">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <Link to={'https://startuptn.in/'} target='_blank'>
                    <img src="/images/White-logo.png" className="img-fluid logo_img p-0" alt="" />
                  </Link>

                  {/* <img src="/images/TNLogoWhiteLogo1.png" alt="blue horizontal.png" className='logo_tanfund_img' /> */}
                </div>
                <div className="col-lg-7 col-md-6">
                  <h3 className="my-3 font-weight-bolder c-gray">Contact</h3>
                  <ul className="list-unstyled mb-0">
                    <li className='set_flex'>
                      <div>
                        <img src='/images/location.png' className='foo_icon_img' alt="" />
                      </div>
                      {/* <i className="fas fa-map-marker-alt"></i> */}
                      <div>
                        <p className='setHighlight'>Tamil Nadu Startup and Innovation Mission,</p>
                        <p>No. 327, CMRL Building, <br /> 10th Floor, Anna Salai,<br /> Nandanam, Chennai - 600 035.</p>
                      </div>
                    </li>
                    <li className='set_flex'>
                      <div>
                        <img src='/images/phone.png' className='foo_icon_img' alt="" />
                      </div>
                      <p>Helpline Number : 155343</p>
                    </li>
                    <li className='set_flex'>
                      <div>
                        <img src='/images/mail.png' className='foo_icon_img' alt="" />
                      </div>
                      <p>openinnovation@startuptn.in</p>
                    </li>
                  </ul>

                </div>
                {/* <div className="col-lg-1 col"></div> */}
                <div className="col-lg-5 col-md-6">
                  {/* <h3 className="mt-3 font-weight-bolder c-gray">Useful Links</h3> */}
                  <ul className="list-Links my-2 mb-5">
                    {/* <li>
                      <a className="nav text-white" onClick={handleLC}>Challenges</a>
                    </li> */}
                    <li>
                      {/* <a className="text-white">FAQ</a> */}
                      <Link className="text-white"
                        to='/Faq'
                        onClick={scrollToTop}
                      >
                        FAQ
                      </Link>
                    </li>

                    <li>
                      {/* to='/resourcespage'  */}
                      <Link className="text-white" onClick={modalOpen}>Resources</Link>
                    </li>
                  </ul>
                  <div className="stay_update">
                    <h3>Stay Updated</h3>
                    <p>Subscribe to get updates of the Tamil Nadu Startup Ecosystem.</p>
                    <div className='footer-btn'>
                      <input type="text" placeholder="Email address" className="form-input" />
                      <button className="check_btn">Subscribe</button>
                    </div>
                  </div>
                  {/* <div className="col-lg-5 col-md-6 mb-4">
              <div className="col-2">
              <TiSocialFacebookCircular />
                </div>
                </div> */}
                  {/* <div className="col-lg-7 col-md-6 mb-4"></div> */}
                  <div className='my-3'>
                    <div className="social_icon">
                      <Link to={'https://www.facebook.com/TheStartupTN/'} target='_blank'>
                        <img src='/images/facebook.png' className='img-fluid' alt="" />
                      </Link>
                      <Link to={'https://x.com/TheStartupTN'} target='_blank'><img src='/images/twitter.png' className='img-fluid' alt="" /></Link>
                      <Link to={'https://www.linkedin.com/company/thestartuptn/?originalSubdomain=in'} target='_blank'><img src='/images/linkedin.png' className='img-fluid' alt="" /></Link>
                      <Link to={'https://www.instagram.com/thestartuptn/'} target='_blank'>                      <img src='/images/instagram.png' className='img-fluid' alt="" /></Link>
                      <Link to={'https://www.youtube.com/channel/UCr0du18taGeXH35dZZD4RnQ'} target='_blank'>                      <img src='/images/youtupe2.png' className='img-fluid' alt="" /></Link>




                      {/* <img src='./images/whatsapp.png' className='img-fluid' alt="" /> */}
                    </div>
                  </div>
                </div>

              </div>
              <div className='row flex-der'>
                <div className="col-lg-7 col-md-7 mb-4">
                  {/* | Powered by */}
                  <div className='poewe'><span className='clr-span mb-2'>2024 © StartupTN. All rights reserved.</span>
                    {/* <img src='/images/amizhth-logo.png' className='img_footer' alt='' /> */}
                  </div>
                </div>

                <div className="col-lg-5 col-md-5 mb-4">
                  <div className='terms_p'>
                    <Link>Terms of Service</Link>
                    <Link to='/privacy-policy'>Privacy Policy</Link>
                    <Link>Cookie Policy</Link>
                  </div>
                </div>
              </div>
            </section>
            {/* <p className='hr' /> */}
            {/* <div className="text-center">
            

          </div> */}
          </div>
        </div>
        <span id="backtotop" title="Back to top" style={{ display: 'none' }}>&uarr;</span>
      </footer>

      <React.Fragment>

        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const email = formJson.email;
              console.log(email);
              handleClose();
            },
          }}
        >
          {/* <DialogTitle>Subscribe</DialogTitle> */}
          <DialogContent>
            <section>
              <center><h1 className='font-weight-bold my-5'>Resources PDF</h1></center>
              <div className='container'>
                <div className='row mb-5'>
                  <div className='col-lg-6'>
                    <center><div><a href="https://startuptn-public-files.s3.ap-south-1.amazonaws.com/nasscom-avasant-open-innovation-in-india.pdf" target="_blank"><img style={{ color: 'red' }} src='/images/file.png' className='pdf mb-3' alt='' />Click Here</a></div>
                      <h5 className='mt-3'>Nasscom Avasant Open Innovation PDF</h5></center>
                  </div>
                  <div className='col-lg-6'>
                    <center><div><a className='docdownload' href="https://startuptn-public-files.s3.ap-south-1.amazonaws.com/NASSCOM_Open_Innovation_Final_Oct_2022.pdf" target="_blank"><img style={{ color: 'red' }} src='/images/file.png' className='pdf mb-3' alt='' />Click Here</a></div>
                      <h5 className='mt-3'>NASSCOM Open Innovation Final PDF</h5></center>
                  </div>
                </div>
              </div>
            </section>
            <center><Button variant='outlined' style={{ backgroundColor: 'red', color: 'white', border: 'none' }} onClick={handleClose}>Close</Button></center>
            {/* className='closebtn' */}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    </>
  )
}

export default Footer