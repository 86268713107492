import React from 'react'
import './sessiontimeout.css'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import { Button } from '@mui/material'
import { Helmet } from 'react-helmet'

const Sessiontimeout = () => {
    useDidMountEffect(() => {
        setTimeout(() => {
            logout()
        }, 5000);
    }, [])
    const logout = () => {
        localStorage.clear()
        window.location.assign('/sign-in')
    }
  return (
    <>
    <Helmet>
        <title>Open Innovation Portal | Session Timeout</title>
    </Helmet>
        <div className='session_div'>
            <div className='text-center'>
                <img src='/images/timeout.gif' className='img-fluid gifimg' alt='' />
                <h4>Session Timeout</h4>
                <p>You weren't clicking around any more, so we logged you out for security reasons. To get back in,
                    just login again</p>
                <div className="text-center mt-3">
                    <Button variant="contained" className='log_btn_sesstion' onClick={logout}>Login</Button>
                </div>
            </div>
        </div >
    </>
  )
}

export default Sessiontimeout
