import React, { useContext, useState } from "react";
// import "./techmarket.css";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Container,
  FormHelperText,
  FormLabel,
  Grid,
  Pagination,
  Stack,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { tabsClasses } from "@mui/material/Tabs";
import { useForm, Controller } from "react-hook-form";

const Techmarket = () => {
  const { navigator, setPageLoading } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({});
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  let roleId = localStorage.getItem("roleId");
  let roleName = localStorage.getItem("role");
  const [instituteName, setInstituteName] = useState("");
  const [selectSector, setselectSector] = useState("");
  const [selectStage, setSelectStage] = useState("");
  useDidMountEffect(() => {
    dashBoardApi();
    challengesListApi();
    dropdownValueApi();
    // dropdownStageValueApi()
  }, []);
  useDidMountEffect(() => {
    inventorList();
  }, [page, instituteName, selectSector, selectStage]);

  const [homeData, setHomeData] = useState(0);
  const dashBoardApi = () => {
    var req = {};
    setHomeData({});
    apiService(`techmarket/dashboard`, req, "post").then((res) => {
      if (res.data) {
        setHomeData(res.data);
      }
    });
  };
  const [statusValue, setStatusValue] = useState(0);
  const [inventorListValue, setInventorListValue] = useState([]);
  const inventorList = () => {
    setPageLoading(true);
    setInventorListValue([]);
    var req = {
      listSize: 12,
      pageNumber: page,
      institute: instituteName,
      sector: selectSector,
      stageOfDevelopment: selectStage,
    };
    // let url;
    // if (roleName === null) {
    //   url = "techmarket/home/list";
    // } else {
    //   url = "techmarket/list";
    // }

    apiService("techmarket/home/list", req, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.responseModelList) {
            setInventorListValue(res.data.responseModelList);
            setTotalPage(res.data.totalPages);
          }
        }
      }
    });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleTechdetail = (id, userid) => {
    // if (roleId === null) {
    //   navigator("/sign-in");
    // } else {
    //   navigator(`/techmarket/details/${btoa(id)}`);
    // }
    navigator(`/techmarket/details/${btoa(id)}/${btoa(userid)}`);
  };
  let rolval = localStorage.getItem("role");
  const pageNavgate = () => {
    if (rolval === null) {
      navigator("/sign-in");
    } else {
    }
  };

  const handleSelectSector = (value) => {
    setselectSector(value);
    setValue("sector", value);
    const name = sectorDataList.filter(
      (item) => item.sector.includes(value) || item.sector.includes(value)
    );
  };

  const handleSelectChallenges = (value) => {
    setInstituteName(value);
  };

  const handleSelectstage = (value) => {
    setSelectStage(value);
  };

  const resetFiliter = () => {
    // setTechnologyTitleValue('')
    setselectSector("");
    setInstituteName("");
    setSelectStage("");
  };

  const [sectorDataList, setsectorDataList] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(
      `admin/dropdownvalues/get?key=dropdownall`,
      "",
      "unauthget"
    ).then((res) => {
      if (res) {
        if (res.data) {
          var val = res.data.dropDownValues;
          setsectorDataList(val.sectors);
        }
      }
    });
  };
  const [stageData, setStageData] = useState([]);
  const dropdownStageValueApi = async () => {
    await apiService(
      `admin/dropdownvalues/get?key=dropdown`,
      "",
      "unauthget"
    ).then((res) => {
      if (res) {
        if (res.data) {
          var val = res.data.dropDownValues;
          setStageData(val.stageofdevelopment);
        }
      }
    });
  };
  const [challengesDataList, setChallengesDataList] = useState([]);
  const challengesListApi = () => {
    setChallengesDataList([]);
    apiService(
      `techmarket/institution/list?entityType=${""}`,
      "",
      "unauthget"
    ).then((res) => {
      if (res) {
        if (res.data) {
          setChallengesDataList(res.data);
        }
      }
    });
  };

  // || rolval === 'Admin' || rolval === 'Project Management Team'

  return (
    <>
      <section className="container my-5">
        <div className="tech-bg">
          <h3 className="text-center">Tech Marketplace</h3>
          <p className="text-center py-3">
            Tech Marketplace facilitates know-how, intellectual property, and
            capabilities developed at higher educational institutions and
            research institutions to be transferred to external entities,
            including startups, MSMEs, industries in the public and private
            sectors, and state and central government organisations.
          </p>
          {/* <center><button className='blubtn ' onClick={pageNavgate}>Showcase your Technologies</button></center> */}
          {rolval === null ? (
            <center>
              <button className="blubtn " onClick={pageNavgate}>
                Showcase your Technologies
              </button>
            </center>
          ) : (
            <></>
          )}
        </div>
      </section>

      <section className="container mb-4">
        <h5 className="text-center font-weight-bold my-3">
          Technologies available for Commercialization
        </h5>
        <div className="row">
          <div className="col-lg-4">
            <div className="liggrn">
              <div className="d-flex justify-content-between pt-2">
                {/* <p>Technologies</p>
                                <h4>1215</h4> */}
                <h4 className="text-center">{homeData[0]}</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ligblu">
              <div className="d-flex justify-content-between pt-2">
                {/* <p>Patents</p>
                                <h4>3001</h4> */}
                <h4 className="text-center">{homeData[1]}</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ligpnk">
              <div className="d-flex justify-content-between pt-2">
                {/* <p>Institutions</p> */}
                <h4 className="text-center">{homeData[2]}</h4>
                {/* <h4>230</h4> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid my-5">
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <form className="grybg signin-form filter_form mt-10">
              <div className="">
                <h5 className="input_Lable mb-3 text-center">Filter</h5>
                <div className="">
                  <div className="form-group mb-3 text-center">
                    <FormLabel shrink="true" className="input_Lable">
                      Institute Name
                    </FormLabel>
                    <Controller
                      name="institute"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          {...field}
                          sx={{ background: "white" }}
                          ListboxProps={{
                            style: { textAlign: "left" },
                          }}
                          value={instituteName}
                          options={challengesDataList}
                          onChange={(e, selectedOptions) =>
                            handleSelectChallenges(selectedOptions)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Filter by Institute Name"
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.institute && errors.institute.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="">
                  <div className="form-group mb-3 text-center">
                    <FormLabel shrink="true" className="input_Lable">
                      Sector{" "}
                    </FormLabel>
                    <Controller
                      name="sector"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          {...field}
                          sx={{ background: "white" }}
                          ListboxProps={{
                            style: { textAlign: "left" },
                          }}
                          value={selectSector}
                          options={sectorDataList.map(
                            (option) => option.sector
                          )}
                          onChange={(e, selectedOptions) =>
                            handleSelectSector(selectedOptions)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Filter by Sector"
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.sector && errors.sector.message}
                    </FormHelperText>
                  </div>
                </div>
                {/* <div className=''>
                  <div className='form-group mb-3 text-center'>
                    <FormLabel shrink="true" className='input_Lable'>Stage of development </FormLabel>
                    <Controller name="stageOfDevelopment" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <Autocomplete
                          disableClearable
                          disablePortal
                          {...field}
                          sx={{ background: 'white' }}
                          value={selectStage}
                          options={stageData}
                          onChange={(e, selectedOptions) => handleSelectstage(selectedOptions)}
                          renderInput={(params) => <TextField {...params} placeholder="Filter by Stage of development" />}
                        />
                      } />
                    <FormHelperText className='text-danger'>{errors.stageOfDevelopment && errors.stageOfDevelopment.message}</FormHelperText>
                  </div>
                </div> */}
                {(instituteName !== "" ||
                  selectSector !== "" ||
                  selectStage !== "") && (
                  <div className="col-12 text-center">
                    <Button
                      onClick={resetFiliter}
                      variant="outlined"
                      color="error"
                    >
                      Reset
                    </Button>
                  </div>
                )}
              </div>
            </form>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <div className="grybg">
              {inventorListValue.length > 0 ? (
                <>
                  <h5 className="text-center font-weight-bold my-3">
                    Technology Portfolio
                  </h5>
                  <div className="row mt-3">
                    {inventorListValue &&
                      inventorListValue.map((item, index) => (
                        <div className="col-lg-4 col-12">
                          <div className="portfolio-card">
                            <div className="whitebx p-3" key={index}>
                              <h5 className="text-center">{item.title}</h5>
                            </div>
                            <div className="techmarket-box-height">
                              <p className="pt-4">
                                Chief Inventor : <b>{item.inventorName}</b>
                              </p>
                              <p>
                                Institution : <b>{item.instituteName}</b>
                              </p>
                              <p>
                                Stage of Development :{" "}
                                <b>{item.stageOfDevelopment}</b>
                              </p>
                              <p>
                                Sector : <b>{item.sector}</b>
                              </p>
                            </div>
                            <center>
                              <button
                                className="morebtn"
                                onClick={() => {
                                  handleTechdetail(item.id, item.userId);
                                }}
                              >
                                Know More
                              </button>
                            </center>
                          </div>
                        </div>
                      ))}
                    {totalPage > 1 && (
                      <div className="col-12">
                        <Stack spacing={2}>
                          <Pagination
                            count={totalPage}
                            page={page}
                            onChange={handleChange}
                          />
                        </Stack>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="no_data_div">
                  <img
                    src="/images/blue-logo.png"
                    alt=""
                    className="img-fluid mb-4"
                  />
                  <h5 className="no_data_h5">
                    Technology Portfolio Not Available
                  </h5>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default Techmarket;
