import { Autocomplete, Button, FormHelperText, FormLabel, Switch, TextField, Fab } from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import EditorToolbars, { modules, formats } from "./EditorToolbars";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import Switch from '@mui/material/Switch';
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import notifyService from '../../../api/notifySerivce';
import DataContext from '../../../context/DataContext';
import { Link, useParams } from 'react-router-dom';
import PreviewIcon from '@mui/icons-material/Preview';
import DOMPurify from 'dompurify';
import PDFViewer from '../Techmarket-page/PDFViewer';
import Viewimage from '../../../api/Viewimage';
// import { MuiFileInput } from 'mui-file-input';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const Inventoradd = ({ investorId, handleClose, htmlContent, userId }) => {
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    const { navigator,setPageLoading } = useContext(DataContext)
    const { readyOnlyValue, setReadyOnlyValue, VisuallyHiddenInput, toolbarOptions, validateEmail, validatePhoneNumber, } = useContext(DataContext)
    const [formData, setFormData] = useState()
    const { handleSubmit, control, setValue, watch, getValues, clearErrors, reset, formState: { errors } } = useForm({ values: formData });
    const [checkMethod, setCheckMethod] = useState('save')
    const [inCorporationValue, setInCorporationValue] = useState(null)
    const [inCorporationName, setInCorporationName] = useState(null)
    const [inCorporationURL, setInCorporationURL] = useState(null)
    useDidMountEffect(() => {
        dropdownValueApi()
        if (investorId !== 0) {
            getApi()
        } else {
            setValue('approvalStatus', 1)
            photosAppend();
        }
        // console.log(getValues('pdfDescription'))
    }, [])
    // if (photosFields.length === 0) {
    //     photosAppend();
    // }
    const deletePhotos = async (index) => {
        const id = getValues(`techMarketImage.${index}.id`);
        if (id) {
            apiService(`techmarket/image/delete?id=${id}`, '', 'get').then((res) => {
                if (res) {

                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Deleted successfully !!')
                        photosRemove(index)

                    }
                }
            })
        } else {
            photosRemove(index)
        }

    }




    const getApi = () => {
        setPageLoading(true)
        setFormData([])
        apiService(`techmarket/get?id=${investorId}&userId=${userId}`, '', 'get').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data) {
                    // var response = res.data
                    // 

                    res.data.techMarketImage.forEach(element => {
                        addPhotoDetails()
                        // debugger
                        // element.imageURL=element
                    });
                    setFormData(res.data)
                    if (res.data.patentApplied) {
                        setPatentApplied(res.data.patentApplied);
                    }

                    debugger
                }
            }
        })
    }
    let rolVal = localStorage.getItem("role")
    const [technologyAreasDataList, setTechnologyAreasDataList] = useState([])
    const [sectorDataList, setsectorDataList] = useState([])
    const [developmentList, setDevelopmentList] = useState([])
    const dropdownValueApi = () => {
        apiService(`admin/dropdownvalues/get?key=dropdown`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            setsectorDataList(val.sectors)
            setDevelopmentList(val.stageofdevelopment)

        })
    }


    const handleSelectSector = (value) => {
        setValue('sector', value)
        clearErrors('sector')
        setValue('sectorOther', '')
    }

    const handleSelectStage = (selectedOption) => {
        setValue('stageOfDevelopment', selectedOption)
        clearErrors('stageOfDevelopment')
    }
    const [content, setContent] = useState('');
    const handleContentChange = (value) => {
        setContent(value);
    };

    const inputRef = useRef(null);
    const handleFileUpload = (event, filekey, fileName, setdata, setName, setUrl, format) => {
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type
                    var imagedatatype = imagetype.split("/")
                    var img_crt_type = imagedatatype[1]
                    if (img_crt_type === "pdf") {
                        setValue(filekey, "")
                        setValue(fileName, "")
                        setName('')
                        setUrl('')
                        if (event.target === undefined) {
                            setdata(event)
                        } else {
                            setdata(event.target.files[0])
                        }
                        var fileValue = logoselecetdFile
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(',')
                            var ImageValue = spl[1]
                            var img_name = fileValue.name
                            setUrl(logourl1)
                            setValue(filekey, ImageValue)
                            setValue(fileName, img_name)
                            setName(img_name)
                            clearErrors(filekey);
                            clearErrors(fileName);
                        }
                    } else {
                        notifyService('danger', 'File Format Invalided', 'Please check your file format')
                    }
                } else {
                    notifyService(
                        "danger",
                        "File size exceeds 5MB",
                        "Please check your file size"
                    );
                }
            }
        } else {
            // setValue(filekey, "")
            // setValue(fileName, "")
            // setName('')
            // setUrl('')
            // inputRef.current.value = '';
        }
    }

    // const handleSwitchChange = (e, name) => {
    //     if (e.target.checked === true) {
    //         setValue(name, true)
    //     } else {
    //         setValue(name, false)
    //     }
    // };

    const {
        fields: photosFields,
        append: photosAppend,
        remove: photosRemove,
    } = useFieldArray({
        control,
        name: "techMarketImage",
    });
    const addPhotoDetails = () => {
        photosAppend({
            id: 0,
            image: "",
            imageName: "",
            imageURL: "",
        });
    };
    // let logoselecetdFile = "";
    // const handleFileUploads = (event, image, imageName, imageURL, index) => {
    //     if (event !== null) {
    //         if (event.target === undefined) {
    //             logoselecetdFile = event;
    //         } else {
    //             logoselecetdFile = event.target.files[0];
    //         }
    //         if (logoselecetdFile) {
    //             var reader = new FileReader();
    //             var imagetype = logoselecetdFile.type;
    //             var imagedatatype = imagetype.split("/");
    //             var img_crt_type = imagedatatype[1];
    //             if (
    //                 img_crt_type === "jpeg" ||
    //                 img_crt_type === "jpg" ||
    //                 img_crt_type === "png"
    //             ) {
    //                 var fileValue = logoselecetdFile;
    //                 reader.readAsDataURL(logoselecetdFile);
    //                 reader.onload = () => {
    //                     var logourl1 = reader.result;
    //                     var spl = logourl1.split(",");
    //                     var ImageValue = spl[1];
    //                     var img_name = fileValue.name;
    //                     setValue(`techMarketImage.${index}.${image}`, ImageValue);
    //                     setValue(`techMarketImage.${index}.${imageName}`, img_name);
    //                     setValue(`techMarketImage.${index}.${imageURL}`, logourl1);
    //                     clearErrors(image);
    //                     clearErrors(imageName);
    //                     clearErrors(imageURL);
    //                 };
    //             } else {
    //                 // notifyService(
    //                 //   "danger",
    //                 //   "File Format Invalided",
    //                 //   "Please check your file format"
    //                 // );
    //             }
    //         }
    //     }
    // };
    let logoselecetdFile = ''
    const handleFileUploads = (event, filekey, fileName, fileURL, arrayName, index) => {
        // 
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type
                    var imagedatatype = imagetype.split("/")
                    var img_crt_type = imagedatatype[1]
                    if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf") {
                        var fileValue = logoselecetdFile
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(',')
                            var ImageValue = spl[1]
                            var img_name = fileValue.name
                            // setValue(filekey, ImageValue)
                            // setValue(fileName, img_name)
                            // setValue(fileURL, logourl1)
                            if (arrayName !== '') {
                                setValue(`${arrayName}.${index}.${filekey}`, ImageValue)
                                setValue(`${arrayName}.${index}.${fileName}`, img_name)
                                setValue(`${arrayName}.${index}.${fileURL}`, logourl1)
                            } else {
                                setValue(filekey, ImageValue)
                                setValue(fileName, img_name)
                                setValue(fileURL, logourl1)
                            }
                            // setPartnerLogoURL(logourl1)
                            clearErrors(filekey);
                            clearErrors(fileName);
                            clearErrors(fileURL);
                        }
                    } else {
                        notifyService('danger', 'File Format Invalid', 'Please check your file format')
                    }
                } else {
                    notifyService(
                        "danger",
                        "File size exceeds 5MB",
                        "Please check your file size"
                    );
                }
            }
        }
    }
    const onSubmit = (data) => {
        setPageLoading(true)
        console.log(data)
        let getform = data
        getform.approvalStatus = 0
        // debugger
        apiService(`techmarket/save`, getform, 'post').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data.responseStatus === "Success") {
                    debugger
                    notifyService('success', res.data.responseStatus)
                    handleClose()
                    window.location.reload();
                    // if(rolVal === "Project Management Team"){
                    //     navigator('techmarket')
                    // }
                }
            }
        })
    }


    // const patentObtained = watch('patentObtained', false);
    // const handleFilter = (ispatentObtained) => {
    //     setValue('patentObtained', ispatentObtained);
    //     if (!ispatentObtained) {
    //         setValue('patentObtained', '');
    //     }
    // };

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value) => {
        setViewImage(value)
        setOpen(true);
    };
    const handleClickClose = () => {
        setOpen(false);
    };


    const [patentApplied, setPatentApplied] = useState(false);
    const patentObtained = watch('patentObtained', false);

    const handleSwitchChange = (e, name) => {
        if (e.target.checked === true) {
            setValue(name, true);
            if (name === 'patentApplied') {
                setPatentApplied(true);
            }
        } else {
            setValue(name, false);
            if (name === 'patentApplied') {
                setPatentApplied(false);
                setValue('patentObtained', false);

            }
        }
    };

    const handleFilter = (isPatentObtained) => {
        setValue('patentObtained', isPatentObtained);
        if (!isPatentObtained) {
            setValue('patentObtained', '');
        }
    };

    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
            <h4 className='head-blue p-4'>Add Technology</h4>
            <section>
                <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='col-lg-12 col-12'>
                        <div className='row'>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="inventorName" control={control} defaultValue="" rules={{ required: 'Inventor name is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Name of Inventor *"
                                                placeholder='Enter Inventor Name'
                                                autoFocus
                                                fullWidth
                                                {...field}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.inventorName && errors.inventorName.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="mobileNumber" control={control} defaultValue="" rules={{ required: 'Mobile Number is required', validate: validatePhoneNumber }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Mobile Number *"
                                                placeholder='Enter Mobile Number'
                                                fullWidth
                                                {...field}
                                                inputProps={{ maxLength: 10 }}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.mobileNumber && errors.mobileNumber.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="emailId" control={control} defaultValue="" rules={{ required: 'Email is required', validate: validateEmail }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Email Id *"
                                                placeholder='Enter Email Id'
                                                fullWidth
                                                {...field}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.emailId && errors.emailId.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="instituteName" control={control} defaultValue="" rules={{ required: 'Institute name is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Institute name *"
                                                placeholder='Enter Institute name'
                                                fullWidth
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.instituteName && errors.instituteName.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="title" control={control} defaultValue="" rules={{ required: 'Title is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Title *"
                                                placeholder='Enter Title'
                                                autoFocus
                                                fullWidth
                                                {...field}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.title && errors.title.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4'></div>
                            {/* <div className='col-lg-4 col-12 mb-3'>
                                <div className='form-group mt-2'>
                                    <div className='d-flex'>
                                        <span className='mt-1'>Is Patent Applied?</span>
                                        <Controller name='patentApplied' control={control} defaultValue='' render={({ field: props }) => (
                                            <span className='ml-5'>No <Switch checked={props.value === true} onChange={(e) =>
                                                handleSwitchChange(e, 'patentApplied')} disabled={readyOnlyValue} color="primary" /> Yes </span>
                                        )} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mb-3'>
                                <div className='form-group mt-2'>
                                    <div className='d-flex'>
                                        <span className='mt-1'>Is Patent Obtained?</span>
                                        <span className='ml-5'>No <Switch checked={patentObtained} disabled={readyOnlyValue} onChange={() => handleFilter(!patentObtained)} color="primary" /> Yes </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                {
                                    patentObtained === true && (
                                        <div className='form-group mt-2'>
                                            <Controller name='patentNumber' control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) => (
                                                    <TextField
                                                        variant='outlined'
                                                        label="Patent Number"
                                                        placeholder='Enter Patent Number'
                                                        {...field}
                                                        type='text'
                                                        className='form-control'
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                }
                            </div> */}
                            {/* <div className='row'> */}
                            <div className='col-lg-4 col-12 mb-3'>
                                <div className='form-group mt-2'>
                                    <div className='d-flex'>
                                        <span className='mt-1'>Is Patent Applied?</span>
                                        <Controller
                                            name='patentApplied'
                                            control={control}
                                            defaultValue={false}
                                            render={({ field: props }) => (
                                                <span className='ml-5'>
                                                    No
                                                    <Switch
                                                        checked={props.value === true}
                                                        onChange={(e) => handleSwitchChange(e, 'patentApplied')}
                                                        disabled={readyOnlyValue}
                                                        color="primary"
                                                    />
                                                    Yes
                                                </span>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            {patentApplied && (
                                <>
                                    <div className='col-lg-4 col-12 mb-3'>
                                        <div className='form-group mt-2'>
                                            <div className='d-flex'>
                                                <span className='mt-1'>Is Patent Obtained?</span>
                                                <span className='ml-5'>
                                                    No
                                                    <Switch
                                                        checked={patentObtained}
                                                        disabled={readyOnlyValue}
                                                        onChange={() => handleFilter(!patentObtained)}
                                                        color="primary"
                                                    />
                                                    Yes
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {patentObtained && (
                                        <div className='col-lg-4 col-12'>
                                            <div className='form-group mt-2'>
                                                <Controller
                                                    name='patentNumber'
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: false }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant='outlined'
                                                            label="Patent Number"
                                                            placeholder='Enter Patent Number'
                                                            {...field}
                                                            type='text'
                                                            className='form-control'
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {/* </div> */}

                            <div className='col-lg-4 col-12'>
                                <div className='form-group mb-3'>
                                    <FormLabel shrink="true" className='input_Lable'>Stage of Development <span className='text-danger'>*</span> </FormLabel>
                                    <Controller name="stageOfDevelopment" control={control} defaultValue="" rules={{ required: 'Stage Of Development Is required' }}  // 'Stage of Development is required'
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                ListboxProps={{
                                                    style: { textAlign: 'left' }
                                                  }}
                                                // options={developmentList.map((option) => option.stageofdevelopment)}
                                                options={developmentList ? developmentList.map((option) => option) : []}
                                                // options={developmentList.map((option) => option)}
                                                onChange={(e, selectedOptions) => handleSelectStage(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} placeholder="Select the Stage of Development" />}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.stageOfDevelopment && errors.stageOfDevelopment.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className='form-group mb-3'>
                                    <FormLabel shrink="true" className='input_Lable'>Sector <span className='text-danger'>*</span> </FormLabel>
                                    <Controller name="sector" control={control} defaultValue="" rules={{ required: 'Sector Name is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                ListboxProps={{
                                                    style: { textAlign: 'left' }
                                                  }}
                                                options={sectorDataList.map((option) => option.sector)}
                                                onChange={(e, selectedOptions) => handleSelectSector(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} placeholder="Select the Sector" />}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.sector && errors.sector.message}</FormHelperText>
                                </div>
                            </div>
                            {
                                getValues('sector') === 'Others' ? (
                                    <>
                                        <div className='col-lg-4 col-12'>
                                            <div className='form-group mb-3'>
                                                <FormLabel shrink="true" className='input_Lable'>Other Sector <span className='text-danger'>*</span> </FormLabel>
                                                <Controller name="sectorOther" control={control} defaultValue="" rules={{ required: 'Other Sector Name is required' }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            placeholder="Enter Other Sector Name"
                                                            fullWidth
                                                            type='text'
                                                            {...field}
                                                        />
                                                    } />
                                                <FormHelperText className='text-danger'>{errors.sectorOther && errors.sectorOther.message}</FormHelperText>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )
                            }
                            <div className='col-lg-12 col-md-6 col-12'>
                                <div className='form-group mb-3'>
                                    <FormLabel shrink="true" className='input_Lable'>Short Description<span className='text-danger'>*</span>  </FormLabel>
                                    <Controller name="shortDescription" control={control} defaultValue="" rules={{ required: 'Short Description is required' }}
                                        render={({ field }) =>
                                            <ReactQuill
                                                theme="snow"
                                                value={content}
                                                modules={toolbarOptions}
                                                formats={formats}
                                                onChange={(content, delta, source, editor) => {
                                                    handleContentChange(content);
                                                    field.onChange(content);
                                                }}
                                                placeholder="Enter Short Description"
                                                fullWidth
                                                type='text'
                                                {...field}
                                                multiline
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.shortDescription && errors.shortDescription.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                {
                                    (getValues('pdfURL') === undefined) ? (
                                        <div className="form-group mt-2">
                                            <Controller name="pdfName" control={control} defaultValue="" rules={{ required: 'File is required' }}
                                                render={({ field }) =>
                                                    <MuiFileInput
                                                        {...field}
                                                        onChange={(e) => handleFileUpload(e, "pdfDescription", "pdfName", setInCorporationValue, setInCorporationName, setInCorporationURL, 'file')}
                                                        value={inCorporationValue}
                                                        variant='outlined'
                                                        // inputProps={}
                                                        // onReset={(e) => handleremove(e)}
                                                        inputRef={inputRef}
                                                        // inputProps= {
                                                        //   ref=
                                                        // }

                                                        fullWidth
                                                        label="PDF Description *"
                                                        placeholder='Choose File' />
                                                } />
                                            <FormHelperText className='text-danger'>{errors.pdfName && errors.pdfName.message}</FormHelperText>

                                        </div>
                                    ) : (
                                        <div>
                                            <p className='mb-3'>PDF Description <span className='text-danger'>*</span></p>
                                            <div className='row'>
                                                <div className='col-lg-6 col-12'>
                                                    <Controller name="pdfName" control={control} defaultValue="" rules={{ required: false }}
                                                        render={({ field }) =>
                                                            <Button
                                                                component="label"
                                                                variant="contained"
                                                                {...field}
                                                                fullWidth
                                                                onChange={(e) => handleFileUpload(e, "pdfDescription", "pdfName", setInCorporationValue, setInCorporationName, setInCorporationURL, 'file')}
                                                                startIcon={<CloudUploadIcon />}
                                                                href="#file-upload"
                                                            >
                                                                Change
                                                                {
                                                                    (readyOnlyValue === false) ? (
                                                                        <VisuallyHiddenInput type="file" />
                                                                    ) : (<></>)
                                                                }

                                                            </Button>

                                                        } />

                                                </div>
                                                <div className='col-lg-6 col-12'>
                                                    {/* <Fab variant="extended"> */}
                                                    <Button onClick={() => handleClickOpen(getValues('pdfURL'))}>
                                                        {/* <PreviewIcon sx={{ mr: 1 }} /> */}
                                                        View Document
                                                    </Button>
                                                    {/* </Fab> */}
                                                </div>
                                            </div>
                                            <FormHelperText className='text-danger'>{errors.pdfName && errors.pdfName.message}</FormHelperText>

                                        </div>
                                    )
                                }
                                <div>
                                    <span className='fn-12'>Maximum 5 mb allowed doc (pdf) <span className='text-danger'>*</span></span>
                                </div>
                            </div>
                            <div className='col-lg-8'></div>
                            <div className='col-lg-12'>
                                <div className="d-flex align-items-center mb-3">
                                    {photosFields.length < 5 && (
                                        <Button className="ml-auto" onClick={addPhotoDetails}>
                                            Add Photos
                                            <AddIcon />
                                        </Button>
                                    )}
                                </div>
                                <div className="d-flex">
                                    {photosFields.map((field, index) => (
                                        <div>
                                            <Controller
                                                name={`techMarketImage.${index}.imageURL`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: "required",
                                                }}
                                                render={({ field }) => (
                                                    <>
                                                        <Button
                                                            {...field}
                                                            fullWidth
                                                            className="imgupload_div"
                                                            component="label"
                                                            size="small"
                                                            // sx={{ width: "0%" }}
                                                            onChange={(e) =>
                                                                handleFileUploads(e, "image", "imageName", "imageURL", "techMarketImage", index)
                                                            }
                                                        >
                                                            {getValues(`techMarketImage.${index}.imageURL`) ? (
                                                                <>
                                                                    <div className="">
                                                                        <img
                                                                            src={getValues(`techMarketImage.${index}.imageURL`)}
                                                                            className="vehicle_logo"
                                                                            alt={`techMarketImage.${index}.imageName`}
                                                                            style={{
                                                                                width: "100px",
                                                                                height: "100px",
                                                                                objectFit: 'contain',
                                                                            }}
                                                                        />


                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="camera_icon disPhotos">
                                                                    <VisuallyHiddenInput type="file" />
                                                                    <img
                                                                        src="/images/image-.png"
                                                                        className="no_logo"
                                                                        alt={`techMarketImage.${index}.imageName`}
                                                                        style={{
                                                                            width: "100px",
                                                                            height: "100px",
                                                                            objectFit: 'contain',
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                            <div>
                                                                {/* <p className="text-danger">Document is required </p> */}
                                                            </div>
                                                        </Button>
                                                        {
                                                            getValues(`techMarketImage.${index}.imageURL`) && (
                                                                <div className='text-center'>
                                                                    <DeleteForeverIcon
                                                                        color="error"
                                                                        onClick={() => deletePhotos(index)}
                                                                    // onClick={() => deletePhotos((`techMarketImage.${index}.id`), index)}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </>

                                                )}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center my-3'>
                        <LoadingButton
                            type="submit"
                            // loading={loading}
                            loadingPosition="start"
                            // startIcon={<SaveIcon />}
                            variant="contained"
                        >
                            Submit
                        </LoadingButton>
                        <Button variant='outlined' className='ml-3' onClick={handleClose}>Close</Button>
                    </div>
                </form>
            </section>
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClickClose} />
        </div>
    )
}

export default Inventoradd