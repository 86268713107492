import React, { useContext, useState } from 'react'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Autocomplete, Button, Divider, FormLabel, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DataContext from '../../../../context/DataContext';
import apiService from '../../../../api/apiService';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import downloadExcel from '../../../../hooks/downloadExcel';
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
const SearchinputDiv = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "21ch",
            "&:focus": {
                width: "27ch",
            },
        },
    },
}));
const SolutionApplicationList = ({ name }) => {
    const { navigator, setPageLoading } = useContext(DataContext)
    const [page, setPage] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState(1);
    let role = localStorage.getItem('role')
    const [title, setTitle] = useState('')
    useDidMountEffect(() => {
        handleSoluctionList()
        // challengesApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page,title])
    
    const handleSearch = (searchValue) => {
        setTitle(searchValue);
    };
    const [solutionListValue, getSoluctionValue] = useState([])
    const handleSoluctionList = () => {
        setPageLoading(true)
        let path
        var req
        if (role === 'Project Management Team' || role === 'GOVT') {
            path = 'requirement/application/list'
            req = {
                "listSize": 12,
                "pageNumber": page,
                "requirementTitle": title,
            }
        } else {
            path = 'requirement/solution/application/list'
            req = {
                "listSize": 12,
                "pageNumber": page,
                "requirementTitle": title,
            }
        }
        getSoluctionValue([])

        apiService(path, req, 'post').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data) {
                    if (res.data.responseModelList) {
                        getSoluctionValue(res.data.responseModelList)
                        setTotalPage(res.data.totalPages)
                    }
                }
            }
        })
    }
    const [loading, setLoading] = useState(false)
    const exportExcel = () => {
        // setDownloadValue(download)
        setLoading(true);
        // setPageLoading(true);
        var req = {
            "download": 'yes',
            "listSize": 12,
            "pageNumber": page,
        }
        apiService('requirement/application/list', req, 'downloadpost').then((res) => {
            setLoading(false);
            // setPageLoading(false);
            if (res) {
                if (res.data) {
                    downloadExcel(res.data, 'Requirement_Solution_')
                    // setDownloadValue('')
                }
            }
        })
    }
    const handleChange = (event, value) => {
        setPage(value);
    };
    const handleSolutionDetails = (id, userid) => {
        if (role === 'Project Management Team' || role === 'GOVT') {
            navigator(`/admin/requirement/status/solution-provider/${btoa(id)}/${btoa(userid)}`)
        } else {
            navigator(`/requirement/details/status/${btoa(id)}`)
        }

    }
    
    return (
        <div>
            <div className='Requirements-search'>
            <div>
                <SearchinputDiv
                    sx={{ display: { xs: "none", sm: "block" } }}
                    onChange={(e) => handleSearch(e.target.value)}
                >
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search by Requirement Title"
                        type="search"
                        inputProps={{ "aria-label": "Search by Requirement Title" }}
                    />
                </SearchinputDiv>
            </div>
            <div className='export-btn'>
                {
                    ((role === 'Project Management Team' || role === 'GOVT') && solutionListValue.length > 0) && (
                        <LoadingButton className='export-btn'
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<CloudDownloadIcon />}
                            variant="outlined"
                            onClick={exportExcel}
                        >
                            <span>EXPORT EXCEL</span>
                        </LoadingButton>
                    )
                }
            </div>
            </div>
            {
                solutionListValue.length > 0 ? (
                    <div className="row mt-3">
                        <div className='col-12'>
                            <p className='showPage'>Showing Page <span>{page}</span> of <span>{totalPage}</span></p>
                        </div>
                        {
                            solutionListValue.map((list, index) => (
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                    {
                                        (role === 'Project Management Team' || role === 'GOVT') ? (
                                            <div className='challege_status_div'>
                                                <div>
                                                    <h6>{list.requirementTitle}</h6>
                                                </div>
                                                <div className='status_details'>
                                                    {/* Participant Name : Surya solution */}
                                                    <p><span className='c-gray'> Name  :</span> <span className='c-blue font-lg f-weight-600'>{list.startupName ? (list.startupName) : (`-`)}</span></p>
                                                    <p><span className='c-gray'> DPIIT Registration No
                                                        :</span> <span className='c-blue font-lg f-weight-600'>{list.dpiitRegNo ? (list.dpiitRegNo) : (`-`)}</span></p>

                                                    {/* {
                                                        list.participant && list.participant.map((res, index) => (
                                                            <p><span className='c-gray'> Participant Name  :</span> <span className='c-blue font-lg f-weight-600'>{res.participantName}</span></p>
                                                        ))
                                                    } */}
                                                    {/* <p><span className='c-gray'>Email :</span> <span className='c-blue font-lg f-weight-600'>{list.email}</span></p> */}
                                                    <p><span className='c-gray'>Project Management Team Status : </span>
                                                        {/* <span className='c-green font-lg f-weight-600'>{list.pmtApproval === 1 && <>Approved</>}</span>
                                                        <span className='c-origin font-lg f-weight-600'>{list.pmtApproval === 0 && <>Pending for Review</>}</span>
                                                        <span className='c-red font-lg f-weight-600'>{list.pmtApproval === 2 && <>Rejected</>}</span> */}
                                                        <span className='c-blue font-lg f-weight-600'>{list.pmtApproval === 0 && <>Pending for Approval</>}</span>
                                                        <span className='c-green font-lg f-weight-600'>{list.pmtApproval === 1 && <>Approved</>}</span>
                                                        <span className='c-red font-lg f-weight-600'>{list.pmtApproval === 2 && <>Rejected</>}</span>
                                                    </p>
                                                </div>
                                                <div className='button_div text-center px-3'>
                                                    <Button className='view_live_btn' onClick={() => { handleSolutionDetails(list.id, list.userId) }}>View Details</Button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='live_req_list'>
                                                <div>
                                                    <Button className='challenges_pro_btn' onClick={() => { handleSolutionDetails(list.id, '') }}>
                                                        {list.requirementTitle}
                                                    </Button>
                                                </div>
                                                <p><span className='c-gray'> Name  :</span> <span className='c-blue font-lg f-weight-600'>{list.startupName ? (list.startupName) : (`-`)}</span></p>
                                                <p><span className='c-gray'> DPIIT Registration No
                                                    :</span> <span className='c-blue font-lg f-weight-600'>{list.dpiitRegNo ? (list.dpiitRegNo) : (`-`)}</span></p>
                                                <p>
                                                    <span className='c-gray'>Ends On </span>  <span className='c-blue font-md f-weight-600'>{list.endDate2 ? list.endDate2 : (`-`)}</span>
                                                </p>
                                                <p>
                                                    <span className='c-gray'>Project Management Team Status :</span>  <span className='c-blue font-md f-weight-600'>{list.pmtApproval === 0 && <>Pending for Approval</>}</span>
                                                    <span className='c-green font-md f-weight-600'>{list.pmtApproval === 1 && <>Approved</>}</span>
                                                    <span className='c-origin font-md f-weight-600'>{list.pmtApproval === 2 && <>Rejected</>}</span>
                                                </p>
                                                <div className='d-flex'>
                                                    <div className='view_div'>
                                                        <Button className='view_live_btn' onClick={() => { handleSolutionDetails(list.id) }}> View Details</Button>
                                                    </div>
                                                    <div className='ml-auto'>
                                                        <p className='c-gray mb-0 font-sm'>Last updated</p>
                                                        <p className='c-blue font-sm'>{list.lastUpdated}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            ))
                        },
                        {
                            totalPage > 1 && (
                                <div className='col-12'>
                                    <Stack spacing={2}>
                                        <Pagination count={totalPage} page={page} onChange={handleChange} />
                                    </Stack>
                                </div>
                            )
                        }

                    </div>
                ) : (
                    <div className='no_data_div'>
                        <img src='/images/blue-logo.png' alt='' className='img-fluid mb-4' />
                        <h5 className='no_data_h5'>Applications Not Available</h5>
                    </div>
                )
            }
        </div>
    )
}

export default SolutionApplicationList