import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Controller, useForm } from 'react-hook-form';
import { FormHelperText, FormLabel } from '@mui/material';
import apiService from '../../api/apiService';
import notifyService from '../../api/notifySerivce';
import { LoadingButton } from '@mui/lab';
import DataContext from '../../context/DataContext';
const Rejectreason = ({ open, handleClose, solutionId, challengeId, rejectLbl, handleBack }) => {
    const { setPageLoading } = useContext(DataContext)
    const { handleSubmit, control, formState: { errors } } = useForm({});
    const [loading, setLoading] = useState(false);
    let role = localStorage.getItem('role')
    const onSubmit = async (data) => {
        setPageLoading(true)
        setLoading(true);
        var getform = data
        var req = {}
        let path
        if (role === 'Project Management Team') {
            if (rejectLbl === 'Solution Reject') {
                path = 'admin/solution/approve'
                req = {
                    "solutionId": solutionId,
                    "approval": 3,
                    "remarks": getform.remarks
                }
            } else if (rejectLbl === 'Requirement Reject') {
                path = 'admin/requirement/approve'
                req = {
                    "solutionId": solutionId,
                    "approval": 3,
                    "remarks": getform.remarks
                }
            } else {
                path = 'admin/challenge/approve'
                req = {
                    "challengeId": challengeId,
                    "approval": 3,
                    "remarks": getform.remarks
                }
            }
        } else {
            path = 'admin/solution/shortlist'
            req = {
                "challengeId": challengeId,
                "solutionId": solutionId,
                "approval": 2,
                "remarks": getform.remarks
            }
        }

        apiService(path, req, 'post').then((res) => {
            setPageLoading(false)
            setLoading(false);
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', res.data.responseMessage)
                    handleClose()
                    handleBack()
                }
            }
        })
    }
    const onError = (errors, e) => {
        notifyService('danger', 'Form is invalid', 'Please check the mandatory fields')
    };
    return (
        <>
            <Dialog open={open}>
                <DialogTitle>Reject Reason</DialogTitle>
                <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
                    <DialogContent>
                        {/* <DialogContentText>
                            To subscribe to this website, please enter your email address here. We
                            will send updates occasionally.
                        </DialogContentText> */}
                        <div className='row'>
                            <div className='col-12'>
                                <div className='form-group mb-3'>
                                    <FormLabel shrink="true" className='input_Lable'>Remark <span className='text-danger'>*</span>  </FormLabel>
                                    <Controller name="remarks" control={control} defaultValue="" rules={{ required: 'Remark is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                placeholder="Enter Remark"
                                                fullWidth
                                                type='text'
                                                multiline={true}
                                                rows={3}
                                                {...field}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.remarks && errors.remarks.message}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <LoadingButton
                                loadingPosition="start"
                                type="submit"
                                loading={loading}
                                className="b-yellow-btn"
                            >
                                <span>Reject</span>
                            </LoadingButton>
                            <Button className='blue-btn ml-3' onClick={handleClose}>Cancel</Button>
                        </div>
                    </DialogContent>

                </form>
            </Dialog>
        </>
    )
}

export default Rejectreason