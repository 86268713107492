// import { DatePicker } from '@mui/lab';
import React, { useState } from 'react'
import Chart from 'react-apexcharts'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Helmet } from 'react-helmet';
const Dashboard = () => {
    const [victimsChartData, setVictimsChartData] = useState({
        series: [
            {
                name: 'Approved',
                type: 'column',

                data: [0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    8],
            },
            {
                name: 'Rejected',
                type: 'column',
                data: [0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    10],
            },
        ],
        colors: ['#03C988', '#FF5E5E'],
        chart: {
            height: 350, type: 'line', stacked: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [1, 1, 4],
        },
        // title: {
        //     text: 'FIR AND VICTIM', align: 'center',
        // },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: [
            {
                // axisTicks: {
                //     show: true,
                // },
                // axisBorder: {
                //     show: true
                // },
                // labels: {
                //     style: {
                //         colors: '#008FFB',
                //     },
                // },
                tooltip: {
                    enabled: true,
                },
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', offsetY: 30, offsetX: 60,
            },
        },
        legend: {
            horizontalAlign: 'center', offsetX: 20,
        },
    });
    const [slutionProvidersChartData, setSolutionProvidersChartData] = useState({
        series: [{
            name: 'Approved',
            data: [3,
                2,
                2,
                0,
                0]
        }, {
            name: 'Rejected',
            data: [1,
                0,
                0,
                0,
                0]
        }, {
            name: 'Hold',
            data: [0,
                0,
                0,
                0,
                0]
        },

        ],
        colors: ['#03C988', '#2B377A', '#F8AC22'],
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        // title: {
        //   text: 'VICTIMS BASED ON AGE', align: 'center',
        // },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        // xaxis: {
        //   type: '',
        //   categories: ['0 to 20', '21 to 40', '41 to 60', '61 to 80', '80 to 100'],
        // },
        legend: {
            horizontalAlign: 'center', offsetX: 20,
        },
        fill: {
            opacity: 1
        }
    });
    // const [value, setValue] = React.useState(new Date());
    return (
        <>
        <Helmet>
            <title>Open Innovation Portal | Admin | Dashboard</title>
        </Helmet>
            <main className='dashboard_section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='challenges_count'>
                                <div className='rount_count'>
                                    <img src='/images/count1.png' alt='' className='rount_count_img' />
                                </div>
                                <h3>25</h3>
                                <h5><b>Total Challenges</b></h5>
                                <p className='c-gray'>Live Challenges</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='challenges_count'>
                                <div className='rount_count'>
                                    <img src='/images/count2.png' alt='' className='rount_count_img' />
                                </div>
                                <h3>25</h3>
                                <h5><b>New Challenges</b></h5>
                                <p className='c-gray'>Live Challenges</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='challenges_count'>
                                <div className='rount_count'>
                                    <img src='/images/count3.png' alt='' className='rount_count_img' />
                                </div>
                                <h3>25</h3>
                                <h5><b>Application Submitted</b></h5>
                                <p className='c-gray'>Live Challenges</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div className='live_dashboard_dev'>
                                <h5>Live Challenges</h5>
                                <div className='box_dashboard'>
                                    <div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker label="Monthly" views={['month', 'year']} slotProps={{ textField: { size: 'small' } }}/>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <Chart options={victimsChartData} series={victimsChartData.series} type="line" width="500" />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div className='live_dashboard_dev'>
                                <h5>Solution Providers</h5>
                                <div className='box_dashboard'>
                                    <div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker label="Monthly" views={['month', 'year']} slotProps={{ textField: { size: 'small' } }}/>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <Chart options={slutionProvidersChartData} series={slutionProvidersChartData.series} type="bar" width="500" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Dashboard