import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from '@mui/icons-material/Delete';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import Jurysave from "./Jurysave";
// import DataContext from "../../../context/DataContext";
import TablePagination from "@mui/material/TablePagination";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { LoadingButton } from "@mui/lab";
import apiService from "../../../../api/apiService";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import Bulkupload from "../../admin-module/Bulkupload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import downloadExcel from "../../../../hooks/downloadExcel";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { Dialog } from "@mui/material";
import Juryadd from "./Juryadd";
import DataContext from "../../../../context/DataContext";
import notifyService from "../../../../api/notifySerivce";
import Associatejurysave from "./Associatejurysave";
import Viewjurynamelist from "./Viewjurynamelist";
const SearchinputDiv = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "19ch",
            "&:focus": {
                width: "27ch",
            },
        },
    },
}));
const Associatejury = () => {
    const {
        setPageLoading,
        // handleChangePage,
        // handleChangeRowsPerPage,
        // count,
        // page,
        // rowsPerPage,
        modalstyle,
    } = useContext(DataContext);
    // const [partnerListData, setPartnerListData] = useState([]);
    // const [searchInputValue, setSearchInputValue] = useState("");
    // const roleName = localStorage.getItem("role");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('')
    useDidMountEffect(() => {
        challengeListItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, page, search]);


    // const [rowsPerPage,rowsPerPage]=useState()
    // const [page,setPage]=useState()
    const [count, setCount] = useState(0);
    const [challengeListValue, setChallengeListValue] = useState([])
    const [juryList, setJuryList] = useState([])
    const challengeListItem = () => {
        setPageLoading(true)
        setChallengeListValue([])
        var req = {
            "listSize": rowsPerPage,
            "pageNumber": page + 1,
            "challengeType": 1,
            "challengeTitle": search
        };
        apiService("challenge/jury/list", req, "post").then((res) => {
            setPageLoading(false);
            if (res) {
                setCount(res.data.count)
                if (res.data.challengeJuryList) {
                    setChallengeListValue(res.data.challengeJuryList)
                    setJuryList(res.data.challengeJuryList.juryList)
                }
            }
        });
    };
    // pagination code

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        challengeListItem()
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        challengeListItem()
    };
    // const [loading, setLoading] = useState(false);

    const [challengeId, setChallengeId] = useState(0)
    const [open, setOpen] = useState(false);
    const handleOpen = (id) => {
        setOpen(true);
        setChallengeId(id)
    }
    const handleClose = () => {
        setOpen(false);
        challengeListItem()

        setItemsChecked(false)
        setIndexValue([])
        setSelectedChallengeList([])
        setSelectedClaimIds([])
        setSelectedSolutionsIds([])
    }
    const [viewJuryData, setViewJuryData] = useState([])
    const [openJury, setOpenJury] = React.useState(false);
    const [solutionId, setSolutionId] = useState('')
    // const []
    const viewJury = (value, id, solutionId) => {
        // console.log(value);

        setViewJuryData(value)
        setChallengeId(id)
        setSolutionId(solutionId)
        // setAssignStatus(status)
        setOpenJury(true);
    }
    const handleCloseJury = () => {
        setViewJuryData([])
        setChallengeId()
        // setAssignStatus('')
        setOpenJury(false);
        challengeListItem()

    }



    // const [selectAll, setSelectAll] = useState(false);
    const [selectedChallengeList, setSelectedChallengeList] = useState([]);
    const [selectedClaimIds, setSelectedClaimIds] = useState([]);
    const [selectedSolutionsIds, setSelectedSolutionsIds] = useState([]);
    // const [singleSelect, setSingleSelect] = useState([]);
    // const [checkbox, setCheckbox] = useState(false)
    // Handle individual checkbox change

    // const handleCheckboxChange = (claimId) => {
    //     console.log(claimId);
    //     setSelectedClaimIds(prevSelectedClaimIds => {
    //         const updatedSelectedClaimIds = prevSelectedClaimIds.includes(claimId)
    //             ? prevSelectedClaimIds.filter(id => id !== claimId)
    //             : [...prevSelectedClaimIds, claimId];
    //         setSingleSelect(updatedSelectedClaimIds)
    //         console.log(updatedSelectedClaimIds); 
    //         return updatedSelectedClaimIds;
    //     });
    // };
    const [itemsChecked, setItemsChecked] = useState(false);
    const [indexValue, setIndexValue] = useState([])
    const handleCheckboxChange = (event, i, claimId, solutionId) => {
        const { checked } = event.target;
        if (checked) {
            setSelectedClaimIds(prev => [...prev, claimId]);
            setSelectedSolutionsIds(prev => [...prev, solutionId]);
            setIndexValue([...indexValue, i])
            var req = {
                "challengeId": claimId,
                "solutionId": solutionId
            }
            setSelectedChallengeList(prev => [...prev, req]);
            sectFgs()
        } else {
            setIndexValue(indexValue => indexValue.filter((item) => item !== i))
            setSelectedClaimIds(selectedClaimIds.filter(item => item !== claimId));
            setSelectedSolutionsIds(selectedSolutionsIds.filter(item => item !== solutionId));
            setSelectedChallengeList(selectedChallengeList.filter((item, index) => index !== i));
            if (selectedSolutionsIds.length === 1) {
                setSelectedSolutionsIds([])
            }
            setItemsChecked(false);
        }

    };
    const sectFgs = () => {
        if ((challengeListValue.length - 1) === indexValue.length) {
            setItemsChecked(true);
        } else {
            setItemsChecked(false);
        }
    }
    const handleSelectAllChange = (event) => {
        const { checked } = event.target;
        if (checked) {
            const allClaimIds = challengeListValue.map(val => val.solutionId);
            setSelectedSolutionsIds(allClaimIds);
            let valPush = []
            challengeListValue.forEach(element => {
                var req = {
                    "challengeId": element.challengeId,
                    "solutionId": element.solutionId
                }
                valPush.push(req)
            });
            setSelectedChallengeList(valPush);

            // console.log('IDs to be selected:', allClaimIds);            
        } else {
            setSelectedSolutionsIds([]);
        }
        setItemsChecked(checked);
    };

    const handleSearch = (searchValue) => {
        setSearch(searchValue);
    };
    const isButtonVisible = selectedSolutionsIds.length > 0;
    return (

        <section>
            <div className="jury-btn-flex">
                <div>
                    <SearchinputDiv
                        sx={{ display: { xs: "none", sm: "block" } }}
                        onChange={(e) => handleSearch(e.target.value)}
                    >
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder={`Search by Title `}
                            type="search"
                        />
                    </SearchinputDiv>
                </div>
                {isButtonVisible && (
                    <div >
                        <Button variant="contained" onClick={() => handleOpen(challengeId)}>Associate Jury</Button>
                    </div>
                )}

            </div>
            <div className="container-fluid">
                <div className="form_div chart">
                    <div className="header_tag mb-3">
                    </div>
                    <div className="table_tag">

                        {challengeListValue.length ? (
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <input
                                                    type="checkbox"
                                                    checked={itemsChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </TableCell>
                                            <TableCell>No</TableCell>
                                            <TableCell sx={{ maxWidth: '100px' }}>Challenge Title</TableCell>
                                            <TableCell sx={{ maxWidth: '100px' }}>Solution Provider Name</TableCell>
                                            <TableCell align='center'>Jury</TableCell>
                                            {/* <TableCell>Status</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {challengeListValue.map((list, index) => (
                                            <TableRow key={list.challengeId}>
                                                <TableCell sx={{ width: '5%' }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedSolutionsIds.includes(list.solutionId)}
                                                        onChange={(e) => handleCheckboxChange(e, index, list.challengeId, list.solutionId)}
                                                    />
                                                </TableCell>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell sx={{ width: '30%' }}>{list.challengeTitle}</TableCell>
                                                <TableCell >{list.solutionProviderName}</TableCell>
                                                {/* <TableCell align='center'>
                                                    <p><span className='c-gray'></span>  <span className='c-blue f-weight-600'>{list.pmtApproval === 0 && <>Pending for Approval</>}</span>
                                                        <span className='c-origin f-weight-600'>{list.pmtApproval === 1 && <>On Hold</>}</span>
                                                        <span className='c-green f-weight-600'>{list.pmtApproval === 2 && <>Approved</>}</span>
                                                        <span className='c-red f-weight-600'>{list.pmtApproval === 3 && <>Rejected</>}</span></p>
                                                </TableCell> */}
                                                <TableCell align='center'>
                                                    {list.juryList ? (
                                                        <Button onClick={() => viewJury(list.juryList, list.challengeId, list.solutionId)}>View Jury</Button>
                                                    ) : (
                                                        <>
                                                            -
                                                        </>


                                                    )}
                                                    {/* <Button onClick={() => viewJury(list.juryList,list.challengeId)}>View Jury</Button> */}
                                                </TableCell>
                                                {/* <TableCell >
                                                    NA */}
                                                    {/* <Button variant="contained" onClick={() => handleOpen(list.id)}>Associate Jury</Button> */}
                                                    {/* <EditIcon style={{ fill: '#66bb6a', cursor: 'pointer' }} onClick={() => editValue(row.id)} /> */}
                                                    {/* <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }}  /> */}
                                                {/* </TableCell> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <caption>
                                        <TablePagination
                                            component="div"
                                            count={count}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </caption>
                                </Table>
                            </TableContainer>
                        ) : (
                            <div className="nodata_tag">
                                <p>Associate Jury is empty</p>
                            </div>
                        )}
                    </div>
                    <Dialog
                        fullWidth={true}
                        maxWidth={`sm`}
                        open={open}
                    >
                        <Box className="form_tag p-4">
                            <Associatejurysave handleClose={handleClose} selectedClaimIds={selectedChallengeList} />
                        </Box>
                    </Dialog>
                    <Dialog
                        open={openJury}
                        onClose={handleCloseJury}
                        fullWidth={true}
                        maxWidth={`md`}
                    >
                        <Box className="form_tag p-4">
                            <Viewjurynamelist setSelectedClaimIds={setSelectedClaimIds} solutionId={solutionId} viewJuryData={viewJuryData} challengeId={challengeId} setOpenJury={setOpenJury} challengeListItem={challengeListItem} />
                        </Box>
                    </Dialog>
                </div>
            </div>
        </section>
    )
}

export default Associatejury