import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DataContext from '../../context/DataContext';
// import cryptoJS from '../../hooks/cryptoJS';
import notifyService from '../../api/notifySerivce';
import apiService from '../../api/apiService';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { FormHelperText, FormLabel, TextField } from '@mui/material';
import cryptoJS from '../../hooks/cryptoJS';
import ReCAPTCHA from 'react-google-recaptcha';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const Forgetpage = ({ handleClose }) => {

    const { setPageLoading, validateEmail } = useContext(DataContext)
    const reCAPTCHA=process.env.REACT_APP_CAPTCHA
    // const [formData, setFormData] = useState({});
    const { handleSubmit, control, formState: { errors } } = useForm({});
    const [loading, setLoading] = useState(false);
    const [capVal, setCapVal] = useState(null)
    const onSubmitemail = async (data) => {
        setPageLoading(true)
        setLoading(true);
        var getform = data
        getform.email = cryptoJS(data.email)
        apiService(`user/password/reset`, getform, 'unauthpost').then((res) => {
            setPageLoading(false)
            setLoading(false);
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', res.data.responseMessage)
                    handleClose()
                } else {
                    notifyService('danger', res.data.responseMessage)
                }
            }
        })
    }
    const onMailError = (errors, e) => {
        notifyService('danger', 'Form is invalid', 'Please check the mandatory fields')
    };

    return (
        <>
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Forgot Password
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmitemail, onMailError)}>
                        <div className='row'>
                            <div className='col-lg-12 col-12'>
                                <div className='form-group mb-3'>
                                    <FormLabel shrink="true" className='input_Lable'>Email <span className='text-blue'></span></FormLabel>
                                    <Controller name="email" control={control} defaultValue="" rules={{ required: `Email is required`, validate: validateEmail }}
                                        render={({ field }) =>
                                            <TextField
                                                placeholder='Enter your Email'
                                                fullWidth
                                                autoFocus
                                                type='text'
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.email && errors.email.message}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <div className='d-flex justify-content-center mb-3'>
                                {/* <ReCAPTCHA sitekey={reCAPTCHA} onChange={val => setCapVal(val)} /> */}
                            </div>
                            <LoadingButton
                                loadingPosition="start"
                                type="submit"
                                loading={loading}
                                // disabled={!capVal}
                                className="b-yellow-btn"
                            >
                                <span>Send</span>
                            </LoadingButton>
                            <Button className='blue-btn ml-3' onClick={handleClose}>Cancel</Button>
                        </div>
                    </form>
                </Typography>
            </Box>


        </>
    )
}

export default Forgetpage