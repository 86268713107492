import React from 'react'
import { Helmet } from 'react-helmet'

const Resourcespage = () => {
  return (
    <>
      <Helmet>
        <title>Open Innovation Portal | Resources</title>
      </Helmet>
        <section>
            <div className='bg-resources'>
                <img src='/images/resources-bg.jpg' className='res-pg-bg img-fluid' alt='' />
                <h1 className='res-pg-bg-h1 font-weight-bold'>Open Innovation</h1>
                <h1 className='res-pg-bg-h2 font-weight-bold'>Resources</h1>
            </div>
        </section>

        <section>
            <center><h1 className='font-weight-bold my-5'>Resources PDF</h1></center>
            <div className='container'>
                <div className='row mb-5'>
                    <div className='col-lg-6'>
                        <center><div><a href="https://startuptn-public-files.s3.ap-south-1.amazonaws.com/nasscom-avasant-open-innovation-in-india.pdf"><img src='/images/file.png' className='pdf mb-5' alt='' />Click Here</a></div>
                        <h5>Nasscom Avasant Open Innovation PDF</h5></center>
                    </div>
                    <div className='col-lg-6'>
                        <center><div><a href="https://startuptn-public-files.s3.ap-south-1.amazonaws.com/NASSCOM_Open_Innovation_Final_Oct_2022.pdf"><img src='/images/file.png' className='pdf mb-5' alt='' />Click Here</a></div>
                        <h5>NASSCOM Open Innovation Final PDF</h5></center>
                    </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default Resourcespage