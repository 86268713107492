import React, { useContext, useState } from 'react';
import { Autocomplete, Checkbox, Container, FormHelperText } from '@mui/material'
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import DataContext from '../../../../context/DataContext';
import { Controller, useForm } from 'react-hook-form';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import cryptoJS from '../../../../hooks/cryptoJS';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Juryadd = ({ handleClose, userId }) => {
    const { readyOnlyEmail, validatePhoneNumber, validateEmail, btnLoading, setBtnLoading,setPageLoading } = useContext(DataContext)
    const [lableName, setLableName] = useState('Add')
    const [registerData, setRegisterData] = useState({});
    const { handleSubmit, control, getValues, reset, setError, clearErrors, formState: { errors } } = useForm(
        { values: registerData }
    );
    const [paData, setPartnerListData] = useState([])
    const [searchInputValue, setSearchInputValue] = useState('');
    useDidMountEffect(() => {
        if (userId !== 0) {
            (async () => await getRegisterItem())()
            setLableName('Update')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // get user api   
    const getRegisterItem = async () => {
        apiService(`user/get?id=${userId}`, '', 'get').then((res) => {
            if (res) {
                //   setReadyOnlyValue(true)
                setRegisterData(res.data)
                //   if (res.data.preferredSectors) {
                //     setPreferredSectors(res.data.preferredSectors)
                //   }
            }
        })
    }
    // const onSubmit = (data, event) => {
    //   var getform = data
    //   
    //   apiService('jury/save', getform, 'post').then((res) => {
    //     setLoading(false);
    //     if (res) {
    //       notifyService('success', 'Success', 'Your registration details added successfully !!')
    //       event.target.reset();
    //       handleClose()
    //       handleSuccessOpen()
    //     }
    //   })
    // }
    // const [email, setEmail] = useState()
    // const [associateJury, setassociateJury] = useState(false)
    const onSubmit = (data) => {
        handleSave(data, false)
    };
    const associateJurySave = () => {
        var data = getValues()
        handleSave(data, true)
    }
    const [associate, setAssociate] = useState(true)
    const handleSave = (data, associateJury) => {
        setPageLoading(true)
        var getform = data;
        getform.associateJury = associateJury
        getform.email = cryptoJS(data.email)
        getform.phone = cryptoJS(data.phone)
        apiService('scoring/jury/add', getform, 'post').then((res) => {
            setPageLoading(false)
            setBtnLoading(false);
            if (res && res.data) {
                // setEmail(res.data.email)
                debugger
                
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', res.data.responseMessage);
                    handleClose();
                    handleAlartClose();
                } else if (res.data.responseStatus === "Conflict") {
                    notifyService('warning', 'Warning', res.data.responseMessage);
                    handleAlartOpen();
                }
            }

        });
    }

    const [openSuccess, setOpenSuccess] = useState(false);
    const handleSuccessOpen = () => setOpenSuccess(true);

    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const handleAlartOpen = () => {
        setOpen(true);
    };
    const handleAlartClose = () => {
        setOpen(false);
    };
    return (
        <section className=''>
            <Container>
                <div className='header_tag'>
                    <h5 className='c-black'>{lableName} Jury</h5>
                </div>
                <div className=''>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='d-none'>
                                <div className="form-group mb-3">
                                    <Controller name="id" control={control} defaultValue="0" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>

                            <div className='col-lg-12 col-12 mb-3'>
                                <div className="form-group mb-3">
                                    <Controller name="name" control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Name *"
                                                placeholder='Enter Name'

                                                fullWidth
                                                {...field}
                                            //   inputProps={
                                            //     { readOnly: readyOnlyValue }
                                            //   }
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.name && errors.name.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12 mb-3'>
                                <div className="form-group mb-3">
                                    <Controller name="phone" control={control} defaultValue="" rules={{ required: 'Phone Number is required', validate: validatePhoneNumber }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Phone Number *"
                                                placeholder='Enter Phone Number'
                                                fullWidth
                                                {...field}
                                                inputProps={
                                                    { maxLength: 10 }
                                                }
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.phone && errors.phone.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12 mb-3'>
                                <div className="form-group mb-3">
                                    {/* userId */}
                                    <Controller name="email" control={control} defaultValue="" rules={{ required: 'Email Id is required', validate: validateEmail }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Email Id *"
                                                placeholder='Enter Email Id'
                                                fullWidth
                                                //   inputProps={
                                                //     { readOnly: readyOnlyValue }
                                                //   }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.email && errors.email.message}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <LoadingButton
                                type="submit"
                                loading={btnLoading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                className="btn btn-primary submit px-3"
                            // onClick={handleClickOpen}
                            >
                                {
                                    (lableName === 'Add') ? (<span>Save</span>) : (<span>Update</span>)
                                }
                            </LoadingButton>
                            <Button variant="contained" onClick={handleClose} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>
                            <Dialog
                                open={open}
                                onClose={handleAlartClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth={fullWidth}
                                maxWidth={maxWidth}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <center><h4 className='my-3'>Jury Already Exists</h4></center>
                                </DialogTitle>
                                <DialogContent>
                                    <center><p>Do you like to associate this Jury</p></center>
                                </DialogContent>
                                <DialogActions className='jury-modal my-3'>
                                    <Button variant='contained' onClick={associateJurySave} autoFocus>Yes</Button>
                                    <Button variant='outlined' onClick={handleAlartClose}>No</Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </form>
                </div>
            </Container>
        </section>
    )
}

export default Juryadd