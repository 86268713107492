import {
  Button,
  Divider,
  FormHelperText,
  FormLabel,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";

import dayjs from "dayjs";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import Accordionpage from "../../common-pages/Accordionpage";

import Dialog from "@mui/material/Dialog";
import { Controller, useForm } from "react-hook-form";
import notifyService from "../../../api/notifySerivce";
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["PDF"];
const Requirementdetails = () => {
  // const htmlToText = require('html-to-text');
  // const { fromHtml } = require('html-to-text');
  const { id, userid } = useParams();
  let userId = localStorage.getItem("userId");
  let requirementId;
  let userIds;
  if (id !== undefined) {
    requirementId = atob(id);
  }
  if (userid !== undefined) {
    userIds = atob(userid);
  }
  const {
    navigator,
    setPageLoading,
    btnLoading,
    setBtnLoading,
    validateEmail,
    validatePhoneNumber,
  } = useContext(DataContext);
  const [formData, setFormData] = useState();
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({ values: formData });
  // const role = localStorage.getItem('role')
  let roleName = localStorage.getItem("role");
  useDidMountEffect(() => {
    getApi();
  }, []);
  const [getValue, setGetValue] = useState({});
  const getApi = () => {
    setPageLoading(true);
    apiService(
      `requirement/get?id=${requirementId}&userId=${userId ? userId : 0}`,
      "",
      "get"
    ).then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          var response = res.data;
          // response.startDate = dayjs(response.startDate, "YYYY-MM-DD");
          response.endDate = dayjs(response.endDate, "YYYY-MM-DD");
          if (response.requirementImageName) {
            response.requirementImageURL = response.requirementImageName;
          }
          if (response.requirements) {
            response.requirements.forEach((element) => {
              element.imageURL = element.imageName;
            });
          }

          setGetValue(res.data);

          if (response.images) {
            response.images.forEach((element) => {
              if (element.file) {
                element.imageURL = `data:image/jpeg;base64,${element.file}`;
              }
            });
          }
        }
      }
    });
  };
  let token = localStorage.getItem("token");
  const handleBack = () => {
    if (token) {
      navigator("/requirement/list");
    } else {
      navigator("/challenge/government");
    }
  };

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearErrors();
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const [inCorporationValue, setInCorporationValue] = useState(null);
  const [inCorporationName, setInCorporationName] = useState(null);
  const [inCorporationURL, setInCorporationURL] = useState(null);

  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName, fileURL) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (img_crt_type === "pdf") {
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileURL, logourl1);
              clearErrors(filekey);
              clearErrors(fileName);
              clearErrors(fileURL);
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File size exceeds 5MB",
            "Please check your file size"
          );
        }
      }
    }
  };
  const [ids, setIds] = useState();
  const onSubmit = (data) => {
    setPageLoading(true);
    data.id = requirementId;
    debugger;
    apiService(`requirement/expression/interest`, data, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.response.responseStatus === "Success") {
          notifyService("success", res.data.response.responseMessage);
          getApi();
          handleClose();
        }
      }
    });
  };

  const handleApproval = (id) => {
    onSubmit(formData, id);
    setIds(id);
    debugger;
    console.log(id, "getformid");
  };
  const handleLogin = () => {
    navigator("/sign-in");
  };
  return (
    <>
      {/* <Helmet>
          <title>Open Innovation Portal | Challenges | Live-challenges | Details</title>
        </Helmet> */}
      <section className="Challenges_details_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row mb-4">
                <div className="col-lg-8 col-md-8 col-12">
                  {getValue.requirementImageURL && (
                    <img
                      src={getValue.requirementImageURL}
                      className="img-fluid challenge_img"
                    />
                  )}
                  <h1 className="my-3">{getValue.requirementTitle}</h1>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="align_data">
                    <div className="apply_challenges">
                      <div className="row">
                        <div className="col-12">
                          <div className="timeline_div">
                            <h5>Timeline</h5>
                            {/* <p>Start Date : <b>{getValue.startDate2}</b></p> */}
                            <p>
                              End Date :{" "}
                              <b>
                                {getValue.endDate2 ? getValue.endDate2 : `-`}
                              </b>
                            </p>
                          </div>
                        </div>
                        {/*  */}
                        {!token && getValue.showButton === true && (
                          <div className="m-auto">
                            <LoadingButton
                              loadingPosition="start"
                              onClick={handleLogin}
                              className="b-yellow-btn mt-3"
                            >
                              <span>Expression Of Interest</span>
                            </LoadingButton>
                          </div>
                        )}
                        {roleName === "Solution Provider" &&
                        getValue.showButton === true ? (
                          <>
                            <div className="m-auto">
                              <LoadingButton
                                loadingPosition="start"
                                onClick={handleClickOpen}
                                loading={btnLoading}
                                className="b-yellow-btn mt-3"
                              >
                                <span>Expression Of Interest</span>
                              </LoadingButton>
                            </div>
                          </>
                        ) : (
                          roleName === "Solution Provider" &&
                          getValue.showButton === false && (
                            <>
                              <div className="m-auto">
                                <LoadingButton
                                  loadingPosition="start"
                                  className="gray_btn mt-3"
                                  disabled
                                >
                                  <span>Expression Of Interest</span>
                                </LoadingButton>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='challenge_banner_details'>
                                <h1 className='mb-3'>{getValue.requirementTitle}</h1>
                                <div className='challenge_intro mb-2' dangerouslySetInnerHTML={{ __html: getValue.requirementIntroduction }}></div>
                            </div> */}
              <Divider />
            </div>
          </div>
        </div>
      </section>
      <section className="more_details_section">
        <div className="container">
          <div className="challenge_banner_details">
            {/* <div className='challenge_intro my-3'>
                            <h4 className='challenge_intro_title mb-4'><span>Department</span> Name</h4>
                            <h6>{getValue.departmentName}</h6>
                        </div>
                        <Divider /> */}
            <div className="challenge_intro my-3">
              <h4 className="challenge_intro_title mb-4">Requirements</h4>
              {getValue.requirements ? (
                <>
                  <ul>
                    {getValue.requirements.map((list, index) => (
                      <li key={index} className="box-code">
                        <div className="row">
                          {/* <div className='col-lg-3 col-md-4 col-12'>
                                                                <img src={list.imageURL} className='img-fluid prob_img' />
                                                            </div> */}
                          <div className="col-lg-12 col-md-12 col-12">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: list.requirementDesc,
                              }}
                            ></div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                "-"
              )}
            </div>
            <Divider />
          </div>
        </div>
      </section>
      <main className="live_challenges_main">
        <div className="container">
          {/* <div className='challenge_more'>
              <h4><span>Additional</span> Details</h4>
            </div>
            <Divider /> */}
          <Accordionpage getValue={getValue} path="Requirement" />
        </div>
        <div className="text-center mb-5">
          <Button
            className="yellow-btn mr-3"
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
          >
            BACK
          </Button>
          {!token && getValue.showButton === true && (
            <LoadingButton
              loadingPosition="start"
              onClick={handleLogin}
              className="b-yellow-btn"
            >
              <span>Expression Of Interest</span>
            </LoadingButton>
          )}
          {roleName === "Solution Provider" && getValue.showButton === true ? (
            <>
              <LoadingButton
                loadingPosition="start"
                onClick={handleClickOpen}
                loading={btnLoading}
                className="blue-btn-sm ml-3"
              >
                <span>Expression Of Interest</span>
              </LoadingButton>
            </>
          ) : (
            roleName === "Solution Provider" &&
            getValue.showButton === false && (
              <>
                <LoadingButton
                  loadingPosition="start"
                  className="gray-btn-sm ml-3"
                  disabled
                >
                  <span>Expression Of Interest</span>
                </LoadingButton>
              </>
            )
          )}
        </div>
      </main>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <h3 className="m-3 d-inline">
          <center>Expression Of Interest </center>
        </h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    Startup Name <span className="text-danger">*</span>
                  </FormLabel>
                  <Controller
                    name="startupName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Startup Name is required" }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Enter Startup Name"
                        fullWidth
                        type="text"
                        {...field}
                        inputProps={{ maxLength: 50 }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.startupName && errors.startupName.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    DPIIT Registration No <span className="text-danger">*</span>
                  </FormLabel>
                  <Controller
                    name="dpiitRegNo"
                    control={control}
                    defaultValue=""
                    rules={{ required: "DPIIT Registration No is required" }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Enter DPIIT Registration No"
                        fullWidth
                        type="text"
                        {...field}
                        inputProps={{ maxLength: 50 }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.dpiitRegNo && errors.dpiitRegNo.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="form-group mx-0">
                  <FormLabel shrink="true" className="input_Lable">
                    Pitch Deck <span className="text-danger">*</span>{" "}
                  </FormLabel>
                  <Controller
                    name="pitchDeckFile"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Pitch Deck is required" }}
                    render={({ field }) => (
                      <FileUploader
                        {...field}
                        handleChange={(e) =>
                          handleFileUpload(
                            e,
                            "pitchDeckFile",
                            "pitchDeckFileName",
                            "pitchDeckURL"
                          )
                        }
                        name="file"
                        types={fileTypes}
                      />
                    )}
                  />
                  <FormHelperText>File format should be in .PDF</FormHelperText>
                  <FormHelperText>Maximum file size: 5 MB</FormHelperText>
                  <FormHelperText className="text-danger">
                    {errors.pitchDeckFile && errors.pitchDeckFile.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-12">
                <h6>POC Details</h6>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    Name <span className="text-danger">*</span>
                  </FormLabel>
                  <Controller
                    name="pocName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "POC Name is required" }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Enter POC Name"
                        fullWidth
                        type="text"
                        {...field}
                        inputProps={{ maxLength: 50 }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.pocName && errors.pocName.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    Email <span className="text-danger">*</span>
                  </FormLabel>
                  <Controller
                    name="pocEmail"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "POC Email is required",
                      validate: validateEmail,
                    }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Enter POC Email"
                        fullWidth
                        type="text"
                        {...field}
                        inputProps={{ maxLength: 50 }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.pocEmail && errors.pocEmail.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    Mobile <span className="text-danger">*</span>
                  </FormLabel>
                  <Controller
                    name="pocMobile"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "POC Mobile is required",
                      validate: validatePhoneNumber,
                    }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Enter POC Mobile"
                        fullWidth
                        type="number"
                        {...field}
                        inputProps={{ maxLength: 10 }}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.pocMobile && errors.pocMobile.message}
                  </FormHelperText>
                </div>
              </div>
            </div>
            <center>
              <Button className="b-yellow-btn-sm" type="submit">
                Submit
              </Button>
              <Button className="blue-btn-sm m-3" onClick={handleClose}>
                Close
              </Button>
            </center>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default Requirementdetails;
