import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  FormLabel,
  Grid,
  Tab,
  TextField,
} from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
import React, { useContext, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DataContext from "../../../../context/DataContext";
import { Controller, useForm } from "react-hook-form";
import notifyService from "../../../../api/notifySerivce";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import AddIcon from "@mui/icons-material/Add";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Challengelist from "./Challengelist";
import Solutionlist from "../Solution-page/Solutionlist";
import { Helmet } from "react-helmet";
// import Jurylist from '../jury-page/Jurylist';
import Jurylayout from "../jury-page/Jurylayout";
import HomeSolutionlist from "./HomeSolutionlist";
import SearchInput from "../../../common-pages/SearchInput";

const Livechallengeslist = ({ name }) => {
  const { navigator, setPageLoading } = useContext(DataContext);
  // const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({});
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  let roleId = localStorage.getItem("roleId");
  let roleName = localStorage.getItem("role");
  const [challengeCategory, setChallengeCategory] = useState(0);
  const [title, setTitle] = useState("");
  useDidMountEffect(() => {
    dropdownValueApi();
    challengesListApi();
    if (roleId === null || roleName === "Solution Provider") {
      setValue("live", 2);
      setValue("challengeType", 1);
    }
    if (
      roleName === "Challenge Provider" ||
      roleName === "GOVT" ||
      roleName === "MSME's" ||
      roleName === "Other Enablers"
    ) {
      setValue("live", 4);
      setValue("challengeType", 1);
    }
    // if (name === 'government') {
    //   setChallengeCategory(1)
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useDidMountEffect(() => {
    challengeList();
  }, [page, name, title]);
  const [tabValue, setTabValue] = React.useState("1");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [sectorDataList, setsectorDataList] = useState([]);
  const [technologyAreasDataList, setTechnologyAreasDataList] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(
      `admin/dropdownvalues/get?key=dropdownall`,
      "",
      "unauthget"
    ).then((res) => {
      if (res) {
        if (res.data) {
          var val = res.data.dropDownValues;
          setsectorDataList(val.sectors);
        }
      }
    });
  };
  const [challengesDataList, setChallengesDataList] = useState([]);
  let role = localStorage.getItem("role");
  const challengesListApi = () => {
    setChallengesDataList([]);
    let path;
    let mathod;
    if (role === "Solution Provider") {
      path = "solution/title/list";
      mathod = "get";
    } else {
      if (name === "government") {
        path = `challenge/title/list?entityType=${""}?challengeCategory=1`;
      } else {
        path = `challenge/title/list?entityType=${""}`;
      }
      // path = `challenge/title/list?entityType=${''}?challengeCategory=${name === 'government' ? 1 : 0}`
      mathod = "unauthget";
    }
    apiService(path, "", mathod).then((res) => {
      if (res) {
        if (res.data) {
          setChallengesDataList(res.data);
        }
      }
    });
  };
  const [showSector, setShowSector] = useState(true);
  const [showChallenges, setShowChallenges] = useState(true);
  const handleSelectChallenges = (value) => {
    setValue("challengeTitle", value);
    setValue("sector", "");
    setValue("technologyArea", "");
    setShowSector(false);
    setShowChallenges(true);
    challengeList();
  };
  const handleSelectSector = (value) => {
    setValue("sector", value);
    setValue("technologyArea", "");
    const name = sectorDataList.filter(
      (item) => item.sector.includes(value) || item.sector.includes(value)
    );
    setTechnologyAreasDataList(name[0].technologyAreas);
    setShowSector(true);
    setShowChallenges(false);
    challengeList();
  };
  const [technologyAreaValue, setTechnologyAreaValue] = useState([]);
  const handleSelecttechnologyArea = (value) => {
    setTechnologyAreaValue(value);
    setValue("technologyArea", value);
    challengeList();
  };
  const resetFiliter = () => {
    setValue("sector", "");
    setValue("technologyArea", "");
    setValue("challengeTitle", "");

    setShowSector(true);
    setShowChallenges(true);
    setTechnologyAreasDataList([]);
    challengeList();
  };
  const resetFiliter2 = () => {
    setValue("shortlisted", ""); // Clear the shortlisted value
    handleDepartment(null); // Optionally, reset or clear related department logic
    handleSoluctionList(); // Trigger any additional logic needed on reset
  };
  const handleType = (value) => {
    setValue("challengeType", value);
    challengeList();
  };
  const [challengeListValue, setChallengeListValue] = useState([]);
  const filterval = [
    { id: 0, status: "Pending" },
    { id: 1, status: "Shortlisted" },
    { id: 2, status: "Rejected" },
  ];
  const [filterVal, setFilterVal] = useState("");
  const handleDepartment = (id) => {
    console.log(id);

    setFilterVal(id);
    // handleSoluctionList()
  };
  let challengeCategoryVal;
  if (name === "corporate") {
    challengeCategoryVal = 0;
  } else if (name === "government") {
    challengeCategoryVal = 1;
  } else if (name === "msme") {
    challengeCategoryVal = 2;
  } else if (name === "enablers") {
    challengeCategoryVal = 3;
  }
  const challengeList = () => {
    setPageLoading(true);
    setChallengeListValue([]);
    let path;
    let mathod;
    if (
      role === "Challenge Provider" ||
      role === "GOVT" ||
      role === "MSME's" ||
      role === "Other Enablers"
    ) {
      path = "challenge/list";
      mathod = "post";
    } else if (role === "Solution Provider") {
      path = "challenge/home/list";
      mathod = "post";
    } else if (role === "Project Management Team") {
      path = "admin/challenge/list";
      mathod = "post";
    } else {
      path = "challenge/home/list";
      mathod = "unauthpost";
    }

    var req = {
      listSize: 12,
      pageNumber: page,
      challengeTitle: title,
      sector: getValues("sector"),
      technologyArea: getValues("technologyArea"),
      live: getValues("live"),
      challengeType: getValues("challengeType"),
      challengeCategory: challengeCategoryVal,
    };
    apiService(path, req, mathod).then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.responseModelList) {
            setChallengeListValue(res.data.responseModelList);
            setTotalPage(res.data.totalPages);
          }
        }
      }
    });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleViewDetails = (id, userId) => {
    // if (roleId === null) {
    //   navigator('/sign-in')
    //   localStorage.setItem('click', 'live')
    // } else {
    //   navigator(`${name}/challenges/details/${btoa(id)}`)
    // }
    debugger;
    navigator(`${name}/challenges/details/${btoa(id)}/${btoa(userId)}`);
  };
  const handlePostChallenge = () => {
    // if (roleId === null) {
    //   navigator('/sign-in')
    //   localStorage.setItem('click', 'post')
    // } else {
    //   navigator(`${name}/post-challenges`)
    // }
    navigator(`${name}/post-challenges`);
  };

  // solution page code

  useDidMountEffect(() => {
    handleSoluctionList();
  }, [page, title, filterVal]);
  const [solutionListValue, getSoluctionValue] = useState([]);

  const handleSelectSolutionChallenges = (value) => {
    setTitle(value);
  };
  const handleSoluctionList = () => {
    setPageLoading(true);
    let path;
    let challengeProvider;
    var req;
    if (
      role === "Challenge Provider" ||
      role === "MSME's" ||
      role === "Other Enablers"
    ) {
      path = "solution/approval/list";
      challengeProvider = true;
      req = {
        userId: localStorage.getItem("userId"),
        listSize: 12,
        pageNumber: page,
        challengeTitle: title,
        challengeProvider: challengeProvider,
        shortlisted: filterVal,
      };
    } else if (role === "Project Management Team") {
      path = "admin/solution/list";
      challengeProvider = false;
      req = {
        userId: localStorage.getItem("userId"),
        listSize: 12,
        pageNumber: page,
        challengeTitle: title,
        challengeProvider: challengeProvider,
        challengeCategory: challengeCategoryVal,
      };
    } else {
      path = "solution/approval/list";
      challengeProvider = false;
      req = {
        userId: localStorage.getItem("userId"),
        listSize: 12,
        pageNumber: page,
        challengeTitle: title,
        challengeProvider: challengeProvider,
        challengeCategory: challengeCategoryVal,
        shortlisted: filterVal,
      };
    }
    getSoluctionValue([]);
    apiService(path, req, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.responseModelList) {
            getSoluctionValue(res.data.responseModelList);
            setTotalPage(res.data.totalPages);
          }
        }
      }
    });
  };
  const handleSolutionChange = (event, value) => {
    setPage(value);
  };
  const handleSolutionDetails = (id) => {
    if (role === "Project Management Team") {
      navigator(
        `/admin/${name}/challenge-status/solution-provider/${btoa(id)}`
      );
    } else {
      navigator(`${name}/challenges/solution-details/${btoa(id)}`);
    }
  };
  const resetSolutionFiliter = () => {
    setTitle("");
  };

  return (
    <>
      <Helmet>
        <title>
          Open Innovation Portal | Challenges | Live-challenges | List
        </title>
      </Helmet>
      <main className="live_challenges_main">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12">
              <div className="live_bgs">
                <>
                  {name === "corporate" && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className="mb-4"
                    >
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{ margin: "0px auto", textAlign: "center" }}
                      >
                        <img
                          src="/images/corporate-img.jpg"
                          className="cor-govt img-fluid"
                          alt=""
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <h2>Corporates Challenge</h2>
                        <p className="cor-govt-txt">
                          Corporates interested in utilising external expertise
                          to accelerate innovation can post their problem
                          statements in the portal to source solutions from
                          startups and innovators.
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  {name === "government" && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className="mb-4"
                    >
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{ margin: "0px auto", textAlign: "center" }}
                      >
                        <img
                          src="/images/government-img.jpg"
                          className="cor-govt img-fluid"
                          alt=""
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <h2>Government Challenge</h2>
                        <p className="cor-govt-txt">
                          Government Departments / Agencies can post their
                          problem statements in the portal to make use of
                          innovative solutions for their public service delivery
                          from startups and innovators.
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  {name === "enablers" && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className="mb-4"
                    >
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{ margin: "0px auto", textAlign: "center" }}
                      >
                        <img
                          src="/images/corporate-img.jpg"
                          className="cor-govt img-fluid"
                          alt=""
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <h2>Other Enablers Challenge</h2>
                        <p className="cor-govt-txt">
                          Other Enablers interested in utilising external
                          expertise to accelerate innovation can post their
                          problem statements in the portal to source solutions
                          from startups and innovators.
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  {name === "msme" && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className="mb-4"
                    >
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{ margin: "0px auto", textAlign: "center" }}
                      >
                        <img
                          src="/images/hand.jpg"
                          className="cor-govt img-fluid"
                          alt=""
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <h2>MSME's Challenge</h2>
                        <p className="cor-govt-txt">
                          MSME's interested in utilising external expertise to
                          accelerate innovation can post their problem
                          statements in the portal to source solutions from
                          startups and innovators.
                        </p>
                      </Grid>
                    </Grid>
                  )}
                </>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                >
                  {tabValue !== "4" && (
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      {tabValue === "1" && (
                        <form className="grybg signin-form filter_form mt-10">
                          <div className="">
                            <h5 className="input_Lable mb-3 text-center">
                              Filter
                            </h5>
                            <SearchInput
                              setValue={setTitle}
                              lable={"Challenge Title"}
                            />
                            {showSector === true && (
                              <>
                                <div className="">
                                  <div className="form-group mb-3 text-center">
                                    <FormLabel
                                      shrink="true"
                                      className="input_Lable"
                                    >
                                      Sector{" "}
                                    </FormLabel>
                                    <Controller
                                      name="sector"
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: false }}
                                      render={({ field }) => (
                                        <Autocomplete
                                          disableClearable
                                          disablePortal
                                          {...field}
                                          sx={{ background: "white" }}
                                          ListboxProps={{
                                            style: { textAlign: "left" },
                                          }}
                                          options={sectorDataList.map(
                                            (option) => option.sector
                                          )}
                                          onChange={(e, selectedOptions) =>
                                            handleSelectSector(selectedOptions)
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Filter by Sector"
                                            />
                                          )}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.sector && errors.sector.message}
                                    </FormHelperText>
                                  </div>
                                </div>
                                {getValues("sector") !== "All" &&
                                  getValues("sector") !== "Others" &&
                                  getValues("sector") !== "" && (
                                    <div className="">
                                      <div className="form-group mb-3 text-center">
                                        <FormLabel
                                          shrink="true"
                                          className="input_Lable"
                                        >
                                          Technology Area{" "}
                                        </FormLabel>
                                        <Controller
                                          name="technologyArea"
                                          control={control}
                                          defaultValue=""
                                          rules={{ required: false }}
                                          render={({ field }) => (
                                            <Autocomplete
                                              disableClearable
                                              disablePortal
                                              {...field}
                                              sx={{ background: "white" }}
                                              ListboxProps={{
                                                style: { textAlign: "left" },
                                              }}
                                              options={technologyAreasDataList}
                                              onChange={(e, selectedOptions) =>
                                                handleSelecttechnologyArea(
                                                  selectedOptions
                                                )
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder="Filter by Technology Area"
                                                />
                                              )}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.technologyArea &&
                                            errors.technologyArea.message}
                                        </FormHelperText>
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                            {getValues("sector") !== "" && (
                              <div className="col-12 text-center">
                                <Button
                                  onClick={resetFiliter}
                                  variant="outlined"
                                  color="error"
                                >
                                  Reset
                                </Button>
                              </div>
                            )}
                          </div>
                        </form>
                      )}
                      {tabValue === "2" && (
                        <form className="grybg signin-form filter_form mt-10">
                          <h5 className="input_Lable mb-3 text-center">
                            Filter
                          </h5>
                          <div className="">
                            <SearchInput
                              setValue={setTitle}
                              lable={"Challenge Title"}
                            />
                            {showSector === true && (
                              <>
                                <div className="">
                                  <div className="form-group mb-3 text-center">
                                    <FormLabel
                                      shrink="true"
                                      className="input_Lable"
                                    >
                                      Sector{" "}
                                    </FormLabel>
                                    <Controller
                                      name="sector"
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: false }}
                                      render={({ field }) => (
                                        <Autocomplete
                                          disableClearable
                                          disablePortal
                                          {...field}
                                          sx={{ background: "white" }}
                                          ListboxProps={{
                                            style: { textAlign: "left" },
                                          }}
                                          options={sectorDataList.map(
                                            (option) => option.sector
                                          )}
                                          onChange={(e, selectedOptions) =>
                                            handleSelectSector(selectedOptions)
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Filter by Sector"
                                            />
                                          )}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.sector && errors.sector.message}
                                    </FormHelperText>
                                  </div>
                                </div>
                                {getValues("sector") !== "All" &&
                                  getValues("sector") !== "Others" &&
                                  getValues("sector") !== "" && (
                                    <div className="">
                                      <div className="form-group mb-3 text-center">
                                        <FormLabel
                                          shrink="true"
                                          className="input_Lable"
                                        >
                                          Technology Area{" "}
                                        </FormLabel>
                                        <Controller
                                          name="technologyArea"
                                          control={control}
                                          defaultValue=""
                                          rules={{ required: false }}
                                          render={({ field }) => (
                                            <Autocomplete
                                              disableClearable
                                              disablePortal
                                              {...field}
                                              sx={{ background: "white" }}
                                              ListboxProps={{
                                                style: { textAlign: "left" },
                                              }}
                                              options={technologyAreasDataList}
                                              onChange={(e, selectedOptions) =>
                                                handleSelecttechnologyArea(
                                                  selectedOptions
                                                )
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder="Filter by Technology Area"
                                                />
                                              )}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.technologyArea &&
                                            errors.technologyArea.message}
                                        </FormHelperText>
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                            {getValues("sector") !== "" && (
                              <div className="col-12 text-center">
                                <Button
                                  onClick={resetFiliter}
                                  variant="outlined"
                                  color="error"
                                >
                                  Reset
                                </Button>
                              </div>
                            )}
                          </div>
                        </form>
                      )}
                      {tabValue === "3" && (
                        // <form className="signin-form filter_form mt-10">
                        //   <h5 className='input_Lable mb-3 text-center'>Filter</h5>
                        //   <div className='row'>
                        //     <div className='col-lg-12 col-md-12 col-12'>
                        //       <SearchInput setValue={setTitle} lable={'Challenge Title'} />
                        //     </div>
                        //   </div>
                        //   <div className=''>
                        //     <div className='form-group mb-3 text-center'>
                        //       <FormLabel shrink="true" className='input_Lable'>Shortlisted  </FormLabel>
                        //       <Controller name="shortlisted" control={control} defaultValue="" rules={{ required: false }}
                        //         render={({ field }) =>
                        //           <Autocomplete
                        //             disableClearable
                        //             disablePortal
                        //             {...field}
                        //             sx={{ background: 'white' }}
                        //             value={filterval.find(option => option.id === filterVal) || null} // Set the value based on id
                        //             options={filterval}
                        //             getOptionLabel={(option) => option.status} // Display the status in the dropdown
                        //             onChange={(e, selectedOption) => {
                        //               handleDepartment(selectedOption ? selectedOption.id : null); // Pass the id or null
                        //             }}
                        //             className="meetingListDropdown"
                        //             renderInput={(params) => <TextField {...params} />}

                        //           />
                        //         } />
                        //       <FormHelperText className='text-danger'>{errors.shortlisted && errors.shortlisted.message}</FormHelperText>
                        //     </div>
                        //     {
                        //       ( getValues('shortlisted') !== 0) && (
                        //         <div className='col-12 text-center'>
                        //           <Button onClick={resetFiliter2} variant="outlined" color="error">Reset</Button>
                        //         </div>
                        //       )
                        //     }
                        //   </div>

                        // </form>
                        <form className="signin-form filter_form mt-10">
                          <h5 className="input_Lable mb-3 text-center">
                            Filter
                          </h5>
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                              <SearchInput
                                setValue={setTitle}
                                lable={"Challenge Title"}
                              />
                            </div>
                          </div>
                          <div className="">
                            <div className="form-group mt-3 mb-3 text-center">
                              {/* <FormLabel shrink="true" className='input_Lable'>Shortlisted</FormLabel> */}
                              <Controller
                                name="shortlisted"
                                control={control}
                                defaultValue=""
                                rules={{ required: false }}
                                render={({ field }) => (
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    {...field}
                                    sx={{ background: "white" }}
                                    ListboxProps={{
                                      style: { textAlign: "left" },
                                    }}
                                    value={
                                      filterval.find(
                                        (option) =>
                                          option.id === getValues("shortlisted")
                                      ) || null
                                    }
                                    options={filterval}
                                    getOptionLabel={(option) => option.status}
                                    onChange={(e, selectedOption) => {
                                      setValue(
                                        "shortlisted",
                                        selectedOption ? selectedOption.id : ""
                                      ); // Set value based on selection
                                      handleDepartment(
                                        selectedOption
                                          ? selectedOption.id
                                          : null
                                      ); // Pass the id or null
                                    }}
                                    className="meetingListDropdown"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Filter by Shortlisted "
                                      />
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.shortlisted &&
                                  errors.shortlisted.message}
                              </FormHelperText>
                            </div>
                            {/* Conditionally render the Reset button */}
                            {(getValues("shortlisted") === "0" ||
                              getValues("shortlisted") !== "") && (
                              <div className="col-12 text-center">
                                <Button
                                  onClick={resetFiliter2}
                                  variant="outlined"
                                  color="error"
                                >
                                  Reset
                                </Button>
                              </div>
                            )}
                          </div>
                        </form>
                      )}
                    </Grid>
                  )}

                  <Grid
                    item
                    lg={tabValue !== "4" ? 9 : 12}
                    md={tabValue !== "4" ? 9 : 12}
                    sm={12}
                    xs={12}
                  >
                    <div className="grybg">
                      <TabContext sx={{ padding: "25px" }} value={tabValue}>
                        {roleId && (
                          <Box
                            sx={{
                              flexGrow: 1,
                              bgcolor: "background.paper",
                            }}
                          >
                            <TabList
                              value={tabValue}
                              onChange={handleTabChange}
                              variant="scrollable"
                              // scrollButtons
                              aria-label="visible arrows tabs example"
                              sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                  "&.Mui-disabled": { opacity: 0.3 },
                                },
                              }}
                            >
                              <Tab
                                className="tab_lable_name"
                                label="Live Challenges"
                                value="1"
                                onClick={() => handleType(1)}
                              />
                              <Tab
                                className="tab_lable_name"
                                label="Past Challenges"
                                value="2"
                                onClick={() => handleType(0)}
                              />
                              {roleName === "Solution Provider" ? (
                                <Tab
                                  className="tab_lable_name"
                                  label="Applied Challenges"
                                  value="3"
                                  onClick={() => handleSoluctionList()}
                                />
                              ) : (
                                <Tab
                                  className="tab_lable_name"
                                  label="Applications"
                                  value="3"
                                  onClick={() => handleSoluctionList()}
                                />
                              )}
                              {(roleName === "Challenge Provider" ||
                                roleName === "GOVT" ||
                                roleName === "MSME's" ||
                                roleName === "Other Enablers") && (
                                <Tab
                                  className="tab_lable_name"
                                  label="Jury"
                                  value="4"
                                />
                              )}
                              {/* {
                              (roleName === 'Challenge Provider' || roleName === 'GOVT') && (
                                <Button startIcon={<AddIcon />} className='yellow-btn-sm ml-auto px-3' onClick={() => handlePostChallenge()}>Post Your Challenge</Button>
                              )
                            } */}
                            </TabList>
                          </Box>
                        )}
                        <Box>
                          <TabPanel value="1" sx={{ padding: "15px 0px" }}>
                            <div className="title_filter_dev ">
                              {!roleId && (
                                <>
                                  <h5>
                                    <span className="text-uppercase"></span>{" "}
                                    LIVE CHALLENGES
                                  </h5>
                                </>
                              )}
                            </div>
                            <div className="text-right">
                              {(roleName === "Challenge Provider" ||
                                roleName === "GOVT" ||
                                roleName === "MSME's" ||
                                roleName === "Other Enablers") && (
                                <Button
                                  startIcon={<AddIcon />}
                                  className="yellow-btn-sm px-3"
                                  onClick={() => handlePostChallenge()}
                                >
                                  Post Your Challenge
                                </Button>
                              )}
                            </div>
                            <Challengelist
                              challengeListValue={challengeListValue}
                              page={page}
                              totalPage={totalPage}
                              handleViewDetails={handleViewDetails}
                              handleChange={handleChange}
                              viewShow={true}
                            />
                          </TabPanel>
                          <TabPanel value="2" sx={{ padding: "15px 0px" }}>
                            <Challengelist
                              challengeListValue={challengeListValue}
                              page={page}
                              totalPage={totalPage}
                              handleViewDetails={handleViewDetails}
                              handleChange={handleChange}
                            />
                          </TabPanel>
                          <TabPanel value="3" sx={{ padding: "15px 0px" }}>
                            <HomeSolutionlist
                              solutionListValue={solutionListValue}
                              handleSolutionDetails={handleSolutionDetails}
                              totalPage={totalPage}
                              filterVal={filterVal}
                              page={page}
                              handleSolutionChange={handleSolutionChange}
                              name={name}
                            />
                          </TabPanel>
                          <TabPanel value="4" sx={{ padding: "15px 0px" }}>
                            <Jurylayout />
                          </TabPanel>
                        </Box>
                      </TabContext>
                    </div>
                  </Grid>
                </Grid>

                {/* <Solutionlist solutionListValue={solutionListValue} page={page} totalPage={totalPage} handleSolutionDetails={handleSolutionDetails} handleChange={handleChange} /> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Livechallengeslist;
