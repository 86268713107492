import React, { useContext } from "react";
import { RingLoader } from "react-spinners";
import Signinpage from "./login-module/Signinpage";
import Signuppage from "./login-module/Signuppage";
// import Homepage from './home-module/Homepage';
import Layout from "./home-module/Layout";
import Challengesroute from "./home-module/Challengesroute";
import Livechallengeslist from "./home-module/live-challenges-page/challenges-page/Livechallengeslist";
import Livechallengesdetails from "./home-module/live-challenges-page/challenges-page/Livechallengesdetails";
import Postchallenges from "./home-module/post-challenges-page/Postchallenges";
// import DataContext, { DataProvider } from './context/DataContext';
import { Navigate, Route, Routes } from "react-router-dom";
import Livechallengeroute from "./home-module/live-challenges-page/Livechallengeroute";
import Applyform from "./home-module/live-challenges-page/apply-page/Applyform";
import Undermaintenance from "./under-maintenance-page/Undermaintenance";
import Solutiondetails from "./home-module/live-challenges-page/Solution-page/Solutiondetails";
import Adminroute from "./admin-module/Adminroute";
import Adminchallengedetails from "./admin-module/challenge-page/Adminchallengedetails";
import Adminchallengelist from "./admin-module/challenge-page/Adminchallengelist";
// import Homepage from './home-module/home-page/Homepage';
import Dashboard from "./admin-module/dashboard-page/Dashboard";
// import useDidMountEffect from './hooks/useDidMountEffect';
import Resourcespage from "./home-module/resource-page/Resourcespage";
import Homepagenew from "./home-module/home-page/Homepagenew";
import Sessiontimeout from "./session-time-out-page/Sessiontimeout";
import Faq from "./home-module/FaqPage/Faq";
import Inventor from "./home-module/inventor-page/Inventor";
import Techmarket from "./home-module/Techmarket-page/Techmarket";
import Juryroute from "./jury-module/Juryroute";
import Juryscorelist from "./jury-module/Juryscorelist";
import DataContext from "../context/DataContext";
import Challengestatusroute from "./admin-module/challenge-page/Challengestatusroute";
import TechmarketDetail from "./home-module/Techmarket-page/TechmarketDetail";
import Homechallengelist from "./home-module/home-page/Homechallengelist";
import AdminTechmarketList from "./admin-module/admin-techmarket-page/AdminTechmarketList";
import AdminTechmarketLayout from "./admin-module/admin-techmarket-page/AdminTechmarketLayout";
import Requirement from "./home-module/post-requirement-page/Requirement";
import Requirementroute from "./home-module/Requirementroute";
import RequirementList from "./home-module/post-requirement-page/RequirementList";
import Requirementdetails from "./home-module/post-requirement-page/Requirementdetails";
import Adminrequirementlist from "./admin-module/requirement-page/Adminrequirementlist";
import Adminrequirementdetail from "./admin-module/requirement-page/Adminrequirementdetail";
import Requirementapplieddetails from "./admin-module/requirement-page/Requirementapplieddetails";
import PrivacyPolicy from "./common-pages/PrivacyPolicy";
const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "red",
};
const Baseroute = () => {
  const { pageLoading } = useContext(DataContext);
  return (
    <div>
      <div className="circle"></div>
      {pageLoading && (
        // <div className='loader_div'>
        //     <RingLoader
        //         color='#1be5d9'
        //         loading={pageLoading}
        //         cssOverride={override}
        //         size={150}
        //         className='loader_css'
        //     />
        // </div>
        <div className="loader_div">
          <div className="loader">
            <div className="load-inner load-one"></div>
            <div className="load-inner load-two"></div>
            <div className="load-inner load-three"></div>
            <span className="loader-text">Loading...</span>
          </div>
        </div>
      )}
      <>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="/home" element={<Homepagenew />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/challenge/:name" element={<Homechallengelist />} />
            <Route path="/Faq" element={<Faq />} />
            <Route path="/resourcespage" element={<Resourcespage />} />
            <Route path="/techmarket" element={<Techmarket />} />
            <Route
              path="/techmarket/details/:id/:userid"
              element={<TechmarketDetail />}
            />
            <Route path="/admin" element={<Adminroute />}>
              <Route
                path="/admin"
                element={<Navigate replace to="/admin/dashboard" />}
              />
              <Route path="/admin/dashboard" element={<Dashboard />} />

              <Route path="/admin/corporate" element={<Challengestatusroute />}>
                <Route
                  path="/admin/corporate"
                  element={
                    <Navigate replace to="/admin/corporate/challenge-status" />
                  }
                />
                <Route
                  path="/admin/corporate/challenge-status"
                  element={<Adminchallengelist name={"corporate"} />}
                />
                <Route
                  path="/admin/corporate/challenge-status/:id"
                  element={<Adminchallengedetails name={"corporate"} />}
                />
                <Route
                  path="/admin/corporate/challenge-status/solution-provider/:id"
                  element={<Solutiondetails name={"corporate"} />}
                />
                <Route
                  path="/admin/corporate/challenge-status/post-challenges/:id"
                  element={<Postchallenges name={"corporate"} />}
                />
              </Route>
              <Route
                path="/admin/government"
                element={<Challengestatusroute />}
              >
                <Route
                  path="/admin/government"
                  element={
                    <Navigate replace to="/admin/government/challenge-status" />
                  }
                />
                <Route
                  path="/admin/government/challenge-status"
                  element={<Adminchallengelist name={"government"} />}
                />
                <Route
                  path="/admin/government/challenge-status/:id"
                  element={<Adminchallengedetails name={"government"} />}
                />
                <Route
                  path="/admin/government/challenge-status/solution-provider/:id"
                  element={<Solutiondetails name={"government"} />}
                />
                <Route
                  path="/admin/government/challenge-status/post-challenges/:id"
                  element={<Postchallenges name={"government"} />}
                />
              </Route>

              <Route path="/admin/msme" element={<Challengestatusroute />}>
                <Route
                  path="/admin/msme"
                  element={
                    <Navigate replace to="/admin/msme/challenge-status" />
                  }
                />
                <Route
                  path="/admin/msme/challenge-status"
                  element={<Adminchallengelist name={"msme"} />}
                />
                <Route
                  path="/admin/msme/challenge-status/:id"
                  element={<Adminchallengedetails name={"msme"} />}
                />
                <Route
                  path="/admin/msme/challenge-status/solution-provider/:id"
                  element={<Solutiondetails name={"msme"} />}
                />
                <Route
                  path="/admin/msme/challenge-status/post-challenges/:id"
                  element={<Postchallenges name={"msme"} />}
                />
              </Route>

              <Route path="/admin/enablers" element={<Challengestatusroute />}>
                <Route
                  path="/admin/enablers"
                  element={
                    <Navigate replace to="/admin/enablers/challenge-status" />
                  }
                />
                <Route
                  path="/admin/enablers/challenge-status"
                  element={<Adminchallengelist name={"enablers"} />}
                />
                <Route
                  path="/admin/enablers/challenge-status/:id"
                  element={<Adminchallengedetails name={"enablers"} />}
                />
                <Route
                  path="/admin/enablers/challenge-status/solution-provider/:id"
                  element={<Solutiondetails name={"enablers"} />}
                />
                <Route
                  path="/admin/enablers/challenge-status/post-challenges/:id"
                  element={<Postchallenges name={"enablers"} />}
                />
              </Route>

              <Route
                path="/admin/requirement"
                element={<Challengestatusroute />}
              >
                <Route
                  path="/admin/requirement"
                  element={<Navigate replace to="/admin/requirement/status" />}
                />
                <Route
                  path="/admin/requirement/status"
                  element={<Adminrequirementlist />}
                />
                <Route
                  path="/admin/requirement/status/:id"
                  element={<Adminrequirementdetail />}
                />
                <Route
                  path="/admin/requirement/status/solution-provider/:id/:userid"
                  element={<Requirementapplieddetails />}
                />
                <Route
                  path="/admin/requirement/status/post-requirement/:id"
                  element={<Requirement />}
                />
              </Route>

              <Route
                path="/admin/tech-market"
                element={<AdminTechmarketLayout />}
              >
                <Route
                  path="/admin/tech-market"
                  element={<Navigate replace to="/admin/tech-market/list" />}
                />
                <Route
                  path="/admin/tech-market/list"
                  element={<AdminTechmarketList />}
                />
                <Route
                  path="/admin/tech-market/details/:id/:userid"
                  element={<TechmarketDetail />}
                />
              </Route>
            </Route>
            <Route path="/corporate" element={<Challengesroute />}>
              <Route
                path="/corporate"
                element={<Navigate replace to="/corporate/challenges" />}
              />
              <Route
                path="/corporate/challenges"
                element={<Livechallengeroute />}
              >
                <Route
                  path="/corporate/challenges"
                  element={<Navigate replace to="/corporate/challenges/list" />}
                />
                <Route
                  path="/corporate/challenges/list"
                  element={<Livechallengeslist name={"corporate"} />}
                />
                <Route
                  path="/corporate/challenges/details/:id/:userid"
                  element={<Livechallengesdetails name={"corporate"} />}
                />
                <Route
                  path="/corporate/challenges/apply/:id/:userid/:name"
                  element={<Applyform url={"corporate"} />}
                />
                <Route
                  path="/corporate/challenges/solution-details/:id"
                  element={<Solutiondetails name={"corporate"} />}
                />
              </Route>
              <Route
                path="/corporate/post-challenges"
                element={<Postchallenges name={"corporate"} />}
              />
            </Route>
            <Route path="/government" element={<Challengesroute />}>
              <Route
                path="/government"
                element={<Navigate replace to="/government/challenges" />}
              />
              <Route
                path="/government/challenges"
                element={<Livechallengeroute />}
              >
                <Route
                  path="/government/challenges"
                  element={
                    <Navigate replace to="/government/challenges/list" />
                  }
                />
                <Route
                  path="/government/challenges/list"
                  element={<Livechallengeslist name={"government"} />}
                />
                <Route
                  path="/government/challenges/details/:id/:userid"
                  element={<Livechallengesdetails name={"government"} />}
                />

                <Route
                  path="/government/challenges/apply/:id/:userid/:name"
                  element={<Applyform url={"government"} />}
                />
                <Route
                  path="/government/challenges/solution-details/:id"
                  element={<Solutiondetails name={"government"} />}
                />
              </Route>
              <Route
                path="/government/post-challenges"
                element={<Postchallenges name={"government"} />}
              />
            </Route>
            <Route path="/msme" element={<Challengesroute />}>
              <Route
                path="/msme"
                element={<Navigate replace to="/msme/challenges" />}
              />
              <Route path="/msme/challenges" element={<Livechallengeroute />}>
                <Route
                  path="/msme/challenges"
                  element={<Navigate replace to="/msme/challenges/list" />}
                />
                <Route
                  path="/msme/challenges/list"
                  element={<Livechallengeslist name={"msme"} />}
                />
                <Route
                  path="/msme/challenges/details/:id/:userid"
                  element={<Livechallengesdetails name={"msme"} />}
                />

                <Route
                  path="/msme/challenges/apply/:id/:userid/:name"
                  element={<Applyform url={"msme"} />}
                />
                <Route
                  path="/msme/challenges/solution-details/:id"
                  element={<Solutiondetails name={"msme"} />}
                />
              </Route>
              <Route
                path="/msme/post-challenges"
                element={<Postchallenges name={"msme"} />}
              />
            </Route>
            <Route path="/enablers" element={<Challengesroute />}>
              <Route
                path="/enablers"
                element={<Navigate replace to="/enablers/challenges" />}
              />
              <Route
                path="/enablers/challenges"
                element={<Livechallengeroute />}
              >
                <Route
                  path="/enablers/challenges"
                  element={<Navigate replace to="/enablers/challenges/list" />}
                />
                <Route
                  path="/enablers/challenges/list"
                  element={<Livechallengeslist name={"enablers"} />}
                />
                <Route
                  path="/enablers/challenges/details/:id/:userid"
                  element={<Livechallengesdetails name={"enablers"} />}
                />

                <Route
                  path="/enablers/challenges/apply/:id/:userid/:name"
                  element={<Applyform url={"enablers"} />}
                />
                <Route
                  path="/enablers/challenges/solution-details/:id"
                  element={<Solutiondetails name={"enablers"} />}
                />
              </Route>
              <Route
                path="/enablers/post-challenges"
                element={<Postchallenges name={"enablers"} />}
              />
            </Route>
            {/* <Route path='/msme/challenges/details/:id' element={<Livechallengesdetails name={'msme'} />} /> */}
            <Route path="/requirement" element={<Requirementroute />}>
              <Route
                path="/requirement"
                element={<Navigate replace to="/requirement/list" />}
              />
              <Route path="/requirement/list" element={<RequirementList />} />
              <Route
                path="/requirement/details/:id/:userid"
                element={<Requirementdetails />}
              />
              <Route
                path="/requirement/details/status/:id"
                element={<Requirementapplieddetails />}
              />
              <Route
                path="/requirement/post-requirement"
                element={<Requirement />}
              />
            </Route>
            <Route path="/jury" element={<Juryroute />}>
              <Route
                path="/jury"
                element={<Navigate replace to="/jury/challenge" />}
              />
              <Route path="/jury/challenge" element={<Juryscorelist />} />
            </Route>
            <Route path="/inventor" element={<AdminTechmarketLayout />}>
              <Route
                path="/inventor"
                element={<Navigate replace to="/inventor/list" />}
              />
              <Route path="/inventor/list" element={<Inventor />} />
              <Route
                path="/inventor/details/:id/:userid"
                element={<TechmarketDetail />}
              />
            </Route>
          </Route>
          <Route path="/sign-in" element={<Signinpage />} />
          <Route path="/sign-up" element={<Signuppage />} />

          <Route path="/under-maintenance" element={<Undermaintenance />} />
          <Route path="/session-timeout" element={<Sessiontimeout />} />
        </Routes>
      </>
    </div>
  );
};

export default Baseroute;
