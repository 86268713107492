import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Divider } from '@mui/material';
const Accordionpage = ({ getValue, path }) => {
  let roleName = localStorage.getItem('role')
  return (
    <div className='my-4'>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-12'>
          <div className='row'>
            {/* <div className='col-lg-4 col-md-6 col-12'>
              <div className='add_details_div'>
                <div className='mr-3'>
                  <img src='/images/participation.png' alt='' />
                </div>
                <div className=''>
                  <h5 className='accordion_title'>How to participate</h5>
                  <Typography className='accordion_p'>{getValue.howToParticipate}</Typography>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='add_details_div'>
                <div className='mr-3'>
                  <img src='/images/user.png' alt='' />
                </div>
                <div className=''>
                  <h5 className='accordion_title'>Eligibility</h5>
                  {
                    getValue.eligibility?.map((res, index) => (
                      <Typography className='accordion_p' key={index} >{res.eligibility}</Typography>
                    ))
                  }

                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='add_details_div'>
                <div className='mr-3'>
                  <img src='/images/welfare.png' alt='' />
                </div>
                <div className=''>
                  <h5 className='accordion_title'>Benefits</h5>
                  {
                    getValue.benefits?.map((res, index) => (
                      <Typography className='accordion_p' key={index}>{res.benefits}</Typography>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className='col-lg-12 col-md-12 col-12'>
              <div className='add_details_div'>
                <div className='mr-3'>
                  <img src='/images/work-process.png' alt='' />
                </div>
                <div className=''>
                  <h5 className='accordion_title'>Procedure post solution</h5>
                  <Typography className='accordion_p'>{getValue.procedurePostSubmission}</Typography>
                </div>
              </div>
            </div> */}

            {
              path !== 'Requirement' ? (
                <>
                  <div className='col-lg-6 col-md-12 col-12'>
                    <div className='add_details_div'>
                      <div className='mr-3'>
                        <img src='/images/telephone.png' alt='' />
                      </div>
                      <div className=''>
                        <h5 className='accordion_title'>Point of contact</h5>
                        {
                          getValue.pocName && (<Typography className="accordion_p">Name : <b>{getValue.pocName}</b></Typography>)
                        }
                        {
                          getValue.pocDesignation && (<Typography className="accordion_p">Designation : <b>{getValue.pocDesignation}</b></Typography>)
                        }
                        {
                          getValue.departmentName && (<Typography className="accordion_p">Department : <b>{getValue.departmentName}</b></Typography>)
                        }
                        {
                          getValue.pocEmail && (<Typography className="accordion_p">Email : <b>{getValue.pocEmail}</b></Typography>)
                        }
                        {
                          getValue.pocMobile && (<Typography className="accordion_p">Mobile Number : <b>{getValue.pocMobile}</b></Typography>)
                        }
                        {
                          getValue.pocPhone && (<Typography className="accordion_p"> Landline : <b>{getValue.pocPhone}</b></Typography>)
                        }
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-12 col-12'>
                    <div className='add_details_div'>
                      <div className='mr-3'>
                        <img src='/images/svg.png' alt='' />
                      </div>
                      <div className=''>
                        <h5 className='accordion_title'>Contact the host</h5>
                        {
                          getValue.contactHostName && (<Typography className="accordion_p">Name : <b>{getValue.contactHostName}</b></Typography>)
                        }
                        {
                          getValue.contactHostEmail && (<Typography className="accordion_p">Email : <b>{getValue.contactHostEmail}</b></Typography>)
                        }
                        {
                          getValue.contactHostPhone && (<Typography className="accordion_p">Mobile Number : <b>{getValue.contactHostPhone}</b></Typography>)
                        }
                      </div>
                    </div>
                  </div>
                </>

              ) : (
                <>
                  {
                    roleName !== 'Solution Provider' && (
                      <div className='col-lg-12 col-md-12 col-12'>
                        <div className='add_details_divs'>
                          <div className='mr-3'>
                            <img src='/images/telephone.png' alt='' />
                            <h5 className='accordion_title'>Point of contact</h5>
                          </div>
                          <div className='row'>
                            {
                              getValue.pocName && (
                                <div className='col-lg-4 col-md-4 col-12 mb-3'>
                                  <Typography className="accordion_p">Name : <b>{getValue.pocName}</b></Typography>
                                </div>

                              )
                            }
                            {
                              getValue.pocDesignation && (
                                <div className='col-lg-4 col-md-4 col-12  mb-3'>
                                  <Typography className="accordion_p">Designation : <b>{getValue.pocDesignation}</b></Typography>
                                </div>

                              )
                            }
                            {
                              getValue.departmentName && (
                                <div className='col-lg-4 col-md-4 col-12  mb-3'>
                                  <Typography className="accordion_p">Department : <b>{getValue.departmentName}</b></Typography>
                                </div>

                              )
                            }
                            {
                              getValue.pocEmail && (
                                <div className='col-lg-4 col-md-4 col-12  mb-3'>
                                  <Typography className="accordion_p">Email : <b>{getValue.pocEmail}</b></Typography>
                                </div>

                              )
                            }
                            {
                              getValue.pocMobile && (
                                <div className='col-lg-4 col-md-4 col-12  mb-3'>
                                  <Typography className="accordion_p">Mobile Number : <b>{getValue.pocMobile}</b></Typography>
                                </div>

                              )
                            }
                            {
                              getValue.pocPhone && (
                                <div className='col-lg-4 col-md-4 col-12 mb-3'>
                                  <Typography className="accordion_p"> Landline : <b>{getValue.pocPhone}</b></Typography>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    )
                  }

                </>
              )
            }

          </div>
        </div>
        {/* <div className='col-lg-3 col-md-4 col-12 text-center'>
          <h5 className='accordion_title'>Partner</h5>
          <img src={getValue.partnerLogoFileName} alt='' className='img-fluid partner_logo' />
          <p><b>{getValue.partnerName}</b></p>
        </div> */}

      </div>
      {/* <Accordion sx={{ background: "#F2F7F8", border: "none", boxShadow: "none", marginBottom: "15px" }}>
              <AccordionSummary
                expandIcon={<ExpandCircleDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className='accordion_title'>How to participate </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className='accordion_p'>
                  {getValue.howToParticipate}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion sx={{ background: "#F2F7F8", border: "none", boxShadow: "none", marginBottom: "15px" }}>
              <AccordionSummary
                expandIcon={<ExpandCircleDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className='accordion_title'>Point of contact </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className='accordion_p poc_p'>
                  {
                    getValue.pocName && (<span>Name : <b>{getValue.pocName}</b></span>)
                  }
                  {
                    getValue.pocEmail && (<span>Email : <b>{getValue.pocEmail}</b></span>)
                  }
                  {
                    getValue.pocMobile && (<span>Mobile Number : <b>{getValue.pocMobile}</b></span>)
                  }
                  {
                    getValue.pocPhone && (<span> Landline : <b>{getValue.pocMobile}</b></span>)
                  }
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion sx={{ background: "#F2F7F8", border: "none", boxShadow: "none", marginBottom: "15px" }}>
              <AccordionSummary
                expandIcon={<ExpandCircleDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className='accordion_title'>Eligibility </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className='accordion_p'>
                  {getValue.eligibility}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion sx={{ background: "#F2F7F8", border: "none", boxShadow: "none", marginBottom: "15px" }}>
              <AccordionSummary
                expandIcon={<ExpandCircleDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className='accordion_title'>Benefits </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className='accordion_p'>
                  {getValue.benefits}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion sx={{ background: "#F2F7F8", border: "none", boxShadow: "none", marginBottom: "15px" }}>
              <AccordionSummary
                expandIcon={<ExpandCircleDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className='accordion_title'>Procedure post solution </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className='accordion_p'>
                  {getValue.procedurePostSubmission}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion sx={{ background: "#F2F7F8", border: "none", boxShadow: "none", marginBottom: "15px" }}>
              <AccordionSummary
                expandIcon={<ExpandCircleDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className='accordion_title'>Contact the host </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className='accordion_p poc_p'>
                  {
                    getValue.contactHostName && (<span>Name : <b>{getValue.contactHostName}</b></span>)
                  }
                  {
                    getValue.contactHostEmail && (<span>Email : <b>{getValue.contactHostEmail}</b></span>)
                  }
                  {
                    getValue.contactHostMobile && (<span>Mobile Number : <b>{getValue.contactHostMobile}</b></span>)
                  }
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion sx={{ background: "#F2F7F8", border: "none", boxShadow: "none", marginBottom: "15px" }}>
              <AccordionSummary
                expandIcon={<ExpandCircleDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className='accordion_title'>Partner name and Logo </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className='accordion_p'>
                  <span>Partner Name : <b>{getValue.partnerName}</b></span>
                  <img src={getValue.partnerLogoFileName} alt='' className='img-fluid partner_logo' />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Divider /> */}
    </div>
  )
}

export default Accordionpage