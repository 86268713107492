import React, { useContext, useState, useEffect } from "react";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DataContext from "../../../context/DataContext";
import "./homepagenew.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Helmet } from "react-helmet";
import AnimateOnScroll from "react-animate-on-scroll";
import Aos from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import ProcessStructure from "./ProcessStructure";
import apiService from "../../../api/apiService";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Homepagenew = () => {
  const { navigator, setPageLoading } = useContext(DataContext);
  useDidMountEffect(() => {
    Aos.init();
    localStorage.removeItem("click");
    // setPageLoading(true)
    // setTimeout(function () {
    //   document.body.style.backgroundColor = 'black'; // Change to black after 2 seconds
    // }, 7000);
    challangeList();
    modalGet();
  }, []);
  // const role = localStorage.getItem('role')
  const [challengeListValue, setChallengeListValue] = useState([]);
  const [modalDetails, setModalDetails] = useState([]);
  const challangeList = () => {
    setPageLoading(true);
    var req = {
      listSize: 5,
      pageNumber: 1,
      live: 2,
      challengeType: 1,
    };
    apiService("challenge/home/list", req, "unauthpost").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.responseModelList) {
            setChallengeListValue(res.data.responseModelList);
          }
        }
      }
    });
  };
  const modalGet = () => {
    setPageLoading(true);
    var req = {
      listSize: 5,
      pageNumber: 1,
      live: 2,
      challengeType: 1,
    };
    apiService("challenge/home/popup/list", req, "unauthpost").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.responseModelList) {
            setModalDetails(res.data.responseModelList);
          }
        }
      }
    });
  };
  let roleId = localStorage.getItem("roleId");
  const handleViewDetails = (id, userId) => {
    navigator(`corporate/challenges/details/${btoa(id)}/${btoa(userId)}`);
    // if (roleId === null) {
    //   navigator("/sign-in");
    //   localStorage.setItem("click", "live");
    // } else {
    //   navigator(`corporate/challenges/details/${btoa(id)}`);
    // }
  };
  const handleLC = () => {
    navigator("/challenge/corporate");
    localStorage.setItem("val", "Corporates");
  };

  const handleGovt = () => {
    navigator("/challenge/government");
    localStorage.setItem("val", "Government");
  };
  const handleMsme = () => {
    navigator("/challenge/msme");
    localStorage.setItem("val", "MSME's");
  };
  const handleEnablers = () => {
    navigator("/challenge/enablers");
    localStorage.setItem("val", "Other Enablers");
  };
  const setStore = (value) => {
    localStorage.setItem("click", value);
    navigator("/challenges/post-challenges");
  };

  const handleLogin = () => {
    navigator("/sign-in");
  };

  const hoverFun = () => {
    const button1 = document.getElementById("button1");
    const button2 = document.getElementById("button2");
    const button3 = document.getElementById("button3");
    const button4 = document.getElementById("button4");

    if (button1 && button2 && button3 && button4) {
      button2.addEventListener("mouseenter", () => {
        button1.style.backgroundColor = "rgb(0,24,103)";
        button1.style.color = "white";
        button3.style.backgroundColor = "rgb(0,24,103)";
        button3.style.color = "white";
        button4.style.backgroundColor = "rgb(0,24,103)";
        button4.style.color = "white";
      });
      button3.addEventListener("mouseenter", () => {
        button1.style.backgroundColor = "rgb(0,24,103)";
        button1.style.color = "white";
        button2.style.backgroundColor = "rgb(0,24,103)";
        button2.style.color = "white";
        button4.style.backgroundColor = "rgb(0,24,103)";
        button4.style.color = "white";
      });
      button4.addEventListener("mouseenter", () => {
        button1.style.backgroundColor = "rgb(0,24,103)";
        button1.style.color = "white";
        button2.style.backgroundColor = "rgb(0,24,103)";
        button2.style.color = "white";
        button3.style.backgroundColor = "rgb(0,24,103)";
        button3.style.color = "white";
      });

      button2.addEventListener("mouseleave", () => {
        button1.style.backgroundColor = "";
        button1.style.color = "";
        button3.style.backgroundColor = "";
        button3.style.color = "";
        button4.style.backgroundColor = "";
        button4.style.color = "";
      });
      button3.addEventListener("mouseleave", () => {
        button1.style.backgroundColor = "";
        button1.style.color = "";
        button2.style.backgroundColor = "";
        button2.style.color = "";
        button4.style.backgroundColor = "";
        button4.style.color = "";
      });
      button4.addEventListener("mouseleave", () => {
        button1.style.backgroundColor = "";
        button1.style.color = "";
        button2.style.backgroundColor = "";
        button2.style.color = "";
        button3.style.backgroundColor = "";
        button3.style.color = "";
      });
    }
  };

  const [showSection, setShowSection] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const handleAnimationEnd = () => {
    setShowSection(true);
  };
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  // const { navigator } = useContext(DataContext);

  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [darkBackground, setDarkBackground] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setShouldAnimate(true);
      } else {
        setShouldAnimate(false);
      }
      if (scrollPosition >= window.innerHeight * 0.8) {
        setDarkBackground(true);
      } else {
        setDarkBackground(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [hideImage, setHideImage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const ellipseSection = document.querySelector(".ellipse");
      if (ellipseSection) {
        const rect = ellipseSection.getBoundingClientRect();
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        if (rect.top < windowHeight && rect.bottom >= 0) {
          setTimeout(() => {
            setHideImage(true);
          }, 2000);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (hideImage) {
      const ellipseSection = document.querySelector(".ellipse");
      if (ellipseSection) {
        ellipseSection.classList.add("hide-background");
      }
    }
  }, [hideImage]);

  // $(document).ready(function () {
  //   var scrolled = false;
  //   $(window).scroll(function () {
  //     var scroll = $(window).scrollTop();
  //     var ellipseSection = $('.ellipse');
  //     var animationSection = $('.section-animation');
  //     if (scroll >= ellipseSection.offset().top - $(window).height()) {
  //       if (!scrolled) {
  //         setTimeout(function () {
  //           ellipseSection.css('background-image', 'none');
  //           scrolled = true;
  //         }, 2000);
  //       }
  //       animationSection.show();
  //     } else {
  //       console.log('Else condition triggered');
  //       ellipseSection.css('background-image', 'url(/images/Ellipsesmall.png)');
  //       animationSection.hide();
  //       scrolled = false;
  //     }
  //   });
  // });

  // $(document).ready(function () {
  //   var scrolled = false;
  //   $(window).scroll(function () {
  //     var scroll = $(window).scrollTop();
  //     var ellipseSection = $('.ellipse');
  //     var animationSection = $('.section-animation');
  //     var ellipseTop = ellipseSection.offset().top;
  //     var ellipseHeight = ellipseSection.outerHeight();
  //     // Check if the user has scrolled to the ellipse section
  //     if (scroll >= ellipseTop - $(window).height() + ellipseHeight / 2) {
  //       // Hide ellipse section background image after 2 seconds of scrolling down
  //       if (!scrolled) {
  //         setTimeout(function () {
  //           ellipseSection.css('background-image', 'none');
  //           scrolled = true;
  //         }, 2000);
  //       }
  //       // Show animation section
  //       animationSection.show();
  //       Aos.refresh();
  //     } else {
  //       // Show ellipse section background image
  //       ellipseSection.css('background-image', 'url(images/Ellipsesmall.png)');
  //       // Hide animation section
  //       animationSection.hide();
  //       // Reset scrolled flag when the user scrolls up
  //       scrolled = false;
  //     }
  //   });
  // });

  // $(document).ready(function () {
  //   var scrolled = false;
  //   $(window).scroll(function () {
  //     var scroll = $(window).scrollTop();
  //     var ellipseSection = $('.ellipse');
  //     // var animationSection = $('.section-animation');
  //     var ellipseTop = ellipseSection.offset().top;
  //     var ellipseHeight = ellipseSection.outerHeight();
  //     if (scroll >= ellipseTop - $(window).height() + ellipseHeight / 2) {
  //       if (!scrolled) {
  //         setTimeout(function () {
  //           ellipseSection.css('background-image', 'none');
  //           scrolled = true;
  //           setTimeout(function () {
  //             // animationSection.show();
  //             Aos.refresh();
  //           });
  //         }, 2000);
  //       }
  //     } else {
  //       ellipseSection.css('background-image', 'url(images/Ellipsesmall.png)');
  //       // animationSection.hide();
  //       scrolled = false;
  //     }
  //   });
  // });

  $(document).ready(function () {
    var scrolled = false;
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      var ellipseSection = $(".ellipse");
      if (!ellipseSection.length) return;
      var ellipseTop = ellipseSection.offset().top;
      var ellipseHeight = ellipseSection.outerHeight();
      if (scroll >= ellipseTop - $(window).height() + ellipseHeight / 2) {
        if (!scrolled) {
          setTimeout(function () {
            ellipseSection.css("background-image", "none");
            scrolled = true;
            setTimeout(function () {
              Aos.refresh();
            });
          }, 2000);
        }
      } else {
        ellipseSection.css("background-image", "url(images/Ellipsesmall.png)");
        scrolled = false;
      }
    });
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (modalDetails.length > 0) {
        setDialogOpen(true);
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const handleClosePop = () => {
    setDialogOpen(false);
  };

  const dialogImg = [
    {
      img: "/images/m1.png",
    },
    {
      img: "/images/m2.png",
    },
    {
      img: "/images/m3.png",
    },
    {
      img: "/images/m4.png",
    },
    {
      img: "/images/m5.png",
    },
    {
      img: "/images/m6.png",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Open Innovation Portal | Home</title>
      </Helmet>
      <div className="back-color">
        <main className="home-bg">
          {/* <div className={`blueCircle ${shouldAnimate ? 'animate' : ''}`}></div> */}
          {/* <div className={`blueCircle ${shouldAnimate ? 'animate' : ''}`} style={{ opacity: shouldAnimate ? 1 : 0 }}></div> */}
          {/* <div className={`blueCircle ${shouldAnimate ? 'animate darkBackground' : 'blueBackground'}`} style={{ opacity: shouldAnimate ? 1 : 0 }}></div> */}
          {/* <div className={`blueCircle ${shouldAnimate ? 'animate' : ''} ${darkBackground ? 'darkBackground' : 'blueBackground'}`} style={{ opacity: shouldAnimate ? 1 : 0 }}></div> */}
          <section className="head-sec pt-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="head-con mb-5">
                    <img
                      alt=""
                      src="/images/LOGOOpeninnovation.png"
                      className="title-head img-fluid"
                    />
                    <p className="head-text mt-3">
                      StartupTN assists Corporates, Research Institutions and
                      Government agencies in developing innovative products and
                      services by connecting them with innovators and Startups
                      from Tamilnadu
                    </p>
                    <div className="OI-btn mt-4">
                      {/* {
                        (role !== 'Solution Provider' && role !== 'Admin' && role !== 'Project Management Team') && (
                        <> */}
                      {/* Animation button css name -- upsale-button */}
                      <div>
                        <button
                          className="btn1 "
                          id="button1"
                          onMouseMove={hoverFun}
                          onClick={handleLC}
                        >
                          <span> Corporates </span>
                        </button>
                      </div>
                      {/* <a href='/' className="upsale-button">
                        <span>Button</span>
                    </a> */}
                      <div>
                        <button
                          className="btn2 "
                          id="button2"
                          onMouseMove={hoverFun}
                          onClick={handleMsme}
                        >
                          <span> MSME's </span>
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn3 "
                          id="button3"
                          onMouseMove={hoverFun}
                          onClick={handleEnablers}
                        >
                          <span> Other Enablers </span>
                        </button>
                      </div>
                      {/* <div>
                      <button
                        className="btn3"
                        id="button4"
                        onMouseMove={hoverFun}
                        onClick={handleGovt}
                      >
                        <span> Government </span>
                      </button>
                      </div> */}
                      {/* onClick={() => setStore('post')} */}
                      {/* </>
                        )
                      } */}
                    </div>
                    <div>
                      <button
                        className="btn4"
                        id="button4"
                        onMouseMove={hoverFun}
                        onClick={handleGovt}
                      >
                        <span> Government </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="clr-img">
                    <img
                      alt=""
                      src="/images/human-mind.png"
                      className="banner-img img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="holder pt-5">
              <h1 className="text-center font-weight-bold">Our Stakeholders</h1>
            </div>
            <marquee>
              <div className="logo-mar py-5">
                <img alt="" src="/images/l1.png" className="img-fluid" />
                <img alt="" src="/images/l8.png" className="img-fluid" />
                <img alt="" src="/images/l4.png" className="img-fluid" />
                <img alt="" src="/images/l7.png" className="img-fluid" />
                <img alt="" src="/images/l2.png" className="img-fluid" />
                <img alt="" src="/images/l5.png" className="img-fluid" />
                <img alt="" src="/images/l3.png" className="img-fluid" />
                <img alt="" src="/images/l6.png" className="img-fluid" />
              </div>
            </marquee>
          </section>

          <section className="ellipse">
            <section
              className="section-animation"
              id="animtion-start"
              data-aos="zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-delay="1000"
            >
              <div className="circle-bg">
                {/* <div className="content-wrapper"> */}
                <div className="circle-head">
                  <h1 className="font-weight-bold text-center">
                    Live Challenges
                  </h1>
                </div>
                {/* <div className='swiper-btn py-3'>
                    <button className='btn2' id='button2'>
                      <span>Government</span>
                    </button>
                    <button className='btn2 ml-3' id='button2'>
                      <span>Corporates</span>
                    </button>
                  </div> */}
                <div className="swiper-container">
                  <Swiper
                    centeredSlides={false}
                    autoplay={{
                      delay: 10000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: false,
                    }}
                    navigation={false}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                  >
                    {challengeListValue.length > 0 ? (
                      challengeListValue.map((list, index) => (
                        <SwiperSlide>
                          <div className="container">
                            <center>
                              <div className="rec-box">
                                <div
                                  className="text-center"
                                  data-aos="fade-up"
                                  data-aos-delay="2100"
                                >
                                  <img
                                    alt=""
                                    src={list.challengeImageName}
                                    className="mt-3 img-fluid ban-img"
                                  />
                                  <h1 className="rec-bx-head text-center mt-4">
                                    {list.challengeTitle}
                                  </h1>
                                  <h4>
                                    <span className="blue-clr">End Date :</span>
                                    <span className="yellow-clr font-weight-bold">
                                      {" "}
                                      {list.endDate2}
                                    </span>
                                  </h4>
                                  <button
                                    className="btn-sub text-center mb-3"
                                    onClick={() =>
                                      handleViewDetails(list.id, list.userId)
                                    }
                                  >
                                    Apply Now
                                  </button>
                                </div>
                              </div>
                            </center>
                          </div>
                        </SwiperSlide>
                      ))
                    ) : (
                      <SwiperSlide>
                        <div className="container">
                          <center>
                            <div className="rec-box h-50px">
                              <div
                                className="text-center"
                                data-aos="fade-up"
                                data-aos-delay="2100"
                              >
                                {/* <img alt='' src={list.challengeImageName} className='mt-3 img-fluid ban-img' /> */}
                                <h1 className="rec-bx-head text-center py-5">
                                  Coming soon
                                </h1>
                                {/* <h4><span className='blue-clr'>End Date :</span><span className='yellow-clr font-weight-bold'> {list.endDate2}</span></h4> */}
                                {/* <button className='btn-sub text-center mb-3' onClick={()=>handleViewDetails(list.id)}>View Details</button> */}
                              </div>
                            </div>
                          </center>
                        </div>
                      </SwiperSlide>
                    )}
                  </Swiper>
                </div>
                {/* <div className="scrollable-content">
                    <div className='pb-5'>
                      <div className='' id="hideDiv" >
                        <center><img alt='' src='/images/new-key.png' className=' mt-4 img-fluid' /></center>
                        <h4 className='rec-2-sh text-center font-weight-bold mt-5'>CHALLENGES UNLOCKED</h4>
                        <h1 className='blue-clr2 text-center font-weight-bolder pt-4'>The catalyst to redefine innovation culture</h1>
                        <center><button className='yellow-clr-button mt-4' onClick={handleLC}>Click to Innovate!</button></center>
                      </div>
                    </div>
                  </div> */}
                {/* </div> */}
              </div>
            </section>
          </section>

          {/* <section className='pb-5'>
            <div className='holder mt-5'>
              <h1 className='text-center font-weight-bold'>Our Stakeholders</h1>
            </div>
            <div className='logo-image1 my-5'>
              <span><img alt='' src='/images/l1.png' className='logos m-3 img-fluid' /></span>
              <span><img alt='' src='/images/l8.png' className='logos m-3 img-fluid' /></span>
              <span><img alt='' src='/images/l4.png' className='logos m-3 img-fluid' /></span>
            </div>
            <div className='container'>
              <div className='logo-image2 my-5'>
                <span><img alt='' src='/images/l7.png' className='logos1 img-fluid' /></span>
                <span><img alt='' src='/images/l2.png' className='logos1 img-fluid' /></span>
                <span><img alt='' src='/images/l5.png' className='logos1 img-fluid' /></span>
                <span><img alt='' src='/images/l3.png' className='logos1 img-fluid' /></span>
                <span><img alt='' src='/images/l6.png' className='logos1 img-fluid' /></span>
              </div>
            </div>
          </section> */}

          {/* <ProcessStructure /> */}

          {/* <section className='mt-5'>
          <div className='rec-2-box'>
            <h1 className='blue-clr text-center font-weight-bolder pt-5'>Unleash the innovation together</h1>
            <h4 className='rec-2-sh text-center font-weight-bold mt-3'>CHALLENGES UNLOCKED</h4>
           <div className='ver-ani d-flex justify-content-center'>
              <div className='text1 mt-5'>
                <article><span className='text-ani font-weight-bold'>CORPORATES<br />GOVERNMENT<br />RESEARCH INSTITUTIONS</span></article>
              </div>
              <img alt='' src='/images/key.png' className='rec-2-img mt-4 img-fluid' />
              <div className='text2 mt-5'>
                <article><span className='text-ani1 font-weight-bold'>INNOVATORS<br />STARTUPS<br />INNOVATORS</span></article>
              </div>
            </div>

            <center><button className='yellow-clr-button mt-5' onClick={handleLC}>Ready to Innovate!</button></center>
          </div>
        </section> */}

          {/* NEW CODE */}
          {/* <section className='pb-5'>
          <div className='' id="hideDiv" data-aos="fade-up" data-aos-delay="700">
            <center><img alt='' src='/images/new-key.png' className=' mt-4 img-fluid' /></center>
            <h4 className='rec-2-sh text-center font-weight-bold mt-5'>CHALLENGES UNLOCKED</h4>
            <h1 className='blue-clr2 text-center font-weight-bolder pt-4'>The catalyst to redefine innovation culture</h1>
            <center><button className='yellow-clr-button mt-4' onClick={handleLC}>Click to Innovate!</button></center>
          </div>
        </section> */}

          {/* <section>
            <div className='holder pt-5'>
              <h1 className='text-center font-weight-bold'>Our Stakeholders</h1>
            </div>
            <marquee>
              <div className='logo-mar py-5'>
                <span><img alt='' src='/images/l1.png' className='img-fluid' /></span>
                <span><img alt='' src='/images/l8.png' className='img-fluid' /></span>
                <span><img alt='' src='/images/l4.png' className='img-fluid' /></span>
                <span><img alt='' src='/images/l7.png' className='img-fluid' /></span>
                <span><img alt='' src='/images/l2.png' className='img-fluid' /></span>
                <span><img alt='' src='/images/l5.png' className='img-fluid' /></span>
                <span><img alt='' src='/images/l3.png' className='img-fluid' /></span>
                <span><img alt='' src='/images/l6.png' className='img-fluid' /></span>
              </div>
            </marquee>
          </section> */}
        </main>

        <ProcessStructure />

        <section className="">
          <div className="rec-2-box">
            <h1 className="blue-clr text-center font-weight-bolder pt-5">
              Unleash the innovation together
            </h1>
            <h4 className="rec-2-sh text-center font-weight-bold mt-3">
              CHALLENGES UNLOCKED
            </h4>
            <div className="ver-ani d-flex justify-content-center">
              <div className="text1 mt-5">
                <article>
                  <span className="text-ani font-weight-bold">
                    CORPORATES
                    <br />
                    GOVERNMENT
                    <br />
                    RESEARCH INSTITUTIONS
                  </span>
                </article>
              </div>
              <img
                alt=""
                src="/images/key.png"
                className="rec-2-img mt-4 img-fluid"
              />
              <div className="text2 mt-5">
                <article>
                  <span className="text-ani1 font-weight-bold">
                    INNOVATORS
                    <br />
                    STARTUPS
                    <br />
                    INNOVATORS
                  </span>
                </article>
              </div>
            </div>
            <center>
              <button className="yellow-clr-button mt-5" onClick={handleLC}>
                Ready to Innovate!
              </button>
            </center>
          </div>
        </section>
      </div>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {/* <DialogTitle>Welcome to Open Innovation Portal</DialogTitle>
        <DialogContent>
          <p>We connect Corporates, Research Institutions, and Government agencies with innovators and Startups from Tamilnadu. Explore our live challenges and join us in unlocking innovation!</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions> */}
        <div className="swiper-container">
          <Swiper
            centeredSlides={false}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            {/* {
              dialogImg.length > 0 && (dialogImg.map((list, index) => (
                <SwiperSlide>
                  <img alt='' src={list.img} className="img-fluid" />
                </SwiperSlide>
              )))
            } */}
            {
              modalDetails.length > 0 &&
                modalDetails.map((list, index) => (
                  <>
                    <SwiperSlide>
                      <div className="container" key={index}>
                        <div className="float" onClick={handleClosePop}>
                          <CloseIcon />
                        </div>
                        <center>
                          <div className="rec-box-popup">
                            <div className="text-center">
                              <img
                                alt=""
                                src={list.challengeImageName}
                                className="img-fluid ban-img"
                              />
                              <h2 className="rec-bx-head text-center mt-2">
                                {list.challengeTitle}
                              </h2>
                              <h4>
                                <span className="blue-clr">End Date :</span>
                                <span className="yellow-clr font-weight-bold">
                                  {" "}
                                  {list.endDate2}
                                </span>
                              </h4>
                              {list.showButton === false ? (
                                <button
                                  className="btn-sub text-center mb-3"
                                  onClick={() =>
                                    handleViewDetails(list.id, list.userId)
                                  }
                                >
                                  Apply Now
                                </button>
                              ) : (
                                <button
                                  className="btn-sub text-center mb-3"
                                  onClick={() =>
                                    handleViewDetails(list.id, list.userId)
                                  }
                                >
                                  Expression of Interest
                                </button>
                              )}
                            </div>
                          </div>
                        </center>
                      </div>
                    </SwiperSlide>
                  </>
                ))
              // ) : (
              // <SwiperSlide>
              //   <div className="container">
              //     <div className="float" onClick={handleClosePop}>
              //       <CloseIcon />
              //     </div>
              //     <center>
              //       <div className="rec-box-popup h-50px">
              //         <div className="text-center">
              //           {/* <img alt='' src={list.challengeImageName} className='mt-3 img-fluid ban-img' /> */}
              //           <h1 className="rec-bx-head text-center py-5">
              //             Coming soon
              //           </h1>
              //           {/* <h4><span className='blue-clr'>End Date :</span><span className='yellow-clr font-weight-bold'> {list.endDate2}</span></h4> */}
              //           {/* <button className='btn-sub text-center mb-3' onClick={()=>handleViewDetails(list.id)}>View Details</button> */}
              //         </div>
              //       </div>
              //     </center>
              //   </div>
              // </SwiperSlide>
              // <></>
            }
          </Swiper>
        </div>
      </Dialog>
    </div>
  );
};

export default Homepagenew;
