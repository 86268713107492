import { Button, Divider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Accordionpage from "../../../common-pages/Accordionpage";
import notifyService from "../../../../api/notifySerivce";
import { LoadingButton } from "@mui/lab";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
// import { Divider } from '@mui/material';
const Livechallengesdetails = ({ name }) => {
  const htmlToText = require("html-to-text");
  const { fromHtml } = require("html-to-text");
  const { id, userid } = useParams();
  let challengeId;
  let userId;
  if (id !== undefined) {
    challengeId = atob(id);
  }
  if (userid !== undefined) {
    userId = atob(userid);
  }
  const { navigator, setPageLoading, btnLoading, setBtnLoading } =
    useContext(DataContext);
  const role = localStorage.getItem("role");
  useDidMountEffect(() => {
    getApi();
    onApplyNow();
  }, []);

  const [getValue, setGetValue] = useState({});
  const getApi = () => {
    setPageLoading(true);
    apiService(`challenge/get?id=${challengeId}`, "", "get").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          var response = res.data;
          if (response.challengeImageName) {
            response.challengeImageURL = response.challengeImageName;
          }
          if (response.problemStatement) {
            response.problemStatement.forEach((element) => {
              element.imageURL = element.imageName;
            });
          }
          if (response.partner) {
            response.partner.forEach((element) => {
              element.partnerLogoURL = element.partnerLogoFileName;
            });
          }
          setGetValue(res.data);
        }
      }
    });
  };
  const [checkBtn, setCheckBtn] = useState(false);
  const [ids, setIds] = useState();
  const onApplyNow = (value) => {
    setBtnLoading(true);
    apiService(
      `solution/apply/verify?challengeid=${challengeId}&userId=${userId}`,
      "",
      "get"
    ).then((res) => {
      debugger;
      setBtnLoading(false);
      if (res) {
        if (
          res.data.responseStatus ===
          "You have already applied for this Challenge"
        ) {
          setCheckBtn(false);
          setIds(res.data.id);
          debugger;
        } else {
          setCheckBtn(true);
          // notifyService('danger', res.data.responseMessage)
        }
      }
    });
  };

  const onApplyPage = (value) => {
    navigator(`${name}/challenges/apply/${id}/${userid}/${value}`);
  };
  const onViewPage = (value) => {
    navigator(`/corporate/challenges/solution-details/${btoa(ids)}`);
    // debugger
  };
  let token = localStorage.getItem("token");
  const handleBack = () => {
    if (token) {
      navigator(`${name}/challenges`);
    } else {
      navigator(`/challenge/${name}`);
    }
  };
  const handleLoginPage = () => {
    navigator(`/sign-in`);
  };
  return (
    <>
      <Helmet>
        <title>
          Open Innovation Portal | Challenges | Live-challenges | Details
        </title>
      </Helmet>
      <section className="Challenges_details_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row mb-4">
                <div className="col-lg-8 col-md-8 col-12">
                  <img
                    src={getValue.challengeImageURL}
                    className="img-fluid challenge_img"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="align_data">
                    <div className="apply_challenges">
                      {role === "Solution Provider" && <h4>TO PARTICIPATE</h4>}

                      <div className="row">
                        <div className="col-12">
                          <div className="timeline_div">
                            <h5>Timeline</h5>
                            <p>
                              Start Date : <b>{getValue.startDate2}</b>
                            </p>
                            <p>
                              End Date : <b>{getValue.endDate2}</b>
                            </p>

                            {role === "Solution Provider" &&
                              getValue.showButton === true && (
                                <>
                                  {checkBtn === false && <p>Already Applied</p>}
                                </>
                              )}
                          </div>
                        </div>
                        {!token && getValue.showButton === true && (
                          <div className="col-12 text-center mt-3">
                            <LoadingButton
                              // loadingPosition="start"
                              onClick={handleLoginPage}
                              // loading={btnLoading}
                              className="b-yellow-btn"
                            >
                              <span>Apply Now</span>
                            </LoadingButton>
                          </div>
                        )}
                        {role === "Solution Provider" &&
                          getValue.showButton === true && (
                            <>
                              {checkBtn === true ? (
                                <div className="col-12 text-center mt-3">
                                  <LoadingButton
                                    // loadingPosition="start"
                                    onClick={() =>
                                      onApplyPage(getValue.challengeTitle)
                                    }
                                    // loading={btnLoading}
                                    className="b-yellow-btn"
                                  >
                                    <span>Apply Now</span>
                                  </LoadingButton>
                                </div>
                              ) : (
                                <div className="col-12 text-center mt-3">
                                  <LoadingButton
                                    // loadingPosition="start"
                                    onClick={() => onViewPage(ids)}
                                    // loading={btnLoading}
                                    className="b-yellow-btn"
                                  >
                                    <span>View Application</span>
                                  </LoadingButton>
                                </div>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="challenge_banner_details">
                <h1 className="mb-3">{getValue.challengeTitle}</h1>
                <div
                  className="challenge_intro mb-2"
                  dangerouslySetInnerHTML={{
                    __html: getValue.challengeIntroduction,
                  }}
                ></div>
              </div>
            </div>
            {/* <div className='col-lg-4 col-md-6 col-12'>
              <div className='align_data'>
                <div className='apply_challenges'>
                  {
                    role === 'Solution Provider' && (<h4>TO PARTICIPATE</h4>)
                  }

                  <div className='row'>
                    <div className='col-12'>
                      <div className='timeline_div'>
                        <h5>Timeline</h5>
                        <p>Start Date : <b>{getValue.startDate2}</b>
                        </p>
                        <p>End Date : <b>{getValue.endDate2}</b>
                        </p>
                      </div>
                    </div>
                    {
                      (role === 'Solution Provider' && getValue.showButton===true) && (
                        <div className='col-12 text-center mt-3'>
                          <LoadingButton
                            loadingPosition="start"
                            onClick={() => onApplyNow(getValue.challengeTitle)}
                            loading={btnLoading}
                            className="b-yellow-btn"
                          >
                            <span>Apply Now</span>
                          </LoadingButton>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="more_details_section">
        <div className="container">
          <div className="challenge_banner_details">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="challenge_intro my-3">
                  <h4 className="challenge_intro_title mb-4">
                    <span>Technology Area</span>
                  </h4>
                  {getValue.technologyArea &&
                    getValue.technologyArea.map((data) => <p> {data}</p>)}
                  {/* <div>{getValue.sector}</div> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="challenge_intro my-3">
                  <h4 className="challenge_intro_title mb-4">
                    <span>Sector</span>
                  </h4>
                  <div>{getValue.sector}</div>
                </div>
              </div>
            </div>
            <div className="challenge_intro my-3">
              <h4 className="challenge_intro_title mb-4">
                <span>Expected</span> Outcome
              </h4>
              <div
                dangerouslySetInnerHTML={{ __html: getValue.expectedOutcome }}
              ></div>
            </div>
            <Divider />
            <div className="challenge_intro my-3">
              <h4 className="challenge_intro_title mb-4">
                <span>Problem</span> Statement
              </h4>
              {getValue.problemStatement && (
                <>
                  <ul>
                    {getValue.problemStatement.map((list, index) => (
                      <li key={index} className="box-code">
                        {/* {list.problemStatement} */}
                        <div className="row">
                          <div className="col-lg-3 col-md-4 col-12">
                            <img
                              src={list.imageURL}
                              className="img-fluid prob_img"
                            />
                          </div>
                          <div className="col-lg-9 col-md-8 col-12">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: list.problemStatement,
                              }}
                            ></div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <Divider />
            <div className="challenge_intro my-3">
              <h4 className="challenge_intro_title mb-4">
                <span>How To</span> Participate
              </h4>
              <div
                dangerouslySetInnerHTML={{ __html: getValue.howToParticipate }}
              ></div>
            </div>
            <Divider />
            <div className="challenge_intro my-3">
              <h4 className="challenge_intro_title mb-4">
                <span>Benefits</span>
              </h4>
              {getValue.benefits && (
                <>
                  <ul>
                    {getValue.benefits.map((list, index) => (
                      <li key={index} className="box-code">
                        <div
                          dangerouslySetInnerHTML={{ __html: list.benefits }}
                        ></div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <Divider />
            <div className="challenge_intro my-3">
              <h4 className="challenge_intro_title mb-4">
                <span>Eligibility</span>
              </h4>
              {getValue.eligibility && (
                <>
                  <ul>
                    {getValue.eligibility.map((list, index) => (
                      <li key={index} className="box-code">
                        <div
                          dangerouslySetInnerHTML={{ __html: list.eligibility }}
                        ></div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <Divider />
            <div className="challenge_intro my-3">
              <h4 className="challenge_intro_title mb-4">
                <span>Procedure</span> Post Submission
              </h4>
              {getValue.process?.map((res, index) => (
                <>
                  <Accordion
                    key={index}
                    sx={{
                      background: "#F2F7F8",
                      border: "none",
                      boxShadow: "none",
                      marginBottom: "15px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandCircleDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="accordion_title">
                        {`Step ${index + 1}`}{" "}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordion_p">
                        <div
                          dangerouslySetInnerHTML={{ __html: res.process }}
                        ></div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
            </div>
            <Divider />
            <div className="challenge_intro my-3">
              <h4 className="challenge_intro_title mb-4">
                <span>Partners</span>
              </h4>
              {getValue.partner && (
                <div className="d-flex justify-content-center">
                  {getValue.partner.map((res, index) => (
                    <div className="partner_logo_details">
                      <>
                        {res.partnerLogoURL ? (
                          <>
                            <img
                              key={index}
                              src={res.partnerLogoURL}
                              className="partner-logo"
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        <h5>{res.partnerName}</h5>
                      </>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <main className="live_challenges_main">
        <div className="container">
          {/* <div className='challenge_more'>
            <h4><span>Additional</span> Details</h4>
          </div>
          <Divider /> */}
          <Accordionpage getValue={getValue} />
        </div>
        <div className="text-center mb-5">
          <Button
            className="yellow-btn mr-3"
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
          >
            BACK
          </Button>
          {!token && getValue.showButton === true && (
            <LoadingButton onClick={handleLoginPage} className="b-yellow-btn">
              <span>Apply Now</span>
            </LoadingButton>
          )}
          {/* <Button className='yellow-btn ml-3'>Apply Now</Button> */}
          {/* {
            (role === 'Solution Provider' && getValue.showButton === true) && (
              <LoadingButton
                loadingPosition="start"
                // onClick={() => onApplyNow(getValue.challengeTitle)}
                loading={btnLoading}
                className="blue-btn-sm ml-3"
              >
                <span>Apply Now</span>
              </LoadingButton>
            )
          } */}
          {role === "Solution Provider" && getValue.showButton === true && (
            <>
              {checkBtn === true ? (
                <div className="">
                  <LoadingButton
                    // loadingPosition="start"
                    onClick={() => onApplyPage(getValue.challengeTitle)}
                    // loading={btnLoading}
                    className="blue-btn-sm ml-3"
                  >
                    <span>Apply Now</span>
                  </LoadingButton>
                </div>
              ) : (
                <div className="">
                  <LoadingButton
                    // loadingPosition="start"
                    onClick={() => onViewPage(ids)}
                    // loading={btnLoading}
                    className="blue-btn-sm ml-3"
                  >
                    <span>View Application</span>
                  </LoadingButton>
                </div>
              )}
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default Livechallengesdetails;
