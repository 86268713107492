import { Box, Button, IconButton, ListItemIcon, Menu, MenuItem, Modal, Tooltip } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import DataContext from '../../context/DataContext'
import MenuIcon from '@mui/icons-material/Menu';
// import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';
import LogoutIcon from '@mui/icons-material/Logout';
import apiService from '../../api/apiService';
import Changepassword from '../login-module/Changepassword';
import $ from 'jquery';
const Navbar = () => {
  const { navigator } = useContext(DataContext)
  const handleSignIn = () => {
    navigator('/sign-in')
  }
  const handleSignOut = () => {
    navigator('/sign-up')
  }
  const handleLogOut = () => {
    const userId = localStorage.getItem('userId')
    apiService(`auth/logout?userid=${userId}`, '', 'get').then((res) => {
      localStorage.clear()
      navigator('/home')
      handleCloseUserMenu()
    })

  }
  const role = localStorage.getItem('role')
  const userName = localStorage.getItem('name')

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const setStore = (value) => {
    localStorage.setItem('click', value)
    // handleCss()
  }
  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const handleChangePassword = () => {
    setOpenPasswordModal(true)
  }
  const handleClosePasswordModal = () => {
    setOpenPasswordModal(false)
    handleCloseUserMenu()

  }
  const handleInventor = () => {
    handleCloseUserMenu()
    navigator('inventor/list')
  }
  const Corporates = (value) => {
    localStorage.setItem("val", value)
  }
  // const handleCss = () => {
  //   window.scrollTo(0,0)
  //   // debugger
  //   $('.nav-link').addClass('cir1');
  //   $('.cir1').addClass('animate-cir1');
  //   $('.cir1').on('animationend webkitAnimationEnd oAnimationEnd', function () {
  //     $('.cir1').removeClass('animate-cir1');
  //     $('.nav-link').removeClass('cir1');
  //   });
  // }
  return (
    <nav className="navbar navbar-expand-lg sticky-top nav-bg">
      <div className='container-fluid'>
        <NavLink className="navbar-brand" to="">
          <img src='/images/logopng.png' className='img-fluid logo-img' alt='' />
        </NavLink>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav m-auto">  
            {
              (role === 'Admin' || role === 'Project Management Team') && (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/corporate" >Corporates</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/government" >Government</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/msme" >MSME's</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/enablers" >Other Enablers</NavLink>
                  </li>
                  <li className="nav-item" >
                    <NavLink className="nav-link" to="/admin/tech-market">Tech Marketplace</NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/requirement" >Requirements</NavLink>
                  </li> */}
                </>
              )
            }
            {
              (role === 'Solution Provider') && (
                <>
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Challenges
                    </Link>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <NavLink className="dropdown-item" to="/corporate">Corporates</NavLink>
                      <NavLink className="dropdown-item" to="/government">Government</NavLink>
                      <NavLink className="dropdown-item" to="/msme">MSME's</NavLink>
                      <NavLink className="dropdown-item" to="/enablers">Other Enablers</NavLink>
                    </div>
                  </li>
                  <li className="nav-item" >
                    <NavLink className="nav-link" to="/requirement">Requirement</NavLink>
                  </li>
                  <li className="nav-item" >
                    <NavLink className="nav-link" to="/techmarket">Tech Marketplace</NavLink>
                  </li>
                </>
              )
            }
            {
              (role === null) && (
                <>
                  <NavLink className="nav-link" to="/home">Home</NavLink>
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Challenges
                    </Link>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <NavLink className="dropdown-item" to="/challenge/government" onClick={() => Corporates("Government")}>Government</NavLink>
                      <NavLink className="dropdown-item" to="/challenge/corporate" onClick={() => Corporates("Corporates")}>Corporates</NavLink>
                     
                      <NavLink className="dropdown-item" to="/challenge/msme" onClick={() => Corporates("MSME's")}>MSME's</NavLink>
                      <NavLink className="dropdown-item" to="/challenge/enablers" onClick={() => Corporates("Other Enablers")}>Other Enablers</NavLink>
                    </div>
                  </li>
                  <li className="nav-item" >
                    <NavLink className="nav-link" to="/techmarket">Tech Marketplace</NavLink>
                  </li>
                  <li className="nav-item" >
                    <NavLink className="nav-link" to="/Faq">FAQ</NavLink>
                  </li>
                </>
              )
            }
            {
              role === 'Challenge Provider' && (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/corporate" >Corporates</NavLink>
                  </li>
                  <li className="nav-item" >
                    <NavLink className="nav-link" to="/techmarket">Tech Marketplace</NavLink>
                  </li>
                </>
              )
            }
            {
              role === 'GOVT' && (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/government" >Challenges</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/requirement" >Requirements</NavLink>
                  </li>
                  <li className="nav-item" >
                    <NavLink className="nav-link" to="/techmarket">Tech Marketplace</NavLink>
                  </li>
                </>
              )
            }
             {
              role === "MSME's" && (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/msme" >MSME's</NavLink>
                  </li>
                  <li className="nav-item" >
                    <NavLink className="nav-link" to="/techmarket">Tech Marketplace</NavLink>
                  </li>
                </>
              )
            }
             {
              role === "Other Enablers" && (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/enablers" >Other Enablers</NavLink>
                  </li>
                  <li className="nav-item" >
                    <NavLink className="nav-link" to="/techmarket">Tech Marketplace</NavLink>
                  </li>
                </>
              )
            }
            {
              role === 'Jury' && (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/jury/challenge" >Challenges</NavLink>
                  </li>
                  <li className="nav-item" >
                    <NavLink className="nav-link" to="/techmarket">Tech Marketplace</NavLink>
                  </li>
                </>
              )
            }
            {
              role === 'Inventor' && (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/inventor" >Researcher</NavLink>
                </li>
              )
            }
            {/* {
              (role === 'Solution Provider') && (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/challenges" >Live Challenges</NavLink>
                </li>
              )
            } */}
            {/* {
              (role === 'Admin' || role === 'Project Management Team') && (
                <> */}
            {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/dashboard" >Dashboard</NavLink>
                  </li> */}
            {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/challenge-status"  >Challenges</NavLink>
                  </li>
                </>
              )
            } */}
            

            {/* <li className="nav-item">
              <Link className="nav-link" to="/resourcespage" >Resources</Link>
              onClick={()=>handleCss()}
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link">Contact  us</Link>
            </li> */}
          </div>
          {
            !userName ? (
              <div>
                <Button className='signin_btn font-weight-bold' onClick={handleSignIn}>Log In</Button>
                {/* <Button className='signup_btn' onClick={handleSignOut}>Sign Up</Button> */}
              </div>
            ) : (
              <>
                <p className='user_menu'>Welcome <span className='nav_user_span'>{userName}</span></p>
                <Box sx={{ marginLeft: '10px' }} >

                  <Tooltip title="Open settings" className='nav-bar-btn-line'>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <MenuIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&::before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                  >
                    <MenuItem onClick={handleChangePassword}>
                      <ListItemIcon>
                        <PasswordIcon fontSize="small" />
                      </ListItemIcon> Change Password
                    </MenuItem>
                    <MenuItem onClick={handleLogOut}>
                      <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                      </ListItemIcon> Logout
                    </MenuItem>
                  </Menu>
                </Box>
                <Modal open={openPasswordModal}>
                  <Changepassword handleClosePasswordModal={handleClosePasswordModal} />
                </Modal>
              </>

              // <Button className='signup_btn' onClick={handleLogOut}>Logout</Button>
            )
          }

        </div >
      </div >
    </nav >
  )
}

export default Navbar