import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../api/apiService';
import notifyService from '../../api/notifySerivce';
import { FormHelperText, FormLabel, TextField } from '@mui/material';
import DataContext from '../../context/DataContext';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import cryptoJS from '../../hooks/cryptoJS';
import Forgetpage from './Forgetpage';
import Modal from '@mui/material/Modal';
import SvgIcon from '@mui/material/SvgIcon';
import { Helmet } from 'react-helmet';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ReCAPTCHA from 'react-google-recaptcha';


const Signinpage = () => {
  const { navigator, setPageLoading, validateEmail } = useContext(DataContext)
  const reCAPTCHA = process.env.REACT_APP_CAPTCHA
  // const [formData, setFormData] = useState({});
  const { handleSubmit, control, formState: { errors } } = useForm({});
  const [loading, setLoading] = useState(false);
  const [capVal, setCapVal] = useState(null)
  const onSubmit = async (data) => {
    setPageLoading(true)
    setLoading(true);
    var getform = data
    getform.userName = cryptoJS(data.userName)
    getform.password = cryptoJS(data.password)
    debugger
    apiService(`auth/login`, getform, 'unauthpost').then((res) => {
      setPageLoading(false)
      setLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {

          localStorage.setItem('token', res.data.token)
          localStorage.setItem('email', res.data.email)
          localStorage.setItem('phone', res.data.phone)
          localStorage.setItem('userName', res.data.userName)
          localStorage.setItem('name', res.data.name)
          localStorage.setItem('userId', res.data.userId)
          localStorage.setItem('roleId', res.data.roleId)
          localStorage.setItem('role', res.data.role)
          if (res.data.role === 'Project Management Team' || res.data.role === 'Admin') {
            navigator('/admin/corporate')
          } else if (res.data.role === 'Challenge Provider') {
            navigator('/corporate/challenges/list')
          } else if (res.data.role === 'Solution Provider') {
            navigator('/corporate/challenges/list')
          } else if (res.data.role === 'GOVT') {
            navigator('/government/challenges/list')
          } else if (res.data.role === "MSME's") {
            navigator('/msme/challenges/list')
          } else if (res.data.role === "Other Enablers") {
            navigator('/enablers/challenges/list')
          }else if (res.data.role === 'Inventor') {
            navigator('/inventor/list')
          } else if (res.data.role === 'Jury') {
            navigator('/jury')
          }
          notifyService('success', res.data.responseMessage)
        } else {
          notifyService('danger', res.data.responseMessage)
        }
      }
    })
  }
  const onError = (errors, e) => {
    notifyService('danger', 'Form is invalid', 'Please check the mandatory fields')
  };
  const handleHome = () => {
    navigator('/home')
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  }
  let checkMenu = localStorage.getItem('click')
  function HomeIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </SvgIcon>
    );
  }
  const [opens, setOpens] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("lg");
  const handleClickOpend = () => {
    setOpens(true);
  };
  const handleClosed = () => {
    setOpens(false);
  };
  const handleRoleName = (value) => {
    debugger
    localStorage.setItem("roleName", value);
    setOpens(false);
  }

  return (
    <div className='log_section'>
      <Helmet>
        <title>Open Innovation Portal | Signin</title>
      </Helmet>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-12 p-0'>
            <div className='login_section'>
              <p className='home-nav-btn text-left font-weight-bold mt-2' onClick={handleHome}><HomeIcon /> Home</p>
              <div className='login_header'>
                <img src='/images/blue-logo.png' alt='' onClick={handleHome} className='img-fluid login-logo' />
                <div className='login_title'>
                  <h4>
                    Welcome Back !
                  </h4>
                  <p>Welcome Back ! Please Enter Your Credentials to LogIn</p>
                </div>
              </div>
              <div>
                <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
                  <div className='row'>
                    <div className='col-lg-12 col-12'>
                      <div className='form-group mb-3'>
                        <FormLabel shrink="true" className='input_Lable'>Email <span className='text-blue'></span></FormLabel>
                        <Controller name="userName" control={control} defaultValue="" rules={{ required: `Email is required`, validate: validateEmail }}
                          render={({ field }) =>
                            <TextField
                              placeholder='Enter your Email'
                              fullWidth
                              autoFocus
                              type='text'
                              {...field}
                            />} />
                        <FormHelperText className='text-danger'>{errors.userName && errors.userName.message}</FormHelperText>
                      </div>
                    </div>
                    <div className='col-lg-12 col-12'>
                      <div className='form-group mb-3'>
                        <FormLabel shrink="true" className='input_Lable'>Password <span className='text-blue'></span></FormLabel>
                        <Controller name="password" control={control} defaultValue="" rules={{ required: `Password is required` }}
                          render={({ field }) =>
                            <TextField
                              placeholder='Enter your Password'
                              fullWidth
                              type='password'
                              {...field}
                            />} />
                        <FormHelperText className='text-danger'>{errors.password && errors.password.message}</FormHelperText>
                      </div>
                      <p className='forget_p text-right' onClick={handleOpen}><Link>Forgot Password ?</Link></p>
                      {/*  */}
                    </div>
                    <div className='col-lg-12 col-12 text-center mt-3'>
                      <div className='d-flex justify-content-center mb-3'>
                        {/* dev -> 6Ld9hD0pAAAAAOD8lvivdqQZHhaEPo9SJUfeip_b */}
                        {/* local -> 6Lf2gT0pAAAAADMQ2mq5NlyBWg6fHoPSdOATjcaL */}
                        {/* live -> 6Lf-A0cpAAAAAJ5we6S4MfrqYQWnFWRainQOG87u */}
                        {/* qa -> 6Lf3-VspAAAAANoYAU-Q9iOjsBxgieXM3zeyPIUH */}
                        <ReCAPTCHA sitekey={reCAPTCHA} onChange={val => setCapVal(val)} />
                      </div>
                      <LoadingButton
                        loadingPosition="start"
                        type="submit"
                        loading={loading}
                        disabled={!capVal}
                        className="b-blue-btn"
                      >
                        <span>Login</span>
                      </LoadingButton>
                    </div>
                  </div>
                  {/* {
                    checkMenu && (
                      <div className='text-center my-4'>
                        <p className='signuproute_p'>Don’t have an account? <Link to="/sign-up">Sign up</Link></p>
                      </div>
                    )
                  } */}
                  <div className="text-center my-4">
                    <p className="signuproute_p c-pointer">
                      Don’t have an account? <Link to="/sign-up">Sign up</Link>
                    </p>
                  </div>

                </form>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-12 p-0'>
            <div className="box_login">
              <img src='/images/Login-page.png' alt='' className='img-fluid' />
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} >
        <Forgetpage handleClose={handleClose} />
      </Modal>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={opens}
        onClose={handleClosed}
      >
        <DialogContent>
          <div className="wrap d-md-flex">
            <div className="text-wrap p-3  text-center d-flex align-items-center order-md-last mx-1">
              <div className="text w-100">
                <img
                  src="/images/tanseed-logo.png"
                  className="white_logo"
                  alt="tanseed-logo"
                />
                <p className="set-fnt mb-3 mt-3">Are you an Corporate ? </p>
                {/* <Link onClick={handleClose} to="/startup/dashboard"><img src='/images/applygiffy.gif' className='white_logo' alt='tanseed-logo'/></Link> */}
                <Link
                  onClick={() => handleRoleName(3)}
                  to="/sign-up"
                  className="btn btn-white btn-outline-white mt-3"

                >
                  Register Now
                </Link>
              </div>
            </div>
            <div className="text-wrap p-3  text-center d-flex align-items-center order-md-last  mx-1">
              <div className="text w-100">
                <img
                  src="/images/tanseed-logo.png"
                  className="white_logo"
                  alt="tanseed-logo"
                />
                <p className="set-fnt mb-3 mt-3">Are you an Government ? </p>
                <Link
                  onClick={() => handleRoleName(5)}
                  to="/sign-up"
                  className="btn btn-white btn-outline-white  mt-3"
                >
                  Register Now
                </Link>
              </div>
            </div>
            <div className="text-wrap p-3  text-center d-flex align-items-center order-md-last  mx-1">
              <div className="text w-100">
                <img
                  src="/images/tanseed-logo.png"
                  className="white_logo"
                  alt="tanseed-logo"
                />
                <p className="set-fnt mt-3 mb-3">Are you an Inventor ?</p>

                <Link
                  onClick={() => handleRoleName(7)}
                  to="/sign-up"
                  className="btn btn-white btn-outline-white  mt-3"
                >
                  Register Now
                </Link>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Signinpage