import React, { useContext, useState, useRef } from "react";
import DataContext from "../../../context/DataContext";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import notifyService from "../../../api/notifySerivce";
import {
  Autocomplete,
  Button,
  FormHelperText,
  FormLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { useParams } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import DOMPurify from "dompurify";
import $ from "jquery";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import { Helmet } from "react-helmet";
const fileTypes = ["JPG", "JPEG", "PNG"];
const tomorrow = dayjs().add(0, "day");

const Requirement = ({ name, htmlContent }) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  const { id } = useParams();
  let userId = localStorage.getItem("userId");
  let requirementIds;
  if (id !== undefined) {
    requirementIds = atob(id);
  }
  const [formData, setFormData] = useState({
    requirements: [
      {
        id: 0,
        requirementId: 0,
        requirements: "",
        requirementDesc: "",
      },
    ],
  });
  const {
    navigator,
    setPageLoading,
    validatePhoneNumber,
    validateEmail,
    VisuallyHiddenInput,
    toolbarOptions,
  } = useContext(DataContext);
  const {
    reset,
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  let roleId = localStorage.getItem("roleId");
  let roleName = localStorage.getItem("role");
  let role = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  useDidMountEffect(() => {
    const userName = localStorage.getItem("userName");
    if (userName === null) {
      navigator("/sign-in");
    }
    if (requirementIds) {
      getApi();
    } else {
      setValue("id", 0);
      // dropdownValueApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let logoselecetdFile = "";
  const handleFileUpload = (
    event,
    filekey,
    fileName,
    fileURL,
    arrayName,
    index
  ) => {
    // debugger
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            img_crt_type === "jpeg" ||
            img_crt_type === "jpg" ||
            img_crt_type === "png"
          ) {
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              if (arrayName !== "") {
                setValue(`${arrayName}.${index}.${filekey}`, ImageValue);
                setValue(`${arrayName}.${index}.${fileName}`, img_name);
                setValue(`${arrayName}.${index}.${fileURL}`, logourl1);
              } else {
                setValue(filekey, ImageValue);
                setValue(fileName, img_name);
                setValue(fileURL, logourl1);
              }
              clearErrors(filekey);
              clearErrors(fileName);
              clearErrors(fileURL);
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalid",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File size exceeds 5MB",
            "Please check your file size"
          );
        }
      }
    }
  };

  // DATE
  const [date, setDate] = useState(null);

  const handleStartDateFormat = (newValue) => {
    setEndDateValue(newValue);
    setDate(newValue);
    setValue("startDate", newValue);
    clearErrors("startDate");
    setEndDate("");
    setValue("endDate", "");
  };
  const [endDate, setEndDate] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const handleEndDateFormat = (newValue) => {
    setEndDate(newValue);
    setValue("endDate", newValue);
    clearErrors("endDate");
  };

  const deletePhotos = () => {
    setValue("requirementImageName", "");
    setValue("requirementImageURL", "");
    setValue("requirementImage", "");

    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const [content, setContent] = useState("");
  const handleContentChange = (value) => {
    setContent(value);
  };

  const [problemContent, setProblemContent] = useState("");
  const handleProblemContentChange = (value) => {
    setProblemContent(value);
  };

  const deleteRequirementsPhotos = (index) => {
    setValue(`requirements.${index}.image`, "");
    setValue(`requirements.${index}.imageURL`, "");
    setValue(`requirements.${index}.imageName`, "");

    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const getApi = () => {
    setPageLoading(true);
    apiService(
      `requirement/get?id=${requirementIds}&userId=${userId}`,
      "",
      "get"
    ).then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          var response = res.data;
          response.startDate = dayjs(response.startDate, "YYYY-MM-DD");
          response.endDate = dayjs(response.endDate, "YYYY-MM-DD");
          if (response.requirementImageName) {
            response.requirementImageURL = response.requirementImageName;
          }
          if (response.requirements) {
            response.requirements.forEach((element) => {
              element.imageURL = element.imageName;
            });
          }

          setFormData(res.data);

          if (response.images) {
            response.images.forEach((element) => {
              if (element.file) {
                element.imageURL = `data:image/jpeg;base64,${element.file}`;
              }
            });
          }
        }
      }
    });
  };

  const onSubmit = async (data) => {
    setPageLoading(true);
    setLoading(true);
    var getform = data;
    getform.userId = userId;
    // getform.startDate = data.startDate.format("YYYY-MM-DD");
    // getform.endDate = data.endDate.format("YYYY-MM-DD");
    if (roleName === "Admin" || roleName === "Project Management Team") {
      getform.endDate = data.endDate.format("YYYY-MM-DD");
    }
    console.log(getform);
    debugger;
    apiService(`requirement/save`, getform, "post").then((res) => {
      setPageLoading(false);
      setLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", "Success", "Submited successfully !!");
          handleCancel();
        }
      }
    });
  };
  const onError = (errors, e) => {
    notifyService(
      "danger",
      "Form is invalid",
      "Please check the mandatory fields"
    );
  };

  function createMarkup() {
    return { __html: "First &middot; Second" };
  }

  const {
    fields: requirementFields,
    append: requirementAppend,
    remove: requirementRemove,
  } = useFieldArray({
    control,
    name: "requirements",
  });
  const addProblemDetailsBtn = () => {
    requirementAppend({
      id: 0,
      requirements: "",
      filename: "",
    });
  };
  const removeProblemDetailsBtn = (index) => {
    setPageLoading(true);
    var id = formData.requirements[index].id;
    debugger;
    if (id !== 0) {
      apiService(`requirement/requirements/remove?id=${id}`, "", "delete")
        .then((res) => {
          setPageLoading(false);
          if (res) {
            if (res.data.responseStatus === "Success") {
              requirementRemove(index);
            }
          }
        })
        .catch((err) => {});
    } else {
      requirementRemove(index);
      setPageLoading(false);
    }
  };
  const [outcomeCount, setOutcomeCount] = React.useState(0);
  let token = localStorage.getItem("token");
  const handleCancel = () => {
    if (token) {
      if (roleName === "Admin" || roleName === "Project Management Team") {
        navigator(`/admin/requirement/status/${id}`);
      } else {
        navigator("/requirement/list");
      }
    } else {
      navigator("/challenge/government");
    }
  };

  return (
    <>
      <main>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        <div className="post_challenges_title">
          <h5>POST YOUR REQUIREMENT</h5>
        </div>
        <div className="post_challenges_details">
          <div className="container">
            <div className="col-12 p-0">
              <h5 className="point_Lable">
                Requirement Details<span className="c-blue"> *</span>{" "}
              </h5>
            </div>
            <form
              className="signin-form"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group mb-3">
                    <FormLabel shrink="true" className="input_Lable">
                      Title <span className="text-danger">*</span>
                    </FormLabel>
                    <Controller
                      name="requirementTitle"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Title is required" }}
                      render={({ field }) => (
                        <TextField
                          placeholder="Enter Title Name"
                          fullWidth
                          type="text"
                          {...field}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.requirementTitle &&
                        errors.requirementTitle.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="text-center">
                    <div className="form-group m-0">
                      <FormLabel shrink="true" className="input_Lable">
                        Logo Image <span className="text-danger">*</span>
                      </FormLabel>
                    </div>
                    <div className="row">
                      <Controller
                        name="requirementImage"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Image is required" }}
                        render={({ field }) => (
                          <Button
                            fullWidth
                            className="imgupload_div"
                            component="label"
                            onChange={(e) =>
                              handleFileUpload(
                                e,
                                "requirementImage",
                                "requirementImageName",
                                "requirementImageURL",
                                "",
                                ""
                              )
                            }
                          >
                            {getValues(`requirementImageName`) ? (
                              <div className="disPhotos">
                                <img
                                  src={getValues(`requirementImageURL`)}
                                  className="logo"
                                  alt={`requirementImageName`}
                                />
                                <div className="hoverDelete">
                                  <div className="center_display">
                                    <DeleteForeverIcon
                                      onClick={() => deletePhotos()}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="camera_icon disPhotos">
                                <VisuallyHiddenInput type="file" />
                                <img
                                  src="/images/product-image.png"
                                  style={{ width: "67%" }}
                                  className="no_logo"
                                  alt={`requirementImageName`}
                                />
                                <div className="ml-4 mt-3">Upload Logo *</div>
                                <FormHelperText>
                                  File format should be in .jpg, .jpeg, .png
                                </FormHelperText>
                                <FormHelperText>
                                  Maximum file size: 5 MB
                                </FormHelperText>
                              </div>
                            )}
                          </Button>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.requirementImage &&
                          errors.requirementImage.message}
                      </FormHelperText>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-12 col-md-6 col-12">
                                    <div className="form-group mb-3">
                                        <FormLabel shrink="true" className="input_Lable">
                                            Requirement Introduction <span className="text-danger">*</span>{" "}
                                        </FormLabel>
                                        <Controller
                                            name="requirementIntroduction"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Requirement Introduction is required" }}
                                            render={({ field }) => (
                                                <ReactQuill
                                                    theme="snow"
                                                    value={content}
                                                    modules={toolbarOptions}
                                                    formats={formats}
                                                    onChange={(content, delta, source, editor) => {
                                                        handleContentChange(content);
                                                        field.onChange(content);
                                                    }}
                                                    placeholder="Enter Requirement Introduction"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    multiline
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.requirementIntroduction && errors.requirementIntroduction.message}
                                        </FormHelperText>
                                    </div>
                                </div> */}
                <div className="col-12">
                  <h5 className="point_Lable">
                    Requirements <span className="c-blue">*</span>{" "}
                  </h5>
                </div>
                {requirementFields.map((field, index, type) => (
                  <div className="col-lg-12 col-md-12 col-12 mb-3" key={index}>
                    <div className="row align-item-center">
                      <div className="col-lg-10 col-md-8 col-12">
                        <div className="form-groups">
                          <Controller
                            name={`requirements.${index}.requirementDesc`}
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Requirements is required",
                            }}
                            render={({ field }) => (
                              <ReactQuill
                                theme="snow"
                                modules={toolbarOptions}
                                formats={formats}
                                value={problemContent}
                                onChange={handleProblemContentChange}
                                placeholder="Describe your Requirement"
                                fullWidth
                                type="text"
                                {...field}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.requirements?.[index]?.requirementDesc &&
                              errors.requirements?.[index]?.requirementDesc
                                .message}
                          </FormHelperText>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-12">
                        <Button
                          className="green_btn"
                          onClick={addProblemDetailsBtn}
                          startIcon={<AddIcon />}
                        >
                          Add
                        </Button>
                        {index !== 0 && (
                          <Button
                            className="red_btn ml-3"
                            onClick={() => removeProblemDetailsBtn(index)}
                          >
                            <DeleteForeverIcon />
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="col-lg-6 col-md-4 col-12 mar-top">
                                    <div className="form-group mb-3">
                                        <FormLabel shrink="true" className="input_Lable">
                                            Start Date <span className="text-danger">*</span>{" "}
                                        </FormLabel>
                                        <Controller
                                            name="startDate"
                                            control={control}
                                            defaultValue={date}
                                            rules={{ required: "Start Date is required" }}
                                            render={({ field }) => (
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={["DatePicker"]}>
                                                        <DatePicker
                                                            format="DD/MM/YYYY"
                                                            minDate={tomorrow}
                                                            {...field}
                                                            sx={{ width: "100% " }}
                                                            inputFormat="DD/MM/YYYY"
                                                            onChange={(newValue) =>
                                                                handleStartDateFormat(newValue)
                                                            }
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.startDate && errors.startDate.message}
                                        </FormHelperText>
                                    </div>
                                </div> */}
                {roleName === "Admin" ||
                roleName === "Project Management Team" ? (
                  <div className="col-lg-6 col-md-4 col-12 mar-top">
                    <div className="form-group mb-3">
                      <FormLabel shrink="true" className="input_Lable">
                        End Date <span className="text-danger">*</span>{" "}
                      </FormLabel>
                      <Controller
                        name="endDate"
                        control={control}
                        defaultValue={endDate}
                        rules={{ required: "End Date is required" }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                format="DD/MM/YYYY"
                                minDate={tomorrow}
                                {...field}
                                sx={{ width: "100% " }}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) =>
                                  handleEndDateFormat(newValue)
                                }
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.endDate && errors.endDate.message}
                      </FormHelperText>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="col-12">
                  <h5 className="point_Lable">Point of Contact Details </h5>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Name <span className="text-danger">*</span>{" "}
                        </FormLabel>
                        <Controller
                          name="pocName"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Name is required" }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Name"
                              fullWidth
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pocName && errors.pocName.message}
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Department Name <span className="text-danger">*</span>
                        </FormLabel>
                        <Controller
                          name="departmentName"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Department Name is required" }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Department Name"
                              fullWidth
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.departmentName &&
                            errors.departmentName.message}
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Email <span className="text-danger">*</span>{" "}
                        </FormLabel>
                        <Controller
                          name="pocEmail"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Email is required",
                            validate: validateEmail,
                          }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Email"
                              fullWidth
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pocEmail && errors.pocEmail.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Designation <span className="text-danger">*</span>{" "}
                        </FormLabel>
                        <Controller
                          name="pocDesignation"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Designation is required" }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Designation"
                              fullWidth
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pocDesignation &&
                            errors.pocDesignation.message}
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Mobile Number <span className="text-danger">*</span>{" "}
                        </FormLabel>
                        <Controller
                          name="pocMobile"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Mobile Number is required",
                            validate: validatePhoneNumber,
                          }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Mobile Number"
                              fullWidth
                              type="number"
                              {...field}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 10);
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pocMobile && errors.pocMobile.message}
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Landline Number{" "}
                        </FormLabel>
                        <Controller
                          name="pocPhone"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Landline Number"
                              fullWidth
                              type="number"
                              {...field}
                              // onInput={(e) => {
                              //     e.target.value = Math.max(
                              //         0,
                              //         parseInt(e.target.value)
                              //     )
                              //         .toString()
                              //         .slice(0, 15);
                              // }}
                              inputProps={{
                                maxLength: 10,
                                minLength: 5,
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .replace(/[^0-9]/g, "")
                                  .slice(0, 10);
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pocPhone && errors.pocPhone.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <div className="text-center">
                <LoadingButton
                  loadingPosition="start"
                  type="submit"
                  loading={loading}
                  className="b-yellow-btn"
                  id="richtext"
                >
                  <span>SUBMIT</span>
                </LoadingButton>
                <Button className="blue-btn ml-3" onClick={handleCancel}>
                  BACK
                </Button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </>
  );
};

export default Requirement;
