import React, { useContext, useState } from "react";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DataContext from "../../../context/DataContext";
import Challengelist from "../live-challenges-page/challenges-page/Challengelist";

const Homepreviouslist = ({ path }) => {
  const { navigator, setPageLoading } = useContext(DataContext);
  const [challengeListValue, setChallengeListValue] = useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  useDidMountEffect(() => {
    challengeList();
  }, [page]);
  const challengeList = () => {
    setPageLoading(true);
    setChallengeListValue([]);
    let challengeCategoryVal;
    if (path === "corporate") {
      challengeCategoryVal = 0;
    } else if (path === "government") {
      challengeCategoryVal = 1;
    } else if (path === "msme") {
      challengeCategoryVal = 2;
    } else if (path === "enablers") {
      challengeCategoryVal = 3;
    }
    var req = {
      listSize: 3,
      pageNumber: page,
      live: 2,
      challengeType: 0,
      challengeCategory: challengeCategoryVal,
    };
    apiService("challenge/home/list", req, "unauthpost").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.responseModelList) {
            setChallengeListValue(res.data.responseModelList);
            setTotalPage(res.data.totalPages);
          }
        }
      }
    });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  let roleId = localStorage.getItem("roleId");
  const handleViewDetails = (id, userid) => {
    // if (roleId === null) {
    //   navigator("/sign-in");
    //   localStorage.setItem("click", "live");
    // } else {
    //   navigator(`${path}/challenges/details/${btoa(id)}`);
    // }
    debugger;
    navigator(`${path}/challenges/details/${btoa(id)}/${btoa(userid)}`);
  };
  return (
    <div>
      {roleId === null ? (
        <></>
      ) : (
        <h5>
          <span className="text-uppercase"></span> PAST CHALLENGES
        </h5>
      )}

      <div className="title_filter_dev"></div>
      {challengeListValue.length > 0 ? (
        <>
          <Challengelist
            challengeListValue={challengeListValue}
            page={page}
            totalPage={totalPage}
            handleViewDetails={handleViewDetails}
            handleChange={handleChange}
            viewShow={false}
          />
        </>
      ) : (
        <div className="no_data_div">
          <img src="/images/blue-logo.png" alt="" className="img-fluid mb-4" />
          <h5 className="no_data_h5">Challenges Not Available</h5>
        </div>
      )}
    </div>
  );
};

export default Homepreviouslist;
