
import React from 'react'
import Faqaccordion from './Faqaccordion';

const Faq = () => {
    const faqData = [
        {
            question: "What is the Open Innovation Platform about?",
            answer: "Open Innovation platform is a virtual platform that connects and matches corporates and/or government agencies with business/social challenges (i.e., Challenge Providers) to Innovators or startups (i.e., Challenge solvers)."
        },
        {
            question: "How can I apply for the challenge?",
            answer: "To apply, navigate to the 'Apply Now' section on the portal, fill in the required information, and submit your innovative solution."
        },
        {
            question: "Can I edit my application after submission?",
            answer: "Once an application is submitted, editing may be restricted. Ensure all information is accurate before finalizing your submission."
        },
        {
            question: "Is it allowed for innovators/startups to submit multiple applications?",
            answer: "No, duplicate submissions will be removed. If addressed for multiple problem statements, it will be accepted."
        },
        {
            question: "What criteria are considered during the initial screening?",
            answer: "Initial screening criteria include factors such as relevance to the challenge, feasibility, and potential impact."
        },
        {
            question: "How will I be notified if selected for the next round?",
            answer: "If advanced to the next round, you will receive a notification via email with further instructions for the subsequent screening process."
        },
        {
            question: "What happens if selected as a winner?",
            answer: "The winning team may receive support, funding, or collaboration opportunities with the Challenge provider. Details of the rewards will be communicated upon the announcement of winners."
        },
    ];
    return (
        <>
            <div className="whole_page  pb-5  ">
                <div className="content ">
                    <center>
                        <h1 className=" pt-5 pb-5  ">Frequently Asked Questions</h1>
                    </center>
                    <div className="Accordion-content container   ">
                        {faqData.map((faq, index) => (
                            <div className=" mt-3 accordion">
                                <Faqaccordion
                                    key={index}
                                    question={faq.question}
                                    answer={faq.answer}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq
