import React, { createContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import $ from 'jquery';
const DataContext = createContext({})
export const DataProvider = ({ children }) => {
    const navigator = useNavigate()
    const validateEmail = (value) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValid = emailRegex.test(value);
        return isValid || 'Invalid email address';
    };
    const validatePhoneNumber = (value) => {
        if (value.length) {
            if (value.length !== 10) {
                return 'Phone number must be 10 digits';
            }
            const matches = value.match(
                /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
            );
            if (matches === null) {
                return "Only numbers allowed";
            }
        }
    };
    const validateAadharNumber = (value) => {
        if (value.length) {
            if (value.length !== 12) {
                return 'Aadhar number must be 12 digits';
            }
            // const matches = value.match(
            //     /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/
            // );
            // if (matches === null) {
            //     return "Please enter a valid Aadhar number";
            // }
        }
        // const aadharRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
        // const isValid = aadharRegex.test(value);
        // return isValid || 'Please enter a valid Aadhar number';
    }
    const validateLicenceNumber = (value) => {
        if (value.length) {
            if (value.length !== 15) {
                return 'Licence number must be 15 digits';
            }
            // const matches = value.match(
            //     /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/
            // );
            // if (matches === null) {
            //     return "Please enter a valid Aadhar number";
            // }
        }
        // const aadharRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
        // const isValid = aadharRegex.test(value);
        // return isValid || 'Please enter a valid Aadhar number';
    }
    const validatePincodeNumber = (value) => {
        if (value.length) {
            if (value.length !== 6) {
                return 'Pincode number must be 6 digits';
            }
            // const matches = value.match(
            //     /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/
            // );
            // if (matches === null) {
            //     return "Please enter a valid Aadhar number";
            // }
        }
        // const aadharRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
        // const isValid = aadharRegex.test(value);
        // return isValid || 'Please enter a valid Aadhar number';
    }
    const validateIfscNumber = (value) => {
        const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        const isValid = ifscRegex.test(value);
        return isValid || 'Please enter a valid IFSC number';
    }
    const validateNumberonly = (e) => {
        // if (value > 0) {
        //     var data = value.toString()
        //     const matches = data.match(
        //         /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
        //     );
        //     if (matches === null) {
        //         return "Only numbers allowed";
        //     }
        // } else {
        //     return "Min Value 1 is required";
        // }
        const validKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
        if (!/[0-9]/.test(e.key) && !validKeys.includes(e.key)) {
            e.preventDefault();
        }
    };
    // const validateNumberOnly = (value) => {
    //     if (isNaN(value)) {
    //         return "Only numbers allowed";
    //     } else if (value < 1) {
    //         return "Min Value 1 is required";
    //     }

    //     return "Valid input";
    // };
    // language Change
    const { t, i18n } = useTranslation();
    let uselang = localStorage.getItem('lang')
    function changeLanguage(e) {
        i18n.changeLanguage(e.target.value);
        if (uselang == null) {
            $("html").attr("lang", 'en');
            localStorage.setItem('lang', 'en')
        } else {
            $("html").attr("lang", e.target.value);
            localStorage.setItem('lang', e.target.value)
        }
    }
    const checkLang = () => {
        i18n.changeLanguage(uselang);
        $("html").attr("lang", uselang);
    }
    // layout code 
    const drawerWidth = 240;
    const [open, setOpen] = useState(true);
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        color: 'black',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
        background: '#3F6FDB',
        borderRadius: '0px 25px 25px 0px;'
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
        background: '#3F6FDB',
        borderRadius: '0px 25px 25px 0px;'
    });

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
            background: 'none',
        }),
    );
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const screenSize = () => {
        if (window.innerWidth > 768) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }
    const [yearListValue, setYearListValue] = useState([])

    let yearList = []
    const yearListApi = () => {
        setYearListValue([])
        yearList = []
        let year = new Date().getFullYear()
        for (let i = year; i >= 2020; i--) {
            var val = i.toString()
            yearList.push(val)
        }
        setYearListValue(yearList)
    }
    const VisuallyHiddenInput = styled('input')`
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        white-space: nowrap;
        width: 1px;
    `;
    const [pageLoading, setPageLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    const toolbarOptions = {
        toolbar: {
            container: [
                // [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                // ['blockquote', 'code-block'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                // [{ 'indent': '-1'}, { 'indent': '+1' }],          
                // [{ 'direction': 'rtl' }],        
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'size': ['small', false, 'large'] }],
                // [{ 'color': [] }, { 'background': [] }],          
                [{ 'align': [] }],
                // ['link', 'image', 'video'],
                // ['clean']                                         
            ],
        },
    };

    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const modalstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };
    return (
        <>
            <DataContext.Provider value={{
                pageLoading, setPageLoading, btnLoading, setBtnLoading, readyOnlyValue, setReadyOnlyValue,
                navigator, validateEmail, validatePhoneNumber, validateAadharNumber, validateIfscNumber, validateNumberonly, validateLicenceNumber, validatePincodeNumber,
                changeLanguage, t, i18n, checkLang, yearListValue, yearListApi,
                AppBar, openedMixin, closedMixin, DrawerHeader, Drawer, handleDrawerOpen, handleDrawerClose, open, screenSize,
                VisuallyHiddenInput, toolbarOptions, modalstyle
            }}>
                {children}
            </DataContext.Provider>

        </>
    )
}
export default DataContext;