import React, { useState } from 'react'
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete, Button, FormControl, FormHelperText, InputLabel, MenuItem, Pagination, Select, Stack, TextField } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { LoadingButton } from '@mui/lab';
import downloadExcel from '../../../hooks/downloadExcel';
const InterestedCanditatesList = () => {
    const { control, setValue, formState: { errors } } = useForm({});
    const [techTitleDataList, setTechTitleDataList] = useState([])
    useDidMountEffect(() => {
        inventorList()
    }, [])
    let userId = localStorage.getItem("userId");
    const inventorList = () => {
        setTechTitleDataList([]);
        var req = {
            approvalStatus: 1,
            userId:userId
        };
        apiService("techmarket/list", req, "post").then((res) => {
            if (res) {
                if (res.data) {
                    if (res.data.responseModelList) {
                        setTechTitleDataList(res.data.responseModelList)
                        debugger
                    }
                }
            }
        });
    };
    const [selectSector, setselectSector] = useState("");
    const [technologyTitleValue, setTechnologyTitleValue] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    useDidMountEffect(() => {
        adminList()
    }, [selectSector, technologyTitleValue]);
    const [adminListValue, setAdminListValue] = useState([]);
    let role = localStorage.getItem('role')
    const adminList = () => {
        setAdminListValue([]);
        let url
        let req
        if (role === 'Inventor') {
            url = 'techmarket/applyform/list'
            req = {
                techTitle: technologyTitleValue,
                listSize: 12,
                pageNumber: page,
            };
        } else {
            url = 'techmarket/applyform/admin/list'
            req = {
                roleId: selectSector,
                techTitle: technologyTitleValue,
                listSize: 12,
                pageNumber: page,
            };
        }
        apiService(url, req, "post").then((res) => {
            if (res) {
                if (res.data) {
                    if (res.data.responseModelList) {
                        setAdminListValue(res.data.responseModelList);
                        setTotalPage(res.data.totalPages);
                    }
                }
            }
        });
    };
    const handleSectorsSelect = (newStatus) => {
        setselectSector(newStatus);
        setShowRest(true)
    };
    const [showReset, setShowRest] = useState(false)
    const handleSelecttechArea = (value) => {
        setTechnologyTitleValue(value);
        setShowRest(true)
    };
    const resetBtn = () => {
        setselectSector('');
        setTechnologyTitleValue([]);
        setShowRest(false)
    }
    const handleChange = (event, value) => {
        setPage(value);
    };
    const [loading, setLoading] = useState(false)
    const exportExcel = () => {
        // setDownloadValue(download)
        setLoading(true);
        // setPageLoading(true);
        var req = {
            "download": 'yes',
            roleId: selectSector,
            techTitle: technologyTitleValue,
            listSize: 12,
            pageNumber: page,
        }
        apiService('techmarket/applyform/admin/list', req, 'downloadpost').then((res) => {
            setLoading(false);
            // setPageLoading(false);
            if (res) {
                if (res.data) {
                    downloadExcel(res.data, 'Interested_Canditates_')
                    // setDownloadValue('')
                }
            }
        })
    }
    return (
        <section className="container ">
            <div>
                <div className="d-flex align-items-center">
                    <div className="mr-3 ">
                        <Controller
                            name="techTitle"
                            control={control}
                            defaultValue=""
                            rules={{ required: false }}
                            render={({ field }) => (
                                <Autocomplete
                                    disableClearable
                                    disablePortal
                                    multiple
                                    {...field}
                                    limitTags={2}
                                    sx={{ background: "white", width: '300px' }}
                                    ListboxProps={{
                                        style: { textAlign: 'left' }
                                      }}
                                    value={technologyTitleValue}
                                    options={techTitleDataList.map((option) => option.title)}
                                    onChange={(e, selectedOptions) =>
                                        handleSelecttechArea(selectedOptions)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Filter by Technology Title"
                                            label="Technology Title" />
                                    )}
                                />

                            )}
                        />
                        {/* <FormHelperText className="fnt-sm c-blue">
                            Select one or more
                        </FormHelperText> */}
                    </div>
                    {
                        role !== 'Inventor' && (
                            <div>
                                <FormControl sx={{ width: "300px" }}>
                                    <InputLabel id="demo-simple-select-label">
                                        Sector
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectSector}
                                        label="Sector"
                                        onChange={(event) =>
                                            handleSectorsSelect(event.target.value)
                                        }
                                        sx={{ textAlign: "left" }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    textAlign: "left",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={3}>Corporate</MenuItem>
                                        <MenuItem value={5}>Government</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        )
                    }

                    {
                        showReset === true && (
                            <div>
                                <Button onClick={() => resetBtn()} variant="outlined" color="error" className='ml-3'>Clear</Button>
                            </div>
                        )
                    }
                    {
                        (adminListValue.length > 0) && (
                            <div className='ml-auto'>
                                <LoadingButton className='export-btn'
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<CloudDownloadIcon />}
                                    variant="outlined"
                                    onClick={exportExcel}
                                >
                                    <span>EXPORT EXCEL</span>
                                </LoadingButton>
                            </div>
                        )
                    }
                </div>

                {adminListValue.length > 0 ? (
                    <div className="row mt-3">
                        {adminListValue &&
                            adminListValue.map((item, index) => (
                                <div className="col-lg-4 col-12">
                                    <div className="portfolio-card">
                                        <div className="whitebx p-3" key={index}>
                                            <h5 className="text-center">{item.title}</h5>
                                        </div>
                                        <p className="pt-4">
                                            Inventor Name: <b>{item.inventorName}</b>
                                        </p>
                                        <p>
                                            POC Name : <b>{item.pocName}</b>
                                        </p>
                                        <p>
                                            Name : <b>{item.name}</b>
                                        </p>
                                        <p>
                                            Reference No : <b>{item.referenceNo}</b>
                                        </p>
                                    </div>
                                </div>
                            ))}
                        {totalPage > 1 && (
                            <div className="col-12">
                                <Stack spacing={2}>
                                    <Pagination
                                        count={totalPage}
                                        page={page}
                                        onChange={handleChange}
                                    />
                                </Stack>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="no_data_div">
                        <img
                            src="/images/blue-logo.png"
                            alt=""
                            className="img-fluid mb-4"
                        />
                        <h5 className="no_data_h5">Interested Canditates List Not Available</h5>
                    </div>
                )}
            </div>
        </section>
    )
}

export default InterestedCanditatesList