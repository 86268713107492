import { Box, Button, Dialog, Drawer } from '@mui/material'
import React from 'react'

const Viewremarks = ({ remarks ,name}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };
    const DrawerList = (
        <Box className="p-4">
            <p>{remarks}</p>
        </Box>
    )
    return (
        <>
            <div className="tab_side">
                <Button onClick={() => handleOpen()} className="ani_btn">
                    {name}
                </Button>
            </div>
            <Dialog
                maxWidth={`md`}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {DrawerList}
            </Dialog>
        </>
    )
}

export default Viewremarks