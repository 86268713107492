import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Button, Dialog } from "@mui/material";
import Smarttext from "../../../common-pages/Smarttext";
import Viewmarks from "./Viewmarks";
const Challengelist = ({
  challengeListValue,
  page,
  totalPage,
  handleViewDetails,
  handleChange,
  viewShow,
}) => {
  let roleName = localStorage.getItem("role");
  const [solutionId, setSolutionId] = useState(0);
  const [challengeId, setChallengeId] = useState(0);
  const [challengeStatus, setChallengeStatus] = useState(0);
  const [open, setOpen] = useState(false);
  const handleViewMark = (challengeid) => {
    setOpen(true);
    // setSolutionId(solutionid)
    setChallengeId(challengeid);
    // setChallengeStatus(status)
  };
  const handleClose = () => {
    setOpen(false);
    // userListItem()
  };
  return (
    <>
      {challengeListValue.length > 0 ? (
        <div className="row mt-3 ml-2">
          <div className="col-12">
            <p className="showPage">
              Showing Page <span>{page}</span> of <span>{totalPage}</span>
            </p>
          </div>
          {challengeListValue.map((list, index) => (
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="live_challenge_list">
                {/* <div className='live_challenge_img'>
                              <img src='/images/challenges_1.png' alt='' className='img_challenge' />
                            </div> */}
                <div>
                  <Button
                    className="challenges_pro_btn"
                    onClick={() => {
                      handleViewDetails(list.id, list.userId);
                    }}
                  >
                    {list.challengeTitle}
                  </Button>
                </div>
                <div className="challenges_details">
                  {/* <h6>Expecting Solution</h6> */}
                  <Smarttext text={list.challengeIntroduction} length={100} />
                  {/* <div dangerouslySetInnerHTML={{ __html: list.challengeIntroduction }}></div> */}
                  {/* <p >
                                                <ReactReadMoreReadLess
                                                    charLimit={100}
                                                    readMoreText={"Read more"}
                                                    readLessText={"Read less"}
                                                >
                                                    {list.challengeIntroduction}
                                                    
                                                </ReactReadMoreReadLess>
                                            </p> */}
                </div>
                <div className="benefits_div">
                  <h6 className="c-blue f-weight-700">Benefits</h6>
                  <ul className="m-0 p-0">
                    {list.benefits &&
                      list.benefits.map((res) => (
                        // <li className='c-blue'>
                        <Smarttext text={res.benefits} length={100} />
                        // </li>
                      ))}
                  </ul>
                </div>
                <div className="d-flex">
                  <div>
                    <p className="c-blue f-weight-700 mb-1">Start Date</p>
                    <p className="font-md c-origin f-weight-700 mr-3">
                      {list.startDate2}
                    </p>
                  </div>
                  <div className="ml-auto">
                    <p className="c-blue f-weight-700 mb-1">End Date</p>
                    <p className="font-md c-origin f-weight-700">
                      {list.endDate2}
                    </p>
                  </div>
                </div>
                {(roleName === "Challenge Provider" ||
                  roleName === "GOVT" ||
                  roleName === "MSME's" ||
                  roleName === "Other Enablers") && (
                  <p>
                    <span className="c-gray">PMT Approval :</span>{" "}
                    <span className="c-blue font-lg f-weight-600">
                      {list.pmtApproval === 0 && <>Pending for Approval</>}
                    </span>
                    <span className="c-origin font-lg f-weight-600">
                      {list.pmtApproval === 1 && <>On Hold</>}
                    </span>
                    <span className="c-green font-lg f-weight-600">
                      {list.pmtApproval === 2 && <>Approved</>}
                    </span>
                    <span className="c-red font-lg f-weight-600">
                      {list.pmtApproval === 3 && <>Rejected</>}
                    </span>
                  </p>
                )}
                <div className="d-flex">
                  <div className="view_div">
                    <Button
                      className="view_live_btn"
                      onClick={() => {
                        handleViewDetails(list.id, list.userId);
                      }}
                    >
                      View Details
                    </Button>
                    {/* {
                                                    (roleName === 'Challenge Provider' || roleName === "MSME's" || roleName === "Other Enablers") && viewShow===true && (
                                                        <Button className='view_download_btn ml-0 mt-2' onClick={() => handleViewMark(list.id)}>View Marks</Button>
                                                    )
                                                } */}
                  </div>
                  <div className="ml-auto">
                    <p className="c-gray mb-0 font-sm">Last updated</p>
                    <p className="c-blue font-sm">{list.lastUpdated}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {totalPage > 1 && (
            <div className="col-12">
              <Stack spacing={2}>
                <Pagination
                  count={totalPage}
                  page={page}
                  onChange={handleChange}
                />
              </Stack>
            </div>
          )}
        </div>
      ) : (
        <div className="no_data_div">
          <img src="/images/blue-logo.png" alt="" className="img-fluid mb-4" />
          <h5 className="no_data_h5">Challenges Not Available</h5>
        </div>
      )}
      <Dialog
        maxWidth={`lg`}
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Viewmarks onClose={handleClose} id={challengeId} />
      </Dialog>
    </>
  );
};

export default Challengelist;
