import React, { useContext, useState } from 'react';
import { Autocomplete, Checkbox, Container, FormControl, FormHelperText } from '@mui/material'
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import DataContext from '../../../../context/DataContext';
import { Controller, useForm } from 'react-hook-form';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import cryptoJS from '../../../../hooks/cryptoJS';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Associatejurysave = ({ handleClose, selectedClaimIds }) => {
    const { readyOnlyEmail, validatePhoneNumber, validateEmail, btnLoading, setBtnLoading, setPageLoading } = useContext(DataContext)
    // const [lableName, setLableName] = useState('Add')
    const [registerData, setRegisterData] = useState({});
    const { handleSubmit, control, getValues, setValue, setError, clearErrors, formState: { errors } } = useForm(
        { values: registerData }
    );
    const [partnerListData, setPartnerListData] = useState([]);
    const [searchInputValue, setSearchInputValue] = useState('');
    useDidMountEffect(() => {
        userListItem()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // get user api   
    const userListItem = () => {
        setPageLoading(true)
        setPartnerListData([]);
        var req = {
            "roleId": 6,
            "active": 1
        };
        apiService("scoring/jury/list", req, "post").then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data.responseModelList) {
                    setPartnerListData(res.data.responseModelList);
                } else {
                    setPartnerListData([]);
                }
            }
        });
    };
    const [juryIds, setJuryIds] = useState([])
    const handleSelectJury = (value) => {
        const ids = value.map(item => item.id);
        console.log(ids, "ids");
        setJuryIds(ids)
        setValue('juryid', value)
        clearErrors('juryid')

    }
    const onSubmit = (data) => {
        // console.log(data);

        setPageLoading(true)
        // var getform = data;
        // let jury = []
        // partnerListData.forEach(element => {
        //     data.juryid.forEach(value => {
        //         if (element.name === value) {
        //             jury.push(element.id)
        //         }
        //     })
        // });
        // getform.juryid=jury
        // getform.challengeId = challengeId
        // getform.juryid = selectedClaimIds
        // debugger
        var req = {
            "juryid": juryIds,
            "challengeJuryList": selectedClaimIds
        }
        // console.log(req);

        apiService('scoring/jury/assign', req, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                setBtnLoading(false);
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', res.data.responseMessage);
                    handleClose();
                    // setSelectedSolutionsIds('')
                    // setSelectAll(false)
                } else {
                    notifyService('danger', 'danger', res.data.responseMessage);
                }
            }

        });
    };
    return (
        <section className=''>
            <Container>
                <div className='header_tag'>
                    <h5 className='c-black'>Associate Jury</h5>
                </div>
                <div className=''>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            {/* <div className='d-none'>
                                <div className="form-group mb-3">
                                    <Controller name="id" control={control} defaultValue="0" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div> */}

                            <div className='col-lg-12 col-12 mb-3'>
                                <div className="form-group mt-2">
                                    <FormControl sx={{ width: "100%" }}>
                                        <Controller
                                            name="juryid"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Jury is required" }}
                                            render={({ field }) => (
                                                // <Autocomplete
                                                //     disableClearable
                                                //     disablePortal
                                                //     multiple
                                                //     {...field}
                                                //     limitTags={2}
                                                //     value={juryIds}
                                                //     options={partnerListData.map((option) => option.name)}
                                                //     onChange={(e, selectedOptions) => handleSelectJury(selectedOptions)}
                                                //     renderInput={(params) => (
                                                //         <TextField {...params} label="Jury Name *" />
                                                //     )}
                                                // />
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable
                                                    disablePortal
                                                    multiple
                                                    {...field}
                                                    limitTags={2}
                                                    ListboxProps={{
                                                        style: { textAlign: 'left' }
                                                      }}
                                                    value={partnerListData.find(option => option.id)} // Set the value based on id
                                                    options={partnerListData}
                                                    getOptionLabel={(option) => option.name} // Display the status in the dropdown
                                                    onChange={(e, selectedOption) => {
                                                        handleSelectJury(selectedOption); // Pass the id or null
                                                    }}
                                                    className="meetingListDropdown"
                                                    renderInput={(params) => <TextField {...params} label="Jury Name" />}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="fnt-sm c-blue">
                                            Select one or more
                                        </FormHelperText>
                                        <FormHelperText className="text-danger">
                                            {errors.juryid &&
                                                errors.juryid.message}
                                        </FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <LoadingButton
                                type="submit"
                                loading={btnLoading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                className="btn btn-primary submit px-3"
                            >
                                Associate Jury
                            </LoadingButton>
                            <Button variant="contained" onClick={handleClose} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>
                        </div>
                    </form>
                </div>
            </Container>
        </section>
    )
}


export default Associatejurysave