import React, { useContext, useState } from 'react'
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import { Button, Container, Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Viewremarks from '../../../jury-module/Viewremarks';
import DataContext from '../../../../context/DataContext';
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const Viewjuryscore = ({ handleClose, solutionId, challengeId }) => {
    const { setPageLoading } = useContext(DataContext);
    useDidMountEffect(() => {
        getApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [getData, setGetData] = useState([])
    const getApi = () => {
        setPageLoading(true)
        apiService(`scoring/questions/get?solutionid=${solutionId}&challengeid=${challengeId}`, '', 'get').then((result) => {
            setPageLoading(false)
            if (result && result.data.jury) {
                setGetData(result.data.jury)
            }
        }).catch((err) => {
            setPageLoading(false)
        });
    }
    const [expanded, setExpanded] = useState('panel0');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div>
            <h6 className="mb-4">
                <b>View Jury Score</b>
            </h6>
            {getData.length ?(<>
                {getData.map((list, index) => (
                <>
                    {
                        list.questions ? (
                            <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                                <AccordionSummary aria-controls="panel1d-content" id={`panel${index}d-header`}>
                                    <div className='d-flex w-100'>
                                        <div><b>{list.juryName}</b></div>
                                        <div className='ml-auto'><Viewremarks remarks={list.overallRemarks} name={'Comments'} />
                                        </div>
                                        <div className='ml-auto'>Average Score : {list.avgScore}
                                        </div>
                                        <div className='ml-auto'>
                                            Status :
                                            <b>
                                                {list.status === 0 && <span> Inprogress</span>}
                                                {list.status === 1 && <span className='c-green'> Submitted</span>}
                                            </b>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* <div className='d-flex justify-content-between my-3'>
                            <h6>Recommended this Startup: <b>{list.recommended === 1 ? ('Yes') : ('No')}</b></h6>
                            <h6>Interested in Mentoring the Startup: <b>{list.mentorTheStartup === 1 ? ('Yes') : ('No')}</b></h6>
                        </div>   
                                              */}
                                    {
                                        list.questions && list.questions.map((res, index) => (
                                            <div className='row'>
                                                <div className='col-lg-8 col-md-12 col-12'>
                                                    <p>{res.question}</p>
                                                </div>
                                                <div className='col-lg-2 col-md-12 col-12'>
                                                    <p>Score : <b>{res.score}</b></p>
                                                </div>
                                                <div className='col-lg-2 col-md-12 col-12'>
                                                    <Viewremarks remarks={res.remarks} name={'Remarks'} />
                                                </div>
                                            </div>
                                        ))
                                    }

                                </AccordionDetails>
                            </Accordion>
                        ) :
                        (
                            <>
                              {/* <div className='text-center'>
                 <h4 className='no-data-Jury'>No Data Found</h4>
                            </div> */}
                            </>
                        )
                    }
                </>

            ))
            
            }
            </>):(<>
                <div className='text-center'>
                 <h4 className='no-data-Jury'>No Data Found</h4>
                            </div>
            </>)}
           
          
            <div className="text-center mt-3">
                <Button
                    variant="contained"
                    onClick={handleClose}
                    sx={{
                        padding: "10px 23px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                            backgroundColor: "#253b80 ",
                        }
                    }}
                >
                    OK
                </Button>
            </div>
        </div>
    )
}

export default Viewjuryscore