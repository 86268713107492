import React, { useContext, useState } from "react";
import DataContext from "../../../../context/DataContext";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import notifyService from "../../../../api/notifySerivce";
import {
  Autocomplete,
  Button,
  FormHelperText,
  FormLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";
import { Helmet } from "react-helmet";
const fileTypes = ["PDF"];
const Applyform = ({ url }) => {
  const { name, id, userid } = useParams();
  let titleName;
  if (name !== undefined) {
    titleName = name;
  }
  const { navigator, setPageLoading, validatePhoneNumber, validateEmail } =
    useContext(DataContext);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    values: {
      participant: [
        {
          participantName: localStorage.getItem("name"),
        },
      ],
    },
  });
  const [loading, setLoading] = useState(false);
  const {
    fields: participantFields,
    append: participantAppend,
    remove: participantRemove,
  } = useFieldArray({
    control,
    name: "participant",
  });
  // localStorage.setItem('email', res.data.email)
  // localStorage.setItem('phone', res.data.phone)
  // localStorage.setItem('userName', res.data.userName)
  useDidMountEffect(() => {
    dropdownValueApi();
  }, []);
  useDidMountEffect(() => {
    let email = cryptodecryptJS(localStorage.getItem("email"));
    setValue("email", email);
    setValue("challengeId", atob(id));
    let phone = cryptodecryptJS(localStorage.getItem("phone"));
    //
    setValue("phone", phone);
    getProblemStatementListApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [problemStatementList, setProblemStatementList] = useState([]);
  const getProblemStatementListApi = () => {
    setPageLoading(true);
    apiService(`challenge/get?id=${atob(id)}`, "", "get").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          setProblemStatementList(res.data.problemStatement);
        }
      }
    });
  };

  const addParticipantDetailsBtn = () => {
    participantAppend({
      participantName: "",
    });
  };
  const removeParticipantDetailsBtn = (index) => {
    participantRemove(index);
  };
  const [problemStatementValue, setProblemStatementValue] = useState([]);
  const handleSelectProblemStatement = (value) => {
    setProblemStatementValue(value);
    setValue("problemStatementId", value);
    clearErrors("problemStatementId");
  };
  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName, fileURL) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (img_crt_type === "pdf") {
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileURL, logourl1);
              clearErrors(filekey);
              clearErrors(fileName);
              clearErrors(fileURL);
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File size exceeds 5MB",
            "Please check your file size"
          );
        }
      }
    }
  };
  const onSubmit = async (data) => {
    setPageLoading(true);
    setLoading(true);
    var getform = data;
    let ids = [];
    getform.problemStatementId.forEach((element) => {
      const name = problemStatementList.filter(
        (item) =>
          item.problemStatement.includes(element) ||
          item.problemStatement.includes(element)
      );
      ids.push(name[0].id);
    });
    getform.problemStatementId = ids;
    console.log(getform);
    //
    // navigator('/challenges')
    // setPageLoading(false)
    // setLoading(false)
    // var req = {
    //   fundConfigJSONB: data
    // }
    apiService(`solution/save`, getform, "post").then((res) => {
      setPageLoading(false);
      setLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", res.data.responseMessage);
          handleCancel();
        }
      }
    });
  };
  const onError = (errors, e) => {
    notifyService(
      "danger",
      "Form is invalid",
      "Please check the mandatory fields"
    );
  };
  const handleCancel = () => {
    navigator(`${url}/challenges/details/${id}/${userid}`);
  };
  const [count, setCount] = React.useState(0);

  const [typeDataList, setTypeDataList] = useState([]);

  const handleSelectType = (value) => {
    setValue("type", value);
    clearErrors("type");
    setValue("typeOther", "");
    // if (value !== "Others") {
    //     const name = typeDataList.filter(
    //         (item) => item.type.includes(value) || item.type.includes(value)
    //     );
    // }
  };

  const dropdownValueApi = () => {
    apiService(`admin/dropdownvalues/get?key=innovator`, "", "get").then(
      (res) => {
        var val = res.data.dropDownValues;
        setTypeDataList(val.innovator);
      }
    );
  };

  return (
    <main>
      <Helmet>
        <title>
          Open Innovation Portal | Challenges | Live-challenges | Apply
        </title>
      </Helmet>
      <div className="post_challenges_title">
        <h5>{titleName}</h5>
      </div>
      <div className="post_challenges_details">
        <div className="container">
          <form
            className="signin-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="row">
              <div className="col-12">
                <FormLabel shrink="true" className="input_Lable">
                  Name of Participant <span className="text-danger">*</span>{" "}
                </FormLabel>
              </div>
              {participantFields.map((field, index, type) => (
                <div className="col-lg-12 col-md-12 col-12" key={index}>
                  <div className="row align-item-center">
                    <div className="col-lg-8 col-md-8 col-12">
                      <div className="form-group mb-3">
                        <Controller
                          name={`participant.${index}.participantName`}
                          control={control}
                          defaultValue=""
                          rules={{ required: "Participant Name is required" }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Participant Name"
                              fullWidth
                              type="text"
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.participant?.[index]?.participantName &&
                            errors.participant?.[index]?.participantName
                              .message}
                        </FormHelperText>
                      </div>
                    </div>
                    {/* <div className='col-lg-4 col-md-4 col-12'>
                                                <Button className='b-yellow-btn' onClick={addParticipantDetailsBtn} startIcon={<AddIcon />}>Add</Button>
                                                {
                                                    index !== 0 && (
                                                        <Button className='blue-btn ml-3' onClick={() => removeParticipantDetailsBtn(index)}><DeleteForeverIcon /></Button>
                                                    )
                                                }
                                            </div> */}
                  </div>
                </div>
              ))}
              <div className="col-lg-6 col-md-6 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    Email <span className="text-danger">*</span>{" "}
                  </FormLabel>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Email is required",
                      validate: validateEmail,
                    }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Enter Email"
                        fullWidth
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.email && errors.email.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    Mobile Number <span className="text-danger">*</span>{" "}
                  </FormLabel>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Mobile Number is required",
                      validate: validatePhoneNumber,
                    }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Enter Mobile Number"
                        fullWidth
                        type="number"
                        {...field}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.phone && errors.phone.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    Place of residence (Village/Town/City){" "}
                    <span className="text-danger">*</span>{" "}
                  </FormLabel>
                  <Controller
                    name="address"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Place of residence is required" }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Enter Place of residence"
                        fullWidth
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.address && errors.address.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    District <span className="text-danger">*</span>{" "}
                  </FormLabel>
                  <Controller
                    name="district"
                    control={control}
                    defaultValue=""
                    rules={{ required: "District is required" }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Enter District"
                        fullWidth
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.district && errors.district.message}
                  </FormHelperText>
                </div>
              </div>
              {/* <div className='col-lg-12 col-md-12 col-12'>
                                <div className='form-group mb-3'>
                                    <FormLabel shrink="true" className='input_Lable'>Individual innovator / MSMEs / Startups / Academic Institution / Others<span className='text-danger'>*</span> </FormLabel>
                                    <Controller name="type" control={control} defaultValue="" rules={{ required: 'Innovator Type is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                placeholder="Enter Innovator Type"
                                                fullWidth
                                                type='text'
                                                {...field}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.type && errors.type.message}</FormHelperText>
                                </div>
                            </div> */}
              <div className="col-lg-6 col-md-6 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    Choose the Problem statement{" "}
                    <span className="text-danger">*</span>{" "}
                  </FormLabel>
                  <Controller
                    name="problemStatementId"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Problem statement is required" }}
                    render={({ field }) => (
                      // <Autocomplete
                      //     disableClearable
                      //     disablePortal
                      //     {...field}

                      //     options={problemStatementList}
                      //     onChange={(e, selectedOptions) => handleSelectProblemStatement(selectedOptions)}
                      //     renderInput={(params) => <TextField {...params} placeholder="Select Problem statement" />}
                      // />
                      <Autocomplete
                        disableClearable
                        disablePortal
                        {...field}
                        ListboxProps={{
                          style: { textAlign: "left" },
                        }}
                        multiple={true}
                        value={problemStatementValue}
                        limitTags={2}
                        options={problemStatementList.map(
                          (option) => option.problemStatement
                        )}
                        onChange={(e, selectedOptions) =>
                          handleSelectProblemStatement(selectedOptions)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Problem statement"
                          />
                        )}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.problemStatementId &&
                      errors.problemStatementId.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    Pitch Deck <span className="text-danger">*</span>{" "}
                  </FormLabel>
                  <Controller
                    name="pitchDeckFile"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Pitch Deck is required" }}
                    render={({ field }) => (
                      <FileUploader
                        {...field}
                        handleChange={(e) =>
                          handleFileUpload(
                            e,
                            "pitchDeckFile",
                            "pitchDeckFileName",
                            "pitchDeckURL"
                          )
                        }
                        name="file"
                        types={fileTypes}
                      />
                    )}
                  />
                  <FormHelperText>File format should be in .pdf</FormHelperText>
                  <FormHelperText>Maximum file size: 5 MB</FormHelperText>
                  <FormHelperText className="text-danger">
                    {errors.pitchDeckFile && errors.pitchDeckFile.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    Solution Provider Type{" "}
                    <span className="text-danger">*</span>{" "}
                  </FormLabel>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Type Name is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        {...field}
                        ListboxProps={{
                          style: { textAlign: "left" },
                        }}
                        // options={typeDataList.map(
                        //     (option) => option.type
                        // )}
                        options={typeDataList}
                        onChange={(e, selectedOptions) =>
                          handleSelectType(selectedOptions)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select the Type"
                          />
                        )}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.type && errors.type.message}
                  </FormHelperText>
                </div>
              </div>
              {getValues("type") === "Others" && (
                <>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-group mb-3">
                      <FormLabel shrink="true" className="input_Lable">
                        Other Solution Provider Type{" "}
                        <span className="text-danger">*</span>{" "}
                      </FormLabel>
                      <Controller
                        name="typeOther"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Other Type Name is required" }}
                        render={({ field }) => (
                          <TextField
                            placeholder="Enter Other Type Name"
                            fullWidth
                            type="text"
                            {...field}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.typeOther && errors.typeOther.message}
                      </FormHelperText>
                    </div>
                  </div>
                </>
              )}
              <div className="col-lg-12 col-md-12 col-12">
                <div className="form-group mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    Describe the Solution (100 words){" "}
                    <span className="text-danger">*</span>
                  </FormLabel>
                  <Controller
                    name="describeTheSolution"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Describe the Solution is required" }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Enter Describe the Solution"
                        fullWidth
                        type="text"
                        {...field}
                        multiline
                        rows={3}
                        // onInput={e => setCount(e.target.value.length)}
                        // inputProps={{ maxLength: 100 }}
                      />
                    )}
                  />
                  {/* <FormHelperText className='text-right'>{count} / 100</FormHelperText> */}
                  <FormHelperText className="text-danger">
                    {errors.describeTheSolution &&
                      errors.describeTheSolution.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-12">
                <h5 className="point_Lable">
                  Point of Contact Details{" "}
                  <span className="text-danger">*</span>
                </h5>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="form-group mb-3">
                      <FormLabel shrink="true" className="input_Lable">
                        Name <span className="text-danger">*</span>{" "}
                      </FormLabel>
                      <Controller
                        name="pocName"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                          <TextField
                            placeholder="Enter Name"
                            fullWidth
                            type="text"
                            {...field}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.pocName && errors.pocName.message}
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="form-group mb-3">
                      <FormLabel shrink="true" className="input_Lable">
                        Email <span className="text-danger">*</span>{" "}
                      </FormLabel>
                      <Controller
                        name="pocEmail"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Email is required",
                          validate: validateEmail,
                        }}
                        render={({ field }) => (
                          <TextField
                            placeholder="Enter Email"
                            fullWidth
                            type="text"
                            {...field}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.pocEmail && errors.pocEmail.message}
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="form-group mb-3">
                      <FormLabel shrink="true" className="input_Lable">
                        Mobile Number <span className="text-danger">*</span>{" "}
                      </FormLabel>
                      <Controller
                        name="pocMobile"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Mobile Number is required",
                          validate: validatePhoneNumber,
                        }}
                        render={({ field }) => (
                          <TextField
                            placeholder="Enter Mobile Number"
                            fullWidth
                            type="number"
                            {...field}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 10);
                            }}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.pocMobile && errors.pocMobile.message}
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="form-group mb-3">
                      <FormLabel shrink="true" className="input_Lable">
                        Landline Number{" "}
                      </FormLabel>
                      <Controller
                        name="pocPhone"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <TextField
                            placeholder="Enter Landline Number"
                            fullWidth
                            type="number"
                            {...field}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 15);
                            }}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.pocPhone && errors.pocPhone.message}
                      </FormHelperText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <LoadingButton
                loadingPosition="start"
                type="submit"
                loading={loading}
                className="b-yellow-btn"
              >
                <span>APPLY</span>
              </LoadingButton>
              <Button className="blue-btn ml-3" onClick={handleCancel}>
                CANCEL
              </Button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Applyform;
