import {
  Button,
  Divider,
  FormHelperText,
  FormLabel,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import { useParams } from "react-router-dom";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Rejectreason from "../../common-pages/Rejectreason";
import notifyService from "../../../api/notifySerivce";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import { LoadingButton } from "@mui/lab";
import Accordionpage from "../../common-pages/Accordionpage";
import EditIcon from "@mui/icons-material/Edit";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Typography from "@mui/material/Typography";
// import { Divider } from '@mui/material';
const fileTypes = ["JPG", "JPEG", "PNG"];
const Adminchallengedetails = ({ name }) => {
  const { id } = useParams();
  let challengeIds;
  if (id !== undefined) {
    challengeIds = atob(id);
  }
  let userId = localStorage.getItem("userId");
  const {
    navigator,
    setPageLoading,
    validatePhoneNumber,
    validateEmail,
    btnLoading,
    setBtnLoading,
    VisuallyHiddenInput,
  } = useContext(DataContext);
  const [formData, setFormData] = useState({
    eligibility: [
      {
        id: 0,
        challengeId: 0,
        eligibility: "",
      },
    ],
    process: [
      {
        id: 0,
        challengeId: 0,
        process: "",
      },
    ],
    partners: [
      {
        id: 0,
        challengeId: 0,
        partners: "",
        partnerLogoFile: "",
        partnerLogoURL: "",
        partnerLogoFileName: "",
      },
    ],
  });
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const role = localStorage.getItem("role");
  useDidMountEffect(() => {
    getApi();
  }, []);
  const [getValue, setGetValue] = useState({});
  const [partnerLogoURL, setPartnerLogoURL] = useState(
    "/images/product-image.png"
  );
  const getApi = () => {
    setPageLoading(true);
    apiService(
      `challenge/get?id=${challengeIds}&useId=${userId}`,
      "",
      "get"
    ).then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          var response = res.data;
          if (response.challengeImageName) {
            response.challengeImageURL = response.challengeImageName;
          }
          if (response.problemStatement) {
            response.problemStatement.forEach((element) => {
              element.imageURL = element.imageName;
            });
          }
          if (response.partner) {
            response.partner.forEach((element) => {
              element.partnerLogoURL = element.partnerLogoFileName;
            });
          }
          setGetValue(res.data);
          if (res.data.partnerLogoFileName) {
            setPartnerLogoURL(res.data.partnerLogoFileName);
          }
          setFormData(res.data);
        }
      }
    });
  };
  const [open, setOpen] = React.useState(false);
  const [solutionId, setSolutionId] = useState("");
  const [challengeId, setChallengeId] = useState("");
  const handleClickOpen = (challengeId) => {
    setOpen(true);

    setChallengeId(challengeId);
  };
  const handleClose = () => {
    setOpen(false);
    // handleBack()
  };
  let logoselecetdFile = "";
  const handleFileUpload = (
    event,
    filekey,
    fileName,
    fileURL,
    arrayName,
    index
  ) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            img_crt_type === "jpeg" ||
            img_crt_type === "jpg" ||
            img_crt_type === "png" ||
            img_crt_type === "pdf"
          ) {
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              // setValue(filekey, ImageValue)
              // setValue(fileName, img_name)
              // setValue(fileURL, logourl1)
              if (arrayName !== "") {
                setValue(`${arrayName}.${index}.${filekey}`, ImageValue);
                setValue(`${arrayName}.${index}.${fileName}`, img_name);
                setValue(`${arrayName}.${index}.${fileURL}`, logourl1);
              } else {
                setValue(filekey, ImageValue);
                setValue(fileName, img_name);
                setValue(fileURL, logourl1);
              }
              setPartnerLogoURL(logourl1);
              clearErrors(filekey);
              clearErrors(fileName);
              clearErrors(fileURL);
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File size exceeds 5MB",
            "Please check your file size"
          );
        }
      }
    }
  };
  const onSubmit = async (data) => {
    setPageLoading(true);
    setBtnLoading(true);
    var getform = data;
    getform.userId = userId;
    // getform.startDate = data.startDate.format("YYYY-MM-DD")
    // getform.endDate = data.endDate.format("YYYY-MM-DD")
    console.log(getform);

    apiService(`challenge/save`, getform, "post").then((res) => {
      setPageLoading(false);
      setBtnLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          // notifyService('Success', 'Success', 'Submitted successfully !!')

          approvalApi(getform.id, 2);
        }
      }
    });
  };
  const onError = (errors, e) => {
    notifyService(
      "danger",
      "Form is invalid",
      "Please check the mandatory fields"
    );
  };
  const approvalApi = (challengeId, approve) => {
    setPageLoading(true);
    setBtnLoading(true);
    var req = {
      challengeId: challengeId,
      approval: approve,
    };

    apiService("admin/challenge/approve", req, "post").then((res) => {
      setPageLoading(false);
      setBtnLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", res.data.responseMessage);
          handleBack();
        }
      }
    });
  };
  const handleBack = () => {
    navigator(`/admin/${name}/challenge-status`);
  };
  const handleEdit = () => {
    navigator(`/admin/${name}/challenge-status/post-challenges/${id}`);
  };

  const {
    fields: eligibilityFields,
    append: eligibilityAppend,
    remove: eligibilityRemove,
  } = useFieldArray({
    control,
    name: "eligibility",
  });
  const addEligibilityDetailsBtn = () => {
    eligibilityAppend({
      id: 0,
      challengeId: 0,
      eligibility: "",
    });
  };
  const removeEligibilityDetailsBtn = (index) => {
    eligibilityRemove(index);
  };

  const [eligibilityContent, setEligibilityContent] = useState("");
  const handleEligibilityContentChange = (value) => {
    setEligibilityContent(value);
  };

  const [howToParticipateContent, setHowToParticipateContent] = useState("");
  const handleHowToParticipateContentChange = (value) => {
    setHowToParticipateContent(value);
  };

  const {
    fields: processFields,
    append: processAppend,
    remove: processRemove,
  } = useFieldArray({
    control,
    name: "process",
  });
  const addProcessDetailsBtn = () => {
    processAppend({
      id: 0,
      challengeId: 0,
      process: "",
    });
  };
  const removeProcessDetailsBtn = (index) => {
    processRemove(index);
  };

  const {
    fields: partnersFields,
    append: partnersAppend,
    remove: partnersRemove,
  } = useFieldArray({
    control,
    name: "partners",
  });
  const addPartnersDetailsBtn = () => {
    partnersAppend({
      id: 0,
      challengeId: 0,
      partners: "",
      partnerLogoFile: "",
      partnerLogoURL: "",
      partnerLogoFileName: "",
    });
  };
  const removePartnersDetailsBtn = (index) => {
    partnersRemove(index);
  };

  const deletePartnerPhotos = (index) => {
    setValue(`partner.${index}.partnerLogo`, "");
    setValue(`partner.${index}.partnerLogoFileName`, "");
    setValue(`partner.${index}.partnerLogoURL`, "");

    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  // const [activeAccordion, setActiveAccordion] = useState(null);

  // const handleAccordionToggle = (index) => {
  //     setActiveAccordion(activeAccordion === index ? null : index);
  // };

  const [expandedIndex, setExpandedIndex] = useState(0);

  const handleAccordionChange = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  return (
    <>
      <section className="Challenges_details_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row mb-4">
                <div className="col-lg-8 col-md-8 col-12">
                  <img
                    src={getValue.challengeImageURL}
                    className="img-fluid challenge_img"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="align_data">
                    <div className="apply_challenges">
                      {role === "Solution Provider" && <h4>TO PARTICIPATE</h4>}

                      <div className="row">
                        <div className="col-12">
                          <div className="timeline_div">
                            <h5>Timeline</h5>
                            <p>
                              Start Date : <b>{getValue.startDate2}</b>
                            </p>
                            <p>
                              End Date : <b>{getValue.endDate2}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="challenge_banner_details">
                <div className="d-flex">
                  <h1 className="mb-3">{getValue.challengeTitle}</h1>
                  <div className="ml-auto">
                    {(getValue.pmtApproval === 1 ||
                      getValue.pmtApproval === 0) && (
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        startIcon={<EditIcon />}
                        onClick={() => handleEdit()}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                </div>
                <div
                  className="challenge_intro mb-2"
                  dangerouslySetInnerHTML={{
                    __html: getValue.challengeIntroduction,
                  }}
                ></div>
              </div>
            </div>
            {/* <div className='col-lg-4 col-md-6 col-12'>
              <div className='align_data'>
                <div className='apply_challenges'>
                  {
                    role === 'Solution Provider' && (<h4>TO PARTICIPATE</h4>)
                  }

                  <div className='row'>
                    <div className='col-12'>
                      <div className='timeline_div'>
                        <h5>Timeline</h5>
                        <p>Start Date : <b>{getValue.startDate2}</b>
                        </p>
                        <p>End Date : <b>{getValue.endDate2}</b>
                        </p>
                      </div>
                    </div>
                    {
                      (role === 'Solution Provider' && getValue.showButton===true) && (
                        <div className='col-12 text-center mt-3'>
                          <LoadingButton
                            loadingPosition="start"
                            onClick={() => onApplyNow(getValue.challengeTitle)}
                            loading={btnLoading}
                            className="b-yellow-btn"
                          >
                            <span>Apply Now</span>
                          </LoadingButton>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="more_details_section">
        <div className="container">
          <div className="challenge_banner_details">
            <div className="challenge_intro my-3">
              <h4 className="challenge_intro_title mb-4 text-center">
                <span>Problem</span> Statement
              </h4>
              {getValue.problemStatement && (
                <>
                  <ul>
                    {getValue.problemStatement.map((list, index) => (
                      <li key={index} className="box-code">
                        {/* {list.problemStatement} */}
                        <div className="row">
                          <div className="col-lg-3 col-md-4 col-12">
                            <img
                              src={list.imageURL}
                              className="img-fluid prob_img"
                            />
                          </div>
                          <div className="col-lg-9 col-md-8 col-12">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: list.problemStatement,
                              }}
                            ></div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <Divider />
            <div className="challenge_intro my-3">
              <h4 className="challenge_intro_title mb-4 text-center">
                <span>Expected</span> Outcome
              </h4>
              <div
                dangerouslySetInnerHTML={{ __html: getValue.expectedOutcome }}
              ></div>
            </div>
            <Divider />

            <div className="challenge_intro my-3">
              <h4 className="challenge_intro_title mb-4 text-center">
                <span>How To</span> Participate
              </h4>
              <div
                dangerouslySetInnerHTML={{ __html: getValue.howToParticipate }}
              ></div>
            </div>
            <Divider />
            <div className="challenge_intro my-3 text-center">
              <h4 className="challenge_intro_title mb-4">
                <span>Benefits</span>
              </h4>
              {getValue.benefits && (
                <>
                  <ul>
                    {getValue.benefits.map((list, index) => (
                      <li key={index} className="box-code">
                        <div
                          dangerouslySetInnerHTML={{ __html: list.benefits }}
                        ></div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <Divider />
            <div className="challenge_intro my-3 text-center">
              <h4 className="challenge_intro_title mb-4">
                <span>Eligibility</span>
              </h4>
              {getValue.eligibility && (
                <>
                  <ul>
                    {getValue.eligibility.map((list, index) => (
                      <li key={index} className="box-code">
                        <div
                          dangerouslySetInnerHTML={{ __html: list.eligibility }}
                        ></div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <Divider />
            <div className="challenge_intro my-3 text-center">
              <h4 className="challenge_intro_title mb-4">
                <span>Procedure</span> Post Submission
              </h4>
              {getValue.process?.map((res, index) => (
                <>
                  <Accordion
                    key={index}
                    expanded={index === expandedIndex}
                    onChange={() => handleAccordionChange(index)}
                    sx={{
                      background: "#F2F7F8",
                      border: "none",
                      boxShadow: "none",
                      marginBottom: "15px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandCircleDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      // aria-controls={`panel${index + 1}-content`}
                      // id={`panel${index + 1}-header`}
                    >
                      <Typography className="accordion_title">
                        {`Step ${index + 1}`}{" "}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordion_p">
                        <div
                          dangerouslySetInnerHTML={{ __html: res.process }}
                        ></div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
            </div>
            <Divider />
            <div className="challenge_intro my-3 text-center">
              <h4 className="challenge_intro_title mb-4">
                <span>Partners</span>
              </h4>
              {getValue.partner && (
                <div className="d-flex justify-content-center">
                  {getValue.partner.map((res, index) => (
                    <div className="partner_logo_details">
                      <img
                        key={index}
                        src={res.partnerLogoURL}
                        className="partner-logo"
                      />
                      <h5>{res.partnerName}</h5>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <main className="live_challenges_main">
        <div className="container">
          {/* <div className='challenge_more'>
                        <h4><span>Additional</span> Details</h4>
                    </div>
                    <Divider /> */}
          {getValue.pmtApproval === 2 || getValue.pmtApproval === 3 ? (
            <>
              <Accordionpage getValue={getValue} />
              <div className="text-center my-4">
                <Button className="blue_btn ml-2" onClick={() => handleBack()}>
                  BACK
                </Button>
              </div>
            </>
          ) : (
            <div className="my-4">
              <form
                className="signin-form"
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                {/* <div className='row'>
                                        <div className='col-lg-12 col-md-4 col-12'>
                                            <div className='form-group mb-3'>
                                                <FormLabel shrink="true" className='input_Lable'>How to Participate <span className='text-danger'>*</span></FormLabel>
                                                <Controller name="howToParticipate" control={control} defaultValue="" rules={{ required: 'Participate is required' }}
                                                    render={({ field }) =>
                                                        
                                                        <ReactQuill theme="snow"
                                                            value={howToParticipateContent}
                                                            onChange={(value) => {
                                                                handleHowToParticipateContentChange(value);
                                                                setValue('howToParticipate', value);
                                                            }}
                                                            placeholder="Enter Participate Details"
                                                            fullWidth
                                                            type='text' />
                                                    } />
                                                <FormHelperText className='text-danger'>{errors.howToParticipate && errors.howToParticipate.message}</FormHelperText>
                                            </div>
                                        </div>

                                        {
                                            eligibilityFields.map((field, index, type) => (
                                                <div className='col-lg-12 col-md-12 col-12 mb-3' key={index}>
                                                    <div className='row align-item-center'>
                                                        <div className='col-lg-10 col-md-8 col-12'>
                                                            <div className='form-group mb-3'>
                                                                <FormLabel shrink="true" className='input_Lable'>Eligibility <span className='text-danger'>*</span></FormLabel>
                                                                <Controller name={`eligibility.${index}.eligibility`} control={control} defaultValue="" rules={{ required: 'Eligibility is required' }}
                                                                    render={({ field }) =>
                                                                        
                                                                        <ReactQuill
                                                                            theme="snow"
                                                                            value={eligibilityContent}
                                                                            onChange={(value) => {
                                                                                handleEligibilityContentChange(value);
                                                                                setValue('eligibility', value);
                                                                            }}
                                                                            placeholder="Enter Eligibility Details"
                                                                            fullWidth
                                                                            type='text'
                                                                            {...field}
                                                                            multiline
                                                                        />
                                                                    } />
                                                                <FormHelperText className='text-danger'>{errors.eligibility?.[index]?.eligibility && errors.eligibility?.[index]?.eligibility.message}</FormHelperText>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-2 col-md-4 col-12'>
                                                            <Button className='green_btn' onClick={addEligibilityDetailsBtn} startIcon={<AddIcon />}>Add</Button>
                                                            {
                                                                index !== 0 && (
                                                                    <Button className='red_btn ml-3' onClick={() => removeEligibilityDetailsBtn(index)}><DeleteForeverIcon /></Button>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        

                                        <div className='col-12'>
                                            <h6 className='input_Lable'>Procedure Post Submission <span className='text-danger'>*</span> </h6>
                                        </div>
                                        {
                                            processFields.map((field, index, type) => (
                                                <div className='col-lg-12 col-md-12 col-12 mb-3' key={index}>
                                                    <div className='row align-item-center'>
                                                        <div className='col-lg-10 col-md-8 col-12'>
                                                            <div className='form-group mb-3'>
                                                                <div><p className='input_Lable'> {`Step ${index + 1}`} </p></div>
                                                                <Controller name={`process.${index}.process`} control={control} defaultValue="" rules={{ required: 'Procedure Post Submission is required' }}
                                                                    render={({ field }) =>
                                                                        <TextField
                                                                            placeholder="Enter Procedure Post Submission"
                                                                            fullWidth
                                                                            type='text'
                                                                            {...field}
                                                                            multiline
                                                                        />
                                                                    } />
                                                                <FormHelperText className='text-danger'>{errors.process?.[index]?.process && errors.process?.[index]?.process.message}</FormHelperText>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-2 col-md-4 col-12 mt-4'>
                                                            <Button className='green_btn' onClick={addProcessDetailsBtn} startIcon={<AddIcon />}>Add</Button>
                                                            {
                                                                index !== 0 && (
                                                                    <Button className='red_btn ml-3' onClick={() => removeProcessDetailsBtn(index)}><DeleteForeverIcon /></Button>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        <div className='col-lg-4 col-md-4 col-12'>
                                            <div className='form-group mb-3'>
                                                <FormLabel shrink="true" className='input_Lable'>Host Name <span className='text-danger'>*</span></FormLabel>
                                                <Controller name="contactHostName" control={control} defaultValue="" rules={{ required: 'Host Name is required' }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            placeholder="Enter Name"
                                                            fullWidth
                                                            type='text'
                                                            {...field}
                                                        />
                                                    } />
                                                <FormHelperText className='text-danger'>{errors.contactHostName && errors.contactHostName.message}</FormHelperText>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-12'>
                                            <div className='form-group mb-3'>
                                                <FormLabel shrink="true" className='input_Lable'>Host Email <span className='text-danger'>*</span></FormLabel>
                                                <Controller name="contactHostEmail" control={control} defaultValue="" rules={{ required: 'Host Email is required', validate: validateEmail }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            placeholder="Enter Email"
                                                            fullWidth
                                                            type='text'
                                                            {...field}
                                                        />
                                                    } />
                                                <FormHelperText className='text-danger'>{errors.contactHostEmail && errors.contactHostEmail.message}</FormHelperText>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-12'>
                                            <div className='form-group mb-3'>
                                                <FormLabel shrink="true" className='input_Lable'>Host Mobile Number <span className='text-danger'>*</span></FormLabel>
                                                <Controller name="contactHostPhone" control={control} defaultValue="" rules={{ required: 'Mobile Number is required', validate: validatePhoneNumber }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            placeholder="Enter Mobile Number"
                                                            fullWidth
                                                            type='number'
                                                            {...field}
                                                            onInput={(e) => {
                                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                            }}
                                                        />
                                                    } />
                                                <FormHelperText className='text-danger'>{errors.contactHostPhone && errors.contactHostPhone.message}</FormHelperText>
                                            </div>
                                        </div>

                                        <div className='ml-3'>
                                            {
                                                partnersFields.map((field, index, type) => (
                                                    <div className='row mb-5'>
                                                        <div className='col-lg-6 col-md-3 col-12' key={index}>
                                                            <div className='form-group mb-3'>
                                                                <FormLabel shrink="true" className='input_Lable'>Name <span className='text-danger'>*</span></FormLabel>
                                                                <Controller name={`partner.${index}.partnerName`} control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                                                    render={({ field }) =>
                                                                        <TextField
                                                                            placeholder="Enter Name"
                                                                            fullWidth
                                                                            type='text'
                                                                            {...field}
                                                                        />
                                                                    } />
                                                                <FormHelperText className='text-danger'>{errors.partner?.[index]?.partnerName && errors.partner?.[index]?.partnerName.message}</FormHelperText>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className='col-lg-4 col-md-6 col-12' key={index}>
                                                            <div className='form-group mb-3'>
                                                                <FormLabel shrink="true" className='input_Lable'>Logo <span className='text-danger'>*</span></FormLabel>
                                                                <Controller name={`partner.${index}.partnerLogo`} control={control} defaultValue="" rules={{ required: 'Image is required' }}
                                                                    render={({ field }) =>
                                                                        <Button fullWidth className='imgupload_div' component="label" onChange={(e) => handleFileUpload(e, "partnerLogo", "partnerLogoFileName", "partnerLogoURL", "partner", index)} name="file" types={fileTypes}>
                                                                            {
                                                                                (getValues(`partner.${index}.partnerLogoFileName`)) ? (
                                                                                    <div className='disPhotos'>
                                                                                        <img src={getValues(`partner.${index}.partnerLogoURL`)} className='logo1' alt={`partner.${index}.partnerLogoFileName`} />
                                                                                        <div className='hoverDelete'>
                                                                                            <div className='center_display'>
                                                                                                <DeleteForeverIcon onClick={() => deletePartnerPhotos(index)} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className='camera_icon disPhotos'>
                                                                                        <VisuallyHiddenInput type="file" />
                                                                                        <img src='/images/product-image.png' style={{ width: '67%' }} className='no_logo' alt={`partner.${index}.partnerLogoFileName`} />
                                                                                        <div className='ml-4 mt-3'>Upload Photo *</div>
                                                                                        <FormHelperText>File format should be in .jpg, .jpeg, .png</FormHelperText>
                                                                                        <FormHelperText>Maximum file size: 5 MB</FormHelperText>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </Button>
                                                                    } />
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-2 col-md-3 col-12 mt-4'>
                                                            <Button className='green_btn' onClick={addPartnersDetailsBtn} startIcon={<AddIcon />}>Add</Button>
                                                            {
                                                                index !== 0 && (
                                                                    <Button className='red_btn ml-3' onClick={() => removePartnersDetailsBtn(index)}><DeleteForeverIcon /></Button>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        
                                    </div> */}
                <div className="text-center">
                  {
                    // getValue.showButton === true
                    getValue.pmtApproval === 0 &&
                      getValue.showButton === true && (
                        <>
                          <LoadingButton
                            loadingPosition="start"
                            type="submit"
                            loading={btnLoading}
                            className="green_btn"
                          >
                            <span>Submit & Approve</span>
                          </LoadingButton>
                          <LoadingButton
                            loadingPosition="start"
                            onClick={() => approvalApi(getValue.id, 1)}
                            loading={btnLoading}
                            className="hold_btn"
                          >
                            <span>Hold</span>
                          </LoadingButton>
                          <Button
                            className="red_btn"
                            onClick={() => handleClickOpen(getValue.id)}
                          >
                            Reject
                          </Button>
                        </>
                      )
                  }
                  {getValue.pmtApproval === 1 &&
                    getValue.showButton === true && (
                      <>
                        <LoadingButton
                          loadingPosition="start"
                          type="submit"
                          loading={btnLoading}
                          className="green_btn"
                        >
                          <span>Submit & Approve</span>
                        </LoadingButton>
                        <Button
                          className="red_btn"
                          onClick={() => handleClickOpen(getValue.id)}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  <Button
                    className="blue_btn ml-2"
                    onClick={() => handleBack()}
                  >
                    BACK
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </main>
      <Rejectreason
        open={open}
        handleClose={handleClose}
        solutionId={solutionId}
        challengeId={challengeId}
        rejectLbl="Challenge Reject"
        handleBack={handleBack}
      />
    </>
  );
};
export default Adminchallengedetails;
