import React, { useContext, useState } from 'react'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import apiService from '../../api/apiService'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Box, Button, FormHelperText, Modal, RadioGroup, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DataContext from '../../context/DataContext'
import Remarksmodal from './Remarksmodal'
import notifyService from '../../api/notifySerivce'
const Juryscoresave = ({ handleClose, solutionId, challengeId }) => {
    debugger
    // btnLoading, setBtnLoading,
    const { btnLoading, setBtnLoading, modalstyle, setPageLoading,validateNumberonly } = useContext(DataContext);
    const [formData, setFormData] = useState({})
    const { handleSubmit, control, setValue, getValues, reset, setError, clearErrors, formState: { errors } } = useForm(
        { values: formData }
    );
    const { fields: questionsFields } = useFieldArray({
        control,
        name: "questions",
    });
    useDidMountEffect(() => {
        getApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getApi = () => {
        setPageLoading(true)
        apiService(`scoring/jury/questions/get?solutionid=${solutionId}&challengeid=${challengeId}`, '', 'get').then((result) => {
            setPageLoading(false)
            if (result && result.data) {
                setFormData(result.data)
            }
        }).catch((err) => {
            setPageLoading(false)
        });
    }
    // const [approveStatus, setApproveStatus] = useState(true);
    // const [open, setOpen] = useState(false)

    const [openRemark, setOpenRemark] = useState(false)
    const [remarkIndex, setRemarkIndex] = useState()
    const [remarkValue, setRemarkValue] = useState('')
    const handleOpenRemark = (index) => {
        setOpenRemark(true)
        setRemarkIndex(index)
        var data = getValues(`questions.${index}.remarks`)
        setRemarkValue(data)
    }
    const handleCloseRemark = (index, value) => {
        setOpenRemark(false)
        setValue(`questions.${index}.remarks`, value)
        clearErrors(`questions.${index}.remarks`)

    }
    const [comment, setComment] = useState("");
    const handleComment = (value) => {
        setValue("overallRemarks", value);
        clearErrors("overallRemarks");
        setComment(value);
    };
    const onSubmit = (data) => {
        setPageLoading(true)
        setBtnLoading(true);
        // debugger
        apiService('scoring/jury/score/save', data, "post")
            .then((res) => {
                setPageLoading(false)
                setBtnLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService("success", "Success", "Saved successfully !!");
                        toggleClose();
                    }
                }
            })
            .catch((err) => {
                setPageLoading(false)
                setBtnLoading(false);
            });
    }
    const toggleClose = () => {
        reset();
        setFormData([])
        handleClose()
    };
    return (
        <div>
            <h4 className="mb-4">
                <b>Rate your Score here</b>
            </h4>
            <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                {questionsFields.length > 0 ?
                    (
                        <>
                            <div className="qus_div">
                                {
                                    questionsFields.map((value, index) => (
                                        <>
                                            <div className="form-group mb-5">
                                                <p>
                                                    <b>
                                                        {index + 1}. {value.question}
                                                    </b>
                                                </p>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <div>
                                                        <Controller
                                                            name={`questions.${index}.score`}
                                                            control={control}
                                                            rules={{
                                                                required: "Score is required",
                                                                min: { value: 0, message: "Score must be at least 0" },
                                                                max: { value: 10, message: "Score cannot exceed 10" }
                                                            }}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <TextField
                                                                    variant="outlined"
                                                                    label="Score *"
                                                                    placeholder="Enter Score"
                                                                    fullWidth
                                                                    sx={{ width: '200px' }}
                                                                    {...field}
                                                                    type="text"
                                                                    inputProps={{
                                                                        readOnly: formData.status !== 0 && true,
                                                                        min: 0,
                                                                        max: 10
                                                                    }}
                                                                    onWheel={(event) =>
                                                                        event.currentTarget
                                                                            .querySelector("input")
                                                                            ?.blur()
                                                                    }
                                                                    onChange={(event) => {
                                                                        const value = event.target.value;
                                                                        // If value is empty, set it as an empty string, otherwise restrict to range
                                                                        if (value === '') {
                                                                            field.onChange(value);
                                                                        } else {
                                                                            const numberValue = Math.max(0, Math.min(10, Number(value)));
                                                                            field.onChange(numberValue);
                                                                        }
                                                                    }}
                                                                    onKeyDown={(e) => validateNumberonly(e)}
                                                                />
                                                            )}
                                                        />
                                                        <FormHelperText className="text-danger">
                                                            {errors.questions?.[index]?.score &&
                                                                errors.questions?.[index]?.score.message}
                                                        </FormHelperText>
                                                    </div>
                                                    {/* <div>
    <Controller
        name={`questions.${index}.score`}
        control={control}
        defaultValue="" 
        rules={{
            required: "Score is required",
            min: { value: 0, message: "Score must be at least 0" },
            max: { value: 10, message: "Score cannot exceed 10" }
        }}
        render={({ field }) => (
            <TextField
                variant="outlined"
                label="Score *"
                placeholder="Enter Score"
                fullWidth
                {...field}
                type="number"
                inputProps={{
                    readOnly: formData.status !== 0 && true,
                    min: 0,
                    max: 10
                }}
                onWheel={(event) =>
                    event.currentTarget
                        .querySelector("input")
                        ?.blur()
                }
                onChange={(event) => {
                    const value = event.target.value;
                    // If value is empty, set it as an empty string, otherwise restrict to range
                    if (value === '') {
                        field.onChange(value);
                    } else {
                        const numberValue = Math.max(0, Math.min(10, Number(value)));
                        field.onChange(numberValue);
                    }
                }}
            />
        )}
    />
    <FormHelperText className="text-danger">
        {errors.questions?.[index]?.score &&
            errors.questions?.[index]?.score.message}
    </FormHelperText>
</div> */}

                                                    <div className='ml-4'>
                                                        <Controller
                                                            name={`questions.${index}.remarks`}
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: "Remarks is required" }}
                                                            render={({ field }) => (
                                                                <Button variant="outlined" onClick={() => handleOpenRemark(index)}>Remarks</Button>
                                                            )}
                                                        />
                                                        <FormHelperText className="text-danger">
                                                            {errors.questions?.[index]?.remarks &&
                                                                errors.questions?.[index]?.remarks.message}
                                                        </FormHelperText>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                            {
                                formData.status === 0 ? (
                                    <div>
                                        <Controller
                                            name={`overallRemarks`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <TextField
                                                    id="filled-textarea"
                                                    label="Comment"
                                                    placeholder="Add a comment"
                                                    multiline
                                                    variant="filled"
                                                    {...field}
                                                    fullWidth
                                                    rows={4}
                                                    value={comment}
                                                    onInput={(e) => handleComment(e.target.value)}
                                                    inputProps={{ readOnly: formData.status !== 0 && true }}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.overallRemarks &&
                                                "Comment is required"}
                                        </FormHelperText>
                                    </div>
                                ) : (
                                    <div>
                                        <p className="text-center my-4">
                                            <span className="approved_span">
                                                Comment :  <b>{formData.overallRemarks ? (formData.overallRemarks) : (`-`)}</b>
                                            </span>
                                        </p>
                                    </div>
                                )
                            }
                        </>) : (
                        <>
                            <div className="text-center py-4">
                                <h5>No Question Found</h5>
                            </div>

                        </>
                    )}
                {formData.status === 0 ? (
                    <>
                        <div className="form-group text-center my-3">
                            <LoadingButton
                                type="submit"
                                loading={btnLoading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                onClick={() => setValue("status", 1)}
                                sx={{
                                    padding: "10px 20px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                                        backgroundColor: "green",
                                    }
                                }}
                            >
                                <span>Submit</span>
                            </LoadingButton>

                            {/* <Button variant="contained" onClick={toggleClose} className="btn btn-cancel submit px-3 ml-3">Reject</Button> */}
                            <Button
                                variant="contained"
                                onClick={toggleClose}
                                sx={{
                                    padding: "10px 23px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                                        backgroundColor: "#253b80 ",
                                    }
                                }}
                            >
                                {
                                    questionsFields.length > 0 ? ('Cancel') : ('OK')
                                }
                            </Button>
                        </div>


                    </>
                ) : (
                    <>
                        {

                            <>
                                {formData.status === 1 && (
                                    <p className="text-center my-4">
                                        <span className="approved_span">
                                            Average score :  <b>{formData.avgScore}</b>
                                        </span>
                                    </p>
                                )}
                            </>
                        }

                        <div className="text-center mt-3">
                            <Button
                                variant="contained"
                                onClick={toggleClose}
                                sx={{
                                    padding: "10px 23px !important", marginRight: '3px', borderRadius: "25px", "&:hover": {
                                        backgroundColor: "#253b80 ",
                                    }
                                }}
                            >
                                {
                                    questionsFields.length > 0 ? ('Cancel') : ('OK')
                                }
                            </Button>
                        </div>
                    </>
                )}
            </form>
            <Modal
                open={openRemark}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag">
                    <Remarksmodal index={remarkIndex} remarkValue={remarkValue} handleCloseRemark={handleCloseRemark} status={formData.status} />
                </Box>
            </Modal>
        </div>
    )
}

export default Juryscoresave