import React, { useContext, useState } from "react";
import { tabsClasses } from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Autocomplete,
  Button,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Controller, useForm } from "react-hook-form";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
// import Rejectreason from './Rejectreason';
import notifyService from "../../../api/notifySerivce";
import Solutionlist from "../../home-module/live-challenges-page/Solution-page/Solutionlist";
import DataContext from "../../../context/DataContext";
import Smarttext from "../../common-pages/Smarttext";
import { Helmet } from "react-helmet";
import Adminrequirementlist from "../requirement-page/Adminrequirementlist";
import SearchInput from "../../common-pages/SearchInput";
const Adminchallengelist = ({ name }) => {
  const { navigator, setPageLoading } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({});
  const [title, setTitle] = useState("");
  useDidMountEffect(() => {
    setValue("challengeType", 1);
    dropdownValueApi();
    challengesListApi();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useDidMountEffect(() => {
    challengeList();
  }, [name, title]);
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [challengeListValue, setChallengeListValue] = useState([]);
  const [sectorDataList, setsectorDataList] = useState([]);
  const [technologyAreasDataList, setTechnologyAreasDataList] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(
      `admin/dropdownvalues/get?key=dropdownall`,
      "",
      "unauthget"
    ).then((res) => {
      if (res) {
        if (res.data) {
          var val = res.data.dropDownValues;
          setsectorDataList(val.sectors);
        }
      }
    });
  };
  const entityTypeData = [
    "Government Departments",
    "Corporate",
    "Academic and R&D Institutions",
    "Others",
  ];
  const [entityTypeName, setEntityTypeName] = useState("");
  const handleSelectEntityType = (value) => {
    setValue("entityType", value);
    setEntityTypeName(value);
    setShowSector(true);
    challengesListApi();
    challengeList();
  };
  const [challengesDataList, setChallengesDataList] = useState([]);
  const challengesListApi = () => {
    setValue("challengeTitle", "");
    setChallengesDataList([]);
    // var replaced = getValues('entityType').split(' ').join('+');
    apiService(`challenge/title/list?entityType=${""}`, "", "unauthget").then(
      (res) => {
        if (res) {
          if (res.data) {
            setChallengesDataList(res.data);
          }
        }
      }
    );
  };

  const [showSector, setShowSector] = useState(true);
  const [showChallenges, setShowChallenges] = useState(true);
  const handleSelectChallenges = (value) => {
    setValue("challengeTitle", value);
    setValue("sector", "");
    setValue("technologyArea", "");
    setShowSector(false);
    setShowChallenges(true);
    challengeList();
  };
  const handleSelectSector = (value) => {
    setValue("sector", value);
    setValue("technologyArea", "");
    const name = sectorDataList.filter(
      (item) => item.sector.includes(value) || item.sector.includes(value)
    );
    setTechnologyAreasDataList(name[0].technologyAreas);
    setShowSector(true);
    setShowChallenges(false);
    challengeList();
  };
  const [technologyAreaValue, setTechnologyAreaValue] = useState([]);
  const handleSelecttechnologyArea = (value) => {
    setTechnologyAreaValue(value);
    setValue("technologyArea", value);
    challengeList();
  };
  const resetFiliter = () => {
    setValue("sector", "");
    setValue("technologyArea", "");
    setValue("challengeTitle", "");
    setValue("entityType", "");
    setShowSector(true);
    setShowChallenges(true);
    setTechnologyAreasDataList([]);
    challengeList();
  };
  const [challengeType, setChallengeType] = useState("");
  const handleType = (value) => {
    debugger;
    setChallengeType(value);
    setValue("challengeType", value);
    challengeList();
  };
  const challengeList = () => {
    setPageLoading(true);
    setChallengeListValue([]);
    let challengeCategoryVal;
    if (name === "corporate") {
      challengeCategoryVal = 0;
    } else if (name === "government") {
      challengeCategoryVal = 1;
    } else if (name === "msme") {
      challengeCategoryVal = 2;
    } else if (name === "enablers") {
      challengeCategoryVal = 3;
    }
    var req = {
      listSize: 12,
      pageNumber: page,
      live: 4,
      challengeTitle: title,
      sector: getValues("sector"),
      technologyArea: getValues("technologyArea"),
      // "entityType": getValues('entityType'),
      challengeType: getValues("challengeType"),
      challengeCategory: challengeCategoryVal,
    };
    apiService("admin/challenge/list", req, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.responseModelList) {
            setChallengeListValue(res.data.responseModelList);
            setTotalPage(res.data.totalPages);
          }
        }
      }
    });
  };

  const [tabValue, setTabValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setTabMainValue("2");
  };
  const [tabMainValue, setTabMainValue] = React.useState("1");
  const handleTabMainChange = (event, newValue) => {
    setTabMainValue(newValue);
    setTabValue("1");
  };
  const handleChallengeDetails = (id) => {
    navigator(`/admin/${name}/challenge-status/${btoa(id)}`);
  };
  return (
    <>
      <Helmet>
        <title>Open Innovation Portal | Admin | Challenge-status</title>
      </Helmet>
      <main className="live_challenges_main">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12">
              <>
                {name === "corporate" && (
                  // <h6 className='mb-4'>
                  //     Corporates interested in utilising external expertise to accelerate innovation can post their problem statements in the portal to source solutions from startups and innovators.
                  // </h6>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="mb-4"
                  >
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{ margin: "0px auto", textAlign: "center" }}
                    >
                      <img
                        src="/images/corporate-img.jpg"
                        className="cor-govt img-fluid"
                        alt=""
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <p className="cor-govt-txt">
                        <h2>Corporates Challenge</h2>
                        Corporates interested in utilising external expertise to
                        accelerate innovation can post their problem statements
                        in the portal to source solutions from startups and
                        innovators.
                      </p>
                    </Grid>
                  </Grid>
                )}
                {name === "government" && (
                  // <h6 className='mb-4'>
                  //     Government agencies can post their problem statements in the portal to make use of innovative solutions for their public service delivery from startups and innovators.
                  // </h6>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="mb-4"
                  >
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{ margin: "0px auto", textAlign: "center" }}
                    >
                      <img
                        src="/images/government-img.jpg"
                        className="cor-govt img-fluid"
                        alt=""
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      {tabMainValue === "1" ? (
                        <>
                          <h2>Government Challenge</h2>
                          <p className="cor-govt-txt">
                            Government Departments / Agencies can post their
                            problem statements in the portal to make use of
                            innovative solutions for their public service
                            delivery from startups and innovators.
                          </p>
                        </>
                      ) : (
                        <>
                          <h2>Government Requirements</h2>
                          <p className="cor-govt-txt">
                            Government Departments / Agencies can post their
                            requirements in the portal to make use of innovative
                            solutions for their public service delivery from
                            startups and innovators.
                          </p>
                        </>
                      )}
                    </Grid>
                  </Grid>
                )}
                {name === "msme" && (
                  // <h6 className='mb-4'>
                  //     Corporates interested in utilising external expertise to accelerate innovation can post their problem statements in the portal to source solutions from startups and innovators.
                  // </h6>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="mb-4"
                  >
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{ margin: "0px auto", textAlign: "center" }}
                    >
                      <img
                        src="/images/hand.jpg"
                        className="cor-govt img-fluid"
                        alt=""
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <p className="cor-govt-txt">
                        <h2>MSME Challenge</h2>
                        MSME interested in utilising external expertise to
                        accelerate innovation can post their problem statements
                        in the portal to source solutions from startups and
                        innovators.
                      </p>
                    </Grid>
                  </Grid>
                )}
                {name === "enablers" && (
                  // <h6 className='mb-4'>
                  //     Corporates interested in utilising external expertise to accelerate innovation can post their problem statements in the portal to source solutions from startups and innovators.
                  // </h6>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="mb-4"
                  >
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{ margin: "0px auto", textAlign: "center" }}
                    >
                      <img
                        src="/images/corporate-img.jpg"
                        className="cor-govt img-fluid"
                        alt=""
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <p className="cor-govt-txt">
                        <h2>Other Enablers Challenge</h2>
                        Other Enablers interested in utilising external
                        expertise to accelerate innovation can post their
                        problem statements in the portal to source solutions
                        from startups and innovators.
                      </p>
                    </Grid>
                  </Grid>
                )}
              </>
              <div className="live_bg">
                {name === "corporate" && (
                  <TabContext value={tabValue}>
                    <Box
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                      }}
                    >
                      <TabList
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        variant="scrollable"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            "&.Mui-disabled": { opacity: 0.3 },
                          },
                        }}
                      >
                        <Tab
                          className="tab_lable_name"
                          label="Live Challenges"
                          value="1"
                          onClick={() => handleType(1)}
                        />
                        <Tab
                          className="tab_lable_name"
                          label="Past Challenges"
                          value="2"
                          onClick={() => handleType(0)}
                        />
                        <Tab
                          className="tab_lable_name"
                          label="Applications"
                          value="3"
                        />
                      </TabList>
                    </Box>
                    <Box>
                      <TabPanel value="1" sx={{ padding: "15px 0px" }}>
                        <div className="title_filter_dev d-flex w-100">
                          <SearchInput
                            setValue={setTitle}
                            lable={"Challenge Title"}
                          />
                          <div className="ml-auto">
                            <Button
                              className="mb-3 c-blue"
                              startIcon={<FilterAltOutlinedIcon />}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Filter
                            </Button>
                          </div>
                        </div>
                        <div
                          className="filter_select my-3 collapse"
                          id="collapseExample"
                        >
                          <form className="signin-form">
                            <div className="row justify-content-center">
                              {/* <div className='col-lg-3 col-md-4 col-12'>
                                  <div className='form-group mb-3 text-center'>
                                    <FormLabel shrink="true" className='input_Lable'>Entity Type </FormLabel>
                                    <Controller name="entityType" control={control} defaultValue="" rules={{ required: false }}
                                      render={({ field }) =>
                                        <Autocomplete
                                          disableClearable
                                          disablePortal
                                          {...field}
                                          sx={{ background: 'white' }}
                                          options={entityTypeData}
                                          onChange={(e, selectedOptions) => handleSelectEntityType(selectedOptions)}
                                          renderInput={(params) => <TextField {...params} placeholder="Filter by Entity Type" />}
                                        />
                                      } />
                                    <FormHelperText className='text-danger'>{errors.entityType && errors.entityType.message}</FormHelperText>
                                  </div>
                                </div> */}
                              {showSector === true && (
                                <>
                                  <div className="col-lg-3 col-md-4 col-12">
                                    <div className="form-group mb-3 text-center">
                                      <FormLabel
                                        shrink="true"
                                        className="input_Lable"
                                      >
                                        Sector{" "}
                                      </FormLabel>
                                      <Controller
                                        name="sector"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                          <Autocomplete
                                            disableClearable
                                            disablePortal
                                            {...field}
                                            sx={{ background: "white" }}
                                            ListboxProps={{
                                              style: { textAlign: "left" },
                                            }}
                                            options={sectorDataList.map(
                                              (option) => option.sector
                                            )}
                                            onChange={(e, selectedOptions) =>
                                              handleSelectSector(
                                                selectedOptions
                                              )
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Filter by Sector"
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.sector && errors.sector.message}
                                      </FormHelperText>
                                    </div>
                                  </div>
                                  {getValues("sector") !== "All" &&
                                    getValues("sector") !== "Others" &&
                                    getValues("sector") !== "" && (
                                      <div className="col-lg-3 col-md-4 col-12">
                                        <div className="form-group mb-3 text-center">
                                          <FormLabel
                                            shrink="true"
                                            className="input_Lable"
                                          >
                                            Technology Area{" "}
                                          </FormLabel>
                                          <Controller
                                            name="technologyArea"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                sx={{ background: "white" }}
                                                ListboxProps={{
                                                  style: { textAlign: "left" },
                                                }}
                                                options={
                                                  technologyAreasDataList
                                                }
                                                onChange={(
                                                  e,
                                                  selectedOptions
                                                ) =>
                                                  handleSelecttechnologyArea(
                                                    selectedOptions
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    placeholder="Filter by Technology Area"
                                                  />
                                                )}
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.technologyArea &&
                                              errors.technologyArea.message}
                                          </FormHelperText>
                                        </div>
                                      </div>
                                    )}
                                </>
                              )}
                              {getValues("sector") !== "" && (
                                <div className="col-12 text-center">
                                  <Button
                                    onClick={resetFiliter}
                                    variant="outlined"
                                    color="error"
                                  >
                                    Reset
                                  </Button>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                        <div className="challanges_admin_list">
                          {challengeListValue.length > 0 ? (
                            <div className="row">
                              <div className="col-12">
                                <p className="showPage">
                                  Showing Page <span>{page}</span> of{" "}
                                  <span>{totalPage}</span>
                                </p>
                              </div>
                              {challengeListValue.map((list, index) => (
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                  <div className="challege_status_div">
                                    <div>
                                      <h6>{list.challengeTitle}</h6>
                                    </div>
                                    <div className="status_details">
                                      <p>
                                        <span className="c-gray">
                                          Challenge Description :
                                        </span>{" "}
                                        <span>
                                          {list.challengeIntroduction ? (
                                            <>
                                              {/* <ReactReadMoreReadLess
                                            charLimit={100}
                                            readMoreText={"Read more"}
                                            readLessText={"Read less"}
                                          >
                                            {list.challengeIntroduction}
                                          </ReactReadMoreReadLess> */}
                                              <Smarttext
                                                text={
                                                  list.challengeIntroduction
                                                }
                                                length={100}
                                              />
                                            </>
                                          ) : (
                                            <>-</>
                                          )}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="c-gray">
                                          Start Date :
                                        </span>{" "}
                                        <span>{list.startDate2}</span>
                                      </p>
                                      <p>
                                        <span className="c-gray">
                                          End Date :
                                        </span>{" "}
                                        <span>{list.endDate2}</span>
                                      </p>
                                      <p>
                                        <span className="c-gray">Status :</span>{" "}
                                        <span className="c-blue font-md f-weight-600">
                                          {list.pmtApproval === 0 && (
                                            <>Pending for Approval</>
                                          )}
                                        </span>
                                        <span className="c-origin font-md f-weight-600">
                                          {list.pmtApproval === 1 && (
                                            <>On Hold</>
                                          )}
                                        </span>
                                        <span className="c-green font-md f-weight-600">
                                          {list.pmtApproval === 2 && (
                                            <>Approved</>
                                          )}
                                        </span>
                                        <span className="c-red font-md f-weight-600">
                                          {list.pmtApproval === 3 && (
                                            <>Rejected</>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="button_div text-center p-3">
                                      <Button
                                        className="view_live_btn"
                                        onClick={() => {
                                          handleChallengeDetails(list.id);
                                        }}
                                      >
                                        View Details
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="no_data_div">
                              <img
                                src="/images/blue-logo.png"
                                alt=""
                                className="img-fluid mb-4"
                              />
                              <h5 className="no_data_h5">
                                Challenges Not Available
                              </h5>
                            </div>
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel value="2" sx={{ padding: "15px 0px" }}>
                        <div className="title_filter_dev d-flex w-100">
                          <SearchInput
                            setValue={setTitle}
                            lable={"Challenge Title"}
                          />
                          <div className="ml-auto">
                            <Button
                              className="mb-3 c-blue"
                              startIcon={<FilterAltOutlinedIcon />}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Filter
                            </Button>
                          </div>
                        </div>
                        <div
                          className="filter_select my-3 collapse"
                          id="collapseExample"
                        >
                          <form className="signin-form">
                            <div className="row justify-content-center">
                              {/* <div className='col-lg-3 col-md-4 col-12'>
                                  <div className='form-group mb-3 text-center'>
                                    <FormLabel shrink="true" className='input_Lable'>Entity Type </FormLabel>
                                    <Controller name="entityType" control={control} defaultValue="" rules={{ required: false }}
                                      render={({ field }) =>
                                        <Autocomplete
                                          disableClearable
                                          disablePortal
                                          {...field}
                                          sx={{ background: 'white' }}
                                          options={entityTypeData}
                                          onChange={(e, selectedOptions) => handleSelectEntityType(selectedOptions)}
                                          renderInput={(params) => <TextField {...params} placeholder="Filter by Entity Type" />}
                                        />
                                      } />
                                    <FormHelperText className='text-danger'>{errors.entityType && errors.entityType.message}</FormHelperText>
                                  </div>
                                </div> */}
                              {showSector === true && (
                                <>
                                  <div className="col-lg-3 col-md-4 col-12">
                                    <div className="form-group mb-3 text-center">
                                      <FormLabel
                                        shrink="true"
                                        className="input_Lable"
                                      >
                                        Sector{" "}
                                      </FormLabel>
                                      <Controller
                                        name="sector"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                          <Autocomplete
                                            disableClearable
                                            disablePortal
                                            {...field}
                                            sx={{ background: "white" }}
                                            ListboxProps={{
                                              style: { textAlign: "left" },
                                            }}
                                            options={sectorDataList.map(
                                              (option) => option.sector
                                            )}
                                            onChange={(e, selectedOptions) =>
                                              handleSelectSector(
                                                selectedOptions
                                              )
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Filter by Sector"
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.sector && errors.sector.message}
                                      </FormHelperText>
                                    </div>
                                  </div>
                                  {getValues("sector") !== "All" &&
                                    getValues("sector") !== "Others" &&
                                    technologyAreasDataList.length > 0 && (
                                      <div className="col-lg-3 col-md-4 col-12">
                                        <div className="form-group mb-3 text-center">
                                          <FormLabel
                                            shrink="true"
                                            className="input_Lable"
                                          >
                                            Technology Area{" "}
                                          </FormLabel>
                                          <Controller
                                            name="technologyArea"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                sx={{ background: "white" }}
                                                options={
                                                  technologyAreasDataList
                                                }
                                                ListboxProps={{
                                                  style: { textAlign: "left" },
                                                }}
                                                onChange={(
                                                  e,
                                                  selectedOptions
                                                ) =>
                                                  handleSelecttechnologyArea(
                                                    selectedOptions
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    placeholder="Filter by Technology Area"
                                                  />
                                                )}
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.technologyArea &&
                                              errors.technologyArea.message}
                                          </FormHelperText>
                                        </div>
                                      </div>
                                    )}
                                </>
                              )}
                              {getValues("sector") !== "" && (
                                <div className="col-12 text-center">
                                  <Button
                                    onClick={resetFiliter}
                                    variant="outlined"
                                    color="error"
                                  >
                                    Reset
                                  </Button>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                        <div className="challanges_admin_list">
                          {challengeListValue.length > 0 ? (
                            <div className="row">
                              <div className="col-12">
                                <p className="showPage">
                                  Showing Page <span>{page}</span> of{" "}
                                  <span>{totalPage}</span>
                                </p>
                              </div>
                              {challengeListValue.map((list, index) => (
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                  <div className="challege_status_div">
                                    <div>
                                      <h6>{list.challengeTitle}</h6>
                                    </div>
                                    <div className="status_details">
                                      {/* <p><span className='c-gray'>Challenge Name</span> : Fastrack</p> */}
                                      <p>
                                        <span className="c-gray">
                                          Challenge Description :
                                        </span>{" "}
                                        <span>
                                          {list.challengeIntroduction ? (
                                            <>
                                              <Smarttext
                                                text={
                                                  list.challengeIntroduction
                                                }
                                                length={100}
                                              />
                                            </>
                                          ) : (
                                            <>-</>
                                          )}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="c-gray">
                                          Challenge Start Date :
                                        </span>{" "}
                                        <span>{list.startDate2}</span>
                                      </p>
                                      <p>
                                        <span className="c-gray">
                                          Challenge End Date :
                                        </span>{" "}
                                        <span>{list.endDate2}</span>
                                      </p>
                                    </div>
                                    <div className="button_div text-center p-3">
                                      <p className="text-center">
                                        <span className="c-blue font-lg f-weight-600">
                                          {list.pmtApproval === 0 && (
                                            <>Pending for Approval</>
                                          )}
                                        </span>
                                        <span className="c-origin font-lg f-weight-600">
                                          {list.pmtApproval === 1 && (
                                            <>On Hold</>
                                          )}
                                        </span>
                                        <span className="c-green font-lg f-weight-600">
                                          {list.pmtApproval === 2 && (
                                            <>Approved</>
                                          )}
                                        </span>
                                        <span className="c-red font-lg f-weight-600">
                                          {list.pmtApproval === 3 && (
                                            <>Rejected</>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="button_div text-center p-3">
                                      <Button
                                        className="view_live_btn"
                                        onClick={() => {
                                          handleChallengeDetails(list.id);
                                        }}
                                      >
                                        View Details
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="no_data_div">
                              <img
                                src="/images/blue-logo.png"
                                alt=""
                                className="img-fluid mb-4"
                              />
                              <h5 className="no_data_h5">
                                Challenges Not Available
                              </h5>
                            </div>
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel value="3" sx={{ padding: "15px 0px" }}>
                        <Solutionlist name={name} />
                      </TabPanel>
                    </Box>
                  </TabContext>
                )}
                {name === "government" && (
                  <>
                    <TabContext sx={{ padding: "25px" }} value={tabMainValue}>
                      <Box
                        sx={{
                          flexGrow: 1,
                          bgcolor: "background.paper",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <TabList
                            value={tabMainValue}
                            onChange={handleTabMainChange}
                            variant="scrollable"
                            aria-label="visible arrows tabs example"
                            sx={{
                              [`& .${tabsClasses.scrollButtons}`]: {
                                "&.Mui-disabled": { opacity: 0.3 },
                              },
                            }}
                          >
                            {/* <Tab
                                className="tab_lable_name"
                                label="Requirements"
                                value="1"
                                onClick={() => handleType(0)}
                              />
                              <Tab
                                className="tab_lable_name"
                                label="Challenges"
                                value="2"
                                onClick={() => handleType(1)}
                              /> */}
                          </TabList>
                        </div>
                      </Box>
                      {/* <TabPanel value="1" sx={{ padding: "15px 0px" }}>
                        <Adminrequirementlist />
                      </TabPanel>
                      <TabPanel value="2" sx={{ padding: "15px 0px" }}>
                        <TabContext value={tabValue}>
                          <Box sx={{
                            flexGrow: 1,
                            bgcolor: 'background.paper',
                          }}>
                            <TabList value={tabValue} onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" sx={{
                              [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                              },
                            }}>
                              <Tab className='tab_lable_name' label="Live Challenges" value="1" onClick={() => handleType(1)} />
                              <Tab className='tab_lable_name' label="Past Challenges" value="2" onClick={() => handleType(0)} />
                              <Tab className='tab_lable_name' label="Applications" value="3" />
                            </TabList>
                          </Box>
                          <Box>
                            <TabPanel value="1" sx={{ padding: '15px 0px' }}>
                              <div className="title_filter_dev d-flex w-100">
                                <SearchInput setValue={setTitle} lable={'Challenge Title'} />
                                <div className='ml-auto'>
                                  <Button className='mb-3 c-blue' startIcon={<FilterAltOutlinedIcon />} type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Filter</Button>
                                </div>
                              </div>
                              <div className="filter_select my-3 collapse" id="collapseExample">
                                <form className="signin-form">
                                  <div className='row justify-content-center'>
                                   
                                    {
                                      (showSector === true) && (
                                        <>
                                          <div className='col-lg-3 col-md-4 col-12'>
                                            <div className='form-group mb-3 text-center'>
                                              <FormLabel shrink="true" className='input_Lable'>Sector </FormLabel>
                                              <Controller name="sector" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                  <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    {...field}
                                                    sx={{ background: 'white' }}
                                                    options={sectorDataList.map((option) => option.sector)}
                                                    onChange={(e, selectedOptions) => handleSelectSector(selectedOptions)}
                                                    renderInput={(params) => <TextField {...params} placeholder="Filter by Sector" />}
                                                  />
                                                } />
                                              <FormHelperText className='text-danger'>{errors.sector && errors.sector.message}</FormHelperText>
                                            </div>
                                          </div>
                                          {
                                            (getValues('sector') !== 'All' && getValues('sector') !== 'Others' && technologyAreasDataList.length > 0) && (
                                              <div className='col-lg-3 col-md-4 col-12'>
                                                <div className='form-group mb-3 text-center'>
                                                  <FormLabel shrink="true" className='input_Lable'>Technology Area </FormLabel>
                                                  <Controller name="technologyArea" control={control} defaultValue="" rules={{ required: false }}
                                                    render={({ field }) =>
                                                      <Autocomplete
                                                        disableClearable
                                                        disablePortal
                                                        {...field}
                                                        sx={{ background: 'white' }}
                                                        options={technologyAreasDataList}
                                                        onChange={(e, selectedOptions) => handleSelecttechnologyArea(selectedOptions)}
                                                        renderInput={(params) => <TextField {...params} placeholder="Filter by Technology Area" />}
                                                      />
                                                    } />
                                                  <FormHelperText className='text-danger'>{errors.technologyArea && errors.technologyArea.message}</FormHelperText>
                                                </div>
                                              </div>
                                            )
                                          }

                                        </>
                                      )
                                    }
                                    {
                                      (getValues('entityType') !== '' || getValues('challengeTitle') !== '' || getValues('sector') !== '') && (
                                        <div className='col-12 text-center'>
                                          <Button onClick={resetFiliter} variant="outlined" color="error">Reset</Button>
                                        </div>
                                      )
                                    }
                                  </div>
                                </form>
                              </div>
                              <div className='challanges_admin_list'>
                                {
                                  challengeListValue.length > 0 ? (
                                    <div className='row'>
                                      <div className='col-12'>
                                        <p className='showPage'>Showing Page <span>{page}</span> of <span>{totalPage}</span></p>
                                      </div>
                                      {
                                        challengeListValue.map((list, index) => (
                                          <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='challege_status_div'>
                                              <div>
                                                <h6 >{list.challengeTitle}</h6>
                                              </div>
                                              <div className='status_details'>
                                                <p><span className='c-gray'>Challenge Description :</span>  <span >{list.challengeIntroduction ? (<>
                                                 
                                                  <Smarttext text={list.challengeIntroduction} length={100} />
                                                </>
                                                ) : (<>-</>)}</span></p>
                                                <p ><span className='c-gray'>Start Date :</span>  <span >{list.startDate2}</span></p>
                                                <p ><span className='c-gray'>End Date :</span>  <span >{list.endDate2}</span></p>
                                                <p ><span className='c-gray'>Status :</span>  <span className='c-blue font-md f-weight-600'>{list.pmtApproval === 0 && <>Pending for Approval</>}</span>
                                                  <span className='c-origin font-md f-weight-600'>{list.pmtApproval === 1 && <>On Hold</>}</span>
                                                  <span className='c-green font-md f-weight-600'>{list.pmtApproval === 2 && <>Approved</>}</span>
                                                  <span className='c-red font-md f-weight-600'>{list.pmtApproval === 3 && <>Rejected</>}</span></p>

                                              </div>
                                              <div className='button_div text-center p-3'>
                                                <Button className='view_live_btn' onClick={() => { handleChallengeDetails(list.id) }}>View Details</Button>
                                              </div>
                                            </div>
                                          </div>
                                        ))
                                      }
                                    </div>
                                  ) : (
                                    <div className='no_data_div'>
                                      <img src='/images/blue-logo.png' alt='' className='img-fluid mb-4' />
                                      <h5 className='no_data_h5'>Challenges Not Available</h5>
                                    </div>
                                  )}

                              </div>
                            </TabPanel>
                            <TabPanel value="2" sx={{ padding: '15px 0px' }}>
                              <div className="title_filter_dev d-flex w-100">
                                <SearchInput setValue={setTitle} lable={'Challenge Title'} />
                                <div className='ml-auto'>
                                  <Button className='mb-3 c-blue' startIcon={<FilterAltOutlinedIcon />} type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Filter</Button>
                                </div>
                              </div>
                              <div className="filter_select my-3 collapse" id="collapseExample">
                                <form className="signin-form">
                                  <div className='row justify-content-center'>
                                   
                                    {
                                      (showSector === true) && (
                                        <>
                                          <div className='col-lg-3 col-md-4 col-12'>
                                            <div className='form-group mb-3 text-center'>
                                              <FormLabel shrink="true" className='input_Lable'>Sector </FormLabel>
                                              <Controller name="sector" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                  <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    {...field}
                                                    sx={{ background: 'white' }}
                                                    options={sectorDataList.map((option) => option.sector)}
                                                    onChange={(e, selectedOptions) => handleSelectSector(selectedOptions)}
                                                    renderInput={(params) => <TextField {...params} placeholder="Filter by Sector" />}
                                                  />
                                                } />
                                              <FormHelperText className='text-danger'>{errors.sector && errors.sector.message}</FormHelperText>
                                            </div>
                                          </div>
                                          {
                                            (getValues('sector') !== 'All' && getValues('sector') !== 'Others' && technologyAreasDataList.length > 0) && (
                                              <div className='col-lg-3 col-md-4 col-12'>
                                                <div className='form-group mb-3 text-center'>
                                                  <FormLabel shrink="true" className='input_Lable'>Technology Area </FormLabel>
                                                  <Controller name="technologyArea" control={control} defaultValue="" rules={{ required: false }}
                                                    render={({ field }) =>
                                                      <Autocomplete
                                                        disableClearable
                                                        disablePortal
                                                        {...field}
                                                        sx={{ background: 'white' }}
                                                        options={technologyAreasDataList}
                                                        onChange={(e, selectedOptions) => handleSelecttechnologyArea(selectedOptions)}
                                                        renderInput={(params) => <TextField {...params} placeholder="Filter by Technology Area" />}
                                                      />
                                                    } />
                                                  <FormHelperText className='text-danger'>{errors.technologyArea && errors.technologyArea.message}</FormHelperText>
                                                </div>
                                              </div>
                                            )
                                          }

                                        </>
                                      )
                                    }
                                    {
                                      (getValues('entityType') !== '' || getValues('challengeTitle') !== '' || getValues('sector') !== '') && (
                                        <div className='col-12 text-center'>
                                          <Button onClick={resetFiliter} variant="outlined" color="error">Reset</Button>
                                        </div>
                                      )
                                    }
                                  </div>
                                </form>
                              </div>
                              <div className='challanges_admin_list'>
                                {
                                  challengeListValue.length > 0 ? (
                                    <div className='row'>
                                      <div className='col-12'>
                                        <p className='showPage'>Showing Page <span>{page}</span> of <span>{totalPage}</span></p>
                                      </div>
                                      {
                                        challengeListValue.map((list, index) => (
                                          <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='challege_status_div'>
                                              <div>
                                                <h6>{list.challengeTitle}</h6>
                                              </div>
                                              <div className='status_details'>
                                               
                                                <p ><span className='c-gray'>Challenge Description :</span>  <span >{list.challengeIntroduction ? (<>
                                                  <Smarttext text={list.challengeIntroduction} length={100} /></>) : (<>-</>)}</span></p>
                                                <p ><span className='c-gray'>Challenge Start Date :</span>  <span >{list.startDate2}</span></p>
                                                <p ><span className='c-gray'>Challenge End Date :</span>  <span >{list.endDate2}</span></p>
                                              </div>
                                              <div className='button_div text-center p-3'>
                                                <p className='text-center'><span className='c-blue font-lg f-weight-600'>{list.pmtApproval === 0 && <>Pending for Approval</>}</span>
                                                  <span className='c-origin font-lg f-weight-600'>{list.pmtApproval === 1 && <>On Hold</>}</span>
                                                  <span className='c-green font-lg f-weight-600'>{list.pmtApproval === 2 && <>Approved</>}</span>
                                                  <span className='c-red font-lg f-weight-600'>{list.pmtApproval === 3 && <>Rejected</>}</span>
                                                </p>
                                              </div>
                                              <div className='button_div text-center p-3'>
                                                <Button className='view_live_btn' onClick={() => { handleChallengeDetails(list.id) }}>View Details</Button>
                                              </div>
                                            </div>

                                          </div>
                                        ))
                                      }
                                    </div>
                                  ) : (
                                    <div className='no_data_div'>
                                      <img src='/images/blue-logo.png' alt='' className='img-fluid mb-4' />
                                      <h5 className='no_data_h5'>Challenges Not Available</h5>
                                    </div>
                                  )
                                }
                              </div>
                            </TabPanel>
                            <TabPanel value="3" sx={{ padding: '15px 0px' }}>
                              <Solutionlist name={name} />
                            </TabPanel>
                          </Box>
                        </TabContext>
                      </TabPanel> */}
                    </TabContext>
                    <h3 className="challenges-title">Requirements</h3>
                    <Adminrequirementlist />
                    <h3 className="challenges-title mb-2">Challenges</h3>
                    <TabContext value={tabValue}>
                      <Box
                        sx={{
                          flexGrow: 1,
                          bgcolor: "background.paper",
                        }}
                      >
                        <TabList
                          value={tabValue}
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                          variant="scrollable"
                          sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                              "&.Mui-disabled": { opacity: 0.3 },
                            },
                          }}
                        >
                          <Tab
                            className="tab_lable_name"
                            label="Live Challenges"
                            value="1"
                            onClick={() => handleType(1)}
                          />
                          <Tab
                            className="tab_lable_name"
                            label="Past Challenges"
                            value="2"
                            onClick={() => handleType(0)}
                          />
                          <Tab
                            className="tab_lable_name"
                            label="Applications"
                            value="3"
                          />
                        </TabList>
                      </Box>
                      <Box>
                        <TabPanel value="1" sx={{ padding: "15px 0px" }}>
                          <div className="title_filter_dev d-flex w-100">
                            <SearchInput
                              setValue={setTitle}
                              lable={"Challenge Title"}
                            />
                            <div className="ml-auto">
                              <Button
                                className="mb-3 c-blue"
                                startIcon={<FilterAltOutlinedIcon />}
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseExample"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                              >
                                Filter
                              </Button>
                            </div>
                          </div>
                          <div
                            className="filter_select my-3 collapse"
                            id="collapseExample"
                          >
                            <form className="signin-form">
                              <div className="row justify-content-center">
                                {showSector === true && (
                                  <>
                                    <div className="col-lg-3 col-md-4 col-12">
                                      <div className="form-group mb-3 text-center">
                                        <FormLabel
                                          shrink="true"
                                          className="input_Lable"
                                        >
                                          Sector{" "}
                                        </FormLabel>
                                        <Controller
                                          name="sector"
                                          control={control}
                                          defaultValue=""
                                          rules={{ required: false }}
                                          render={({ field }) => (
                                            <Autocomplete
                                              disableClearable
                                              disablePortal
                                              {...field}
                                              sx={{ background: "white" }}
                                              ListboxProps={{
                                                style: { textAlign: "left" },
                                              }}
                                              options={sectorDataList.map(
                                                (option) => option.sector
                                              )}
                                              onChange={(e, selectedOptions) =>
                                                handleSelectSector(
                                                  selectedOptions
                                                )
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder="Filter by Sector"
                                                />
                                              )}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.sector &&
                                            errors.sector.message}
                                        </FormHelperText>
                                      </div>
                                    </div>
                                    {getValues("sector") !== "All" &&
                                      getValues("sector") !== "Others" &&
                                      technologyAreasDataList.length > 0 && (
                                        <div className="col-lg-3 col-md-4 col-12">
                                          <div className="form-group mb-3 text-center">
                                            <FormLabel
                                              shrink="true"
                                              className="input_Lable"
                                            >
                                              Technology Area{" "}
                                            </FormLabel>
                                            <Controller
                                              name="technologyArea"
                                              control={control}
                                              defaultValue=""
                                              rules={{ required: false }}
                                              render={({ field }) => (
                                                <Autocomplete
                                                  disableClearable
                                                  disablePortal
                                                  {...field}
                                                  sx={{ background: "white" }}
                                                  ListboxProps={{
                                                    style: {
                                                      textAlign: "left",
                                                    },
                                                  }}
                                                  options={
                                                    technologyAreasDataList
                                                  }
                                                  onChange={(
                                                    e,
                                                    selectedOptions
                                                  ) =>
                                                    handleSelecttechnologyArea(
                                                      selectedOptions
                                                    )
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      placeholder="Filter by Technology Area"
                                                    />
                                                  )}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.technologyArea &&
                                                errors.technologyArea.message}
                                            </FormHelperText>
                                          </div>
                                        </div>
                                      )}
                                  </>
                                )}
                                {getValues("sector") !== "" && (
                                  <div className="col-12 text-center">
                                    <Button
                                      onClick={resetFiliter}
                                      variant="outlined"
                                      color="error"
                                    >
                                      Reset
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </form>
                          </div>
                          <div className="challanges_admin_list">
                            {challengeListValue.length > 0 ? (
                              <div className="row">
                                <div className="col-12">
                                  <p className="showPage">
                                    Showing Page <span>{page}</span> of{" "}
                                    <span>{totalPage}</span>
                                  </p>
                                </div>
                                {challengeListValue.map((list, index) => (
                                  <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                    <div className="challege_status_div">
                                      <div>
                                        <h6>{list.challengeTitle}</h6>
                                      </div>
                                      <div className="status_details">
                                        <p>
                                          <span className="c-gray">
                                            Challenge Description :
                                          </span>{" "}
                                          <span>
                                            {list.challengeIntroduction ? (
                                              <>
                                                <Smarttext
                                                  text={
                                                    list.challengeIntroduction
                                                  }
                                                  length={100}
                                                />
                                              </>
                                            ) : (
                                              <>-</>
                                            )}
                                          </span>
                                        </p>
                                        <p>
                                          <span className="c-gray">
                                            Start Date :
                                          </span>{" "}
                                          <span>{list.startDate2}</span>
                                        </p>
                                        <p>
                                          <span className="c-gray">
                                            End Date :
                                          </span>{" "}
                                          <span>{list.endDate2}</span>
                                        </p>
                                        <p>
                                          <span className="c-gray">
                                            Status :
                                          </span>{" "}
                                          <span className="c-blue font-md f-weight-600">
                                            {list.pmtApproval === 0 && (
                                              <>Pending for Approval</>
                                            )}
                                          </span>
                                          <span className="c-origin font-md f-weight-600">
                                            {list.pmtApproval === 1 && (
                                              <>On Hold</>
                                            )}
                                          </span>
                                          <span className="c-green font-md f-weight-600">
                                            {list.pmtApproval === 2 && (
                                              <>Approved</>
                                            )}
                                          </span>
                                          <span className="c-red font-md f-weight-600">
                                            {list.pmtApproval === 3 && (
                                              <>Rejected</>
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="button_div text-center p-3">
                                        <Button
                                          className="view_live_btn"
                                          onClick={() => {
                                            handleChallengeDetails(list.id);
                                          }}
                                        >
                                          View Details
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="no_data_div">
                                <img
                                  src="/images/blue-logo.png"
                                  alt=""
                                  className="img-fluid mb-4"
                                />
                                <h5 className="no_data_h5">
                                  Challenges Not Available
                                </h5>
                              </div>
                            )}
                          </div>
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "15px 0px" }}>
                          <div className="title_filter_dev d-flex w-100">
                            <SearchInput
                              setValue={setTitle}
                              lable={"Challenge Title"}
                            />
                            <div className="ml-auto">
                              <Button
                                className="mb-3 c-blue"
                                startIcon={<FilterAltOutlinedIcon />}
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseExample"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                              >
                                Filter
                              </Button>
                            </div>
                          </div>
                          <div
                            className="filter_select my-3 collapse"
                            id="collapseExample"
                          >
                            <form className="signin-form">
                              <div className="row justify-content-center">
                                {showSector === true && (
                                  <>
                                    <div className="col-lg-3 col-md-4 col-12">
                                      <div className="form-group mb-3 text-center">
                                        <FormLabel
                                          shrink="true"
                                          className="input_Lable"
                                        >
                                          Sector{" "}
                                        </FormLabel>
                                        <Controller
                                          name="sector"
                                          control={control}
                                          defaultValue=""
                                          rules={{ required: false }}
                                          render={({ field }) => (
                                            <Autocomplete
                                              disableClearable
                                              disablePortal
                                              {...field}
                                              sx={{ background: "white" }}
                                              ListboxProps={{
                                                style: { textAlign: "left" },
                                              }}
                                              options={sectorDataList.map(
                                                (option) => option.sector
                                              )}
                                              onChange={(e, selectedOptions) =>
                                                handleSelectSector(
                                                  selectedOptions
                                                )
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder="Filter by Sector"
                                                />
                                              )}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.sector &&
                                            errors.sector.message}
                                        </FormHelperText>
                                      </div>
                                    </div>
                                    {getValues("sector") !== "All" &&
                                      getValues("sector") !== "Others" &&
                                      technologyAreasDataList.length > 0 && (
                                        <div className="col-lg-3 col-md-4 col-12">
                                          <div className="form-group mb-3 text-center">
                                            <FormLabel
                                              shrink="true"
                                              className="input_Lable"
                                            >
                                              Technology Area{" "}
                                            </FormLabel>
                                            <Controller
                                              name="technologyArea"
                                              control={control}
                                              defaultValue=""
                                              rules={{ required: false }}
                                              render={({ field }) => (
                                                <Autocomplete
                                                  disableClearable
                                                  disablePortal
                                                  {...field}
                                                  sx={{ background: "white" }}
                                                  ListboxProps={{
                                                    style: {
                                                      textAlign: "left",
                                                    },
                                                  }}
                                                  options={
                                                    technologyAreasDataList
                                                  }
                                                  onChange={(
                                                    e,
                                                    selectedOptions
                                                  ) =>
                                                    handleSelecttechnologyArea(
                                                      selectedOptions
                                                    )
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      placeholder="Filter by Technology Area"
                                                    />
                                                  )}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.technologyArea &&
                                                errors.technologyArea.message}
                                            </FormHelperText>
                                          </div>
                                        </div>
                                      )}
                                  </>
                                )}
                                {getValues("sector") !== "" && (
                                  <div className="col-12 text-center">
                                    <Button
                                      onClick={resetFiliter}
                                      variant="outlined"
                                      color="error"
                                    >
                                      Reset
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </form>
                          </div>
                          <div className="challanges_admin_list">
                            {challengeListValue.length > 0 ? (
                              <div className="row">
                                <div className="col-12">
                                  <p className="showPage">
                                    Showing Page <span>{page}</span> of{" "}
                                    <span>{totalPage}</span>
                                  </p>
                                </div>
                                {challengeListValue.map((list, index) => (
                                  <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                    <div className="challege_status_div">
                                      <div>
                                        <h6>{list.challengeTitle}</h6>
                                      </div>
                                      <div className="status_details">
                                        <p>
                                          <span className="c-gray">
                                            Challenge Description :
                                          </span>{" "}
                                          <span>
                                            {list.challengeIntroduction ? (
                                              <>
                                                <Smarttext
                                                  text={
                                                    list.challengeIntroduction
                                                  }
                                                  length={100}
                                                />
                                              </>
                                            ) : (
                                              <>-</>
                                            )}
                                          </span>
                                        </p>
                                        <p>
                                          <span className="c-gray">
                                            Challenge Start Date :
                                          </span>{" "}
                                          <span>{list.startDate2}</span>
                                        </p>
                                        <p>
                                          <span className="c-gray">
                                            Challenge End Date :
                                          </span>{" "}
                                          <span>{list.endDate2}</span>
                                        </p>
                                      </div>
                                      <div className="button_div text-center p-3">
                                        <p className="text-center">
                                          <span className="c-blue font-lg f-weight-600">
                                            {list.pmtApproval === 0 && (
                                              <>Pending for Approval</>
                                            )}
                                          </span>
                                          <span className="c-origin font-lg f-weight-600">
                                            {list.pmtApproval === 1 && (
                                              <>On Hold</>
                                            )}
                                          </span>
                                          <span className="c-green font-lg f-weight-600">
                                            {list.pmtApproval === 2 && (
                                              <>Approved</>
                                            )}
                                          </span>
                                          <span className="c-red font-lg f-weight-600">
                                            {list.pmtApproval === 3 && (
                                              <>Rejected</>
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="button_div text-center p-3">
                                        <Button
                                          className="view_live_btn"
                                          onClick={() => {
                                            handleChallengeDetails(list.id);
                                          }}
                                        >
                                          View Details
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="no_data_div">
                                <img
                                  src="/images/blue-logo.png"
                                  alt=""
                                  className="img-fluid mb-4"
                                />
                                <h5 className="no_data_h5">
                                  Challenges Not Available
                                </h5>
                              </div>
                            )}
                          </div>
                        </TabPanel>
                        <TabPanel value="3" sx={{ padding: "15px 0px" }}>
                          <Solutionlist name={name} />
                        </TabPanel>
                      </Box>
                    </TabContext>
                  </>
                )}
                {name === "msme" && (
                  <TabContext value={tabValue}>
                    <Box
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                      }}
                    >
                      <TabList
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        variant="scrollable"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            "&.Mui-disabled": { opacity: 0.3 },
                          },
                        }}
                      >
                        <Tab
                          className="tab_lable_name"
                          label="Live Challenges"
                          value="1"
                          onClick={() => handleType(1)}
                        />
                        <Tab
                          className="tab_lable_name"
                          label="Past Challenges"
                          value="2"
                          onClick={() => handleType(0)}
                        />
                        <Tab
                          className="tab_lable_name"
                          label="Applications"
                          value="3"
                        />
                      </TabList>
                    </Box>
                    <Box>
                      <TabPanel value="1" sx={{ padding: "15px 0px" }}>
                        <div className="title_filter_dev d-flex w-100">
                          <SearchInput
                            setValue={setTitle}
                            lable={"Challenge Title"}
                          />
                          <div className="ml-auto">
                            <Button
                              className="mb-3 c-blue"
                              startIcon={<FilterAltOutlinedIcon />}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Filter
                            </Button>
                          </div>
                        </div>
                        <div
                          className="filter_select my-3 collapse"
                          id="collapseExample"
                        >
                          <form className="signin-form">
                            <div className="row justify-content-center">
                              {/* <div className='col-lg-3 col-md-4 col-12'>
                                  <div className='form-group mb-3 text-center'>
                                    <FormLabel shrink="true" className='input_Lable'>Entity Type </FormLabel>
                                    <Controller name="entityType" control={control} defaultValue="" rules={{ required: false }}
                                      render={({ field }) =>
                                        <Autocomplete
                                          disableClearable
                                          disablePortal
                                          {...field}
                                          sx={{ background: 'white' }}
                                          options={entityTypeData}
                                          onChange={(e, selectedOptions) => handleSelectEntityType(selectedOptions)}
                                          renderInput={(params) => <TextField {...params} placeholder="Filter by Entity Type" />}
                                        />
                                      } />
                                    <FormHelperText className='text-danger'>{errors.entityType && errors.entityType.message}</FormHelperText>
                                  </div>
                                </div> */}
                              {showSector === true && (
                                <>
                                  <div className="col-lg-3 col-md-4 col-12">
                                    <div className="form-group mb-3 text-center">
                                      <FormLabel
                                        shrink="true"
                                        className="input_Lable"
                                      >
                                        Sector{" "}
                                      </FormLabel>
                                      <Controller
                                        name="sector"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                          <Autocomplete
                                            disableClearable
                                            disablePortal
                                            {...field}
                                            sx={{ background: "white" }}
                                            ListboxProps={{
                                              style: { textAlign: "left" },
                                            }}
                                            options={sectorDataList.map(
                                              (option) => option.sector
                                            )}
                                            onChange={(e, selectedOptions) =>
                                              handleSelectSector(
                                                selectedOptions
                                              )
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Filter by Sector"
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.sector && errors.sector.message}
                                      </FormHelperText>
                                    </div>
                                  </div>
                                  {getValues("sector") !== "All" &&
                                    getValues("sector") !== "Others" &&
                                    getValues("sector") !== "" && (
                                      <div className="col-lg-3 col-md-4 col-12">
                                        <div className="form-group mb-3 text-center">
                                          <FormLabel
                                            shrink="true"
                                            className="input_Lable"
                                          >
                                            Technology Area{" "}
                                          </FormLabel>
                                          <Controller
                                            name="technologyArea"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                sx={{ background: "white" }}
                                                ListboxProps={{
                                                  style: { textAlign: "left" },
                                                }}
                                                options={
                                                  technologyAreasDataList
                                                }
                                                onChange={(
                                                  e,
                                                  selectedOptions
                                                ) =>
                                                  handleSelecttechnologyArea(
                                                    selectedOptions
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    placeholder="Filter by Technology Area"
                                                  />
                                                )}
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.technologyArea &&
                                              errors.technologyArea.message}
                                          </FormHelperText>
                                        </div>
                                      </div>
                                    )}
                                </>
                              )}
                              {getValues("sector") !== "" && (
                                <div className="col-12 text-center">
                                  <Button
                                    onClick={resetFiliter}
                                    variant="outlined"
                                    color="error"
                                  >
                                    Reset
                                  </Button>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                        <div className="challanges_admin_list">
                          {challengeListValue.length > 0 ? (
                            <div className="row">
                              <div className="col-12">
                                <p className="showPage">
                                  Showing Page <span>{page}</span> of{" "}
                                  <span>{totalPage}</span>
                                </p>
                              </div>
                              {challengeListValue.map((list, index) => (
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                  <div className="challege_status_div">
                                    <div>
                                      <h6>{list.challengeTitle}</h6>
                                    </div>
                                    <div className="status_details">
                                      <p>
                                        <span className="c-gray">
                                          Challenge Description :
                                        </span>{" "}
                                        <span>
                                          {list.challengeIntroduction ? (
                                            <>
                                              {/* <ReactReadMoreReadLess
                                            charLimit={100}
                                            readMoreText={"Read more"}
                                            readLessText={"Read less"}
                                          >
                                            {list.challengeIntroduction}
                                          </ReactReadMoreReadLess> */}
                                              <Smarttext
                                                text={
                                                  list.challengeIntroduction
                                                }
                                                length={100}
                                              />
                                            </>
                                          ) : (
                                            <>-</>
                                          )}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="c-gray">
                                          Start Date :
                                        </span>{" "}
                                        <span>{list.startDate2}</span>
                                      </p>
                                      <p>
                                        <span className="c-gray">
                                          End Date :
                                        </span>{" "}
                                        <span>{list.endDate2}</span>
                                      </p>
                                      <p>
                                        <span className="c-gray">Status :</span>{" "}
                                        <span className="c-blue font-md f-weight-600">
                                          {list.pmtApproval === 0 && (
                                            <>Pending for Approval</>
                                          )}
                                        </span>
                                        <span className="c-origin font-md f-weight-600">
                                          {list.pmtApproval === 1 && (
                                            <>On Hold</>
                                          )}
                                        </span>
                                        <span className="c-green font-md f-weight-600">
                                          {list.pmtApproval === 2 && (
                                            <>Approved</>
                                          )}
                                        </span>
                                        <span className="c-red font-md f-weight-600">
                                          {list.pmtApproval === 3 && (
                                            <>Rejected</>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="button_div text-center p-3">
                                      <Button
                                        className="view_live_btn"
                                        onClick={() => {
                                          handleChallengeDetails(list.id);
                                        }}
                                      >
                                        View Details
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="no_data_div">
                              <img
                                src="/images/blue-logo.png"
                                alt=""
                                className="img-fluid mb-4"
                              />
                              <h5 className="no_data_h5">
                                Challenges Not Available
                              </h5>
                            </div>
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel value="2" sx={{ padding: "15px 0px" }}>
                        <div className="title_filter_dev d-flex w-100">
                          <SearchInput
                            setValue={setTitle}
                            lable={"Challenge Title"}
                          />
                          <div className="ml-auto">
                            <Button
                              className="mb-3 c-blue"
                              startIcon={<FilterAltOutlinedIcon />}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Filter
                            </Button>
                          </div>
                        </div>
                        <div
                          className="filter_select my-3 collapse"
                          id="collapseExample"
                        >
                          <form className="signin-form">
                            <div className="row justify-content-center">
                              {/* <div className='col-lg-3 col-md-4 col-12'>
                                  <div className='form-group mb-3 text-center'>
                                    <FormLabel shrink="true" className='input_Lable'>Entity Type </FormLabel>
                                    <Controller name="entityType" control={control} defaultValue="" rules={{ required: false }}
                                      render={({ field }) =>
                                        <Autocomplete
                                          disableClearable
                                          disablePortal
                                          {...field}
                                          sx={{ background: 'white' }}
                                          options={entityTypeData}
                                          onChange={(e, selectedOptions) => handleSelectEntityType(selectedOptions)}
                                          renderInput={(params) => <TextField {...params} placeholder="Filter by Entity Type" />}
                                        />
                                      } />
                                    <FormHelperText className='text-danger'>{errors.entityType && errors.entityType.message}</FormHelperText>
                                  </div>
                                </div> */}
                              {showSector === true && (
                                <>
                                  <div className="col-lg-3 col-md-4 col-12">
                                    <div className="form-group mb-3 text-center">
                                      <FormLabel
                                        shrink="true"
                                        className="input_Lable"
                                      >
                                        Sector{" "}
                                      </FormLabel>
                                      <Controller
                                        name="sector"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                          <Autocomplete
                                            disableClearable
                                            disablePortal
                                            {...field}
                                            sx={{ background: "white" }}
                                            ListboxProps={{
                                              style: { textAlign: "left" },
                                            }}
                                            options={sectorDataList.map(
                                              (option) => option.sector
                                            )}
                                            onChange={(e, selectedOptions) =>
                                              handleSelectSector(
                                                selectedOptions
                                              )
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Filter by Sector"
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.sector && errors.sector.message}
                                      </FormHelperText>
                                    </div>
                                  </div>
                                  {getValues("sector") !== "All" &&
                                    getValues("sector") !== "Others" &&
                                    technologyAreasDataList.length > 0 && (
                                      <div className="col-lg-3 col-md-4 col-12">
                                        <div className="form-group mb-3 text-center">
                                          <FormLabel
                                            shrink="true"
                                            className="input_Lable"
                                          >
                                            Technology Area{" "}
                                          </FormLabel>
                                          <Controller
                                            name="technologyArea"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                sx={{ background: "white" }}
                                                ListboxProps={{
                                                  style: { textAlign: "left" },
                                                }}
                                                options={
                                                  technologyAreasDataList
                                                }
                                                onChange={(
                                                  e,
                                                  selectedOptions
                                                ) =>
                                                  handleSelecttechnologyArea(
                                                    selectedOptions
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    placeholder="Filter by Technology Area"
                                                  />
                                                )}
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.technologyArea &&
                                              errors.technologyArea.message}
                                          </FormHelperText>
                                        </div>
                                      </div>
                                    )}
                                </>
                              )}
                              {getValues("sector") !== "" && (
                                <div className="col-12 text-center">
                                  <Button
                                    onClick={resetFiliter}
                                    variant="outlined"
                                    color="error"
                                  >
                                    Reset
                                  </Button>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                        <div className="challanges_admin_list">
                          {challengeListValue.length > 0 ? (
                            <div className="row">
                              <div className="col-12">
                                <p className="showPage">
                                  Showing Page <span>{page}</span> of{" "}
                                  <span>{totalPage}</span>
                                </p>
                              </div>
                              {challengeListValue.map((list, index) => (
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                  <div className="challege_status_div">
                                    <div>
                                      <h6>{list.challengeTitle}</h6>
                                    </div>
                                    <div className="status_details">
                                      {/* <p><span className='c-gray'>Challenge Name</span> : Fastrack</p> */}
                                      <p>
                                        <span className="c-gray">
                                          Challenge Description :
                                        </span>{" "}
                                        <span>
                                          {list.challengeIntroduction ? (
                                            <>
                                              <Smarttext
                                                text={
                                                  list.challengeIntroduction
                                                }
                                                length={100}
                                              />
                                            </>
                                          ) : (
                                            <>-</>
                                          )}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="c-gray">
                                          Challenge Start Date :
                                        </span>{" "}
                                        <span>{list.startDate2}</span>
                                      </p>
                                      <p>
                                        <span className="c-gray">
                                          Challenge End Date :
                                        </span>{" "}
                                        <span>{list.endDate2}</span>
                                      </p>
                                    </div>
                                    <div className="button_div text-center p-3">
                                      <p className="text-center">
                                        <span className="c-blue font-lg f-weight-600">
                                          {list.pmtApproval === 0 && (
                                            <>Pending for Approval</>
                                          )}
                                        </span>
                                        <span className="c-origin font-lg f-weight-600">
                                          {list.pmtApproval === 1 && (
                                            <>On Hold</>
                                          )}
                                        </span>
                                        <span className="c-green font-lg f-weight-600">
                                          {list.pmtApproval === 2 && (
                                            <>Approved</>
                                          )}
                                        </span>
                                        <span className="c-red font-lg f-weight-600">
                                          {list.pmtApproval === 3 && (
                                            <>Rejected</>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="button_div text-center p-3">
                                      <Button
                                        className="view_live_btn"
                                        onClick={() => {
                                          handleChallengeDetails(list.id);
                                        }}
                                      >
                                        View Details
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="no_data_div">
                              <img
                                src="/images/blue-logo.png"
                                alt=""
                                className="img-fluid mb-4"
                              />
                              <h5 className="no_data_h5">
                                Challenges Not Available
                              </h5>
                            </div>
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel value="3" sx={{ padding: "15px 0px" }}>
                        <Solutionlist name={name} />
                      </TabPanel>
                    </Box>
                  </TabContext>
                )}
                {name === "enablers" && (
                  <TabContext value={tabValue}>
                    <Box
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                      }}
                    >
                      <TabList
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        variant="scrollable"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            "&.Mui-disabled": { opacity: 0.3 },
                          },
                        }}
                      >
                        <Tab
                          className="tab_lable_name"
                          label="Live Challenges"
                          value="1"
                          onClick={() => handleType(1)}
                        />
                        <Tab
                          className="tab_lable_name"
                          label="Past Challenges"
                          value="2"
                          onClick={() => handleType(0)}
                        />
                        <Tab
                          className="tab_lable_name"
                          label="Applications"
                          value="3"
                        />
                      </TabList>
                    </Box>
                    <Box>
                      <TabPanel value="1" sx={{ padding: "15px 0px" }}>
                        <div className="title_filter_dev d-flex w-100">
                          <SearchInput
                            setValue={setTitle}
                            lable={"Challenge Title"}
                          />
                          <div className="ml-auto">
                            <Button
                              className="mb-3 c-blue"
                              startIcon={<FilterAltOutlinedIcon />}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Filter
                            </Button>
                          </div>
                        </div>
                        <div
                          className="filter_select my-3 collapse"
                          id="collapseExample"
                        >
                          <form className="signin-form">
                            <div className="row justify-content-center">
                              {/* <div className='col-lg-3 col-md-4 col-12'>
                                  <div className='form-group mb-3 text-center'>
                                    <FormLabel shrink="true" className='input_Lable'>Entity Type </FormLabel>
                                    <Controller name="entityType" control={control} defaultValue="" rules={{ required: false }}
                                      render={({ field }) =>
                                        <Autocomplete
                                          disableClearable
                                          disablePortal
                                          {...field}
                                          sx={{ background: 'white' }}
                                          options={entityTypeData}
                                          onChange={(e, selectedOptions) => handleSelectEntityType(selectedOptions)}
                                          renderInput={(params) => <TextField {...params} placeholder="Filter by Entity Type" />}
                                        />
                                      } />
                                    <FormHelperText className='text-danger'>{errors.entityType && errors.entityType.message}</FormHelperText>
                                  </div>
                                </div> */}
                              {showSector === true && (
                                <>
                                  <div className="col-lg-3 col-md-4 col-12">
                                    <div className="form-group mb-3 text-center">
                                      <FormLabel
                                        shrink="true"
                                        className="input_Lable"
                                      >
                                        Sector{" "}
                                      </FormLabel>
                                      <Controller
                                        name="sector"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                          <Autocomplete
                                            disableClearable
                                            disablePortal
                                            {...field}
                                            sx={{ background: "white" }}
                                            ListboxProps={{
                                              style: { textAlign: "left" },
                                            }}
                                            options={sectorDataList.map(
                                              (option) => option.sector
                                            )}
                                            onChange={(e, selectedOptions) =>
                                              handleSelectSector(
                                                selectedOptions
                                              )
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Filter by Sector"
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.sector && errors.sector.message}
                                      </FormHelperText>
                                    </div>
                                  </div>
                                  {getValues("sector") !== "All" &&
                                    getValues("sector") !== "Others" &&
                                    getValues("sector") !== "" && (
                                      <div className="col-lg-3 col-md-4 col-12">
                                        <div className="form-group mb-3 text-center">
                                          <FormLabel
                                            shrink="true"
                                            className="input_Lable"
                                          >
                                            Technology Area{" "}
                                          </FormLabel>
                                          <Controller
                                            name="technologyArea"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                sx={{ background: "white" }}
                                                ListboxProps={{
                                                  style: { textAlign: "left" },
                                                }}
                                                options={
                                                  technologyAreasDataList
                                                }
                                                onChange={(
                                                  e,
                                                  selectedOptions
                                                ) =>
                                                  handleSelecttechnologyArea(
                                                    selectedOptions
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    placeholder="Filter by Technology Area"
                                                  />
                                                )}
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.technologyArea &&
                                              errors.technologyArea.message}
                                          </FormHelperText>
                                        </div>
                                      </div>
                                    )}
                                </>
                              )}
                              {getValues("sector") !== "" && (
                                <div className="col-12 text-center">
                                  <Button
                                    onClick={resetFiliter}
                                    variant="outlined"
                                    color="error"
                                  >
                                    Reset
                                  </Button>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                        <div className="challanges_admin_list">
                          {challengeListValue.length > 0 ? (
                            <div className="row">
                              <div className="col-12">
                                <p className="showPage">
                                  Showing Page <span>{page}</span> of{" "}
                                  <span>{totalPage}</span>
                                </p>
                              </div>
                              {challengeListValue.map((list, index) => (
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                  <div className="challege_status_div">
                                    <div>
                                      <h6>{list.challengeTitle}</h6>
                                    </div>
                                    <div className="status_details">
                                      <p>
                                        <span className="c-gray">
                                          Challenge Description :
                                        </span>{" "}
                                        <span>
                                          {list.challengeIntroduction ? (
                                            <>
                                              {/* <ReactReadMoreReadLess
                                            charLimit={100}
                                            readMoreText={"Read more"}
                                            readLessText={"Read less"}
                                          >
                                            {list.challengeIntroduction}
                                          </ReactReadMoreReadLess> */}
                                              <Smarttext
                                                text={
                                                  list.challengeIntroduction
                                                }
                                                length={100}
                                              />
                                            </>
                                          ) : (
                                            <>-</>
                                          )}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="c-gray">
                                          Start Date :
                                        </span>{" "}
                                        <span>{list.startDate2}</span>
                                      </p>
                                      <p>
                                        <span className="c-gray">
                                          End Date :
                                        </span>{" "}
                                        <span>{list.endDate2}</span>
                                      </p>
                                      <p>
                                        <span className="c-gray">Status :</span>{" "}
                                        <span className="c-blue font-md f-weight-600">
                                          {list.pmtApproval === 0 && (
                                            <>Pending for Approval</>
                                          )}
                                        </span>
                                        <span className="c-origin font-md f-weight-600">
                                          {list.pmtApproval === 1 && (
                                            <>On Hold</>
                                          )}
                                        </span>
                                        <span className="c-green font-md f-weight-600">
                                          {list.pmtApproval === 2 && (
                                            <>Approved</>
                                          )}
                                        </span>
                                        <span className="c-red font-md f-weight-600">
                                          {list.pmtApproval === 3 && (
                                            <>Rejected</>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="button_div text-center p-3">
                                      <Button
                                        className="view_live_btn"
                                        onClick={() => {
                                          handleChallengeDetails(list.id);
                                        }}
                                      >
                                        View Details
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="no_data_div">
                              <img
                                src="/images/blue-logo.png"
                                alt=""
                                className="img-fluid mb-4"
                              />
                              <h5 className="no_data_h5">
                                Challenges Not Available
                              </h5>
                            </div>
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel value="2" sx={{ padding: "15px 0px" }}>
                        <div className="title_filter_dev d-flex w-100">
                          <SearchInput
                            setValue={setTitle}
                            lable={"Challenge Title"}
                          />
                          <div className="ml-auto">
                            <Button
                              className="mb-3 c-blue"
                              startIcon={<FilterAltOutlinedIcon />}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Filter
                            </Button>
                          </div>
                        </div>
                        <div
                          className="filter_select my-3 collapse"
                          id="collapseExample"
                        >
                          <form className="signin-form">
                            <div className="row justify-content-center">
                              {/* <div className='col-lg-3 col-md-4 col-12'>
                                  <div className='form-group mb-3 text-center'>
                                    <FormLabel shrink="true" className='input_Lable'>Entity Type </FormLabel>
                                    <Controller name="entityType" control={control} defaultValue="" rules={{ required: false }}
                                      render={({ field }) =>
                                        <Autocomplete
                                          disableClearable
                                          disablePortal
                                          {...field}
                                          sx={{ background: 'white' }}
                                          options={entityTypeData}
                                          onChange={(e, selectedOptions) => handleSelectEntityType(selectedOptions)}
                                          renderInput={(params) => <TextField {...params} placeholder="Filter by Entity Type" />}
                                        />
                                      } />
                                    <FormHelperText className='text-danger'>{errors.entityType && errors.entityType.message}</FormHelperText>
                                  </div>
                                </div> */}
                              {showSector === true && (
                                <>
                                  <div className="col-lg-3 col-md-4 col-12">
                                    <div className="form-group mb-3 text-center">
                                      <FormLabel
                                        shrink="true"
                                        className="input_Lable"
                                      >
                                        Sector{" "}
                                      </FormLabel>
                                      <Controller
                                        name="sector"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                          <Autocomplete
                                            disableClearable
                                            disablePortal
                                            {...field}
                                            sx={{ background: "white" }}
                                            ListboxProps={{
                                              style: { textAlign: "left" },
                                            }}
                                            options={sectorDataList.map(
                                              (option) => option.sector
                                            )}
                                            onChange={(e, selectedOptions) =>
                                              handleSelectSector(
                                                selectedOptions
                                              )
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Filter by Sector"
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.sector && errors.sector.message}
                                      </FormHelperText>
                                    </div>
                                  </div>
                                  {getValues("sector") !== "All" &&
                                    getValues("sector") !== "Others" &&
                                    technologyAreasDataList.length > 0 && (
                                      <div className="col-lg-3 col-md-4 col-12">
                                        <div className="form-group mb-3 text-center">
                                          <FormLabel
                                            shrink="true"
                                            className="input_Lable"
                                          >
                                            Technology Area{" "}
                                          </FormLabel>
                                          <Controller
                                            name="technologyArea"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                sx={{ background: "white" }}
                                                ListboxProps={{
                                                  style: { textAlign: "left" },
                                                }}
                                                options={
                                                  technologyAreasDataList
                                                }
                                                onChange={(
                                                  e,
                                                  selectedOptions
                                                ) =>
                                                  handleSelecttechnologyArea(
                                                    selectedOptions
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    placeholder="Filter by Technology Area"
                                                  />
                                                )}
                                              />
                                            )}
                                          />
                                          <FormHelperText className="text-danger">
                                            {errors.technologyArea &&
                                              errors.technologyArea.message}
                                          </FormHelperText>
                                        </div>
                                      </div>
                                    )}
                                </>
                              )}
                              {getValues("sector") !== "" && (
                                <div className="col-12 text-center">
                                  <Button
                                    onClick={resetFiliter}
                                    variant="outlined"
                                    color="error"
                                  >
                                    Reset
                                  </Button>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                        <div className="challanges_admin_list">
                          {challengeListValue.length > 0 ? (
                            <div className="row">
                              <div className="col-12">
                                <p className="showPage">
                                  Showing Page <span>{page}</span> of{" "}
                                  <span>{totalPage}</span>
                                </p>
                              </div>
                              {challengeListValue.map((list, index) => (
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                  <div className="challege_status_div">
                                    <div>
                                      <h6>{list.challengeTitle}</h6>
                                    </div>
                                    <div className="status_details">
                                      {/* <p><span className='c-gray'>Challenge Name</span> : Fastrack</p> */}
                                      <p>
                                        <span className="c-gray">
                                          Challenge Description :
                                        </span>{" "}
                                        <span>
                                          {list.challengeIntroduction ? (
                                            <>
                                              <Smarttext
                                                text={
                                                  list.challengeIntroduction
                                                }
                                                length={100}
                                              />
                                            </>
                                          ) : (
                                            <>-</>
                                          )}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="c-gray">
                                          Challenge Start Date :
                                        </span>{" "}
                                        <span>{list.startDate2}</span>
                                      </p>
                                      <p>
                                        <span className="c-gray">
                                          Challenge End Date :
                                        </span>{" "}
                                        <span>{list.endDate2}</span>
                                      </p>
                                    </div>
                                    <div className="button_div text-center p-3">
                                      <p className="text-center">
                                        <span className="c-blue font-lg f-weight-600">
                                          {list.pmtApproval === 0 && (
                                            <>Pending for Approval</>
                                          )}
                                        </span>
                                        <span className="c-origin font-lg f-weight-600">
                                          {list.pmtApproval === 1 && (
                                            <>On Hold</>
                                          )}
                                        </span>
                                        <span className="c-green font-lg f-weight-600">
                                          {list.pmtApproval === 2 && (
                                            <>Approved</>
                                          )}
                                        </span>
                                        <span className="c-red font-lg f-weight-600">
                                          {list.pmtApproval === 3 && (
                                            <>Rejected</>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="button_div text-center p-3">
                                      <Button
                                        className="view_live_btn"
                                        onClick={() => {
                                          handleChallengeDetails(list.id);
                                        }}
                                      >
                                        View Details
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="no_data_div">
                              <img
                                src="/images/blue-logo.png"
                                alt=""
                                className="img-fluid mb-4"
                              />
                              <h5 className="no_data_h5">
                                Challenges Not Available
                              </h5>
                            </div>
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel value="3" sx={{ padding: "15px 0px" }}>
                        <Solutionlist name={name} />
                      </TabPanel>
                    </Box>
                  </TabContext>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Adminchallengelist;
