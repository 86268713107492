import React, { useContext, useState } from "react";
// import "./techmarket.css";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Button, Container, FormHelperText, FormLabel, Grid, Pagination, Stack, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { tabsClasses } from "@mui/material/Tabs";
import { useForm, Controller } from "react-hook-form";
import InterestedCanditatesList from "./InterestedCanditatesList";

const AdminTechmarketList = () => {
    const { navigator } = useContext(DataContext);
    const { control, setValue, formState: { errors } } = useForm({});
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    let roleId = localStorage.getItem("roleId");
    let userId = localStorage.getItem("userId");
    const [tabValue, setTabValue] = useState("1");
    const [statusName, setStatusName] = useState(3);



    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleType = (value) => {
        if (value === 1) {
            inventorList();
        }
    };
    useDidMountEffect(() => {
        dashBoardApi();
        challengesListApi()
        dropdownValueApi()
    }, []);
    const [instituteName, setInstituteName] = useState('')
    const [selectSector, setselectSector] = useState("");
    const [homeData, setHomeData] = useState(0);
    const dashBoardApi = () => {
        var req = {};
        setHomeData({});
        apiService(`techmarket/dashboard`, req, "post").then((res) => {
            if (res.data) {
                setHomeData(res.data);
            }
        });
    };

    // tab 1 api
    useDidMountEffect(() => {
        inventorList();
    }, [page, statusName, instituteName, selectSector]);
    const [inventorListValue, setInventorListValue] = useState([]);
    const inventorList = () => {
        setInventorListValue([]);
        var req = {
            listSize: 12,
            pageNumber: page,
            approvalStatus: statusName,
            userId:userId
        };
        apiService("techmarket/list", req, "post").then((res) => {
            if (res) {
                if (res.data) {
                    if (res.data.responseModelList) {
                        setInventorListValue(res.data.responseModelList);
                        // setTechTitleDataList(res.data.responseModelList)
                        setTotalPage(res.data.totalPages);
                    }
                }
            }
        });
    };
    const handleChange = (event, value) => {
        setPage(value);
    };
    const handleChangeStatus = (newStatus) => {
        setStatusName(newStatus);
    };

    // tab 2 api


    const handleTechdetail = (id, userId) => {
        if (roleId === null) {
            navigator("/sign-in");
        } else {
            navigator(`/admin/tech-market/details/${btoa(id)}/${btoa(userId)}`);
        }
    };

    const handleSelectSector = (value) => {
        setselectSector(value);
        setValue('sector', value)
        const name = sectorDataList.filter(item => ((item.sector)).includes(value)
            || ((item.sector)).includes(value)
        )
    }

    const handleSelectChallenges = (value) => {
        setInstituteName(value)
    }

    const resetFiliter = () => {
        setselectSector('')
        setInstituteName('')
    }

    const [sectorDataList, setsectorDataList] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=dropdownall`, '', 'unauthget').then((res) => {
            if (res) {
                if (res.data) {
                    var val = res.data.dropDownValues
                    setsectorDataList(val.sectors)
                }
            }

        })
    }

    const [challengesDataList, setChallengesDataList] = useState([])
    const challengesListApi = () => {
        setChallengesDataList([])
        apiService(`techmarket/institution/list?entityType=${''}`, '', 'unauthget').then((res) => {
            if (res) {
                if (res.data) {
                    setChallengesDataList(res.data)
                }
            }

        })
    }

    return (
        <>
            <section className="container my-5">
                <div className="tech-bg">
                    <h3 className="text-center">Tech Marketplace</h3>
                    <p className="text-center py-3">
                        Tech Marketplace facilitates know-how, intellectual property, and
                        capabilities developed at higher educational institutions and
                        research institutions to be transferred to external entities,
                        including startups, MSMEs, industries in the public and private
                        sectors, and state and central government organisations.
                    </p>
                    {/* <center><button className='blubtn ' onClick={pageNavgate}>Showcase your Technologies</button></center> */}
                </div>
            </section>

            <section className="container mb-4">
                <h5 className="text-center font-weight-bold my-3">
                    Technologies available for Commercialization
                </h5>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="liggrn">
                            <div className="d-flex justify-content-between pt-2">
                                {/* <p>Technologies</p>
                                  <h4>1215</h4> */}
                                <h4 className="text-center">{homeData[0]}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="ligblu">
                            <div className="d-flex justify-content-between pt-2">
                                {/* <p>Patents</p>
                                  <h4>3001</h4> */}
                                <h4 className="text-center">{homeData[1]}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="ligpnk">
                            <div className="d-flex justify-content-between pt-2">
                                {/* <p>Institutions</p> */}
                                <h4 className="text-center">{homeData[2]}</h4>
                                {/* <h4>230</h4> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container-fluid">
                <Grid container direction="row" justifyContent="center" spacing={2}>
                    {
                        tabValue !== '2' && (
                            <Grid item lg={3} md={3} sm={12} xs={12} >
                                <form className="grybg signin-form filter_form mt-10">
                                    <h5 className='input_Lable mb-3 text-center'>Filter</h5>
                                    <div className=''>
                                        <div className="text-center mb-3">
                                            <FormLabel shrink="true" className='input_Lable'>Status</FormLabel>
                                            <FormControl sx={{ width: "280px" }}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={statusName}
                                                    onChange={(event) =>
                                                        handleChangeStatus(event.target.value)
                                                    }
                                                    sx={{ textAlign: "left" }}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                textAlign: "left",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={3}>All</MenuItem>
                                                    <MenuItem value={1}>Approved</MenuItem>
                                                    <MenuItem value={2}>Rejected</MenuItem>
                                                    <MenuItem value={0}>Pending</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className=''>
                                            <div className='form-group mb-3 text-center'>
                                                <FormLabel shrink="true" className='input_Lable'>Institute Name</FormLabel>
                                                <Controller name="institute" control={control} defaultValue="" rules={{ required: false }}
                                                    render={({ field }) =>
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            {...field}
                                                            sx={{ background: 'white' }}
                                                            ListboxProps={{
                                                                style: { textAlign: 'left' }
                                                              }}
                                                            value={instituteName}
                                                            options={challengesDataList}
                                                            onChange={(e, selectedOptions) => handleSelectChallenges(selectedOptions)}
                                                            renderInput={(params) => <TextField {...params} placeholder="Filter by Institute Name" />}
                                                        />
                                                    } />
                                                <FormHelperText className='text-danger'>{errors.institute && errors.institute.message}</FormHelperText>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div className='form-group mb-3 text-center'>
                                                <FormLabel shrink="true" className='input_Lable'>Sector </FormLabel>
                                                <Controller name="sector" control={control} defaultValue="" rules={{ required: false }}
                                                    render={({ field }) =>
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            {...field}
                                                            sx={{ background: 'white' }}
                                                            ListboxProps={{
                                                                style: { textAlign: 'left' }
                                                              }}
                                                            value={selectSector}
                                                            options={sectorDataList.map((option) => option.sector)}
                                                            onChange={(e, selectedOptions) => handleSelectSector(selectedOptions)}
                                                            renderInput={(params) => <TextField {...params} placeholder="Filter by Sector" />}
                                                        />
                                                    } />
                                                <FormHelperText className='text-danger'>{errors.sector && errors.sector.message}</FormHelperText>
                                            </div>
                                        </div>
                                        {
                                            (instituteName !== '' || selectSector !== '') && (
                                                <div className='col-12 text-center'>
                                                    <Button onClick={resetFiliter} variant="outlined" color="error">Reset</Button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </form>
                            </Grid>
                        )
                    }
                    <Grid item lg={tabValue !== '2' ? 9 : 12} md={tabValue !== '2' ? 9 : 12} sm={12} xs={12}>
                        <section className='container my-2 p-0'>
                            <TabContext value={tabValue}>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        bgcolor: "background.paper",
                                    }}
                                >
                                    {/* <TabList onChange={handleTabChange} aria-label="lab API tabs example"> */}
                                    <TabList
                                        value={tabValue}
                                        onChange={handleTabChange}
                                        aria-label="lab API tabs example"
                                        variant="scrollable"
                                        sx={{
                                            [`& .${tabsClasses.scrollButtons}`]: {
                                                "&.Mui-disabled": { opacity: 0.3 },
                                            },
                                        }}
                                    >
                                        <Tab
                                            className="tab_lable_name"
                                            label="Technology Portfolio"
                                            value="1"
                                            onClick={() => handleType(1)}
                                        />
                                        <Tab
                                            className="tab_lable_name"
                                            label="Interested Canditates"
                                            value="2"
                                        // onClick={() => handleType(2)}
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className="grybg">
                                    <section className="container">
                                        <div>
                                            {inventorListValue.length > 0 ? (
                                                <div className="row mt-3">
                                                    {inventorListValue &&
                                                        inventorListValue.map((item, index) => (
                                                            <div className="col-lg-4 col-12">
                                                                <div className="portfolio-card">
                                                                    <div className="whitebx p-3" key={index}>
                                                                        <h5 className="text-center">{item.title}</h5>
                                                                    </div>
                                                                    <p className="pt-4">
                                                                        Chief Inventor : <b>{item.inventorName}</b>
                                                                    </p>
                                                                    <p>
                                                                        Institution : <b>{item.instituteName}</b>
                                                                    </p>
                                                                    <p>
                                                                        Stage of Development :{" "}
                                                                        <b>{item.stageOfDevelopment}</b>
                                                                    </p>
                                                                    <p>
                                                                        Status :
                                                                        {item.approvalStatus === 0 ? <b> Pending</b> : ""}
                                                                        {item.approvalStatus === 2 ? <b> Reject</b> : ""}
                                                                        {item.approvalStatus === 1 ? (
                                                                            <b> Approved</b>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </p>
                                                                    <div className='button_div text-center p-3'>
                                                                        <Button className='view_live_btn' onClick={() => { handleTechdetail(item.id, item.userId) }}>View Details</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    {totalPage > 1 && (
                                                        <div className="col-12">
                                                            <Stack spacing={2}>
                                                                <Pagination
                                                                    count={totalPage}
                                                                    page={page}
                                                                    onChange={handleChange}
                                                                />
                                                            </Stack>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="no_data_div">
                                                    <img
                                                        src="/images/blue-logo.png"
                                                        alt=""
                                                        className="img-fluid mb-4"
                                                    />
                                                    <h5 className="no_data_h5">Technology Portfolio Not Available</h5>
                                                </div>
                                            )}
                                        </div>
                                    </section>
                                </TabPanel>
                                <TabPanel value="2" className="grybg">
                                    <InterestedCanditatesList />
                                </TabPanel>
                            </TabContext>
                        </section>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
export default AdminTechmarketList