import { Autocomplete, Button, Fab, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Pagination, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import './inventor.css'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import DataContext from '../../../context/DataContext';
import { Link, useNavigate, useNavigation, useParams } from 'react-router-dom';
import PreviewIcon from '@mui/icons-material/Preview';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import EditorToolbars, { modules, formats } from "./EditorToolbars";
import apiService from '../../../api/apiService';
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from '@mui/icons-material/Edit';
import Inventoradd from './Inventoradd';
import notifyService from '../../../api/notifySerivce';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import InterestedCanditatesList from '../../admin-module/admin-techmarket-page/InterestedCanditatesList';
import { tabsClasses } from "@mui/material/Tabs";
import { Controller, useForm } from 'react-hook-form';
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
const SearchinputDiv = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "25ch",
            },
        },
    },
}));
const Inventor = ({ htmlContent }) => {
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    const { readyOnlyValue, setReadyOnlyValue, VisuallyHiddenInput, toolbarOptions } = useContext(DataContext)
    const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({});
    const [tabValue, setTabValue] = useState("1");
    let userId = localStorage.getItem("userId");
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleType = (value) => {
        if (value === 1) {
            inventorList();
        }
    };
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const [page, setPage] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState(1);
    const { id } = useParams()
    const [statusName, setStatusName] = useState(3);
    const [techTitleDataList, setTechTitleDataList] = useState([])
    const [instituteName, setInstituteName] = useState('')
    const [selectSector, setselectSector] = useState("");
    const [technologyTitleValue, setTechnologyTitleValue] = useState("");
    useDidMountEffect(() => {
        dropdownValueApi()
        // inventorListdrop()
        challengesListApi()
    }, [])
    useDidMountEffect(() => {
        inventorList()
    }, [page, statusName, selectSector, instituteName, technologyTitleValue])
    const inventorListdrop = () => {
        setTechTitleDataList([]);
        var req = {
            listSize: 12,
            pageNumber: page,
            institute: getValues('institute')
        };
        apiService("techmarket/list", req, "post").then((res) => {
            if (res) {
                if (res.data) {
                    if (res.data.responseModelList) {
                        setTechTitleDataList(res.data.responseModelList)
                    }
                }
            }
        });
    };
    const [inventorListValue, setInventorListValue] = useState([])
    const inventorList = () => {
        setInventorListValue([])
        var req = {
            "searchString": technologyTitleValue,
            "listSize": 12,
            "pageNumber": page,
            "approvalStatus": statusName,
            "institute": instituteName,
            "sector": selectSector,
            userId:userId
        }
        apiService(`techmarket/list`, req, 'post').then((res) => {
            if (res) {
                if (res.data) {
                    if (res.data.responseModelList) {
                        setInventorListValue(res.data.responseModelList)
                        setTotalPage(res.data.totalPages)
                    }
                }
            }
        })
    }
    const handleChangeStatus = (newStatus) => {
        setStatusName(newStatus);
    };
    const handleClickOpen = () => {
        setInvestorId(0)
        setOpen(true);
        // setFormData({})
    };
    const [investorId, setInvestorId] = useState(0)
    const handleEdit = (id, userId) => {
        setOpen(true);
        setInvestorId(id)
        // getApi(id)
    }
    const handleClose = () => {
        setOpen(false);
        // clearErrors()
        // reset()
        inventorList()
    };
    const deleteListPhotos = async (id) => {
        // const id = getValues(`techMarketImage.${index}.id`);
        // debugger
        apiService(`techmarket/remove?id=${id}`, '', 'get').then((res) => {
            if (res) {

                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Deleted successfully !!')
                    inventorList()
                }
            }
        })
    }
    const handleChange = (event, value) => {
        setPage(value);
    };
    // const handleMaxWidthChange = (event) => {
    //     setMaxWidth(
    //         event.target.value,
    //     );
    // };

    // const handleFullWidthChange = (event) => {
    //     setFullWidth(event.target.checked);
    // };


    // const deletePhotos = (index) => {
    //     if (index === 0) {
    //         ;
    //         setValue(`images.${index}.file`, "");
    //         setValue(`images.${index}.filename`, "");
    //         setValue(`images.${index}.imageURL`, "");
    //         console.log(setValue);
    //     } else {
    //         ;
    //         photosRemove(index);
    //     }
    // };









    // const onSubmit = (data) => {
    //     var req = {

    //     }
    //     apiService(`techmarket/save`, req, 'post').then((res) => {
    //         if (res) {
    //             if (res.data.responseStatus === "Success") {
    //                 notifyService('success', res.data.responseMessage)
    //                 handleClose()
    //             }
    //         }
    //     })
    // }

    const navigation = useNavigate()
    const [inventorId, setInventorId] = useState(0)
    const handleTechdetail = (id, userId) => {
        navigation(`/inventor/details/${btoa(id)}/${btoa(userId)}`)
        setInventorId(id)
    }

    const [sectorDataList, setsectorDataList] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=dropdownall`, '', 'unauthget').then((res) => {
            if (res) {
                if (res.data) {
                    var val = res.data.dropDownValues
                    setsectorDataList(val.sectors)
                }
            }

        })
    }

    const handleSelectSector = (value) => {
        setselectSector(value);
        setValue('sector', value)
        const name = sectorDataList.filter(item => ((item.sector)).includes(value)
            || ((item.sector)).includes(value)
        )
        // setShowSector(true)
        // setShowChallenges(false)
    }

    // const [showSector, setShowSector] = useState(true)
    // const [showChallenges, setShowChallenges] = useState(true)
    const handleSelectChallenges = (value) => {
        setInstituteName(value)
        // setShowSector(false)
        // setShowChallenges(true)
        // inventorList()
    }

    const resetFiliter = () => {
        // setValue('sector', '')
        // setValue('institute', '')
        setTechnologyTitleValue('')
        setselectSector('')
        setInstituteName('')
        setStatusName(3)
        // setShowSector(true)
        // setShowChallenges(true)
        // inventorList()
    }

    const [challengesDataList, setChallengesDataList] = useState([])
    const challengesListApi = () => {
        setChallengesDataList([])
        apiService(`techmarket/institution/list?entityType=${''}`, '', 'unauthget').then((res) => {
            if (res) {
                if (res.data) {
                    setChallengesDataList(res.data)
                }
            }

        })
    }

    const [showReset, setShowRest] = useState(false)
    const handleSelecttechArea = (value) => {
        setTechnologyTitleValue(value);
        setShowRest(true)
    };

    const handleSearch = (searchValue) => {
        setTechnologyTitleValue(searchValue);
    };


    return (
        <>
            <main className='container-fluid my-5'>
                {/* <section className='inventor-background p-3'>
                    <div className='box p-3'>
                        <h4>Inventor List</h4>
                        <div className='add-btn'>
                            <Button variant="contained" color="success" onClick={handleClickOpen}>+ Add</Button>
                        </div>
                    </div>
                    <div className='table my-3'>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>Title</TableCell>
                                        <TableCell align='center'>Status</TableCell>
                                        <TableCell align='center'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow >
                                        <TableCell align='center'>1</TableCell>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </section> */}

                {/* <section className='container my-5'>
                    <div className='tech-bg'>
                        <h3 className='text-center'>Tech Marketplace</h3>
                        <p className='text-center py-3'>
                            Tech Marketplace facilitates know-how, intellectual property, and capabilities developed at higher educational institutions and research institutions to be transferred to external entities, including startups, MSMEs, industries in the public and private sectors, and state and central government organisations.
                        </p>                       
                    </div>
                </section> */}


                {/* <div className="text-center">
                    <FormControl sx={{ width: "300px" }}>
                        <InputLabel id="demo-simple-select-label">
                            Status
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={statusName}
                            label="Status"
                            onChange={(event) =>
                                handleChangeStatus(event.target.value)
                            }
                            sx={{ textAlign: "left" }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        textAlign: "left",
                                    },
                                },
                            }}
                        >
                            <MenuItem value={0}>Pending</MenuItem>
                            <MenuItem value={1}>Approved</MenuItem>
                            <MenuItem value={2}>Rejected</MenuItem>
                        </Select>
                    </FormControl>
                </div> */}
                <Grid container direction="row" justifyContent="center" spacing={2}>
                    {
                        tabValue !== '2' && (
                            <Grid item lg={3} md={3} sm={12} xs={12} >
                                <form className="grybg signin-form filter_form mt-10">
                                    <div className=''>
                                        <h5 className='input_Lable mb-3 text-center'>Filter</h5>

                                        <div className=''>
                                            <div className='form-group mb-3 text-center'>
                                                <FormLabel shrink="true" className='input_Lable'>Status</FormLabel>
                                                <Controller name="status" control={control} defaultValue="" rules={{ required: false }}
                                                    render={({ field }) =>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={statusName}
                                                            fullWidth
                                                            onChange={(event) =>
                                                                handleChangeStatus(event.target.value)
                                                            }
                                                            sx={{ textAlign: "left" }}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        textAlign: "left",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value={3}>All</MenuItem>
                                                            <MenuItem value={1}>Approved</MenuItem>
                                                            <MenuItem value={2}>Rejected</MenuItem>
                                                            <MenuItem value={0}>Pending</MenuItem>
                                                        </Select>
                                                    } />
                                            </div>
                                        </div>

                                        <div className=''>
                                            <div className='form-group mb-3 text-center'>
                                                <FormLabel shrink="true" className='input_Lable'>Institute Name</FormLabel>
                                                <Controller name="institute" control={control} defaultValue="" rules={{ required: false }}
                                                    render={({ field }) =>
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            {...field}
                                                            sx={{ background: 'white' }}
                                                            ListboxProps={{
                                                                style: { textAlign: 'left' }
                                                              }}
                                                            value={instituteName}
                                                            options={challengesDataList}
                                                            onChange={(e, selectedOptions) => handleSelectChallenges(selectedOptions)}
                                                            renderInput={(params) => <TextField {...params} placeholder="Filter by Institute Name" />}
                                                        />
                                                    } />
                                                <FormHelperText className='text-danger'>{errors.institute && errors.institute.message}</FormHelperText>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div className='form-group mb-3 text-center'>
                                                <FormLabel shrink="true" className='input_Lable'>Sector </FormLabel>
                                                <Controller name="sector" control={control} defaultValue="" rules={{ required: false }}
                                                    render={({ field }) =>
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            {...field}
                                                            sx={{ background: 'white' }}
                                                            ListboxProps={{
                                                                style: { textAlign: 'left' }
                                                              }}
                                                            value={selectSector}
                                                            options={sectorDataList.map((option) => option.sector)}
                                                            onChange={(e, selectedOptions) => handleSelectSector(selectedOptions)}
                                                            renderInput={(params) => <TextField {...params} placeholder="Filter by Sector" />}
                                                        />
                                                    } />
                                                <FormHelperText className='text-danger'>{errors.sector && errors.sector.message}</FormHelperText>
                                            </div>
                                        </div>
                                        {
                                            (instituteName !== '' || selectSector !== '' || statusName !== 0) && (
                                                <div className='col-12 text-center'>
                                                    <Button onClick={resetFiliter} variant="outlined" color="error">Reset</Button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </form>
                            </Grid>
                        )
                    }

                    <Grid item lg={tabValue !== '2' ? 9 : 12} md={tabValue !== '2' ? 9 : 12} sm={12} xs={12}>
                        <section className='container my-2 p-0'>
                            <TabContext value={tabValue}>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        bgcolor: "background.paper",
                                    }}
                                >
                                    {/* <TabList onChange={handleTabChange} aria-label="lab API tabs example"> */}
                                    <TabList
                                        value={tabValue}
                                        onChange={handleTabChange}
                                        aria-label="lab API tabs example"
                                        variant="scrollable"
                                        sx={{
                                            [`& .${tabsClasses.scrollButtons}`]: {
                                                "&.Mui-disabled": { opacity: 0.3 },
                                            },
                                        }}
                                    >
                                        <Tab
                                            className="tab_lable_name"
                                            label="Technologies"
                                            value="1"
                                            onClick={() => handleType(1)}
                                        />
                                        <Tab
                                            className="tab_lable_name"
                                            label="Interested Canditates"
                                            value="2"
                                        // onClick={() => handleType(2)}
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className="grybg">
                                    <div >
                                        <div className='d-flex w-100 align-item-center'>
                                            <div className="">
                                                <SearchinputDiv
                                                    sx={{ display: { xs: "none", sm: "block" } }}
                                                    onChange={(e) => handleSearch(e.target.value)}
                                                >
                                                    <SearchIconWrapper>
                                                        <SearchIcon />
                                                    </SearchIconWrapper>
                                                    <StyledInputBase
                                                        placeholder="Search by Technology Name"
                                                        type="search"
                                                        inputProps={{ "aria-label": "Search by Technology Name" }}
                                                    />
                                                </SearchinputDiv>
                                            </div>

                                            <div className='ml-auto add-btn'>
                                                <Button variant="contained" color="success" onClick={handleClickOpen}>+ Add</Button>
                                            </div>
                                        </div>
                                        {
                                            inventorListValue.length > 0 ? (
                                                <div className="row mt-3">
                                                    {inventorListValue && inventorListValue.map((item, index) => (
                                                        <div className="col-lg-4 col-12">
                                                            <div className='portfolio-card'>
                                                                <div className='whitebx p-3' key={index}>
                                                                    <h5 className='text-center'>{item.title}</h5>
                                                                </div>
                                                                <p className='pt-4'>Chief Inventor: {item.inventorName}</p>
                                                                <p>Institution: {item.instituteName}</p>
                                                                <p>Stage of Development: {item.stageOfDevelopment ? item.stageOfDevelopment : (`-`)}</p>
                                                                <p>Sector: {item.sector ? item.sector : (`-`)}</p>
                                                                <p>
                                                                    Status :
                                                                    {item.approvalStatus === 0 ? <b> Pending</b> : ""}
                                                                    {item.approvalStatus === 2 ? <b> Reject</b> : ""}
                                                                    {item.approvalStatus === 1 ? <b> Approved</b> : ""}
                                                                </p>
                                                                <center><button className='morebtn' onClick={() => { handleTechdetail(item.id, item.userId) }}>Know More</button></center>

                                                                {
                                                                    item.approvalStatus === 0 && (
                                                                        <center><div className="icons-container mt-3">
                                                                            <EditIcon className="edit-icon mr-2" onClick={() => handleEdit(item.id, item.userId)} />
                                                                            <DeleteForeverIcon className="delete-icon" onClick={() => deleteListPhotos(item.id)} />
                                                                        </div></center>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                    }
                                                    {
                                                        totalPage > 1 && (
                                                            <div className='col-12'>
                                                                <Stack spacing={2}>
                                                                    <Pagination count={totalPage} page={page} onChange={handleChange} />
                                                                </Stack>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : (
                                                <div className='no_data_div'>
                                                    <img src='/images/blue-logo.png' alt='' className='img-fluid mb-4' />
                                                    <h5 className='no_data_h5'>Technology Not Available</h5>
                                                </div>
                                            )
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel value="2" className="grybg">
                                    <InterestedCanditatesList />
                                </TabPanel>
                            </TabContext>
                        </section>
                    </Grid>
                </Grid>
            </main>
            <React.Fragment>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={handleClose}
                >
                    <Inventoradd userId={userId} investorId={investorId} handleClose={handleClose} htmlContent={htmlContent} />
                </Dialog>
            </React.Fragment>
        </>
    )
}

export default Inventor
