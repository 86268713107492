import React, { useContext, useState } from 'react'
import DataContext from '../../../../context/DataContext'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import apiService from '../../../../api/apiService';
import { Button, Dialog } from '@mui/material';
import notifyService from '../../../../api/notifySerivce';
const Viewjurynamelist = ({ setSelectedClaimIds, viewJuryData,challengeId,setOpenJury,challengeListItem,solutionId }) => {
    // console.log(viewJuryData);

    const { setPageLoading } = useContext(DataContext)
    const [open, setOpen] = useState(false);
    const [juryId,setJuryId] =useState('')
    const handleUnassign = (id) => {
        setOpen(true);
        setJuryId(id)
        // setPageLoading(true)
        // var req = {}
        // apiService(`scoring/jury/unassign?juryid=${id}&challengeid=${challengeId}`, req, 'get').then((result) => {
        //     setPageLoading(false)
        //     if (result) {
        //         if (result.data.responseStatus === 'Success') {
        //             // setJuryIds([])
        //             // reset()
        //             setOpen(false);
        //         }
        //     }
        // }).catch((err) => {

        // });
    }
    const handleClose = () => {
        setOpen(false);
    }
    const UnAssigned = () =>{
         setPageLoading(true)
        apiService(`scoring/jury/unassign?juryid=${juryId}&challengeid=${challengeId}&solutionid=${solutionId}`, '', 'delete').then((result) => {
            setPageLoading(false)
            if (result) {
                if (result.data.responseStatus === 'Success') {
                    notifyService('Success', 'Success', 'Jury UnAssigned !!')
                    challengeListItem()
                    setOpen(false);
                    setOpenJury(false)
                    setSelectedClaimIds('')
                }
            }
        }).catch((err) => {

        });
    }
    return (
        <main>
            <div className='div_header'>
                <h6>View Jury</h6>
            </div>
            {
                (viewJuryData.length) ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell className='text-center'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {viewJuryData.map((list, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>

                                        <TableCell>
                                            {/* {list.juryList && list.juryList.length > 0
                                                ? list.juryList.map(jury => jury.name).join(', ')
                                                : '-'} */}
                                                {list.name}
                                        </TableCell>
                                        <TableCell className='text-center'>
                                            <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => handleUnassign(list.id)} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <div className='nodata_tag'>
                        <p>Jury Details is empty</p>
                    </div>
                )
            }
              <Dialog
                       maxWidth="sm"
                        fullWidth
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className='warning-jury text-center mt-3'>
                            <img src="/images/attention -man.gif" alt="" />
                            <h6>Are you Sure you Want To UnAssign a Jury ?</h6>
                            <p>This will remove the jury's score for the respective application.</p>
                        </div>
                        
                        <div className='UnAssigned'>
                        <Button
                                variant="contained" 
                                onClick={() => UnAssigned()}
                            >
                              UnAssign
                            </Button>
                            </div>
                    </Dialog>
        </main>
    )
}

export default Viewjurynamelist