import React from 'react'
import './undermaintenance.css'
import { useNavigate } from "react-router-dom";
import apiService from '../../api/apiService';
import { Helmet } from 'react-helmet';
const Undermaintenance = () => {
    const navigate = useNavigate();
    const upApi = () => {        
        apiService(`/actuator/health`, '', 'get').then((res) => {
            navigate(-1)
        })
    }
    return (
        <>
        <Helmet>
            <title>Open Innovation Portal | Under maintenance</title>
        </Helmet>
            <div className="under_maintaine">
                <div className="boxunder">
                    <div className="animation">
                        <div className="one spin-one"></div>
                        <div className="two spin-two"></div>
                        <div className="three spin-one"></div>
                    </div>
                    <h1>Under maintenance</h1>
                    <p>We apologize for the inconvenience, but our website is currently undergoing scheduled maintenance. We'll be back online as soon as possible.</p>
                    <p>If you need immediate assistance, please contact us at tanfund@startuptn.in</p>
                    <p>Thank you for your patience.</p>
                    <p className='refbtn' onClick={upApi} target="_blank">Refresh</p>
                </div>
            </div>
        </>
    )
}

export default Undermaintenance