import React, { useContext, useState } from 'react'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Autocomplete, Button, Divider, FormLabel, Grid, TextField } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import Rejectreason from '../../../common-pages/Rejectreason';
import { LoadingButton } from '@mui/lab';
import downloadExcel from '../../../../hooks/downloadExcel';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import SearchInput from '../../../common-pages/SearchInput';

const Solutionlist = ({ name }) => {
    const { navigator, setPageLoading } = useContext(DataContext)
    const [page, setPage] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState(1);
    let role = localStorage.getItem('role')
    const [title, setTitle] = useState('')
    useDidMountEffect(() => {
        handleSoluctionList()
        // challengesApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, title])
    const [challengesDataList, setChallengesDataList] = useState([])
    const challengesApi = () => {
        setChallengesDataList([])
        let path
        if (role === 'Challenge Provider') {
            path = `challenge/user/title/list`
        } else {
            path = `solution/user/title/list`
        }
        apiService(path, '', 'get').then((res) => {
            if (res) {
                if (res.data) {
                    setChallengesDataList(res.data)
                }
            }
        })
    }

    const handleSelectChallenges = (value) => {
        setTitle(value)
        // handleSoluctionList(value)
    }
    const handleSearch = (searchValue) => {
        setTitle(searchValue);
    };
    let challengeCategoryVal
    if(name === "corporate"){
      challengeCategoryVal = 0
    }else if(name === "government"){
      challengeCategoryVal = 1
    }else if(name === "msme"){
      challengeCategoryVal = 2
    }else if(name === "enablers"){
      challengeCategoryVal = 3
    }
    const [solutionListValue, getSoluctionValue] = useState([])
    const handleSoluctionList = () => {
        setPageLoading(true)
        let path
        let challengeProvider
        var req
        if (role === 'Challenge Provider') {
            path = 'solution/approval/list'
            challengeProvider = true
            req = {
                "userId": localStorage.getItem('userId'),
                "listSize": 12,
                "pageNumber": page,
                "challengeTitle": title,
                "challengeProvider": challengeProvider
            }
        } else if (role === 'Project Management Team') {
            path = 'admin/solution/list'
            challengeProvider = false
            req = {
                "userId": localStorage.getItem('userId'),
                "listSize": 12,
                "pageNumber": page,
                "challengeTitle": title,
                "challengeProvider": challengeProvider,
                "challengeCategory": challengeCategoryVal
            }
        } else {
            path = 'solution/list'
            challengeProvider = false
            req = {
                "userId": localStorage.getItem('userId'),
                "listSize": 12,
                "pageNumber": page,
                "challengeTitle": title,
                "challengeProvider": challengeProvider,
                "challengeCategory": challengeCategoryVal
            }
        }
        getSoluctionValue([])

        apiService(path, req, 'post').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data) {
                    if (res.data.responseModelList) {
                        getSoluctionValue(res.data.responseModelList)
                        setTotalPage(res.data.totalPages)
                    }
                }
            }
        })
    }
    const handleChange = (event, value) => {
        setPage(value);
    };
    const handleSolutionDetails = (id) => {
        if (role === 'Project Management Team') {
            navigator(`/admin/${name}/challenge-status/solution-provider/${btoa(id)}`)
        } else {
            navigator(`${name}/challenges/solution-details/${btoa(id)}`)
        }
    }
    const resetFiliter = () => {
        setTitle('')
        // handleSoluctionList()
    }
    const [loading, setLoading] = useState(false);
    const approvalApi = (solutionId, challengeId) => {
        setLoading(true)
        var req = {
            "challengeId": challengeId,
            "solutionId": solutionId,
            "approval": 1,
        }
        apiService('admin/solution/shortlist', req, 'post').then((res) => {
            setLoading(false)
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', res.data.responseMessage)
                    handleSoluctionList()
                }
            }
        })
    }
    const [open, setOpen] = React.useState(false);
    const [solutionId, setSolutionId] = useState('');
    const [challengeId, setChallengeId] = useState('');
    const handleClickOpen = (solutionId, challengeId) => {
        setOpen(true);
        setSolutionId(solutionId)
        setChallengeId(challengeId)
    };
    const handleClose = () => {
        setOpen(false);
        handleSoluctionList()
    };
    const exportExcel = (download) => {
        // setDownloadValue(download)
        setLoading(true);
        // setPageLoading(true);

        var req = {
            "download": download,
            "userId": localStorage.getItem('userId'),
            "listSize": 12,
            "pageNumber": page,
            "challengeTitle": title,
            "challengeCategory":challengeCategoryVal
        }
  
        
        apiService('admin/solution/list', req, 'downloadpost').then((res) => {
            setLoading(false);
            // setPageLoading(false);
            if (res) {
                if (res.data) {
                    downloadExcel(res.data, 'solution_')
                    // setDownloadValue('')
                }
            }
        })
    }
    return (
        <>
            {
                role === 'Project Management Team' && (
                    <div className='mb-3'>
                        <Divider />
                        <div className="d-flex w-100 py-3">
                            <SearchInput setValue={setTitle} lable={'Challenge Title'} />
                            <div className='ml-auto'>
                                <>
                                    {/* <Button className='c-blue' startIcon={<FilterAltOutlinedIcon />} type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Filter</Button> */}
                                    <form className="d-flex align-item-center">

                                        {/* {
                                            (title !== '') && (
                                                <div className='col text-center'>
                                                    <Button onClick={resetFiliter} variant="outlined" color="error" className='ml-3'>Reset</Button>
                                                </div>
                                            )
                                        } */}
                                        {
                                            (role === 'Project Management Team' && solutionListValue.length > 0 ) && (
                                                <div>
                                                    <LoadingButton
                                                        loadingPosition="start"
                                                        onClick={() => exportExcel('Yes')}
                                                        loading={loading}
                                                        variant='outlined'
                                                        // className="ml-3 b-blue px-3"z
                                                        startIcon={<CloudDownloadIcon />}
                                                    >
                                                        <span>EXPORT EXCEL</span>
                                                    </LoadingButton>
                                                </div>
                                            )
                                        }
                                    </form>

                                </>




                                {/* <Button className='ml-3 c-blue' startIcon={<FilterAltOutlinedIcon />} onClick={() => exportExcel('Yes')} >Excel</Button> */}
                            </div>
                        </div>
                        <Divider />
                    </div>
                )
            }
            {/* <div className="filter_select my-3 collapse" id="collapseExample">
               
            </div> */}
            {
                role === 'Project Management Team' ? (
                    <>
                        {
                            solutionListValue.length > 0 ? (
                                <div className="row mt-3">
                                    <div className='col-12'>
                                        <p className='showPage'>Showing Page <span>{page}</span> of <span>{totalPage}</span></p>
                                    </div>
                                    {
                                        solutionListValue.map((list, index) => (
                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                {
                                                    role === 'Challenge Provider' ? (
                                                        <div className='challege_status_div'>
                                                            <div>
                                                                <h6>{list.challengeTitle}</h6>
                                                            </div>
                                                            <div className='status_details'>
                                                                {
                                                                    list.participant.map((res, index) => (
                                                                        <p><span className='c-gray'> Participant Name  :</span> <span className='c-blue font-lg f-weight-600'>{res.participantName}</span></p>
                                                                    ))
                                                                }
                                                                <p><span className='c-gray'>Email :</span> <span className='c-blue font-lg f-weight-600'>{list.email}</span></p>
                                                                <p><span className='c-gray'>Shortlisted :</span> <span className='c-green font-lg f-weight-600'>{list.shortlisted === 1 && <>Yes</>}</span>
                                                                    <span className='c-origin font-lg f-weight-600'>{list.shortlisted === 0 && <>Pending for Review</>}</span>
                                                                    <span className='c-red font-lg f-weight-600'>{list.shortlisted === 2 && <>No</>}</span>
                                                                </p>
                                                                {/* {
                                                            list.shortlisted === 2 && (<p ><span className='c-gray'>Remarks :</span>  <span >{list.remarks}</span></p>)
                                                        } */}

                                                            </div>
                                                            <div className='button_div text-center px-3'>
                                                                <Button className='view_live_btn' onClick={() => { handleSolutionDetails(list.id) }}>View Details</Button>
                                                            </div>
                                                            {/* <div className='button_div text-center px-3'>
                                                        {
                                                            list.shortlisted === 0 && (
                                                                <>
                                                                    <LoadingButton
                                                                        loadingPosition="start"
                                                                        onClick={() => approvalApi(list.id, list.challengeId)}
                                                                        loading={loading}
                                                                        className="green_btn"
                                                                    >
                                                                        <span>Approve</span>
                                                                    </LoadingButton>
                                                                    <Button className='red_btn' onClick={() => handleClickOpen(list.id, list.challengeId)}>Reject</Button>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            (list.shortlisted === 1 || list.shortlisted === 2) && (
                                                                <p className='text-center'>
                                                                    <span className='c-green font-lg f-weight-600'>{list.shortlisted === 1 && <>Approved</>}</span>
                                                                    <span className='c-red font-lg f-weight-600'>{list.shortlisted === 2 && <>Rejected</>}</span>
                                                                </p>
                                                            )
                                                        } 
                                                    </div>*/}

                                                        </div>
                                                    ) : (
                                                        <div className='challege_status_div'>
                                                            <div>
                                                                <h6>{list.challengeTitle}</h6>
                                                            </div>
                                                            <div className='status_details'>
                                                                {
                                                                    list.participant.map((res, index) => (
                                                                        <p><span className='c-gray'> Participant Name :</span> <span className='c-blue font-lg f-weight-600'>{res.participantName}</span></p>
                                                                    ))
                                                                }
                                                                <p><span className='c-gray'>Project Management Team :</span>  <span className='c-blue font-lg f-weight-600'>{list.pmtApproval === 0 && <>Pending for Approval</>}</span>
                                                                    <span className='c-origin font-lg f-weight-600'>{list.pmtApproval === 1 && <>On Hold</>}</span>
                                                                    <span className='c-green font-lg f-weight-600'>{list.pmtApproval === 2 && <>Approved</>}</span>
                                                                    <span className='c-red font-lg f-weight-600'>{list.pmtApproval === 3 && <>Rejected</>}</span></p>
                                                                {
                                                                    list.pmtApproval === 3 && (<p ><span className='c-gray'>PMT Remarks :</span>  <span >{list.pmtRemarks}</span></p>)
                                                                }
                                                                {
                                                                    list.pmtApproval !== 3 && (
                                                                        <p><span className='c-gray'>Shortlisted :</span> <span className='c-green font-lg f-weight-600'>{list.shortlisted === 1 && <>Yes</>}</span>
                                                                            <span className='c-origin font-lg f-weight-600'>{list.shortlisted === 0 && <>Pending for Review</>}</span>
                                                                            <span className='c-red font-lg f-weight-600'>{list.shortlisted === 2 && <>No</>}</span>
                                                                        </p>
                                                                    )
                                                                }

                                                                {
                                                                    list.shortlisted === 2 && (<p ><span className='c-gray'>Remarks :</span>  <span >{list.remarks}</span></p>)
                                                                }

                                                            </div>
                                                            <div className='button_div text-center p-3'>
                                                                <Button className='view_live_btn' onClick={() => { handleSolutionDetails(list.id) }}>View Details</Button>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        ))
                                    }
                                    {
                                        totalPage > 1 && (
                                            <div className='col-12'>
                                                <Stack spacing={2}>
                                                    <Pagination count={totalPage} page={page} onChange={handleChange} />
                                                </Stack>
                                            </div>
                                        )
                                    }

                                </div>
                            ) : (
                                <div className='no_data_div'>
                                    <img src='/images/blue-logo.png' alt='' className='img-fluid mb-4' />
                                    <h5 className='no_data_h5'>Applications Not Available</h5>
                                </div>
                            )
                        }
                    </>
                ) : (
                    <Grid container direction="row" justifyContent="center" className=''>
                        <Grid item lg={3} md={3} sm={12} xs={12} className='mt-3'>
                            <form className="signin-form filter_form">
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='form-group mb-3 text-center'>
                                            <FormLabel shrink="true" className='input_Lable'>Challenge Title </FormLabel>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                sx={{ background: 'white' }}
                                                ListboxProps={{
                                                    style: { textAlign: 'left' }
                                                  }}
                                                options={challengesDataList}
                                                value={title}
                                                onChange={(e, selectedOptions) => handleSelectChallenges(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} placeholder="Filter by Challenge Title" />}
                                            />
                                            {/* <FormHelperText className='text-danger'>{errors.challengeTitle && errors.challengeTitle.message}</FormHelperText> */}
                                        </div>
                                    </div>
                                    {
                                        (title !== '') && (
                                            <div className='col-12 text-center'>
                                                {/* {(role === 'Project Management Team' && solutionListValue.length > 0) && (
                                                    <LoadingButton
                                                        loadingPosition="start"
                                                        onClick={() => exportExcel('Yes')}
                                                        loading={loading}
                                                        className="b-blue px-3"
                                                        startIcon={<CloudDownloadIcon />}
                                                    >
                                                        <span>Excel</span>
                                                    </LoadingButton>
                                                )
                                                } */}
                                                <Button onClick={resetFiliter} variant="outlined" color="error" className='ml-3'>Reset</Button>
                                            </div>
                                        )
                                    }
                                </div>
                            </form>
                        </Grid>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <div>
                                {
                                    solutionListValue.length > 0 ? (
                                        <div className="row mt-3">
                                            <div className='col-12'>
                                                <p className='showPage'>Showing Page <span>{page}</span> of <span>{totalPage}</span></p>
                                            </div>
                                            {
                                                solutionListValue.map((list, index) => (
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        {
                                                            role === 'Challenge Provider' ? (
                                                                <div className='challege_status_div'>
                                                                    <div>
                                                                        <h6>{list.challengeTitle}</h6>
                                                                    </div>
                                                                    <div className='status_details'>
                                                                        {
                                                                            list.participant.map((res, index) => (
                                                                                <p><span className='c-gray'> Participant Name {index + 1} :</span> <span className='c-blue font-lg f-weight-600'>{res.participantName}</span></p>
                                                                            ))
                                                                        }
                                                                        <p><span className='c-gray'>Email :</span> <span className='c-blue font-lg f-weight-600'>{list.email}</span></p>
                                                                        <p><span className='c-gray'>Shortlisted :</span> <span className='c-green font-lg f-weight-600'>{list.shortlisted === 1 && <>Yes</>}</span>
                                                                            <span className='c-origin font-lg f-weight-600'>{list.shortlisted === 0 && <>Pending for Review</>}</span>
                                                                            <span className='c-red font-lg f-weight-600'>{list.shortlisted === 2 && <>No</>}</span>
                                                                        </p>
                                                                        {/* {
                                                                list.shortlisted === 2 && (<p ><span className='c-gray'>Remarks :</span>  <span >{list.remarks}</span></p>)
                                                            } */}

                                                                    </div>
                                                                    <div className='button_div text-center px-3'>
                                                                        <Button className='view_live_btn' onClick={() => { handleSolutionDetails(list.id) }}>View Details</Button>
                                                                    </div>
                                                                    {/* <div className='button_div text-center px-3'>
                                                            {
                                                                list.shortlisted === 0 && (
                                                                    <>
                                                                        <LoadingButton
                                                                            loadingPosition="start"
                                                                            onClick={() => approvalApi(list.id, list.challengeId)}
                                                                            loading={loading}
                                                                            className="green_btn"
                                                                        >
                                                                            <span>Approve</span>
                                                                        </LoadingButton>
                                                                        <Button className='red_btn' onClick={() => handleClickOpen(list.id, list.challengeId)}>Reject</Button>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                (list.shortlisted === 1 || list.shortlisted === 2) && (
                                                                    <p className='text-center'>
                                                                        <span className='c-green font-lg f-weight-600'>{list.shortlisted === 1 && <>Approved</>}</span>
                                                                        <span className='c-red font-lg f-weight-600'>{list.shortlisted === 2 && <>Rejected</>}</span>
                                                                    </p>
                                                                )
                                                            } 
                                                        </div>*/}

                                                                </div>
                                                            ) : (
                                                                <div className='challege_status_div'>
                                                                    <div>
                                                                        <h6>{list.challengeTitle}</h6>
                                                                    </div>
                                                                    <div className='status_details'>
                                                                        {
                                                                            list.participant.map((res, index) => (
                                                                                <p><span className='c-gray'> Participant Name {index + 1} :</span> <span className='c-blue font-lg f-weight-600'>{res.participantName}</span></p>
                                                                            ))
                                                                        }
                                                                        <p><span className='c-gray'>Project Management Team :</span>  <span className='c-blue font-lg f-weight-600'>{list.pmtApproval === 0 && <>Pending for Approval</>}</span>
                                                                            <span className='c-origin font-lg f-weight-600'>{list.pmtApproval === 1 && <>On Hold</>}</span>
                                                                            <span className='c-green font-lg f-weight-600'>{list.pmtApproval === 2 && <>Approved</>}</span>
                                                                            <span className='c-red font-lg f-weight-600'>{list.pmtApproval === 3 && <>Rejected</>}</span></p>
                                                                        {
                                                                            list.pmtApproval === 3 && (<p ><span className='c-gray'>PMT Remarks :</span>  <span >{list.pmtRemarks}</span></p>)
                                                                        }
                                                                        <p><span className='c-gray'>Shortlisted :</span> <span className='c-green font-lg f-weight-600'>{list.shortlisted === 1 && <>Yes</>}</span>
                                                                            <span className='c-origin font-lg f-weight-600'>{list.shortlisted === 0 && <>Pending for Review</>}</span>
                                                                            <span className='c-red font-lg f-weight-600'>{list.shortlisted === 2 && <>No</>}</span>
                                                                        </p>
                                                                        {
                                                                            list.shortlisted === 2 && (<p ><span className='c-gray'>Remarks :</span>  <span >{list.remarks}</span></p>)
                                                                        }

                                                                    </div>
                                                                    <div className='button_div text-center p-3'>
                                                                        <Button className='view_live_btn' onClick={() => { handleSolutionDetails(list.id) }}>View Details</Button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                ))
                                            }
                                            {
                                                totalPage > 1 && (
                                                    <div className='col-12'>
                                                        <Stack spacing={2}>
                                                            <Pagination count={totalPage} page={page} onChange={handleChange} />
                                                        </Stack>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    ) : (
                                        <div className='no_data_div'>
                                            <img src='/images/blue-logo.png' alt='' className='img-fluid mb-4' />
                                            <h5 className='no_data_h5'>Applications Not Available</h5>
                                        </div>
                                    )
                                }
                            </div>
                        </Grid>
                    </Grid>
                )
            }


            {/* <Rejectreason open={open} handleClose={handleClose} solutionId={solutionId} challengeId={challengeId} rejectLbl="Challenge Reject" /> */}
        </>
    )
}

export default Solutionlist