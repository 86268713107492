import React, { useState } from 'react'
import { TabContext, TabList, TabPanel,Autocomplete } from '@mui/lab';
import { Box, Tab,FormLabel } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Jurylist from './Jurylist';
import Associatejury from './Associatejury';
import SearchInput from '../../../common-pages/SearchInput';
const Jurylayout = () => {
    const [navTabValue, setNavTabValue] = useState('1');
    const handleNavTabChange = (event, newValue) => {
        setNavTabValue(newValue);
    };
    const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({});
    return (
        <div>
                   
                
                    <div className="grybg">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={navTabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleNavTabChange} aria-label="lab API tabs example">
                                        <Tab label="Jury Details" value="1" />
                                        <Tab label="Associate Jury" value="2" />
                                        {/* <Tab label="Rounds Completed" value="3" /> */}
                                        {/* <Tab label="Startups Without Rounds" value="4" /> */}
                                    </TabList>
                                </Box>
                                <TabPanel value="1" sx={{ padding: '10px 0px' }}>
                                    <Jurylist />
                                </TabPanel>
                                <TabPanel value="2" sx={{ padding: '10px 0px' }}>
                                    <Associatejury />
                                </TabPanel>
                            </TabContext>
                        </Box>

        </div>
        </div>
    )
}

export default Jurylayout