import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Modal,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import apiService from "../../../api/apiService";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { useParams } from "react-router-dom";
import DataContext from "../../../context/DataContext";
import notifyService from "../../../api/notifySerivce";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
// import Inventoradd from './Inventoradd';
import Inventoradd from "../inventor-page/Inventoradd";
import DOMPurify from "dompurify";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Troubleshoot } from "@mui/icons-material";
import TechmarketReject from "../../admin-module/admin-techmarket-page/TechmarketReject";
import Viewimage from "../../../api/Viewimage";
import { SwiperSlide } from "swiper/react";
import Swiper from "swiper";
import { Autoplay, Navigation } from "swiper/modules";

const TechmarketDetail = ({ htmlContent }) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  const { navigator } = useContext(DataContext);
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  let roleName = localStorage.getItem("role");
  let userID = localStorage.getItem("userId");
  const { id, userid } = useParams();
  let techmarketId;
  let userId;
  if (id !== undefined) {
    techmarketId = atob(id);
  }
  if (userid !== undefined) {
    userId = atob(userid);
  }
  const [formData, setFormData] = useState();
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [openmsg, setOpenmsg] = React.useState(false);
  // const handleOpensucessmsg = () => setOpenmsg(true);
  const handleClosesucessmsg = () => setOpenmsg(false);
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({ values: formData });
  useDidMountEffect(() => {
    console.log(roleName);
    // dropdownValueApi(
    getApi();
    // inventorList();
    // getApiForm();
  }, [page]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  const getApi = () => {
    apiService(
      `techmarket/get?id=${techmarketId}&userId=${userID ? userID : 0}`,
      "",
      "get"
    ).then((res) => {
      if (res) {
        if (res.data) {
          // res.data.techMarketImage.forEach(element => {
          //     addPhotoDetails()
          // });
          setFormData(res.data);
          debugger;
        }
      }
    });
  };
  const handleSave = (data) => {
    if (data === 1) {
      var getform = formData;
      getform.approvalStatus = data;
      apiService(`techmarket/save`, getform, "post").then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            notifyService("success", res.data.responseMessage);
            handleBack();
          }
        }
      });
    } else {
      handleRejectOpen();
    }
  };

  const handleBack = () => {
    if (roleName === "Project Management Team") {
      navigator("admin/tech-market");
    } else if (roleName === "Inventor") {
      navigator("inventor/list");
    } else {
      navigator("techmarket");
    }
  };
  let userIds = localStorage.getItem("userId");
  const [inventorListValue, setInventorListValue] = useState([]);
  const inventorList = () => {
    setInventorListValue([]);
    var req = {
      listSize: 12,
      pageNumber: page,
      userId:userIds
    };
    debugger;
    apiService(`techmarket/list`, req, "post").then((res) => {
      if (res) {
        if (res.data) {
          if (res.data.responseModelList) {
            setInventorListValue(res.data.responseModelList);
            setTotalPage(res.data.totalPages);
          }
        }
      }
    });
  };
  const [investorId, setInvestorId] = useState(0);
  const handleEdit = (id, userId) => {
    setOpen(true);
    setInvestorId(id);
    // getApi(id)
  };
  const handleClose = () => {
    setOpen(false);
    // clearErrors()
    // reset()
    inventorList();
  };
  const [openModal, setOpenModal] = useState(false);
  const [maxWidth2, setMaxWidth2] = React.useState("md");
  const handleClickOpenModal = () => {
    setOpenModal(true);
    var getform = techmarketId;
    debugger;
    apiService(
      `techmarket/applyform/get?id=${techmarketId}`,
      getform,
      "post"
    ).then((res) => {
      if (res) {
        if (res.data) {
          setFormData(res.data);
          debugger;
        }
      }
    });
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onSubmit = async (data) => {
    var getform = data;
    getform.id = techmarketId;
    debugger;
    apiService(`techmarket/applyform/save`, getform, "post").then((res) => {
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("Success", "Success");
          setOpenModal(false);
          setOpenmsg(true);
        }
      }
    });
  };
  const onError = (errors, e) => {
    // notifyService('danger', 'Form is invalid', 'Please check the mandatory fields')
  };
  const [openReject, setOpenReject] = React.useState(false);
  const handleRejectOpen = () => {
    setOpenReject(true);
  };
  const handleRejectClose = () => {
    setOpenReject(false);
  };

  const [openImg, setOpenImg] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpenImg(true);
  };
  const handleClickClose = () => {
    setOpenImg(false);
  };

  const {
    fields: photosFields,
    append: photosAppend,
    remove: photosRemove,
  } = useFieldArray({
    control,
    name: "techMarketImage",
  });

  const onPdfClick = () => {
    const pdfUrl = formData && formData.pdfURL;
    debugger;
    window.open(pdfUrl, "_blank");
    // const link = document.createElement("a");
    // link.href = pdfUrl;
    // link.download = "Pitch Deck.pdf";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };
  let token = localStorage.getItem("token");
  const handleLoginPage = () => {
    navigator("/sign-in");
  };
  return (
    <>
      <section className="solution_details_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="challenge_banner_details">
                <div className="d-inline">
                  <h1 className="mb-5 d-inline">Technology Details</h1>
                  {/* {inventorListValue && inventorListValue.map((item, index) => ( */}
                  {(roleName === "Project Management Team" ||
                    roleName === "Admin") &&
                    formData &&
                    formData.approvalStatus === 0 && (
                      <Button
                        className="ml-3"
                        size="small"
                        variant="contained"
                        color="secondary"
                        startIcon={<EditIcon />}
                        onClick={() => handleEdit(techmarketId, userId)}
                      >
                        Edit
                      </Button>
                    )}
                </div>
                {/* ))
                        } */}
                <div className="challenge_intro">
                  {/* <h4 className='mb-4'><span>Challenge</span> Introduction</h4> */}
                  {/* <p>{getValue.challengeIntroduction}</p> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: getValue.challengeIntroduction }}></div> */}
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12 mt-3">
                    <div className="solution_div">
                      {/* <p>
                        Title : <span>{formData && formData.title}</span>
                      </p> */}
                      <h1 className="mb-5 d-inline">
                        {formData && formData.title}
                      </h1>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-3">
                    <div className="solution_div">
                      <p>
                        Institute Name :{" "}
                        <span>{formData && formData.instituteName}</span>
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="swiper-container">
                  <Swiper
                    centeredSlides={false}
                    autoplay={{
                      delay: 10000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: false,
                    }}
                    navigation={false}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                  >
                    {
                      photosFields.length > 0 && (photosFields.map((list, index) => (
                        <SwiperSlide>
                          <div className='container'>
                            <center>
                              <div className='rec-box' key={index}>
                                {getValues(`techMarketImage.${index}.imageURL`) && (
                                  <>
                                    <div>
                                      <img
                                        src={getValues(`techMarketImage.${index}.imageURL`)}
                                        className="vehicle_logo"
                                        alt={`techMarketImage.${index}.imageName`}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          objectFit: 'contain',
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </center>
                          </div>
                        </SwiperSlide>
                      )))
                    }
                  </Swiper>
                </div> */}
                {/* {photosFields.map((field, index) => (
                    <div key={index} className="flex-item">

                    </div>
                  ))} */}
                <Divider />
                <div className="challenge_intro mt-3">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="solution_div">
                        <p>
                          Inventor Name :{" "}
                          <span>{formData && formData.inventorName}</span>
                        </p>
                      </div>
                    </div>
                    {(roleName === "Project Management Team" ||
                      roleName === "Admin" ||
                      roleName === "Inventor") && (
                      <>
                        <div className="col-lg-4 col-md-6 col-12">
                          <div className="solution_div">
                            <p>
                              Mobile Number :{" "}
                              <span>{formData && formData.mobileNumber}</span>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                          <div className="solution_div">
                            <p>
                              Email :{" "}
                              <span>{formData && formData.emailId}</span>
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="solution_div">
                        <p>
                          Patent Applied :{" "}
                          <span>
                            {formData && formData.patentApplied === true
                              ? "Yes"
                              : "No"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="solution_div">
                        <p>
                          Patent obtained:{" "}
                          <span>
                            {formData && formData.patentApplied === true
                              ? "Yes"
                              : "No"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="solution_div">
                        <p>
                          Patent Number :{" "}
                          <span>{formData && formData.patentNumber}</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="solution_div">
                        <p>
                          Sector : <span>{formData && formData.sector}</span>
                        </p>
                      </div>
                    </div>
                    {formData && formData.sector === "Others" && (
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="solution_div">
                          <p>
                            Sector Other :{" "}
                            <span>{formData && formData.sectorOther}</span>
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="solution_div">
                        <p>
                          Stage of Development :{" "}
                          <span>{formData && formData.stageOfDevelopment}</span>
                        </p>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 col-12">
                      <div className="solution_div">
                        <p>
                          Status :{" "}
                          <span>
                            {formData && formData.approvalStatus === 0 ? <b> Pending</b> : ""}
                            {formData && formData.approvalStatus === 2 ? <b> Reject</b> : ""}
                            {formData && formData.approvalStatus === 1 ? <b> Approved</b> : ""}
                          </span>
                        </p>
                      </div>
                    </div> */}
                    {formData && formData.approvalStatus === 2 && (
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="solution_div">
                          <p>
                            Remarks :{" "}
                            <span>
                              {formData && formData.remarks
                                ? formData.remarks
                                : `-`}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="solution_div">
                        <p>
                          PDF Description :{" "}
                          <span>
                            <Button variant="outlined" onClick={onPdfClick}>
                              View Document
                            </Button>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-12 col-12">
                      <div className="solution_div">
                        <p>
                          Technology Images :
                          <span className="flex-container">
                            {photosFields.map((field, index) => (
                              <div key={index} className="flex-item">
                                {getValues(
                                  `techMarketImage.${index}.imageURL`
                                ) && (
                                  <>
                                    <div>
                                      <img
                                        src={getValues(
                                          `techMarketImage.${index}.imageURL`
                                        )}
                                        className="vehicle_logo"
                                        alt={`techMarketImage.${index}.imageName`}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="solution_div">
                        <p>Short Description : </p>
                        {formData && formData.shortDescription ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: formData.shortDescription,
                            }}
                          ></div>
                        ) : (
                          `-`
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Divider />
              </div>
            </div>
          </div>
          <div className="text-center mt-3">
            {!token && formData && formData.enableButton && (
              <Button className="green_btn" onClick={handleLoginPage}>
                Express Your Interest
              </Button>
            )}
            {roleName === "Admin" ||
              (roleName === "Project Management Team" &&
                (formData && formData.approvalStatus) === 0 && (
                  <>
                    <Button onClick={() => handleSave(1)} className="green_btn">
                      <span>Approve</span>
                    </Button>
                    <Button onClick={() => handleSave(2)} className="red_btn">
                      Reject
                    </Button>
                  </>
                ))}
            {(roleName === "Challenge Provider" ||
              roleName === "GOVT" ||
              roleName === "Solution Provider") && (
              <>
                {(formData && formData.approvalStatus) === 1 &&
                formData &&
                formData.enableButton === true ? (
                  <Button className="green_btn" onClick={handleClickOpenModal}>
                    Express Your Interest
                  </Button>
                ) : (
                  <Button className="gray_btn" disabled>
                    Interest Submitted
                  </Button>
                )}
              </>
            )}
            <Button className="yellow-btn ml-3" onClick={handleBack}>
              BACK
            </Button>
          </div>
        </div>
        <Viewimage
          viewImage={viewImage}
          open={openImg}
          handleClose={handleClickClose}
        />
      </section>
      <React.Fragment>
        {/* <Dialog
          fullScreen
          open={openModal}
          onClose={handleCloseModal}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative', background: '#2B377A' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseModal}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Expression Of Interest
              </Typography>
            </Toolbar>
          </AppBar>
        </Dialog> */}
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth2}
          open={openModal}
          onClose={handleCloseModal}
        >
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <DialogContent>
              <div className="container exp-intrest">
                <h4 className="mt-3">Expression of Interest</h4>
                <h6 className=" mt-5" style={{ lineHeight: " 25px" }}>
                  I, <b> {formData && formData.name} </b>, representative of M/s{" "}
                  <Controller
                    name="pocName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <TextField
                        placeholder=""
                        width={300}
                        type="text"
                        variant="standard"
                        {...field}
                        helperText={errors.pocName && errors.pocName.message}
                      />
                    )}
                  />
                  would like to get details of{" "}
                  <b>{formData && formData.title}</b>, invented by{" "}
                  <b>{formData && formData.inventorName}</b> and the reference
                  number of which is <b>{formData && formData.referenceNo}</b>.
                  <br />
                </h6>
                <h6 style={{ lineHeight: " 25px" }}>
                  Further, I undertake that the purpose of seeking such details
                  is not to transfer/divulge the information to any
                  individual/agency, who/which is not party to this expression,
                  or for the purposes of hindering/delaying/sabotaging the
                  patentability of the invention, or for the purpose of
                  furthering vested research interests in similar fields as the
                  invention.
                </h6>
                <br />
                <h6 style={{ lineHeight: " 25px" }}>
                  I seek this information for the purpose of
                  <Controller
                    name="purpose"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <TextField
                        placeholder=""
                        fullWidth
                        type="text"
                        {...field}
                        row={3}
                        multiline
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.purpose && errors.purpose.message}
                  </FormHelperText>
                </h6>
                <h6></h6>
                <Grid item lg={12} md={12} sm={12} xs={12} className="my-3">
                  <div className="d-flex">
                    <Controller
                      name="acceptTerms"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Requried" }}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          label=""
                          control={
                            // <Checkbox value={field.value} checked={field.value} />
                            <Checkbox
                              value={field.value}
                              checked={field.value}
                            />
                          }
                        />
                      )}
                    />
                    <h6 style={{ lineHeight: " 25px" }}>
                      I also understand that this expression is merely a request
                      to StartupTN to provide details of the above mentioned
                      invention and it, in no way, obliges the StartupTN in any
                      manner possible.
                    </h6>
                  </div>
                  <FormHelperText className="text-danger">
                    {errors.acceptTerms && errors.acceptTerms.message}
                  </FormHelperText>
                </Grid>
              </div>
            </DialogContent>
            <div className="text-center m-2">
              <Button onClick={handleCloseModal} className="yellow-btn mr-3">
                Close
              </Button>
              <Button type="submit" className="green_btn">
                Apply
              </Button>
            </div>
          </form>
        </Dialog>
      </React.Fragment>

      <React.Fragment>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
        >
          <Inventoradd
            investorId={investorId}
            handleClose={handleClose}
            htmlContent={htmlContent}
            userId={userId}
          />
        </Dialog>
      </React.Fragment>

      <Modal
        open={openmsg}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Thank you
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your Interest has been Submitted
          </Typography>
          <div className="text-center mt-4">
            <button onClick={handleClosesucessmsg} className="ani-btn">
              <span>Ok</span>
              <div className="liquid"></div>
            </button>
          </div>
        </Box>
      </Modal>
      <TechmarketReject
        openReject={openReject}
        handleRejectClose={handleRejectClose}
        handleBack={handleBack}
        formData={formData}
      />
    </>
  );
};

export default TechmarketDetail;
