import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from '@mui/icons-material/Delete';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import Jurysave from "./Jurysave";
// import DataContext from "../../../context/DataContext";
import TablePagination from "@mui/material/TablePagination";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { LoadingButton } from "@mui/lab";
import apiService from "../../../../api/apiService";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import Bulkupload from "../../admin-module/Bulkupload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import downloadExcel from "../../../../hooks/downloadExcel";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { Dialog } from "@mui/material";
import Juryadd from "./Juryadd";
import DataContext from "../../../../context/DataContext";
import notifyService from "../../../../api/notifySerivce";
const SearchinputDiv = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "15ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));
const Jurylist = () => {
    const {
        setPageLoading,
        // handleChangePage,
        // handleChangeRowsPerPage,
        // count,
        // page,
        // rowsPerPage,
        modalstyle,
    } = useContext(DataContext);
    const [partnerListData, setPartnerListData] = useState([]);
    const [searchInputValue, setSearchInputValue] = useState("");
    const roleName = localStorage.getItem("role");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    useDidMountEffect(() => {
        userListItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInputValue, rowsPerPage, page]);

    const handleSearch = (searchValue) => {
        setSearchInputValue(searchValue);
    };
    // const [rowsPerPage,rowsPerPage]=useState()
    // const [page,setPage]=useState()
    const [count, setCount] = useState(0);
    const userListItem = () => {
        setPartnerListData([]);
        setPageLoading(true)
        var req = {
            "roleId": 6,
            "listSize": rowsPerPage,
            "pageNumber": page + 1,
            "active": 1
        };
        apiService("scoring/jury/list", req, "post").then((res) => {
            setPageLoading(false);
            if (res) {
                setCount(res.data.count)
                if (res.data.responseModelList) {
                    setPartnerListData(res.data.responseModelList);
                } else {
                    setPartnerListData([]);
                }
            }
        });
    };
    const [deletId,setDeletId] = useState('')
    const deleteUser = (id) => {
        // debugger
        setDeletId(id)
        setOpenJury(true)
       
    }
    // pagination code

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        userListItem()
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        userListItem()
    };
    // const [loading, setLoading] = useState(false);

    const [userId, setUserId] = useState(0)
    const [open, setOpen] = useState(false);
    const handleOpen = (id) => {
        setOpen(true);
        setUserId(id)
    }
    const handleClose = () => {
        setOpen(false);
        userListItem()
    }
    // const [openSector, setOpenSector] = useState(false);
    // const [preferredSectors, setPreferredSectors] = useState([])
    // const handleViewSector = (value) => {
    //     setOpenSector(true)
    //     setPreferredSectors(value)
    // }
    // const handleSectorClose = () => {
    //     setOpenSector(false)
    //     setPreferredSectors([])
    // }
    const [openJury, setOpenJury] = useState(false);
    const handleCloseJury =() =>{
        setOpenJury(false)
    }
    const deletJury =() =>{
 apiService(`scoring/jury/remove?juryid=${deletId}`, '', 'get').then((res) => {
            if (res && res.data) {
               
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', res.data.responseMessage);
                    userListItem()
                    setOpenJury(false)
                }
            }
        }).catch((err) => {

        });
    }
    return (
        <section>
            <div className="container-fluid">
                <div className="form_div chart">
                    <div className="header_tag d-flex mb-3">
                        <Button
                            className="ml-auto"
                            variant="outlined"
                            onClick={() => handleOpen(0)}
                        >
                            + Add Jury
                        </Button>
                    </div>
                    <div className="table_tag">
                        {/* <div className="table_header_tag d-flex">
                            <SearchinputDiv
                                sx={{ display: { xs: "none", sm: "block" } }}
                                onChange={(e) => handleSearch(e.target.value)}
                            >
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    type="search"
                                    placeholder="Search by Name"
                                    inputProps={{ "aria-label": "Search by Name" }}
                                />
                            </SearchinputDiv>
                            <Button
                                className="ml-auto"
                                variant="outlined"
                                onClick={() => handleOpen(0)}
                            >
                                + Add Jury
                            </Button>
                        </div> */}
                        {partnerListData.length ? (
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>No</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email Id</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            {/* <TableCell align='center'>Action</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {partnerListData.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.email} {row.loggedin===true ? (<span className="logged_in">(Active)</span>) : (<span className="logged_not">(Not Logged In)</span>)} </TableCell>
                                                <TableCell>{row.phone}</TableCell>
                                                {/* <TableCell align='center'> */}
                                                    {/* <EditIcon style={{ fill: '#66bb6a', cursor: 'pointer' }} onClick={() => editValue(row.id)} /> */}
                                                    {/* <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => deleteUser(row.id)} /> */}
                                                {/* </TableCell> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <caption>
                                        <TablePagination
                                            component="div"
                                            count={count}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </caption>
                                </Table>
                            </TableContainer>
                        ) : (
                            <div className="nodata_tag">
                                <p>Jury list is empty</p>
                            </div>
                        )}
                    </div>
                    <Dialog
                        maxWidth={`lg`}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className="form_tag p-4">
                            <Juryadd handleClose={handleClose} userId={userId} />
                        </Box>
                    </Dialog>
                </div>
            </div>


            <Dialog
                       maxWidth="sm"
                        fullWidth
                        open={openJury}
                        onClose={handleCloseJury}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className='warning-jury text-center mt-3'>
                            <img src="/images/attention -man.gif" alt="" />
                            <h6>Are You Sure, You Want To Delete Jury ?</h6>
                            {/* <p>This will remove the jury's score for the respective application</p> */}
                        </div>
                        
                        <div className='UnAssigned mt-3'>
                      

                            <Button
                            variant="outlined"
                            onClick={() => handleCloseJury()}
                            className="mr-3"
                            > 
                                Close
                            </Button>
                            <Button
                                variant="contained" 
                                onClick={() => deletJury()}
                                
                            >
                              Confirm
                            </Button>
                            </div>
                    </Dialog>
        </section>
    )
}

export default Jurylist