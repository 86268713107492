import { Button, Divider } from '@mui/material'
import React, { useContext, useState } from 'react'
// import DataContext from '../../../context/DataContext';
// import apiService from '../../../api/apiService';
import { useParams } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
// import useDidMountEffect from '../../../hooks/useDidMountEffect';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DataContext from '../../../../context/DataContext';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import Rejectreason from '../../../common-pages/Rejectreason';
import notifyService from '../../../../api/notifySerivce';
import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet';
import Viewimage from '../../../../api/Viewimage';
import { useForm } from 'react-hook-form';

const Solutiondetails = ({ name }) => {
    const { id } = useParams()
    const { handleSubmit, control, setValue, watch, getValues, clearErrors, reset, formState: { errors }, } = useForm({});
    let userId
    if (id !== undefined) {
        userId = atob(id)
    }
    const { navigator, setPageLoading } = useContext(DataContext)
    let role = localStorage.getItem('role')
    useDidMountEffect(() => {
        getApi()
    }, [])
    const [getValue, setGetValue] = useState({})
    const getApi = () => {
        setPageLoading(true)

        apiService(`solution/get?id=${userId}`, '', 'get').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data) {
                    setGetValue(res.data)

                }
            }
        })
    }
    const [loading, setLoading] = useState(false);
    const approvalApi = (solutionId, challengeId) => {
        setLoading(true)
        let req
        let path
        if (role === 'Project Management Team') {
            req = {
                "solutionId": solutionId,
                "approval": 2,
            }
            path = 'admin/solution/approve'
        } else {
            req = {
                "solutionId": solutionId,
                "approval": 1,
                "challengeId": challengeId
            }
            path = 'admin/solution/shortlist'
        }


        apiService(path, req, 'post').then((res) => {
            setLoading(false)
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', res.data.responseMessage)
                    handleBack()
                }
            }
        })
    }
    const [open, setOpen] = React.useState(false);
    const [solutionId, setSolutionId] = useState('');
    const [challengeId, setChallengeId] = useState('');
    const handleClickOpen = (solutionId, challengeId) => {
        setOpen(true);
        setChallengeId(challengeId)
        setSolutionId(solutionId)
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleBack = () => {
        if (role === 'Project Management Team') {
            navigator(`/admin/${name}/challenge-status`)
        } else {
            navigator(`${name}/challenges/list`)
        }
    }

    const [openImg, setOpenImg] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpenPdf = (value) => {
        debugger
        setViewImage(value)
        setOpenImg(true);
    };
    const handleClickClose = () => {
        setOpenImg(false);
    };

    const onPdfClick = () => {
        const pdfUrl = getValue.pitchDeckFileName
        debugger
        window.open(pdfUrl, "_blank");
        // const link = document.createElement("a");
        // link.href = pdfUrl;
        // link.download = "Pitch Deck.pdf";
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
    };

    return (
        <>
            <Helmet>
                <title>Open Innovation Portal | Challenges | Live-challenges | Solution-details</title>
            </Helmet>
            <section className='solution_details_section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-12'>
                            <div className='challenge_banner_details'>
                                <h1 className='mb-5'>{getValue.challengeTitle}</h1>
                                <div className='challenge_intro'>
                                    {/* <h4 className='mb-4'><span>Challenge</span> Introduction</h4> */}
                                    {/* <p>{getValue.challengeIntroduction}</p> */}
                                    <div dangerouslySetInnerHTML={{ __html: getValue.challengeIntroduction }}></div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-6 col-12'>
                                        <div className='solution_div'>
                                            <p>Start Date : <span>{getValue.startDate2}</span></p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12'>
                                        <div className='solution_div'>
                                            <p>End Date : <span>{getValue.endDate2}</span></p>
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div className='challenge_intro mt-3'>
                                    {/* <h4 className='mb-4'><span>Solution</span> Details</h4> */}
                                    <div className='row'>
                                        {
                                            getValue.participant && (
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <div className='solution_div d-flex'>
                                                        <p>Name : </p>
                                                        <p className='ml-2 mb-0'>
                                                            {
                                                                getValue.participant.map((res) => (
                                                                    <p className='c-blue'>{res.participantName}</p>
                                                                ))
                                                            }
                                                        </p>

                                                    </div>
                                                </div>

                                            )
                                        }
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='solution_div'>
                                                <p>Phone : <span>{getValue.phone}</span></p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='solution_div'>
                                                <p>Email : <span>{getValue.email}</span></p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='solution_div'>
                                                <p>Place of Residence : <span>{getValue.address}</span></p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='solution_div'>
                                                <p>District : <span>{getValue.district}</span></p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='solution_div'>
                                                <p>Solution Provider Type : <span>{getValue.type}</span></p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='solution_div'>
                                                <p>Other Solution Provider Type : <span>{getValue.typeOther ? getValue.typeOther : '-'}</span></p>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='solution_div'>
                                                <p>Solution : <span>{getValue.describeTheSolution}</span></p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='solution_div'>
                                                <p>Admin Approval : <span className='c-blue font-lg f-weight-600'>{getValue.pmtApproval === 0 && <>Pending for Approval</>}</span>
                                                    <span className='c-origin font-lg f-weight-600'>{getValue.pmtApproval === 1 && <>On Hold</>}</span>
                                                    <span className='c-green font-lg f-weight-600'>{getValue.pmtApproval === 2 && <>Approved</>}</span>
                                                    <span className='c-red font-lg f-weight-600'>{getValue.pmtApproval === 3 && <>Rejected</>}</span></p>
                                            </div>
                                        </div>
                                        {
                                            getValue.pmtApproval === 3 && (
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <div className='solution_div'>
                                                        <p>Admin Remarks : <span>{getValue.pmtRemarks}</span></p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='solution_div'>
                                                <p>Shortlisted : <span className='c-green font-lg f-weight-600'>{getValue.shortlisted === 1 && <>Yes</>}</span>
                                                    <span className='c-origin font-lg f-weight-600'>{getValue.shortlisted === 0 && <>Pending for Review</>}</span>
                                                    <span className='c-red font-lg f-weight-600'>{getValue.shortlisted === 2 && <>No</>}</span></p>
                                            </div>
                                        </div>
                                        {
                                            getValue.shortlisted === 2 && (
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <div className='solution_div'>
                                                        <p>Remarks : <span>{getValue.remarks}</span></p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="solution_div">
                                                <p>
                                                    Pitch Deck : <span><Button variant="outlined" onClick={onPdfClick} >View Document</Button></span>

                                                </p>
                                            </div>
                                        </div>
                                    </div>







                                </div>
                                <Divider />
                                <div className='challenge_intro mt-3'>
                                    <h4 className='mb-4'><span>POC</span> Details</h4>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='solution_div'>
                                                <p>Name : <span>{getValue.pocName}</span></p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='solution_div'>
                                                <p>Mobile Number : <span>{getValue.pocMobile}</span></p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='solution_div'>
                                                <p>Email Id : <span>{getValue.pocEmail}</span></p>
                                            </div>
                                        </div>
                                        {
                                            getValue.pocPhone && (
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <div className='solution_div'>
                                                        <p>Landline Number : <span>{getValue.pocPhone}</span></p>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                                <Divider />
                            </div>
                        </div>
                        {/* <div className='col-lg-4 col-md-6 col-12'>
                            <div className='apply_challenges'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='timeline_div'>
                                            <h5>Timeline</h5>
                                            <p>Start Date : <b>{getValue.startDate}</b>
                                            </p>
                                            <p>End Date : <b>{getValue.endDate}</b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                    <div className='text-center mt-3'>
                        {
                            ((role === 'Project Management Team' && getValue.pmtApproval === 0) || role === 'Challenge Provider' && getValue.shortlisted === 0 || role === 'GOVT' && getValue.shortlisted === 0 || role === "MSME's" && getValue.shortlisted === 0 || role === 'Other Enablers' && getValue.shortlisted === 0) && (
                                <>
                                    <LoadingButton
                                        loadingPosition="start"
                                        onClick={() => approvalApi(getValue.id, getValue.challengeId)}
                                        loading={loading}
                                        className="green_btn"
                                    >
                                        <span>Approve</span>
                                    </LoadingButton>
                                    <Button className='red_btn' onClick={() => handleClickOpen(getValue.id, getValue.challengeId)}>Reject</Button>
                                </>
                            )
                        }
                        <Button className='yellow-btn ml-3' onClick={handleBack}>BACK</Button>
                    </div>
                </div>
            </section>
            <Rejectreason open={open} handleClose={handleClose} solutionId={solutionId} challengeId={challengeId} rejectLbl="Solution Reject" handleBack={handleBack} />
            <Viewimage viewImage={viewImage} open={openImg} handleClose={handleClickClose} />
        </>

    )
}

export default Solutiondetails