import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { Button, Dialog } from "@mui/material";
// import Juryadd from "./Juryadd";
// import DataContext from "../../../context/DataContext";
// import Juryscoresave from "../../../jury-module/Juryscoresave";
// import Viewremarks from "../../../jury-module/Viewremarks";
import DataContext from "../../../../context/DataContext";
import Viewjuryscore from "./Viewjuryscore";
// import Juryscoresave from "./Juryscoresave";
// import Viewremarks from "./Viewremarks";

const Viewmarks = ({ onClose, id }) => {
    const { setPageLoading } = useContext(DataContext);
    const [solProvListListData, setSolProvListListData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    useDidMountEffect(() => {
        userListItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, page]);

    const [count, setCount] = useState(0);
    const userListItem = () => {
        setSolProvListListData([]);
        setPageLoading(true)
        var req = {
            "listSize": rowsPerPage,
            "pageNumber": page + 1,
            "challengeId": id
        };
        apiService("scoring/solprov/rankedlist", req, "post").then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data.solProvList) {
                    setCount(res.data.count)
                    setSolProvListListData(res.data.solProvList);
                } else {
                    setSolProvListListData([]);
                }
            }
        });
    };
    // pagination code
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        userListItem()
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        userListItem()
    };
    const [solutionId, setSolutionId] = useState(0)
    const [challengeId, setChallengeId] = useState(0)
    // const [challengeStatus, setChallengeStatus] = useState(0)
    const [open, setOpen] = useState(false);
    const handleOpen = (solutionid) => {
        setOpen(true);
        setSolutionId(solutionid)
        setChallengeId(id)
        debugger
        // setChallengeStatus(status)
    }
    const handleClose = () => {
        setOpen(false);
        // userListItem()
    }
    return (
        <section className="p-4">
            <div className="container-fluid">
                <div className="form_div chart">
                    <div className="header_tag d-flex mb-3">
                        <h5>Mark Details</h5>
                    </div>
                    <div className="table_tag">
                        {solProvListListData.length ? (
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Rank</TableCell>
                                            {/* <TableCell>Challenge Title</TableCell> */}
                                            <TableCell>Solution Provider Name</TableCell>
                                            <TableCell align='center'>Jurys Average Score</TableCell>
                                            {/* <TableCell align='center'>Status</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {solProvListListData.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                {/* <TableCell>{row.challengeTitle}</TableCell> */}
                                                <TableCell >
                                                    <span className="text-primary c-pointer" onClick={() => handleOpen(row.solutionId )}>{row.solProvName}</span>
                                                </TableCell>
                                                <TableCell align='center'>{row.score ? (row.score) : (`-`)}</TableCell>
                                                {/* <TableCell align='center'>
                                                    {
                                                        row.status === 0 && (<span className="inp-span">Inprogress</span>)
                                                    }
                                                    {
                                                        row.status === 1 && (<span className="comp-span">Submitted</span>)
                                                    }
                                                   </TableCell> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <caption>
                                        <TablePagination
                                            component="div"
                                            count={count}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </caption>
                                </Table>
                            </TableContainer>
                        ) : (
                            <div className="nodata_tag">
                                <p>Challenges list is empty</p>
                            </div>
                        )}
                    </div>
                    <div className="text-center mt-3">
                        <Button
                            variant="contained"
                            onClick={onClose}
                            sx={{
                                padding: "10px 23px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                                    backgroundColor: "#253b80 ",
                                }
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </div>
            <Dialog
                maxWidth={`lg`}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="form_tag p-4">
                    <Viewjuryscore handleClose={handleClose} solutionId={solutionId} challengeId={challengeId} />
                </Box>
            </Dialog>
        </section>
    )
}

export default Viewmarks