import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  FormLabel,
  Grid,
  Pagination,
  Stack,
  Tab,
  TextField,
} from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
import React, { useContext, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Controller, useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Helmet } from "react-helmet";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
// import Smarttext from '../../common-pages/Smarttext';
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import SolutionApplicationList from "../live-challenges-page/Solution-page/SolutionApplicationList";
import SearchInput from "../../common-pages/SearchInput";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import downloadExcel from "../../../hooks/downloadExcel";
const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "21ch",
      "&:focus": {
        width: "27ch",
      },
    },
  },
}));
const RequirementList = () => {
  const { navigator, setPageLoading } = useContext(DataContext);
  // const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({});
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  let roleId = localStorage.getItem("roleId");
  let roleName = localStorage.getItem("role");
  let role = localStorage.getItem("role");
  let token = localStorage.getItem("token");
  const [challengeCategory, setChallengeCategory] = useState(1);
  const [title, setTitle] = useState("");
  useDidMountEffect(() => {
    reqTitleApi();
  }, []);
  useDidMountEffect(() => {
    // setValue('requirementType', 1)
    requirementList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, challengeCategory, title]);
  const [requirementListValue, setRequirementListValue] = useState([]);
  const handleSearch = (searchValue) => {
    setTitle(searchValue);
  };
  const requirementList = () => {
    setRequirementListValue([]);
    setPageLoading(true);
    let path;
    let method;
    if (roleName === "GOVT") {
      path = "requirement/list";
      method = "post";
    } else if (role === "Solution Provider") {
      path = "requirement/home/list";
      method = "post";
    } else if (role === null) {
      path = "requirement/home/list";
      method = "unauthpost";
    }
    if (role === null) {
      var req = {
        listSize: 12,
        pageNumber: page,
        requirementTitle: title,
        requirementType: challengeCategory,
      };
    } else {
      var req = {
        listSize: 12,
        pageNumber: page,
        requirementType: challengeCategory,
        requirementTitle: title,
      };
    }

    apiService(path, req, method).then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.responseModelList) {
            setRequirementListValue(res.data.responseModelList);
            setTotalPage(res.data.totalPages);
          }
        }
      }
    });
  };
  const [tabValue, setTabValue] = React.useState("1");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleType = (value) => {
    setChallengeCategory(value);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleViewDetails = (id, userid) => {
    // if (roleId === null) {
    //     navigator('/sign-in')
    //     localStorage.setItem('click', 'post')
    // } else {
    //     navigator(`requirement/details/${btoa(id)}`)
    // }
    navigator(`requirement/details/${btoa(id)}/${btoa(userid)}`);
  };
  const handleRequirement = () => {
    if (roleId === null) {
      navigator("/sign-in");
      localStorage.setItem("click", "post");
    } else {
      navigator(`requirement/post-requirement`);
    }
  };
  const [requestTitleList, setRequestTitleList] = useState([]);
  const reqTitleApi = () => {
    let url;
    if (role === null) {
      url = `requirement/approve/title/list?entityType=${""}`;
    } else {
      url = `requirement/title/list?entityType=${""}`;
    }
    apiService(url, "", "get")
      .then((result) => {
        if (result) {
          if (result.data) {
            setRequestTitleList(result.data);
          }
        }
      })
      .catch((err) => {});
  };
  const handleSelectChallenges = (value) => {
    setTitle(value);
  };
  const resetFiliter = () => {
    setTitle("");
  };

  const exportExcel = () => {
    // setLoading(true);
    // apiService('export/users?roleid=3', '', 'download').then((res) => {
    //     setLoading(false);
    //     downloadExcel(res.data,'jury_')
    // })
    var req = {
      download: "yes",
      roleId: 3,
      active: 1,
    };

    apiService(
      "requirement/solution/application/list",
      req,
      "downloadpost"
    ).then((res) => {
      // setLoading(false);
      if (res) {
        if (res.data) {
          downloadExcel(res.data, "requirementlist_");
        }
      }
    });
  };

  return (
    <>
      <main className="live_challenges_main">
        <div className="container-fluid ">
          {roleName === "GOVT" && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="mb-4"
            >
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{ margin: "0px auto", textAlign: "center" }}
              >
                <img
                  src="/images/government-img.jpg"
                  className="cor-govt img-fluid"
                  alt=""
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <h2>Government Requirement</h2>
                <p className="cor-govt-txt">
                  Government organisations can post their requirements in the
                  portal to make use of innovative solutions for their public
                  service delivery from startups and innovators.
                </p>
              </Grid>
            </Grid>
          )}
          <div className="row">
            <div className="col-12">
              <div className={token ? "grybg" : "live_bgs"}>
                <TabContext sx={{ padding: "25px" }} value={tabValue}>
                  {(roleId || !roleId) && (
                    <Box
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                      }}
                    >
                      <TabList
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="scrollable"
                        aria-label="visible arrows tabs example"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            "&.Mui-disabled": { opacity: 0.3 },
                          },
                        }}
                      >
                        <Tab
                          className="tab_lable_name"
                          label="Live Requirements"
                          value="1"
                          onClick={() => handleType(1)}
                        />
                        <Tab
                          className="tab_lable_name"
                          label="Past Requirements"
                          value="2"
                          onClick={() => handleType(0)}
                        />
                        {roleName === "Solution Provider" && (
                          <Tab
                            className="tab_lable_name"
                            label={"Applied Requirements"}
                            value="3"
                          />
                        )}
                      </TabList>
                    </Box>
                  )}
                  <Box>
                    <TabPanel value="1" sx={{ padding: "15px 0px" }}>
                      <>
                        <div className="d-flex w-100">
                          <SearchInput
                            setValue={setTitle}
                            lable={"Requirement Title"}
                          />
                          <div className="ml-auto d-flex align-item-center">
                            {/* <form className="d-flex align-item-center">
                                                            <div className='col'>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    disablePortal
                                                                    sx={{ background: 'white', width: "250px" }}
                                                                    options={requestTitleList}
                                                                    value={title}
                                                                    onChange={(e, selectedOptions) => handleSelectChallenges(selectedOptions)}
                                                                    renderInput={(params) => <TextField {...params} label="Requirement Title" placeholder="Filter by Requirement Title" />}
                                                                />
                                                            </div>
                                                            {
                                                                (title !== '') && (
                                                                    <div className='col text-center'>
                                                                        <Button onClick={resetFiliter} variant="outlined" color="error" className='ml-3'>Reset</Button>
                                                                    </div>
                                                                )
                                                            }
                                                        </form> */}
                            {(roleName === "GOVT" || roleName === null) && (
                              <Button
                                startIcon={<AddIcon />}
                                className="yellow-btn-sm px-3 ml-3"
                                onClick={() => handleRequirement()}
                              >
                                Post Your Requirement
                              </Button>
                            )}
                          </div>
                        </div>
                        {requirementListValue.length > 0 ? (
                          <div className="row mt-3 ml-2">
                            <div className="col-12">
                              <p className="showPage">
                                Showing Page <span>{page}</span> of{" "}
                                <span>{totalPage}</span>
                              </p>
                            </div>
                            {requirementListValue.map((list, index) => (
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="live_req_list">
                                  <div className="text-center">
                                    <img
                                      src={
                                        list.requirementImageName
                                          ? list.requirementImageName
                                          : "/images/no-image-icon.png"
                                      }
                                      className="img-fluid req_img"
                                      alt=""
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Button
                                      className="challenges_pro_btn"
                                      onClick={() => {
                                        handleViewDetails(list.id, list.userId);
                                      }}
                                    >
                                      {list.requirementTitle}
                                    </Button>
                                  </div>

                                  <div className="d-flex mb-3">
                                    {/* <div>
                                                                                    <p className='c-blue f-weight-700 mb-1'>Start Date</p>
                                                                                    <p className='font-md c-origin f-weight-700'>{list.startDate2}</p>
                                                                                </div> */}
                                    <div className="">
                                      <p className="c-gray f-weight-700 mb-1">
                                        Department :{" "}
                                        <span className="font-md c-origin f-weight-700">
                                          {list.departmentName
                                            ? list.departmentName
                                            : `-`}
                                        </span>
                                      </p>
                                      <p className="c-gray f-weight-700 mb-1">
                                        Ends On{" "}
                                        <span className="font-md c-origin f-weight-700">
                                          {list.endDate2 ? list.endDate2 : `-`}
                                        </span>
                                      </p>

                                      {(roleName === "GOVT" ||
                                        roleName === "Admin" ||
                                        roleName ===
                                          "Project Management Team") && (
                                        <p className="f-weight-700 mb-1">
                                          <span className="c-gray">
                                            Status :
                                          </span>{" "}
                                          <span className="c-blue font-md f-weight-600">
                                            {list.pmtApproval === 0 && (
                                              <>Pending for Approval</>
                                            )}
                                          </span>
                                          <span className="c-green font-md f-weight-600">
                                            {list.pmtApproval === 1 && (
                                              <>Approved</>
                                            )}
                                          </span>
                                          <span className="c-origin font-md f-weight-600">
                                            {list.pmtApproval === 2 && (
                                              <>Rejected</>
                                            )}
                                          </span>
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <div className="view_div">
                                      <Button
                                        className="view_live_btn"
                                        onClick={() => {
                                          handleViewDetails(
                                            list.id,
                                            list.userId
                                          );
                                        }}
                                      >
                                        {" "}
                                        View Details
                                      </Button>
                                    </div>
                                    <div className="ml-auto">
                                      <p className="c-gray mb-0 font-sm">
                                        Last updated
                                      </p>
                                      <p className="c-blue font-sm">
                                        {list.lastUpdated}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {totalPage > 0 && (
                              <div className="col-12">
                                <Stack spacing={2}>
                                  <Pagination
                                    count={totalPage}
                                    page={page}
                                    onChange={handleChange}
                                  />
                                </Stack>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="no_data_div">
                            <img
                              src="/images/blue-logo.png"
                              alt=""
                              className="img-fluid mb-4"
                            />
                            <h5 className="no_data_h5">
                              Live Requirements Not Available
                            </h5>
                          </div>
                        )}
                      </>
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "15px 0px" }}>
                      <div className="d-flex w-100">
                        <SearchInput
                          setValue={setTitle}
                          lable={"Requirement Title"}
                        />
                        {/* <form className="ml-auto d-flex align-item-center">
                                                    <div className='col'>
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            sx={{ background: 'white', width: "250px" }}
                                                            options={requestTitleList}
                                                            value={title}
                                                            onChange={(e, selectedOptions) => handleSelectChallenges(selectedOptions)}
                                                            renderInput={(params) => <TextField {...params} label="Requirement Title" placeholder="Filter by Requirement Title" />}
                                                        />
                                                    </div>
                                                    {
                                                        (title !== '') && (
                                                            <div className='col text-center'>
                                                                <Button onClick={resetFiliter} variant="outlined" color="error" className='ml-3'>Reset</Button>
                                                            </div>
                                                        )
                                                    }
                                                </form> */}
                      </div>
                      {requirementListValue.length > 0 ? (
                        <div className="row mt-3 ml-2">
                          <div className="col-12">
                            <p className="showPage">
                              Showing Page <span>{page}</span> of{" "}
                              <span>{totalPage}</span>
                            </p>
                          </div>
                          {requirementListValue.map((list, index) => (
                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                              <div className="live_req_list">
                                <div className="text-center">
                                  <img
                                    src={
                                      list.requirementImageName
                                        ? list.requirementImageName
                                        : "/images/no-image-icon.png"
                                    }
                                    className="img-fluid req_img"
                                    alt=""
                                  />
                                </div>
                                <div className="mb-3">
                                  <Button
                                    className="challenges_pro_btn"
                                    onClick={() => {
                                      handleViewDetails(list.id, list.userId);
                                    }}
                                  >
                                    {list.requirementTitle}
                                  </Button>
                                </div>
                                <div className="mb-3">
                                  <p className="c-gray f-weight-700 mb-1">
                                    Ends On{" "}
                                    <span className="font-md c-origin f-weight-700">
                                      {list.endDate2 ? list.endDate2 : `-`}
                                    </span>
                                  </p>
                                  {(roleName === "GOVT" ||
                                    roleName === "Admin" ||
                                    roleName === "Project Management Team") && (
                                    <p className="f-weight-700 mb-1">
                                      <span className="c-gray">Status :</span>{" "}
                                      <span className="c-blue font-md f-weight-600">
                                        {list.pmtApproval === 0 && (
                                          <>Pending for Approval</>
                                        )}
                                      </span>
                                      <span className="c-green font-md f-weight-600">
                                        {list.pmtApproval === 1 && (
                                          <>Approved</>
                                        )}
                                      </span>
                                      <span className="c-origin font-md f-weight-600">
                                        {list.pmtApproval === 2 && (
                                          <>Rejected</>
                                        )}
                                      </span>
                                    </p>
                                  )}
                                </div>
                                <div className="d-flex">
                                  <div className="view_div">
                                    <Button
                                      className="view_live_btn"
                                      onClick={() => {
                                        handleViewDetails(list.id, list.userId);
                                      }}
                                    >
                                      {" "}
                                      View Details
                                    </Button>
                                  </div>
                                  <div className="ml-auto">
                                    <p className="c-gray mb-0 font-sm">
                                      Last updated
                                    </p>
                                    <p className="c-blue font-sm">
                                      {list.lastUpdated}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          {totalPage > 0 && (
                            <div className="col-12">
                              <Stack spacing={2}>
                                <Pagination
                                  count={totalPage}
                                  page={page}
                                  onChange={handleChange}
                                />
                              </Stack>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="no_data_div">
                          <img
                            src="/images/blue-logo.png"
                            alt=""
                            className="img-fluid mb-4"
                          />
                          <h5 className="no_data_h5">
                            Past Requirements Not Available
                          </h5>
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel value="3" sx={{ padding: "15px 0px" }}>
                      {/* <div className="d-flex justify-content-between w-100">
                                                <div>
                                                    <SearchinputDiv
                                                        sx={{ display: { xs: "none", sm: "block" } }}
                                                        onChange={(e) => handleSearch(e.target.value)}
                                                    >
                                                        <SearchIconWrapper>
                                                            <SearchIcon />
                                                        </SearchIconWrapper>
                                                        <StyledInputBase
                                                            placeholder="Search by Requirement Title"
                                                            type="search"
                                                            inputProps={{ "aria-label": "Search by Requirement Title" }}
                                                        />
                                                    </SearchinputDiv>
                                                </div>
                                             { (roleName !== 'Solution Provider' && requirementListValue.length>0) ?( 
                                                <LoadingButton className=''
                                                    // loading={loading}
                                                    // loadingPosition="start"
                                                    startIcon={<CloudDownloadIcon />}
                                                    variant="outlined"
                                                onClick={exportExcel}
                                                >
                                                    <span>EXPORT EXCEL</span>
                                                </LoadingButton>
                                            ):(<></>)}
                                            </div> */}
                      <SolutionApplicationList />
                    </TabPanel>
                  </Box>
                </TabContext>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default RequirementList;
